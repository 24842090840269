<template>
  <v-dialog v-model="dialog" hide-overlay max-width="800">
    <template #activator="props">
      <slot name="customBtn" v-bind="props" />

      <v-btn
        v-if="!$scopedSlots.customBtn"
        elevation="0"
        text
        color="info"
        fab
        x-small
        dark
        v-on="props.on"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary bolder" dark
        >{{ storeItem.id ? 'Edit' : 'Create' }} Store Item</v-toolbar
      >
      <v-form ref="itemform" @submit.prevent="saveItem">
        <v-card-text>
          <v-row>
            <v-col cols="12" class="my-0 py-0 d-flex no-gutters flex-wrap pa-6">
              <v-card
                elevation="1"
                width="60"
                height="60"
                class="rounded fill-height align-center justify-center d-flex"
              >
                <v-overlay v-show="picHovered" absolute
                  ><v-icon>upload</v-icon></v-overlay
                >
                <img
                  width="100%"
                  height="100%"
                  class="rounded"
                  :src="previewData || storeItem.image"
                />
              </v-card>
              <div class="text-h4">{{ storeItem.name }}</div>
              <v-col
                cols="12"
                class="my-0 py-0 d-flex align-center justify-space-between no-gutters py-3"
              >
                <v-col cols="auto">
                  <v-btn-toggle
                    v-model="storeItem.inquiry_only"
                    color="primary"
                    class=""
                  >
                    <v-btn :value="false" :disabled="!hasStripeAccount">
                      Purchase
                    </v-btn>
                    <v-btn :value="true"> Inquiry Only </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-switch
                  v-model="storeItem.active"
                  :label="storeItem.active ? 'Enabled' : 'Disabled'"
                  color="success"
                  class="ma-0"
                  hide-details
                />
              </v-col>
              <v-col class="pl-3" cols="12">
                <v-text-field
                  v-model="storeItem.name"
                  label="Name"
                  :rules="[required]"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <dvr-media-upload
                  label="Upload logo"
                  folder="store"
                  :value.sync="logo"
                />
              </v-col>
              <v-col v-if="!isGenericItem" cols="12" class="my-0 py-0">
                <v-select
                  v-model="storeItem.sub_topic.topic_id"
                  label="Topic"
                  :items="topicItems"
                  outlined
                  dense
                  item-text="display_name"
                  item-value="id"
                />
              </v-col>
              <v-col v-if="!isGenericItem" cols="12" class="my-0 py-0">
                <v-select
                  v-model="storeItem.sub_topic.id"
                  label="Sub Topic"
                  :items="subTopicItems"
                  outlined
                  dense
                  item-text="display_name"
                  item-value="id"
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-textarea
                  v-model="storeItem.description"
                  :rows="2"
                  auto-grow
                  outlined
                  dense
                  label="Description"
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-combobox
                  v-model="storeItem.bullets"
                  :items="bulletsItems"
                  chips
                  outlined
                  dense
                  multiple
                  label="Bullets"
                />
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-textarea
                  v-model="storeItem.disclaimer"
                  :rows="2"
                  auto-grow
                  outlined
                  hide-details
                  dense
                  label="Disclaimer"
                />
              </v-col>
              <v-col cols="12" class="pt-0 pb-8">
                <v-checkbox
                  v-model="storeItem.enforce_disclaimer"
                  label="Require confirmation of disclaimer"
                  class="mt-0"
                  hide-details
                  dense
                />
              </v-col>
              <v-col v-if="!isGenericItem" cols="12" class="my-0 py-0">
                <v-text-field
                  v-model="storeItem.duration"
                  type="number"
                  outlined
                  dense
                  label="Hours duration"
                />
              </v-col>
              <v-col v-if="!isGenericItem" cols="12" class="my-0 py-0">
                <dvr-media-upload
                  label="Add media"
                  :items="storeItem.media"
                  folder="store"
                  :value.sync="storeItem.media"
                />
                <gallery :images="storeItem.media || []" :thumbnails="true" />
              </v-col>
              <v-col cols="12">
                <catalog-quick-select
                  label="Purchase Task Catalog"
                  messages="Select a task to create when purchased"
                  :value="storeItem.listing_task_catalog_item_id"
                  :filter-change="catalogChange"
                />
              </v-col>
              <v-row
                v-if="ITEM_AVAILABILIES[storeItem.store_item_type]"
                class="ma-1"
              >
                <v-col cols="12" class="mt-4">
                  <div class="text-h5">Availability</div>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-range-slider
                    v-model="reversedHoursRange"
                    :min="0"
                    :max="maxHoursAhead"
                    dense
                    hide-details
                    class="mt-4"
                  >
                  </v-range-slider>
                  <span>{{
                    `Available starting ${formatHoursAhead} before ${
                      ITEM_AVAILABILIES[storeItem.store_item_type]
                    }${
                      storeItem.min_hours_ahead > 0
                        ? ` until ${formatMinHoursAhead} before ${
                            ITEM_AVAILABILIES[storeItem.store_item_type]
                          }`
                        : ` until ${
                            ITEM_AVAILABILIES[storeItem.store_item_type]
                          }`
                    }`
                  }}</span>
                </v-col>
              </v-row>
              <v-col cols="12">
                <div class="text-h5 my-3">Pricing</div>
                <div
                  class="d-flex align-baseline"
                  v-if="!storeItem.price_formula && !storeItem.fixed_price"
                >
                  <div
                    v-if="storeItem.store_item_type === 'EarlyCheckin'"
                    class="pa-3 light-grey rounded"
                  >
                    (reservation.adr / 2, listing.beds * 70).min
                  </div>
                  <div
                    v-else-if="storeItem.store_item_type === 'LateCheckout'"
                    class="pa-3 light-grey rounded"
                  >
                    (reservation.adr / 2, listing.beds * 60).min
                  </div>
                  <div
                    v-else-if="storeItem.store_item_type === 'ExtraCleaning'"
                    class="pa-3 light-grey rounded"
                  >
                    listing.cleaning_fee
                  </div>
                  <div
                    v-else-if="storeItem.store_item_type === 'LightCleaning'"
                    class="pa-3 light-grey rounded"
                  >
                    listing.cleaning_fee * 0.65
                  </div>
                  <div
                    v-else-if="storeItem.store_item_type === 'PoolHeater'"
                    class="pa-3 light-grey rounded"
                  >
                    reservation.nights_count * 30
                  </div>
                  <div
                    v-else-if="storeItem.store_item_type === 'GolfCart'"
                    class="pa-3 light-grey rounded"
                  >
                    reservation.nights_count * 50
                  </div>
                  <div
                    v-else-if="
                      ['ExtendBefore', 'ExtendAfter'].includes(
                        storeItem.store_item_type
                      )
                    "
                    class="pa-3 light-grey rounded"
                  >
                    listing.day_rate
                  </div>
                </div>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group v-model="storeItem.pricing_type" row>
                      <v-radio label="Fixed Price" value="fixed" />
                      <v-radio label="Dynamic Formula" value="formula" />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="storeItem.pricing_type === 'fixed'"
                  >
                    <v-text-field
                      v-model.number="storeItem.fixed_price"
                      label="Fixed Price"
                      :messages="
                        isGenericItem ||
                        '* Override the default pricing formula'
                      "
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12" v-if="storeItem.pricing_type === 'formula'">
                    <enhanced-formula-builder
                      v-model="storeItem.price_formula"
                      :variables="formulaVariables"
                      label="Price Formula"
                      :hint="'Define a dynamic pricing formula using available variables'"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="storeItem.max_quantity"
                      label="Max Quantity Per Order"
                      type="number"
                      min="1"
                      :rules="[required]"
                      outlined
                      dense
                      hint="Maximum number of items that can be ordered at once"
                      persistent-hint
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div class="text-h5 my-3">Finance</div>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  v-model="storeItem.investor_percent"
                  label="Investor percent"
                  hide-details
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  :value="1 - storeItem.investor_percent"
                  label="Management percent"
                  hide-details
                  disabled
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" class="my-0 pt-2">
                <div class="text-h5 my-3">Advanced Options</div>

                <multi-property-picker
                  v-model="storeItem.whitelist"
                  label="Whitelisted listings"
                  :hide-btns="true"
                />
                <multi-property-picker
                  v-model="storeItem.blacklist"
                  label="Blacklisted listings"
                  :hide-btns="true"
                />
                <v-row
                  v-if="
                    storeItem.store_item_type === 'EarlyCheckin' ||
                    storeItem.store_item_type === 'LateCheckout'
                  "
                  class="d-flex"
                >
                  <v-col cols="12">
                    <v-checkbox
                      v-model="storeItem.should_block"
                      :label="`If a purchase has been made, the day ${
                        storeItem.store_item_type === 'EarlyCheckin'
                          ? 'before'
                          : 'after'
                      } should be blocked`"
                      class="mt-0"
                      hide-details
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="storeItem.allow_on_checkinout"
                      label="Permit purchases for turnover stays (check-out and check-in on the same day)"
                      class="mt-0"
                      hide-details
                      dense
                    />
                  </v-col>
                </v-row>
                <v-list>
                  <v-list-item
                    v-for="item in storeItem.store_item_menus"
                    :key="item.name"
                    @click="editMenuItem(item)"
                  >
                    <v-list-item-avatar>
                      <v-img :src="item.main_image"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey"> mdi-pencil </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" outlined @click="close">Close</v-btn>
          <v-btn type="submit" color="primary">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import MultiPropertyPicker from '../multi-property-picker.vue'
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import CatalogQuickSelect from 'components/catalog-quick-select.vue'
import Gallery from 'components/common/gallery'
import EnhancedFormulaBuilder from '../common/enhanced-formula-builder.vue'
import { mapState } from 'vuex'
import { objectWithKey } from '@fortawesome/vue-fontawesome/src/utils'

const ITEM_AVAILABILIES = {
  EarlyCheckin: 'Check-in',
  LateCheckout: 'Check-out',
  ExtendBefore: 'Check-in',
  ExtendAfter: 'Check-out',
  GenericItem: 'Check-in',
}

export default {
  name: 'EditStoreItem',
  components: {
    Gallery,
    DvrMediaUpload,
    MultiPropertyPicker,
    CatalogQuickSelect,
    EnhancedFormulaBuilder,
  },
  mixins: [formRules, CommonFunctions],
  props: ['item'],
  data() {
    return {
      ITEM_AVAILABILIES,
      dialog: false,
      storeItem: this.initialStoreItem(),
      previewData: null,
      logo: [],
      json: {},
      maxHoursAhead: 336,
      selectedItem: null,
      images: [],
      showMenuDialog: false,
      picHovered: false,
      formulaVariables: [
        {
          value: 'adr',
          category: 'Reservation',
          description: 'Average Daily Rate',
        },
        {
          value: 'nights_count',
          category: 'Reservation',
          description: 'Number of Nights',
        },
        {
          value: 'guests_count',
          category: 'Reservation',
          description: 'Number of Guests',
        },
        {
          value: 'fare_accommodation',
          category: 'Reservation',
          description: 'The nightly rate applied to the reservation',
        },
        {
          value: 'bedrooms',
          category: 'Listing',
          description: 'Number of Bedrooms',
        },
        { value: 'beds', category: 'Listing', description: 'Number of Beds' },
        {
          value: 'cleaning_fee',
          category: 'Listing',
          description: 'Cleaning Fee',
        },
        { value: 'day_rate', category: 'Listing', description: 'Daily Rate' },
      ],
    }
  },
  computed: {
    ...mapState('stripe', ['accounts']),
    hasStripeAccount() {
      return this.accounts.length > 0
    },
    formatHoursAhead() {
      return this.formatHours(this.storeItem.max_hours_ahead)
    },
    formatMinHoursAhead() {
      return this.formatHours(this.storeItem.min_hours_ahead)
    },
    topicItems() {
      return this.$store.getters.topicsList
    },
    subTopicItems() {
      return this.$store.getters.subTopicsList
    },
    bulletsItems() {
      return this.storeItem.bullets || []
    },
    isGenericItem() {
      return this.storeItem.store_item_type === 'GenericItem'
    },
    reversedHoursRange: {
      get() {
        return [
          this.maxHoursAhead - this.storeItem.max_hours_ahead,
          this.maxHoursAhead - this.storeItem.min_hours_ahead,
        ]
      },
      set(value) {
        this.storeItem.max_hours_ahead = this.maxHoursAhead - value[0]
        this.storeItem.min_hours_ahead = this.maxHoursAhead - value[1]
      },
    },
  },
  mounted() {
    if (ITEM_AVAILABILIES[this.storeItem.store_item_type]) {
      this.storeItem.max_hours_ahead ||= 240
      this.storeItem.min_hours_ahead ||= 2
    }
  },
  methods: {
    objectWithKey,
    formatHours(hours) {
      if (hours >= 24) {
        const days = hours / 24
        return days % 1 === 0
          ? `${days} days`
          : `${Math.round(days * 10) / 10} days`
      }
      return `${hours} hours`
    },
    initialStoreItem() {
      return {
        ...(this.item || {
          sub_topic: {},
          inquiry_only: true,
          store_item_type: 'GenericItem',
          pricing_type: 'fixed',
          price_formula: '',
        }),
      }
    },
    editMenuItem(item) {
      this.$store.commit('showModal', {
        name: 'EditStoreMenuItem',
        props: {
          menuItem: item,
          itemId: this.item.id,
        },
        isPersistent: false,
      })
    },
    addMenuItem() {
      this.$store.commit('showModal', {
        name: 'EditStoreMenuItem',
        props: {
          menuItem: {},
          itemId: this.item.id,
        },
        isPersistent: false,
      })
    },
    close() {
      this.dialog = false
      this.storeItem = this.initialStoreItem()
    },
    saveItem(e) {
      if (!this.$refs.itemform.validate()) return
      if (!this.storeItem.id) return this.addItem(e)
      e.preventDefault()
      this.$store.dispatch('updateStoreItem', {
        ...this.storeItem,
        image: this.logo[0],
        blacklist: this.storeItem.blacklist,
        whitelist: this.storeItem.whitelist,
      })
      this.dialog = false
    },
    addItem(e) {
      e.preventDefault()
      this.$store.dispatch('createStoreItem', {
        ...this.storeItem,
      })
      this.dialog = false
    },
    catalogChange({ id }) {
      this.storeItem.listing_task_catalog_item_id = id
    },
  },
}
</script>

<style scoped>
.upload-box {
  border: 1px solid grey;
}
</style>
