<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <div class="mb-4 text-h5 font-weight-bold">Reservations</div>
      <form-dialog
        max-width="500"
        title="Import reservations from file"
        @submit="submit"
      >
        <template #activator="{ on }">
          <v-btn color="success" small v-on="on">
            <v-icon small left>fas fa-table</v-icon>Import Reservations</v-btn
          >
        </template>
        <v-file-input
          v-model="file"
          accept=".xlsx,.csv,.xls"
          label="Excel sheet"
          outlined
          dense
          hide-details
        >
        </v-file-input>
        <template>
          Click
          <a
            class="info--text text-decoration-underline"
            @click.prevent="downloadCsv"
            >here</a
          >
          to download a reservations template
        </template>
        <v-checkbox
          v-model="stopAutomations"
          label="Stop Automations"
        ></v-checkbox>
      </form-dialog>
      <form-dialog
        title="Please pick a date from to update all reservations"
        max-width="500"
        @submit="updateAllReservations"
      >
        <template #activator="{ on }">
          <v-btn color="info" small class="mx-2" v-on="on">
            {{ $t('Update reservations by date') }}</v-btn
          >
        </template>
        <date-picker v-model="from" :label="$t('Check-in from')" />
      </form-dialog>
    </v-card>
  </v-sheet>
</template>

<script>
import FormDialog from 'components/common/form-dialog.vue'
import axios from '@/axios/config'
import DatePicker from 'components/form-fields/date-picker.vue'
import CommonFunctions from 'components/mixins/common_functions'
export default {
  mixins: [CommonFunctions],
  components: {
    DatePicker,
    FormDialog,
  },
  props: {
    tenant: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pms: null,
      file: null,
      from: null,
      pmsList: [
        'guesty',
        'hostaway',
        'avantio',
        'kross',
        'lodgify',
        'track',
        'ownerez',
      ],
      stopAutomations: true,
    }
  },
  methods: {
    async updateAllReservations() {
      try {
        await axios.post(`/api/sync-reservations`, { from: this.from })
      } catch (error) {
        console.error(error)
      }
    },
    submit() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('tenant_id', this.tenant)
      formData.append('stop_automations', this.stopAutomations)
      axios
        .post('/api/reservations/pms-import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {})
    },
    downloadCsv() {
      const rows = [
        [
          'nickname of the property',
          'A873FA95-4F07-E89F-897E-3D44D20D8AF7',
          'test@gmail.com',
          '12345678',
          'Claire Farbos',
          'Airbnb',
          'confirmed',
          '2024-01-01',
          '0',
          '400',
          '350',
          '50',
          '2024-01-01',
          '2024-01-02',
          '0',
          '0',
          '0',
        ],
      ]
      const headers = [
        'Property',
        'Booking #',
        'Email Address',
        'Phone Number',
        'Guest Full name',
        'Source',
        'Status',
        'Booked at',
        'Balance Due',
        'Total Paid',
        'Accommodation fare',
        'Cleaning',
        'Arrival',
        'Departure',
        'Total Taxes',
        'Total Fees',
      ]
      rows.unshift(headers)
      this.autoDownloadLocalCsv(rows, 'reservations_upload_template')
    },
  },
}
</script>

<style></style>
