import { render, staticRenderFns } from "./tax-profile-edit-modal.vue?vue&type=template&id=ca035f10&scoped=true&"
import script from "./tax-profile-edit-modal.vue?vue&type=script&lang=js&"
export * from "./tax-profile-edit-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca035f10",
  null
  
)

export default component.exports