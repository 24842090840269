<template>
  <v-card class="pb-4">
    <conversation-drawer @on-close="fetchReservations" />
    <v-divider />
    <v-data-table
      :options="pagination"
      :server-items-length="pagination.reservations_count"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      :loading="loading"
      :headers="headers"
      :items="items"
      @pagination="updatePagination"
    >
      <template #item.guest="{ item }">
        <a
          v-if="item.conversation && !isAgent"
          @click="
            openChat(item.conversation.guesty_id, item.reservation_guesty_id)
          "
          >{{ item.guest ? item.guest.full_name : '' }}</a
        >
        <div v-else>{{ item.guest ? item.guest.full_name : '' }}</div>
      </template>
      <template #item.guest_status="{ item: reservation }">
        <v-select
          v-model="reservation.guest_status"
          :items="[
            {
              text: 'Checked In',
              value: 'Checked In',
              icon: 'mdi-login-variant',
            },
            {
              text: 'Checked Out',
              value: 'Checked Out',
              icon: 'mdi-logout-variant',
            },
          ]"
          item-text="text"
          item-value="value"
          dense
          hide-details
          class="status-select"
          @change="
            updateReservation({ id: reservation.id, guest_status: $event })
          "
        >
          <template #selection="{ item: status }">
            <v-icon :color="getStatusColor(status.value)" small class="mr-2">
              {{ status.icon }}
            </v-icon>
            {{ status.text }}
          </template>
          <template #item="{ item: status }">
            <v-icon :color="getStatusColor(status.value)" small class="mr-2">
              {{ status.icon }}
            </v-icon>
            {{ status.text }}
          </template>
        </v-select>
      </template>
      <template #item.review_status="{ item }">
        <v-select
          v-model="item.review_status"
          class="grey-text"
          :items="['New', 'In Progress', 'Done', 'Prevented', 'No Action']"
          @change="updateStatus($event, item)"
        />
      </template>
      <template #item.dvr_notes="{ item }">
        <v-textarea
          v-model="item.dvr_notes"
          class="min-w-150"
          placeholder="notes..."
          rows="1"
          @change="updateReservation({ id: item.id, dvr_notes: $event })"
        />
      </template>
      <template #item.nickname="{ item }">
        <div class="min-w-150">
          {{ item.listing.nickname }}
        </div>
      </template>
      <template #item.confirmation_code="{ item }">
        <div v-if="isAgent">{{ item.confirmation_code }}</div>
        <router-link
          v-else
          target="_blank"
          :to="`/dashboard/reservation/${item.reservation_guesty_id}`"
        >
          {{ item.confirmation_code }}
        </router-link>
      </template>
      <template #item.check_out="{ item }">
        {{ parseDate(item.check_out) }}
      </template>
      <template #item.checkup="{ item }">
        <v-btn
          v-if="checkup(item)"
          :class="scoreClass(checkup(item).normilized_scrore_overall)"
          @click="showReviewModal(checkup(item))"
        >
          {{ round(checkup(item).normilized_scrore_overall, 1) / 2 }}
        </v-btn>
      </template>
      <template #item.survey="{ item }">
        <v-btn
          v-if="survey(item)"
          :class="scoreClass(survey(item).normilized_scrore_overall)"
          @click="showReviewModal(survey(item))"
        >
          {{ round(survey(item).normilized_scrore_overall, 1) / 2 }}
        </v-btn>
      </template>
      <template #item.reviews="{ item }">
        <v-btn
          v-if="otaReview(item)"
          :class="scoreClass(otaReview(item).normilized_scrore_overall)"
          @click="showReviewModal(otaReview(item))"
        >
          {{ round(otaReview(item).normilized_scrore_overall, 1) / 2 }}
        </v-btn>
      </template>
      <template #item.day_passed="{ item }">
        {{ howFarBack(item.check_out) }}
      </template>
      <template #item.messages="{ item }">
        <span v-if="item.res_stats">
          {{ item.res_stats.messages }}
        </span>
      </template>
      <template #item.sc_reported="{ item }">
        <span v-if="item.res_stats">
          {{ item.res_stats.sc_reported.length }}
        </span>
      </template>
      <template #item.sc_done="{ item }">
        <span v-if="item.res_stats">
          {{ item.res_stats.manual_sc_performed.length }}
        </span>
      </template>
      <template #item.actions="{ item }">
        <div class="min-w-150">
          <v-btn small icon @click="overrideReservation(item)"
            ><v-icon small>$edit</v-icon></v-btn
          >
          <v-btn
            v-if="isAdmin && (isMismatch || item.balance_due)"
            x-small
            icon
            class="my-2"
            @click="markAspaid(item.id)"
          >
            <v-icon color="success">money</v-icon>
          </v-btn>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="item.confirmation_code && isAdmin"
                v-bind="attrs"
                x-small
                icon
                class="my-2 ml-2"
                v-on="on"
                @click="markUnpaid(item.id)"
              >
                <v-icon color="warning">money</v-icon>
              </v-btn>
            </template>
            <div>Permanent Delete</div>
          </v-tooltip>
        </div>
      </template>
      <template #item.reconciled="{ item }">
        <v-icon v-if="item.bank_transaction_id" color="success"
          >done_all
        </v-icon>
        <div v-else>
          <v-icon v-if="!item.manual_dismiss">close</v-icon>
          <dismiss-reason-modal
            :dismissed="item.manual_dismiss"
            :item="item"
            type="Reservation"
            @after-action="onDismiss"
          />
        </div>
      </template>
      <template #item.reconciled_amount="{ item }">
        {{ item.reconciled.toLocaleString('en') }}
      </template>
      <template #item.owner_paid_at="{ item }">
        {{ item.owner_paid_at ? parseDate(item.owner_paid_at) : '--' }}
      </template>
      <template #item.price="{ item }">
        {{ toMoney(item.price) }}
      </template>
      <template #item.client_price="{ item }">
        {{ toMoney(item.client_price || calcClientPrice(item)) }}
      </template>
      <template #item.balance="{ item }">
        <div class="min-w-150 align-center">
          <v-icon
            v-if="item.confirmation_code"
            small
            :class="
              item.is_fully_paid ||
              item.marked_as_paid ||
              round(item.balance_due, 2) === 0
                ? 'green'
                : 'red'
            "
            style="border-radius: 50%; color: white !important"
            class="mr-2 font-light"
            >attach_money
          </v-icon>
          <span
            v-if="round(item.balance_due, 2) !== 0"
            class="bolder cyan-icon"
            >{{ round(item.balance_due, 2) }}</span
          >
        </div>
      </template>
      <template #item.link="{ item }">
        <router-link
          target="_blank"
          :to="`/dashboard/reservation/${item.reservation_guesty_id}`"
        >
          Res
        </router-link>
      </template>
      <template #item.chat_link="{ item }">
        <a
          v-if="item.conversation && item.conversation.guesty_id"
          target="_blank"
          class="purple-icon"
          :href="`${origin}/dashboard/sales-chat/${item.conversation.guesty_id}`"
          >Chat
        </a>
      </template>

      <template #item.merchant="{ item }">
        <div v-if="item.is_merchant && !isInvestor" mt-2>
          <tag
            color="primary"
            :inverted="true"
            size="xs"
            :class="['d-inline-block ml-1']"
          >
            Merchant
          </tag>
        </div>
      </template>
      <template #item.channel="{ item }">
        <div>
          <!--          <v-icon :title="item.source"-->
          <!--            >${{ item.source.toLowerCase().replace(/[. ]/g, '') }}-->
          <!--          </v-icon>-->
          <div class="text-caption">{{ sourceName(item.source) }}</div>
        </div>
      </template>
      <template #item.account="{ item }">
        <span v-if="item.integration">{{ item.integration.nickname }}</span>
      </template>
      <template #item.check_in="{ item }">
        <div>{{ checkInDate(item) }}</div>
        <div
          v-if="formatPlanned(item, 'requested_check_in_time')"
          class="text-caption secondary--text mt-1"
        >
          (Planned: {{ formatPlanned(item, 'requested_check_in_time') }})
        </div>
      </template>
      <template #item.check_out="{ item }">
        <div>{{ checkOutDate(item) }}</div>
        <div
          v-if="formatPlanned(item, 'requested_check_out_time')"
          class="text-caption secondary--text mt-1"
        >
          (Planned: {{ formatPlanned(item, 'requested_check_out_time') }})
        </div>
      </template>
      <template #item.client_profit="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <span v-if="item.ratio && item.ratio !== 1">
                {{ toMoney(item.ratio * net(item)) }}
                <br />
                <span class="purple-icon"> of</span>
              </span>
            </span>
          </template>
          This reservations is spread on two months, %{{
            round(item.ratio * 100)
          }}
          will be counted for the current month.
        </v-tooltip>
        {{ toMoney(item.client_profit) }}
      </template>
      <template #item.cleaning_fee="{ item }">
        {{ toMoney(item.cleaning_fee) }}
        <span
          v-if="
            item.actual_cleaning_paid && isAdmin && cleaningDiff(item) !== 0
          "
          class="ml-2"
          :class="
            cleaningDiff(item) && cleaningDiff(item) > 0
              ? 'green-health'
              : 'red-health'
          "
          >({{ round(cleaningDiff(item)) }})</span
        >
      </template>
      <template #item.management_commission="{ item }">
        {{ toMoney(item.management_commission) }}
      </template>
      <template #item.processing_fee="{ item }">
        {{ toMoney(item.processing_fee) }}
      </template>
      <template #item.host_channel_fee="{ item }">
        {{ toMoney(item.host_channel_fee) }}
      </template>
      <template #item.fare_accommodation="{ item }">
        {{ toMoney(item.fare_accommodation) }}
      </template>
      <template #item.total_taxes="{ item }">
        {{ toMoney(item.total_taxes) }}
      </template>
      <template #item.net_income="{ item }">
        {{ toMoney(item.net_income) }}
      </template>
      <template #item.pms_unsync="{ item }">
        <v-icon :color="item.pms_unsync ? 'warning' : 'success'">{{
          item.pms_unsync ? 'fas fa-times' : 'fas fa-check'
        }}</v-icon>
      </template>
      <template #item.status="{ item }">
        {{ item.status }}
      </template>
      <template #item.pre_check_in_filled="{ item }">
        <v-icon
          :color="
            item.pre_check_in && item.pre_check_in.step > 2
              ? 'success'
              : 'warning'
          "
          >{{
            item.pre_check_in && item.pre_check_in.step > 2
              ? 'fas fa-check'
              : 'fas fa-times'
          }}</v-icon
        >
        <span class="mx-2">
          <CopyClipboard :text="preCheckInLink(item)" />
        </span>
      </template>
      <template #item.verification_status="{ item }">
        <div>
          <v-icon
            v-if="item.guest.verification_status === 'Confirmed'"
            small
            class="success mx-auto rounded-circle font-light mx-1"
            style="color: white !important"
          >
            $success
          </v-icon>

          <v-icon
            v-else-if="!!item.guest.verification_status"
            small
            class="error mx-auto rounded-circle font-light mx-1"
            style="color: white !important"
            v-on="on"
            @click="handleVerification(item)"
          >
            $info
          </v-icon>

          <span v-else>{{ $t('Not used') }}</span>

          <v-dialog
            v-model="verificationDetailsDialog"
            max-width="500px"
            @input="verificationDetails = null"
          >
            <v-card>
              <v-card-title>{{
                $t('Verification status details')
              }}</v-card-title>
              <v-card-text>
                <pre>{{ verificationDetails }}</pre>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <template #item.guest_details="{ item }">
        <div v-if="item.source === 'Owner'">Owner</div>
        <div v-else-if="item.guest">
          <div>{{ item.guest.full_name }}</div>
          <div>{{ item.guest.primary_email }}</div>
          <div>{{ item.guest.primary_phone }}</div>
        </div>
      </template>
      <template #item.identity="{ item }">
        <gallery
          v-if="item.pre_check_in && item.pre_check_in.id_pic"
          :images="[item.pre_check_in.id_pic]"
          :thumbnails="false"
          :uploadable="false"
          icon="mdi-account-outline"
        />
      </template>
      <template #item.additional_doc="{ item }">
        <gallery
          v-if="item.pre_check_in && item.pre_check_in.additional_doc"
          :images="[item.pre_check_in.additional_doc]"
          :thumbnails="false"
          :uploadable="false"
          icon="mdi-account-outline"
        />
      </template>
      <template #item.special_requests="{ item }">
        <div v-if="hasRequests(item)">
          <div
            v-for="(req, ind) in Object.entries(item.special_requests)"
            :key="ind"
          >
            <div v-if="req[1]">
              <span class="font-weight-medium text-capitalize">{{
                req[0]
              }}</span>
            </div>
          </div>
        </div>
        <div v-else>No requests</div>
      </template>

      <template #item.nights_count="{ item }">
        {{ item.nights_count }}
      </template>
      <template #item.payment_intents="{ item }">
        <span v-for="intent in item.payment_intents" :key="intent.id">
          <a
            v-if="
              item.status !== 'succeeded' &&
              ['deposit', 'accommodation'].includes(intent.metadata.type)
            "
            target="_blank"
            class="text-capitalize me-1"
            :href="`${portalDomain}/checkout/${intent.payment_intent_id}`"
            >{{ intent.metadata.type }}</a
          >
        </span>
      </template>
      <template #item.payment_confirmation="{ item }">
        {{ item.confirmation_code }}
        <a
          v-if="item.source === 'Airbnb'"
          target="_blank"
          :href="
            'https://airbnb.com/hosting/reservations/details/' +
            item.confirmation_code
          "
          >Airbnb link</a
        >
        <div>
          <div
            v-for="confCode in item.payment_confirmation_codes"
            :key="confCode"
          >
            <a
              v-if="confCode"
              class="pointer"
              target="_blank"
              :href="`https://dashboard.stripe.com/payments/${confCode}`"
              >{{ truncateBy(confCode, 7) }}</a
            >
          </div>
        </div>
      </template>
      <template #item.is_fully_paid="{ item }">
        <div>
          <v-tooltip v-if="item.should_pay_deposit" top>
            <template #activator="{ on }">
              <v-icon small class="mx-1" color="error" v-on="on">
                mdi-credit-card
              </v-icon>
            </template>
            <div>Unpaid Deposit</div>
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                :class="[
                  round(item.balance_due, 2) === 0
                    ? 'success mx-auto'
                    : 'error',
                  'rounded-circle font-light mx-1',
                ]"
                style="color: white !important"
                v-on="on"
              >
                attach_money
              </v-icon>
            </template>
            <div v-if="round(item.balance_due, 2) === 0">Reservation Paid</div>
            <div v-else>
              <div>Balance Due</div>
              <div class="font-weight-bold text-center">
                {{ toMoney(item.balance_due, { decimals: 0 }) }}
              </div>
            </div>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import round from 'lodash/round'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DismissReasonModal from 'components/dismiss-reason-modal'
import { mapActions, mapState } from 'vuex'
import CopyClipboard from 'components/copy-clipboard'
import ConversationDrawer from 'components/crm/conversation-drawer.vue'
import Gallery from 'components/common/gallery.vue'
import Tag from 'components/common/tag.vue'
import { get } from 'lodash'

export default {
  components: {
    Tag,
    Gallery,
    CopyClipboard,
    DismissReasonModal,
    ConversationDrawer,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['headers', 'guestReservations', 'isMismatch'],
  data() {
    return {
      verificationDetailsDialog: false,
      verificationDetails: null,
    }
  },
  methods: {
    ...mapActions('reservation', [
      'updateReservation',
      'fetchVerificationDetails',
    ]),
    getStatusColor(status) {
      switch (status) {
        case 'Checked In':
          return 'success'
        case 'Checked Out':
          return 'info'
        default:
          return 'grey'
      }
    },
    async handleVerification(item) {
      this.verificationDetailsDialog = !this.verificationDetailsDialog
      try {
        const statuses = await this.fetchVerificationDetails({
          reservation_id: item.id,
        })
        this.verificationDetails = statuses
      } catch (error) {
        console.error('Error fetching verification details:', error)
      }
    },
    hasRequests(item) {
      return Object.values(item.special_requests).filter(Boolean).length > 0
    },
    calcClientPrice(item) {
      if (item.source === 'Airbnb') {
        return item.price + item.host_channel_fee
      }
      return item.price
    },
    showReviewModal(item) {
      this.$store.commit('showModal', {
        name: 'ReviewCard',
        props: {
          review: item,
        },
        isPersistent: false,
      })
    },
    scoreClass(score) {
      if (score >= 8) {
        return 'green--text lighten-2'
      }
      if (score >= 5) {
        return 'yellow--text accent-4'
      }
      if (score < 5) {
        return 'red--text lighten-2'
      }
    },
    updatePagination({ page, itemsPerPage }) {
      if (
        this.pagination.page !== page ||
        this.pagination.per_page !== itemsPerPage
      ) {
        this.$emit('pagination', { page, itemsPerPage })
      }
    },
    cleaningDiff(item) {
      if (item.actual_cleaning_paid && this.settings.enable_actual_cleaning) {
        return item.cleaning_fee - item.actual_cleaning_paid
      } else {
        return 0
      }
    },
    updateStatus($event, item) {
      this.updateReservation({ id: item.id, review_status: $event })
      setTimeout(() => {
        this.$store.dispatch(
          'getManagedReservations',
          this.$store.state.lastReservationsFilter
        )
      }, 300)
    },
    openChat(conversation, reservation) {
      this.$router.push({ query: { conversation, reservation } })
    },
    howFarBack(date) {
      return this.$moment().utc().diff(this.$moment.utc(date), 'days')
    },
    markAspaid(reservationID) {
      const userConfirm = confirm(
        'Are you sure you want to mark this reservation as paid?'
      )
      if (userConfirm) {
        this.$store.dispatch('markAsPaid', reservationID).then(({ data }) => {
          if (data && data.success) {
            this.$store.dispatch(
              'getManagedReservations',
              this.$store.state.lastReservationsFilter
            )
          }
        })
      }
    },
    markUnpaid(reservationID) {
      const userConfirm = confirm(
        'Are you sure you want to Permanent Delete this reservation?'
      )
      if (userConfirm) {
        this.$store.dispatch('markUnPaid', reservationID)
      }
    },
    fetchReservations() {
      this.$emit('refetch')
    },
    overrideReservation(reservation) {
      this.$store.commit('showModal', {
        name: 'ReservationOverrideModal',
        props: {
          reservation,
          onSuccess: this.fetchReservations,
        },
      })
    },
    net(reservation) {
      return (
        reservation.fare_accommodation -
        reservation.management_commission -
        reservation.host_channel_fee -
        reservation.processing_fee
      )
    },
    sourceColor(channel) {
      if (channel === 'Airbnb') {
        return 'airbnb-color'
      } else if (channel === 'Booking.com') {
        return 'booking-color'
      } else if (channel === 'HomeAway') {
        return 'homeaway-color'
      } else if (channel === 'Designedvr.com') {
        return 'designedvr-color'
      } else if (channel === 'Website') {
        return 'designedvr-color'
      } else if (channel === 'Expedia') {
        return 'expedia-color'
      }
    },
    onDismiss() {
      this.$store.dispatch(
        'getManagedReservations',
        this.$store.state.lastReservationsFilter
      )
    },
    adr(prop) {
      return round(prop.fare_accommodation / prop.nights_count)
    },
    round(input) {
      return round(input, 2)
    },
    preCheckInLink(item) {
      return `${this.portalDomain}/tourist/trips/${item.confirmation_code}`
    },
    checkup(item) {
      return item.reviews.filter(review => review.source === 'checkup')[0]
    },
    survey(item) {
      return item.reviews.filter(review => review.source === 'survey')[0]
    },
    otaReview(item) {
      return item.reviews.filter(
        review => review.source !== 'survey' && review.source !== 'checkup'
      )[0]
    },
    formatPlanned(item, key) {
      return get(item, `pre_check_in.times_info.${key}`)
    },
    checkInDate(reservation) {
      return this.parseDateTZ(
        reservation.check_in_time,
        'YYYY-MM-DD',
        reservation.listing.timezone
      )
    },
    checkOutDate(reservation) {
      return this.parseDateTZ(
        reservation.check_out_time,
        'YYYY-MM-DD',
        reservation.listing.timezone
      )
    },
  },
  computed: {
    ...mapState({
      pagination: state => state.reservationsPagination,
    }),
    settings() {
      return this.$store.state.app.configuration
    },
    portalDomain() {
      return 'https://guest.boomnow.com'
    },
    items() {
      return this.$store.state.managedReservations
    },
    loading() {
      return this.$store.state.conversationLoading
    },
  },
}
</script>
<style scoped>
.gap-3 {
  gap: 3px;
}

.status-select {
  min-width: 200px;
  max-width: 200px;

  ::v-deep .v-select__selection {
    display: flex;
    align-items: center;
  }

  ::v-deep .v-input__slot {
    box-shadow: none !important;
    margin-bottom: 0;
  }

  ::v-deep .v-input__slot:before {
    border-color: transparent !important;
  }

  ::v-deep .v-input__slot:after {
    border-color: transparent !important;
  }
}
</style>
