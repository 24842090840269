<template>
  <v-card elevation="2" class="d-flex flex-column fill-height">
    <v-card-title class="d-flex flex-nowrap">
      <v-chip label :color="`${color} lighten-2`">
        <v-icon :color="`${color} darken-3`">{{ icon }}</v-icon>
      </v-chip>
      <div class="ml-3 flex-fill">
        <text-resize>{{ title }}</text-resize>
      </div>
      <v-btn
        v-if="showImages"
        :color="hasImages ? 'primary' : 'grey lighten-1'"
        icon
        small
        @click="$emit('open-images')"
      >
        <v-icon>mdi-file-image-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="d-flex flex-column flex-fill">
      <v-spacer class="flex-fill" />
      <v-text-field
        v-if="initial !== undefined"
        label="Initial Proposal"
        prepend-inner-icon="mdi-currency-usd"
        outlined
        dense
        hide-details
        :readonly="readonly"
        type="number"
        :rules="[nonNegative]"
        :value="initial"
        class="mb-3"
        @change="emitChange('update:initial', $event)"
      />
      <v-text-field
        v-if="final !== undefined"
        label="Final Proposal"
        prepend-inner-icon="mdi-currency-usd"
        outlined
        dense
        hide-details
        :readonly="readonly"
        type="number"
        :rules="[nonNegative]"
        :value="final"
        class="mb-3"
        @change="emitChange('update:final', $event)"
      />
      <v-text-field
        v-if="dvrCost !== undefined"
        label="DVR Cost"
        prepend-inner-icon="mdi-currency-usd"
        outlined
        dense
        hide-details
        :readonly="readonly"
        type="number"
        :rules="[nonNegative]"
        :value="dvrCost"
        class="mb-3"
        @change="emitChange('update:dvr-cost', $event)"
      />
      <div class="d-flex text-no-wrap align-center">
        <span>So far:</span>
        <v-spacer />
        <span class="mx-2 font-weight-light">({{ count }} expenses)</span>
        <v-btn
          :color="orderedExpenses.length ? 'primary' : 'grey lighten-1'"
          icon
          x-small
          :disabled="!orderedExpenses.length"
          @click="dialog = true"
        >
          <v-icon small>mdi-finance</v-icon>
        </v-btn>
      </div>
      <div
        :class="[
          'text-h5 text-center text mt-2',
          sum <= final ? 'success--text' : 'error--text',
        ]"
      >
        {{ toMoney(sum) }}
      </div>
    </v-card-text>

    <v-dialog v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-card-title class="text-h6 pr-3">
          All {{ title }} Expenses
          <v-spacer />
          <v-btn icon @click="closeDialog">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />

        <v-card-text class="pb-0">
          <v-row class="text-title-1">
            <v-col
              cols="2"
              :class="[
                'pointer',
                { bolder: expensesOrder.key === 'related_division' },
              ]"
              @click="setExpensesOrder('related_division')"
              >Division
            </v-col>
            <v-col
              cols="3"
              :class="[
                'pointer',
                { bolder: expensesOrder.key === 'short_reason' },
              ]"
              @click="setExpensesOrder('short_reason')"
              >Reason
            </v-col>
            <v-col
              cols="2"
              :class="['pointer', { bolder: expensesOrder.key === 'amount' }]"
              @click="setExpensesOrder('amount')"
              >Price
            </v-col>
            <v-col
              cols="2"
              :class="['pointer', { bolder: expensesOrder.key === 'paid_at' }]"
              @click="setExpensesOrder('paid_at')"
              >Paid At
            </v-col>
            <v-col
              cols="2"
              :class="[
                'pointer',
                { bolder: expensesOrder.key === 'validated_by_id' },
              ]"
              @click="setExpensesOrder('validated_by_id')"
              >Validated
            </v-col>
            <v-col cols="1">Action</v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pb-0">
          <v-list dense>
            <div v-for="(expense, index) in orderedExpenses" :key="index">
              <v-row>
                <v-col cols="2">
                  {{ expense.related_division }}
                </v-col>
                <v-col cols="3">
                  {{ expense.short_reason }}
                </v-col>
                <v-col cols="2">
                  {{ toMoney(expense.amount) }}
                </v-col>
                <v-col cols="2">
                  {{ parseDate(expense.paid_at) }}
                </v-col>
                <v-col cols="2">
                  <v-tooltip v-if="expense.validated_by" bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" color="primary" v-on="on">
                        mdi-check-all
                      </v-icon>
                    </template>
                    <div>
                      validated by
                      {{ expense.validated_by.first_name }}
                      {{ expense.validated_by.last_name }}
                    </div>
                  </v-tooltip>
                  <v-icon v-else color="secondary">mdi-minus</v-icon>
                </v-col>
                <v-col cols="1">
                  <simple-menu
                    :items="getActionMenuItems(expense)"
                    :position="{ left: true, offsetY: true }"
                    @action="onExpenseAction(expense, $event)"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="primary">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                  </simple-menu>
                </v-col>
              </v-row>
              <v-divider v-if="index < orderedExpenses.length - 1" />
            </div>
          </v-list>
        </v-card-text>
        <ConfirmationModal
          ref="ConfirmationModal"
          text="Is this expense valid and approved by you?"
          @action="validateSelectedExpense"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import formRules from 'components/mixins/form-rules-mixin'
import TextResize from 'components/common/text-resize'
import { orderBy } from 'lodash'
import SimpleMenu from 'components/common/simple-menu'
import ConfirmationModal from 'components/modals/confirmation-modal'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ListingBudgetCard',
  components: { ConfirmationModal, SimpleMenu, TextResize },
  mixins: [CommonFunctions, PermissionsMixin, formRules, FormattersMixin],
  props: [
    'title',
    'param',
    'icon',
    'color',
    'initial',
    'final',
    'dvrCost',
    'count',
    'sum',
    'expenses',
    'readonly',
    'hasImages',
  ],
  data() {
    return {
      dialog: false,
      showImagesFor: [
        'construction',
        'design',
        'finance',
        'landscape',
        'exterior',
        'other',
      ],
      expensesOrder: {
        key: 'id',
        order: 'asc',
      },
      selected: null,
    }
  },
  computed: {
    showImages() {
      return this.showImagesFor.includes(this.param)
    },
    orderedExpenses() {
      return orderBy(
        this.expenses,
        this.expensesOrder.key,
        this.expensesOrder.order
      )
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    emitChange(field, event) {
      let value = 0
      try {
        value = parseInt(event) || 0
      } catch (e) {
        // ignore
      }
      this.$emit(field, value)
    },
    setExpensesOrder(key) {
      let order = 'asc'
      if (this.expensesOrder.key === key) {
        order = this.expensesOrder.order === 'asc' ? 'desc' : 'asc'
      }
      this.expensesOrder = { key, order }
    },
    openExpenseModal(expense) {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: { expense },
      })
    },
    getActionMenuItems(expense) {
      return [
        {
          title: 'Open',
          icon: 'mdi-open-in-new',
          actionKey: 'open',
        },
        {
          title: expense.validated_by_id ? 'Validated' : 'Validate',
          icon: expense.validated_by_id
            ? 'mdi-check-all'
            : 'mdi-check-circle-outline',
          actionKey: 'validate',
          disabled: !!expense.validated_by_id || !this.isAdmin,
        },
        {
          title: 'Change Division To',
          icon: 'mdi-google-circles-communities',
          disabled: !!expense.validated_by_id || !this.isAdmin,
          items: this.budgetDivisions
            .filter(d => d !== expense.related_division)
            .map(d => ({
              title: d,
              actionKey: 'change-division:' + d,
            })),
        },
      ]
    },
    onExpenseAction(expense, actionKey) {
      if (actionKey === 'open') {
        this.openExpenseModal(expense)
      } else if (actionKey === 'validate') {
        this.selected = expense
        this.$refs.ConfirmationModal.dialog = true
      } else if (actionKey.startsWith('change-division')) {
        const newDivision = actionKey.split(':')[1]
        this.$emit('update-expense', {
          id: expense.id,
          data: { related_division: newDivision },
        })
      }
    },
    validateSelectedExpense() {
      this.$emit('validate-expense', this.selected.id)
    },
  },
}
</script>

<style scoped></style>
