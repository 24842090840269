<template>
  <v-container v-if="currentPricing" row fluid class="comp-wrapper">
    <v-layout wrap>
      <v-btn
        class="ma-2"
        color="blue text--darken-4"
        small
        text
        :to="`/dashboard/edit/${currentPricing.listing_id}`"
      >
        <v-icon small class="me-2">mdi-chevron-left</v-icon>
        <span class="text-capitalize">
          {{ $t('Back to listing') }}
        </span>
      </v-btn>
      <v-card class="ml-3 mr-3 w-100" outlined>
        <v-progress-linear v-if="pricingLoading" :indeterminate="true" />
        <div
          v-if="enableSave"
          class="yellow lighten-5 yellow--text text--darken-4 pa-1 text-body-2 w-100 font-weight-medium text-center"
        >
          You have <b>unsaved changes</b>. Please save them before leaving this
          page.
        </div>
        <v-flex xs12 class="pa-4">
          <v-layout wrap mt-2 align-center>
            <v-layout column>
              <div class="font-weight-medium d-flex">
                <v-avatar rounded :size="70" class="me-4">
                  <v-img :src="currentPricing.main_listing.picture" />
                </v-avatar>
                <div class="d-flex flex-column justify-space-around">
                  <div>
                    {{ currentPricing.address }}
                    <span v-if="isMultiUnit" class="cyan-icon bolder pl-2"
                      >#Multi Unit</span
                    >
                    <span class="ml-3 d-inline-flex">
                      {{ currentPricing.beds }}
                      <v-icon small class="icon alt me-4 ms-1"
                        >fas fa-bed</v-icon
                      >
                      {{ currentPricing.baths }}
                      <v-icon small class="icon alt me-4 ms-1"
                        >fas fa-shower</v-icon
                      >
                    </span>
                  </div>
                  <div v-if="currentPricing.airbnb_link" cols="6">
                    <a target="_blank" :href="currentPricing.airbnb_link"
                      >Airbnb link</a
                    >
                  </div>
                </div>
              </div>
            </v-layout>

            <v-spacer></v-spacer>
            <v-flex class="xs2 d-flex align-center justify-end">
              <span class="d-inline-flex me-4">
                <v-icon
                  small
                  :color="currentPricing.listed ? 'success' : 'secondary'"
                >
                  {{
                    currentPricing.listed
                      ? 'fas fa-check-circle'
                      : 'fas fa-times-circle'
                  }}
                </v-icon>
                <span
                  class="pl-2"
                  :class="currentPricing.listed ? 'green--text' : 'red--text'"
                >
                  {{ currentPricing.listed ? $t('Listed') : $t('Unlisted') }}
                </span>
              </span>
              <confirmation-modal
                :text="`Are you sure you want to ${
                  !currentPricing.activated ? 'Deactivate' : 'Activate'
                } pricing sync?`"
                title="Pricing sync"
                @action="save"
                @cancel="currentPricing.activated = !currentPricing.activated"
              >
                <template #activator="{ on }">
                  <v-switch
                    v-model="currentPricing.activated"
                    :class="
                      currentPricing.activated ? 'cyan-icon' : 'grey-text'
                    "
                    :label="
                      'SYNC: ' + (currentPricing.activated ? 'ON' : 'OFF')
                    "
                    :input-value="currentPricing.activated"
                    v-on="on"
                  />
                </template>
              </confirmation-modal>
            </v-flex>
          </v-layout>
          <v-layout v-if="purchasePrice">
            <stat-card
              label="Listing cost"
              :value="toMoney(purchasePrice, { decimals: 0 })"
            />
            <stat-card
              label="Yearly ADR"
              :value="
                toMoney(currentPricing.main_listing.ytd_adr, { decimals: 0 })
              "
            />
            <stat-card
              label="Yearly Occ"
              :value="toPercent(currentPricing.main_listing.ytd_occupancy, 0)"
            />
            <stat-card
              label="Ytd Cash"
              :value="
                toPercent(currentPricing.main_listing.ytd_cash_on_cash, 0)
              "
            />
          </v-layout>

          <div class="d-flex flex-row-reverse">
            <v-btn
              :disabled="!enableSave"
              depressed
              small
              class="info"
              @click="save"
              >Save Changes</v-btn
            >
          </div>
        </v-flex>
      </v-card>

      <div class="w-100">
        <div class="ma-0 d-flex">
          <v-col class="pe-0">
            <v-card outlined class="pa-2">
              <div class="d-flex justify-space-between align-center 2">
                <v-radio-group
                  v-model="pricingType"
                  class="mt-0 py-4 mr-4"
                  hide-details
                  row
                  @change="sendPreview"
                >
                  <v-radio label="Dynamic Pricing" value="dynamic" />
                  <v-radio label="Static Pricing" value="static" />
                </v-radio-group>
                <v-btn
                  color="primary"
                  class="white--text"
                  small
                  depressed
                  @click="showExtraFilters = !showExtraFilters"
                >
                  <v-icon
                    :class="showExtraFilters ? 'fa-rotate-90' : ''"
                    left
                    small
                    >mdi-cog
                  </v-icon>
                  Additional Settings
                </v-btn>
              </div>
              <v-row v-if="pricingType === 'dynamic'" class="align-center">
                <v-col cols="3">
                  <v-text-field
                    v-model="currentPricing.base_price"
                    label="Base price"
                    dense
                    hide-details
                    outlined
                    required
                    @change="sendPreview"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="currentPricing.min_price_weekend"
                    label="Min price weekends"
                    dense
                    hide-details
                    outlined
                    required
                    @change="sendPreview"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="currentPricing.min_price_weekday"
                    label="Min price weekdays"
                    outlined
                    dense
                    hide-details
                    required
                    @change="sendPreview"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="currentPricing.max_price"
                    label="Max price"
                    dense
                    hide-details
                    outlined
                    required
                    @change="sendPreview"
                  ></v-text-field>
                </v-col>
              </v-row>
              <weekdays-picker
                v-else
                v-model="weekPrices"
                class="mt-4"
                @change="sendPreview"
              />

              <div v-if="showExtraFilters" class="mt-4">
                <v-divider />
                <pricing-page-extra-factors
                  class="mt-2"
                  :get-pricing-preview="sendPreview"
                  :save="save"
                />
              </div>
            </v-card>
            <v-flex xs12 class="mt-3">
              <v-card outlined>
                <v-col v-if="currentPricing.airbnb_review_score" cols="6">
                  <b>Score: </b
                  ><span>{{
                    roundWith(currentPricing.airbnb_review_score, 2)
                  }}</span>
                </v-col>
                <v-col cols="12">
                  <score-bar
                    :occupancy-month="currentPricing.yearly_stats['30_days']"
                    :occupancy-three-month="
                      currentPricing.yearly_stats['90_days']
                    "
                    :occupancy-two-weeks="
                      currentPricing.yearly_stats['14_days']
                    "
                    :occupancy-month-back="
                      currentPricing.yearly_stats['30_days_back']
                    "
                    :occupancy-three-month-back="
                      currentPricing.yearly_stats['90_days_back']
                    "
                    :occupancy-two-weeks-back="
                      currentPricing.yearly_stats['14_days_back']
                    "
                    :score="currentPricing.yearly_stats.health"
                  ></score-bar>
                </v-col>
              </v-card>
              <pricing-monthly
                v-if="$store.state.currentPricingMonthly"
                class="mt-4"
              >
              </pricing-monthly>
              <pricing-yearly
                v-if="$store.state.currentPricingYearly"
                class="mt-4"
              >
              </pricing-yearly>
            </v-flex>
          </v-col>
          <v-col cols="7">
            <pricing-calendar
              v-if="currentPricing.days_rates && !loading"
              :calendar.sync="calendar"
            />
            <velocity-summary
              class="mt-4"
              :velocity-data="currentPricing.velocity_data"
              :show-table="true"
            ></velocity-summary>
            <air-dna-metrics-chart
              v-if="currentPricing"
              :listing-pricing-id="currentPricing.id"
              class="mt-4"
            />
            <v-col
              v-if="currentPricing.pricing_logs"
              class="sm-3 mt-3 pa-0"
              wrap
            >
              <v-flex>
                <v-expansion-panels>
                  <v-expansion-panel key="final" class="pb-2">
                    <v-expansion-panel-header>
                      <div>
                        <div class="font-weight-bold mb-2">
                          Logs ({{
                            Object.keys(currentPricing.pricing_logs).length
                          }})
                        </div>
                        <span v-if="currentPricing.pricing_logs.length">
                          <span class="bolder"
                            >{{
                              parseDate(
                                currentPricing.pricing_logs[0]['created_at']
                              )
                            }}
                            - {{ currentPricing.pricing_logs[0]['log_type'] }}
                          </span>
                          {{
                            truncateBy(
                              currentPricing.pricing_logs[0]['description'],
                              100
                            )
                          }}
                          by
                          {{ currentPricing.pricing_logs[0]['done_by'] }}</span
                        >
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider></v-divider>
                      <v-flex
                        v-for="(logMessage, key) in currentPricing.pricing_logs"
                        :key="key"
                        class="mt-2"
                        xs12
                      >
                        <v-layout wrap>
                          <v-flex xs6 class="purple-icon big-text bolder pl-2"
                            >{{ parseDate(logMessage.created_at) }}
                          </v-flex>
                          <v-flex
                            xs6
                            class="cyan-icon big-text bolder pr-2"
                            style="text-align: right"
                            >{{ logMessage.done_by }}
                          </v-flex>
                          <v-flex xs12 class="align-left pa-2">
                            {{ logMessage.description }}
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
            </v-col>
          </v-col>
        </div>
      </div>
    </v-layout>
    <v-layout
      v-if="currentPricing && !isEmpty(currentPricing.city_revenue_per_bed)"
      wrap
    >
      <v-flex xs10 offset-xs1 mt-2>
        <v-layout wrap class="comp-wrapper">
          <v-flex>
            <v-layout wrap class="bb-1 mb-1 mt-1">
              <v-layout wrap class="center-text bt-1 br-1 bl-1" justify-center>
                <v-flex xs4 class="br-1">
                  50:
                  <span class="red-warn bolder">{{
                    calcTotal(
                      currentPricing.city_revenue_per_bed,
                      '50'
                    ).toLocaleString('en')
                  }}</span>
                </v-flex>
                <v-flex xs4 class="br-1">
                  75:
                  <span class="yellow-health bolder">{{
                    calcTotal(
                      currentPricing.city_revenue_per_bed,
                      '75'
                    ).toLocaleString('en')
                  }}</span>
                </v-flex>
                <v-flex xs4>
                  90:
                  <span class="green-health bolder">{{
                    calcTotal(
                      currentPricing.city_revenue_per_bed,
                      '90'
                    ).toLocaleString('en')
                  }}</span>
                </v-flex>
              </v-layout>
              <v-flex
                v-for="(monthData, key) in currentPricing.city_revenue_per_bed"
                :key="key"
                xs12
                sm3
                class="comp-wrapper center-text"
              >
                {{ monthName(monthData.month) }} <br />
                <span class="yellow-health mr-1 bolder">
                  50% : {{ monthData.percentiles['50'] }}
                </span>
                <span class="cyan-icon mr-1 bolder">
                  75% : {{ monthData.percentiles['75'] }}
                </span>
                <span class="green-health bolder">
                  90% : {{ monthData.percentiles['90'] }}
                </span>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap light-grey purple-icon>
                      <v-flex xs3 class="center-text"> Year </v-flex>
                      <v-flex xs3 class="center-text"> Total </v-flex>
                      <v-flex xs3 class="center-text"> Confirmed </v-flex>
                      <v-flex xs3 class="center-text"> Available </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="bb-1">
                    <v-layout wrap>
                      <v-flex xs3 class="center-text">
                        {{ yearNow() - 1 }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{ monthData.our_last_year }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() - 1][
                            'months'
                          ][monthData.month - 1].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() - 1][
                            'months'
                          ][monthData.month - 1].unconfirmed_sum
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 class="bb-1">
                    <v-layout wrap>
                      <v-flex xs3 class="center-text yellowish">
                        {{ yearNow() }}
                      </v-flex>
                      <v-flex xs3 class="center-text yellowish">
                        {{ monthData.our_current_year }}
                      </v-flex>
                      <v-flex xs3 class="center-text yellowish">
                        {{
                          currentPricing.stats['summary'][yearNow()]['months'][
                            monthData.month - 1
                          ].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text yellowish">
                        {{
                          currentPricing.stats['summary'][yearNow()]['months'][
                            monthData.month - 1
                          ].unconfirmed_sum
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 class="bb-1">
                    <v-layout wrap>
                      <v-flex xs3 class="center-text">
                        {{ yearNow() + 1 }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].unconfirmed_sum +
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].unconfirmed_sum
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PricingMonthly from 'components/pricing-monthly'
import PricingYearly from 'components/pricing-yearly'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import PricingCalendar from 'components/calendar/pricing-calendar'
import VelocitySummary from 'components/velocity-summary'
import ScoreBar from './score-bar'
import StatCard from './common/stat-card'
import FormattersMixin from 'components/mixins/formatters-mixin'
import get from 'lodash/fp/get'
import PricingPageExtraFactors from 'components/pricing-page-extra-factors.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import WeekdaysPicker from 'components/common/weekdays-picker.vue'
import AirDnaMetricsChart from './air-dna-metrics-chart'

export default {
  name: 'PricingPage',

  components: {
    WeekdaysPicker,
    ConfirmationModal,
    PricingPageExtraFactors,
    StatCard,
    ScoreBar,
    PricingCalendar,
    PricingMonthly,
    PricingYearly,
    VelocitySummary,
    AirDnaMetricsChart,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data: function () {
    return {
      weekPrices: [0, 0, 0, 0, 0, 0, 0],
      pricingType: 'dynamic',
      pricingLoading: false,
      showExtraFilters: false,
      enableSave: false,
      calendar: null,
    }
  },
  mounted() {
    this.$store.dispatch('getPricingStrategies')
    this.weekPrices = this.currentPricing.static_pricing
    this.pricingType = this.currentPricing.pricing_type
  },
  computed: {
    isMultiUnit() {
      return (
        this.currentPricing.multi_unit_pricings &&
        this.currentPricing.multi_unit_pricings.length > 0
      )
    },
    currentPricing() {
      return this.$store.state.currentPricing
    },
    purchasePrice() {
      return get('main_listing.purchase_price', this.currentPricing)
    },
    airdnaMetrics() {
      return this.currentPricing?.airdna_data || null
    },
  },
  methods: {
    calcTotal(monthsData, precentile) {
      let res = 0
      monthsData.forEach(month => {
        res += month.percentiles[precentile]
      })
      return res
    },
    back() {
      this.$router.push('/dashboard/pricing-panel')
    },
    save() {
      this.enableSave = false

      this.$store.dispatch('savePricing', {
        pricing: this.currentPricing,
        title: this.calendar.view.title,
        pricing_type: this.pricingType,
        static_pricing: this.weekPrices,
      })
    },
    async sendPreview(options = {}) {
      this.enableSave = true
      this.pricingLoading = true
      await this.$store.dispatch('getPricingPreview', {
        pricing: this.currentPricing,
        title: this.calendar.view.title,
        pricing_type: this.pricingType,
        static_pricing: this.weekPrices,
        strat_id: options.strat_id,
      })
      this.pricingLoading = false
    },
  },
}
</script>
<style scoped>
.panel-header {
  line-height: 1.5rem;
}
</style>
