<template>
  <tr class="center-text">
    <td>
      {{ item.topic }}
    </td>
    <td class="min-w-150">
      <v-text-field
        v-model="item.sub_topic"
        class="small-text-input"
        @change="updateInfoDef($event, 'sub_topic')"
      ></v-text-field>
    </td>
    <td class="min-w-150">
      <v-text-field
        v-model="item.hint"
        class="small-text-input"
        @change="updateInfoDef($event, 'hint')"
      ></v-text-field>
    </td>
    <td>
      <v-combobox
        v-model="item.tags"
        :items="tags"
        class="small-text-input"
        dense
        outlined
        hide-details
        multiple
        chips
        small-chips
        deletable-chips
        @change="updateInfoDef($event, 'tags')"
      ></v-combobox>
    </td>
    <td>
      <v-text-field
        v-model="item.header_name"
        :disabled="!item.tags || !item.tags.length"
        class="small-text-input"
        @change="updateInfoDef($event, 'header_name')"
      ></v-text-field>
    </td>
    <td class="max-w-200">
      <v-layout wrap align-center center-text>
        <v-icon
          v-if="managementRoles"
          small
          class="mr-2 clickable"
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
      </v-layout>
    </td>
  </tr>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import TagsMixin from '../mixins/tags-mixin'
import axios from '@/axios/config'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin, TagsMixin],
  props: ['item', 'afterChange'],
  data() {
    return {
      editMode: false,
      search: null,
      editItem: this.item,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    this.fetchTags()
  },
  methods: {
    updateInfoDef(val, field) {
      this.editItem[field] = val
      this.$store.dispatch('updateListingInfoDef', this.editItem)
    },
    deleteItem() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (!userConfirm) {
        return
      }
      this.$store.commit('updateListingLoading', true)
      axios
        .delete('/api/listing-info-defs/' + this.editItem.id)
        .then(() => {
          this.afterChange()
          this.$store.commit('updateListingLoading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.commit('updateListingLoading', false)
        })
    },
  },
}
</script>
