<template>
  <div class="pa-2 black--text">
    <div class="text-subtitle-1 font-weight-medium">Summary</div>
    <div v-if="payout.reserved" class="d-flex justify-space-between">
      <span class="mr-2">Charges + Refunds: </span>
      <span
        :class="{
          'warning--text': payments < 0,
          'success--text': payments > 0,
        }"
        >{{ toMoney(payments) }}</span
      >
    </div>
    <div v-if="payout.reserved" class="d-flex justify-space-between">
      <span class="mr-2">Reserved funds:</span>
      <span
        :class="{
          'warning--text': payout.reserved < 0,
          'success--text': payout.reserved > 0,
        }"
        >{{ toMoney(payout.reserved) }}</span
      >
    </div>
    <div v-if="payout.stripe_fee" class="d-flex justify-space-between">
      <span class="mr-2">Stripe Fee:</span>
      <span
        :class="{
          'warning--text': payout.stripe_fee < 0,
          'success--text': payout.stripe_fee > 0,
        }"
        >{{ toMoney(payout.stripe_fee) }}</span
      >
    </div>
    <div v-if="payout.cart_amount" class="d-flex justify-space-between">
      <span class="mr-2">Store Purchases:</span>
      <span
        :class="{
          'warning--text': payout.cart_amount < 0,
          'success--text': payout.cart_amount > 0,
        }"
        >{{ toMoney(payout.cart_amount) }}</span
      >
    </div>
  </div>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  name: 'PayoutSummary',
  mixins: [FormattersMixin],
  props: ['payout'],
  computed: {
    payments() {
      return (
        this.payout.amount / 100 -
        (this.payout.reserved +
          this.payout.stripe_fee +
          this.payout.cart_amount)
      )
    },
  },
}
</script>

<style scoped></style>
