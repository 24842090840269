<template>
  <div>
    <voice-modal
      v-if="config.has_twilio_voice"
      ref="voiceModal"
      :phone="voice.phone"
      :name="voice.name"
    />
    <v-tabs v-model="paramsTab" slider-color="teal lighten-1">
      <listing-info-pop v-if="showListingInfoPop" :hide-owners-public="true" />
      <v-tab href="#all" @click="updateChatType('all')"> {{ $t('All') }}</v-tab>
      <v-tab href="#sales" @click="updateChatType('sales')">
        {{ $t('Sales') }}</v-tab
      >
      <v-tab href="#cs" @click="updateChatType('customerSupport')">
        {{ $t('CS') }}</v-tab
      >
      <v-tab href="#contractors"> {{ $t('Contractors') }}</v-tab>
      <v-tab href="#owners"> {{ $t('Owners') }}</v-tab>
      <!--      <v-tab href="#agents"> {{ $t('Agents') }}</v-tab>-->
      <v-tab href="#ai-tasks"
        ><v-icon small color="blue lighten-2" class="me-2">mdi-creation</v-icon>
        {{ $t('AI Tasks') }}</v-tab
      >
      <v-tab v-if="isDesignedVr" href="#summary">{{
        isMobile ? $t('Today') : $t('Today Summary')
      }}</v-tab>
      <v-tab :disabled="!config.has_twilio_voice" href="#call-log"
        ><v-icon small class="me-2">mdi-phone</v-icon> {{ $t('Calls') }}</v-tab
      >

      <v-tab-item
        v-if="paramsTab === 'all'"
        id="all"
        class="col-12 pa-0 pa-sm-3"
      >
        <all-crm />
      </v-tab-item>
      <v-tab-item
        v-if="paramsTab === 'sales'"
        id="sales"
        class="col-12 pa-0 pa-sm-3"
      >
        <sales-crm />
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'cs'" id="cs" class="col-12 pa-0 pa-sm-3">
        <cs-crm />
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'summary' && isDesignedVr" id="summary">
        <daily-summary />
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'ai-tasks'" id="ai-tasks">
        <div class="ma-0 ma-sm-6">
          <ai-tasks />
        </div>
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'contractors'" id="contractors">
        <div class="ma-0 ma-sm-6">
          <users-crm user-group="contractors" />
        </div>
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'owners'" id="owners">
        <div class="ma-0 ma-sm-6">
          <users-crm user-group="owners" />
        </div>
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'agents'" id="agents">
        <div class="ma-0 ma-sm-6">
          <agents-crm />
        </div>
      </v-tab-item>
      <v-tab-item v-if="paramsTab === 'call-log'" id="call-log">
        <div class="ma-0 ma-sm-6">
          <call-log @call="handleCall" />
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SalesCrm from 'components/crm/sales-crm.vue'
import AllCrm from 'components/crm/all-crm.vue'
import DailySummary from '../summaries/daily-summary'
import CsCrm from './cs-crm'
import { mapMutations } from 'vuex'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingInfoPop from 'components/listing-info-pop.vue'
import DeviceMixin from 'components/mixins/device-mixin'
import AiTasks from 'components/crm/ai-tasks.vue'
import UsersCrm from 'components/crm/users-crm.vue'
import AgentsCrm from 'components/crm/agents-crm.vue'
import VoiceModal from 'components/crm/voice-modal'
import CallLog from 'components/crm/call-log.vue'

export default {
  components: {
    CsCrm,
    AllCrm,
    DailySummary,
    SalesCrm,
    ListingInfoPop,
    AiTasks,
    UsersCrm,
    AgentsCrm,
    VoiceModal,
    CallLog,
  },
  mixins: [PermissionsMixin, DeviceMixin],
  methods: {
    ...mapMutations(['updateChatType']),
    handleCall(phone) {
      this.$refs.voiceModal.makeOutgoingCall(phone)
    },
  },
  computed: {
    config() {
      return this.$store.state.app.configuration
    },
    showListingInfoPop() {
      return this.$store.state.showListingInfoPop
    },
    voice() {
      return this.$store.state.conversations.voice
    },
  },
  data() {
    return {
      view: 'pending',
      department: 'all',
    }
  },
  mounted() {
    this.$store.dispatch('getWhatsappTemplates')
  },
}
</script>
<style scoped>
.scaled-radio {
  transform: scale(0.9);
}
</style>
