<template>
  <v-container fluid>
    <v-row align="center">
      <v-col
        v-if="paymentPeriod === 'monthly'"
        cols="12"
        sm="auto"
        align="center"
      >
        <v-btn-toggle v-model="dateType" color="primary" mandatory>
          <v-btn value="monthly">Monthly</v-btn>
          <v-btn value="yearly">Yearly</v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col v-if="paymentPeriod === 'monthly'" cols="12" sm="auto">
        <v-menu v-model="menu" transition="scale-transition" offset-y>
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              :value="date"
              :label="dateType === 'monthly' ? $t('Month') : $t('Year')"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="datePicker"
            v-model="date"
            :max="$moment.utc().endOf('month').toISOString()"
            type="month"
            no-title
            reactive
            cv
            @change="dateChanged"
            @click:year="dateChanged"
          />
        </v-menu>
      </v-col>
      <div v-else class="d-flex align-center">
        <v-col cols="auto">
          <date-picker v-model="from" label="From" @change="fetchData(1)" />
        </v-col>
        <v-col cols="auto">
          <date-picker v-model="to" label="To" @change="fetchData(1)" />
        </v-col>
      </div>
      <v-col cols="auto">
        <v-autocomplete
          v-model="investorListing"
          item-text="name"
          item-value="listings"
          clearable
          :items="investors"
          hide-details
          outlined
          dense
          label="Investor"
          @change="investorChange"
        />
      </v-col>

      <v-col cols="12" sm="5">
        <v-text-field
          v-model="search"
          label="Search..."
          prepend-inner-icon="search"
          outlined
          clearable
          dense
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>

      <v-col cols="12" sm="auto" align="center">
        <v-btn-toggle
          v-model="listingsState"
          color="primary"
          mandatory
          @change="fetchData(1)"
        >
          <v-btn value="active">Active</v-btn>
          <v-btn value="disabled">Disabled</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-tooltip v-if="natchaSupport" bottom open-delay="100">
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-btn
              class="mx-5"
              color="primary"
              icon
              @click="openModalOfListingsWithMissingBankInfo"
            >
              <v-icon>mdi-bank-minus</v-icon>
            </v-btn>
          </span>
        </template>
        <span>Listings With Missing Bank Info</span>
      </v-tooltip>
    </v-row>
    <v-row>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="includeListingsInSetup"
          label="Include listings in setup"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="positiveBalance"
          label="Positive balance"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="negativeBalance"
          label="Negative balance"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="balanced"
          label="Show zero balances"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="inProcess"
          label="In process"
          hide-details
          class="d-inline-block"
          @change="fetchData(1)"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5 pa-3">
      <v-spacer />
      <v-btn
        v-if="managementRoles && hasAbility('listings-owners-payments')"
        class="text-capitalize"
        color="secondary"
        elevation="0"
        :disabled="!selectedIds.size"
        @click="paymentActions"
      >
        Payments Actions
      </v-btn>
      <v-btn
        v-if="managementRoles && hasAbility('listings-owners-payments')"
        class="text-capitalize ml-3"
        color="secondary"
        elevation="0"
        @click="exportToExcel"
      >
        Export To Excel
      </v-btn>
      <v-btn
        v-if="managementRoles && hasAbility('listings-owners-payments')"
        class="text-capitalize ml-3"
        color="secondary"
        elevation="0"
        @click="regenerateReport"
      >
        Re-generate Report
      </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #header.checkbox>
          <v-checkbox
            :input-value="
              availableItemsToSelect.length &&
              selectedIds.size === availableItemsToSelect.length
            "
            :disabled="natchaSupport && !availableItemsToSelect.length"
            hide-details
            class="ma-0 pa-0 d-inline-block pl-2"
            @change="selectAllClicked(!!$event)"
          />
        </template>

        <template #item.checkbox="{ item }">
          <v-tooltip v-if="item.checkboxErrors.length && natchaSupport" top>
            <template #activator="{ on }">
              <v-icon small v-on="on">info</v-icon>
            </template>
            <div v-for="(error, index) in item.checkboxErrors" :key="index">
              {{ error }}
            </div>
          </v-tooltip>
          <div v-else class="flex-center">
            <v-checkbox
              :input-value="selectedIds.has(item.id)"
              hide-details
              class="ma-0 pa-0 d-inline-block pl-2"
              @change="itemSelect(!!$event, item.id)"
            />
            <v-tooltip v-if="!item.reservationsReconciled && isDesignedVr" top>
              <template #activator="{ on }">
                <v-icon class="red--text" small v-on="on">info</v-icon>
              </template>
              <div>Not all reservations are reconciled</div>
            </v-tooltip>
          </div>
        </template>
        <template #item.income="{ item }">
          {{ toMoney(item.income) }} ({{ toMoney(item.incomeReconciled) }})
        </template>
        <template #item.expenses="{ item }">
          {{ toMoney(item.expenses) }} ({{ toMoney(item.expensesReconciled) }})
        </template>
        <template #item.inProcess="{ item }">
          {{ toMoney(item.inProcess) }}
        </template>
        <template #item.paid="{ item }">
          {{ toMoney(item.paid) }}
        </template>
        <template #item.rolledOver="{ item }">
          {{ toMoney(item.rolledOver) }}
        </template>
        <template #item.balance="{ item }">
          {{ toMoney(item.balance) }}
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce, map, round, sum } from 'lodash'
import { mapActions } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker'
import { autoCancelToken } from '@/axios/config'
import Toaster from '@/utils/toaster'

export default {
  name: 'ListingsPayments',
  components: { DatePicker },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['natchaSupport', 'paymentPeriod'],
  data: function () {
    return {
      menu: false,
      dateType: 'monthly',
      listingsState: 'active',
      date: this.$moment.utc().subtract(1, 'month').format('YYYY-MM'),
      from: this.$moment.utc().subtract(7, 'days').format('YYYY-MM-DD'),
      to: this.$moment.utc().format('YYYY-MM-DD'),
      investorListing: null,
      investors: [],
      search: '',
      includeListingsInSetup: false,
      positiveBalance: false,
      negativeBalance: false,
      balanced: false,
      inProcess: false,
      tableHeaders: [
        {
          text: '',
          value: 'checkbox',
          sortable: false,
          align: 'center',
          width: 100,
        },
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Property Nickname', value: 'nickname', sortable: false },
        {
          text: 'Recipient Account Name',
          value: 'recipientAccountName',
          sortable: false,
        },
        { text: 'Property Address', value: 'address', sortable: false },
        { text: 'Income (reconciled)', value: 'income', sortable: false },
        { text: 'Expenses (reconciled)', value: 'expenses', sortable: false },
        { text: 'In Process', value: 'inProcess', sortable: false },
        { text: 'Paid', value: 'paid', sortable: false },
        { text: 'Rolled Over', value: 'rolledOver', sortable: false },
        { text: 'Balance', value: 'balance', sortable: false },
      ],
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
      selectedIds: new Set(),
    }
  },
  computed: {
    availableItemsToSelect() {
      return this.items.filter(item => !item.checkboxErrors.length)
    },
  },
  async mounted() {
    const { data } = await axios.get('/api/users', {
      params: { role: 'investor', includeListings: 1 },
    })
    this.investors = data
    this.fetchData()
  },
  methods: {
    ...mapActions('listings', ['getAllListingsWithMissingBankInfo']),
    investorChange() {
      this.fetchData(1)
    },
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page
      const params = this.preparePayload()
      axios
        .get('/api/listings/accounting_data/owners', {
          params,
          cancelToken: autoCancelToken('GetListingPayments'),
        })
        .then(res => {
          this.selectedIds = new Set()
          this.items = this.mapItems(res.data.items)
          this.pagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    preparePayload() {
      const isYearly = this.dateType === 'yearly'
      if (this.paymentPeriod === 'monthly') {
        this.from = this.$moment(this.date)
          .startOf(isYearly ? 'year' : 'month')
          .format('YYYY-MM-DD')
        this.to = this.$moment(this.date)
          .endOf(isYearly ? 'year' : 'month')
          .format('YYYY-MM-DD')
      }
      return {
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        search: this.search || '',
        include_listings_in_setup: this.includeListingsInSetup,
        positive_balance: this.positiveBalance,
        negative_balance: this.negativeBalance,
        balanced: this.balanced,
        in_process: this.inProcess,
        listings_state: this.listingsState,
        from: this.from,
        to: this.to,
        listings_ids: (this.investorListing || []).map(listing => listing.id),
        is_yearly: isYearly,
      }
    },
    exportToExcel() {
      this.fetchingData = true
      const params = this.preparePayload()

      axios
        .get('/api/listings/accounting_data/owners/excel', {
          params,
        })
        .then(res => {
          Toaster.show([{ type: 'success', text: res.data.message }])
        })
        .catch(alert)
        .finally(() => {
          this.fetchingData = false
        })
    },
    regenerateReport() {
      const isYearly = this.dateType === 'yearly'
      const reportDate = this.$moment(this.date)
        .startOf(isYearly ? 'year' : 'month')
        .format('YYYY-MM-DD')

      axios
        .post('/api/listings/regenerate_report', {
          type: 'owners',
          date: reportDate,
        })
        .then(res => {
          Toaster.show([{ type: 'success', text: res.data.message }])
        })
        .catch(alert)
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    dateChanged(val) {
      this.date = val.toString()
      this.fetchData(1)
    },
    mapItems(resItems = []) {
      return resItems.map(resItem => {
        return {
          id: resItem.listing.id,
          nickname: resItem.listing.nickname,
          recipientAccountName: [
            resItem.listing.owner_bank_account &&
              resItem.listing.owner_bank_account.account_name,
            resItem.listing.referrer_bank_account &&
              resItem.listing.referrer_bank_account.account_name,
          ]
            .filter(Boolean)
            .join(' | '),
          address: resItem.listing.address,
          reservationsReconciled:
            resItem.accounting_stats.all_reservations_reconciled,
          income: resItem.accounting_stats.summary.income,
          incomeReconciled: resItem.accounting_stats.summary.incomes_reconciled,
          expenses: resItem.accounting_stats.summary.expenses,
          expensesReconciled:
            resItem.accounting_stats.summary.expenses_reconciled,
          inProcess: resItem.accounting_stats.summary.in_process_amount,
          paid: resItem.accounting_stats.summary.paid,
          rolledOver: resItem.accounting_stats.summary.rolledover,
          balance: resItem.accounting_stats.summary.balance,
          checkboxErrors: this.errorsToCreatePayment(
            resItem.listing,
            resItem.accounting_stats
          ),
        }
      })
    },
    rsm(val, field) {
      return round(sum(map(val, field)), 2) || 0
    },
    selectAllClicked(value) {
      if (value) {
        this.selectedIds = new Set(
          this.availableItemsToSelect.map(item => item.id)
        )
      } else {
        this.selectedIds.clear()
      }
      this.$forceUpdate()
    },
    itemSelect(val, itemId) {
      if (val) {
        this.selectedIds.add(itemId)
      } else {
        this.selectedIds.delete(itemId)
      }
      this.$forceUpdate()
    },
    errorsToCreatePayment(listing, accountingData) {
      if (!this.natchaSupport) {
        return []
      }
      const errors = []
      if (!listing.bank_account) {
        errors.push('Missing listing bank account info')
      } else if (!listing.bank_account.account_number) {
        errors.push('Missing listing bank account number')
      }

      if (!listing.owner_bank_account) {
        errors.push('Missing listing owner bank account info')
      } else {
        if (!listing.owner_bank_account.account_name) {
          errors.push('Missing listing owner bank account name')
        }
        if (!listing.owner_bank_account.account_number) {
          errors.push('Missing listing owner bank account number')
        }
        if (!listing.owner_bank_account.routing_number) {
          errors.push('Missing listing owner bank account routing number')
        }
      }

      if (listing.referrer_percent) {
        if (!listing.referrer_bank_account) {
          errors.push('Missing listing referrer bank account info')
        } else {
          if (!listing.referrer_bank_account.account_name) {
            errors.push('Missing listing referrer bank account name')
          }
          if (!listing.referrer_bank_account.account_number) {
            errors.push('Missing listing referrer bank account number')
          }
          if (!listing.referrer_bank_account.routing_number) {
            errors.push('Missing listing referrer bank account routing number')
          }
        }
      }

      if (accountingData.summary.balance === 0) {
        errors.push('Empty balance')
      }

      if (accountingData.summary.in_process_amount > 0) {
        errors.push('Transaction in process')
      }

      if (this.dateType === 'yearly') {
        errors.push('Paying on an annual basis is not possible')
      }

      return errors
    },
    selectedItems() {
      return this.items.filter(i => this.selectedIds.has(i.id))
    },
    totalSelectedItemsBalance() {
      return this.rsm(this.selectedItems(), 'balance')
    },
    isAllSelectedItemsHavePositiveBalance() {
      return this.selectedItems().every(i => i.balance > 0 && i.inProcess <= 0)
    },
    paymentActions() {
      const isYearly = this.dateType === 'yearly'
      if (this.paymentPeriod === 'monthly') {
        this.from = this.$moment(this.date)
          .startOf(isYearly ? 'year' : 'month')
          .format('YYYY-MM-DD')
        this.to = this.$moment(this.date)
          .endOf(isYearly ? 'year' : 'month')
          .format('YYYY-MM-DD')
      }
      this.$store.commit('showModal', {
        name: 'ListingsPaymentsModal',
        props: {
          from: this.from,
          to: this.to,
          date: this.date,
          selectedIds: this.selectedIds,
          natchaSupport: this.natchaSupport,
          totalAmount: this.toMoney(this.totalSelectedItemsBalance()),
          enablePay: this.isAllSelectedItemsHavePositiveBalance(),
          afterAction: () => this.fetchData(),
          afterFileDownload: fileName =>
            this.afterDownloadPaymentsFile(fileName),
        },
      })
    },
    afterDownloadPaymentsFile(fileName) {
      const rows = this.selectedItems().map(item => {
        return [
          item.id,
          item.nickname,
          item.recipientAccountName,
          item.address,
          item.balance,
        ]
      })
      rows.unshift([
        'Id',
        'Nickname',
        'Recipient Account Name',
        'Address',
        'Balance',
      ])
      this.autoDownloadLocalCsv(rows, fileName.replace('Nacha', 'Info'))
    },
    async openModalOfListingsWithMissingBankInfo() {
      this.$store.commit('updateLoading', true)
      const listings = await this.getAllListingsWithMissingBankInfo()
      this.$store.commit('updateLoading', false)
      this.$store.commit('showModal', {
        name: 'ListingsListModal',
        maxWidth: 950,
        isPersistent: false,
        props: {
          title: 'Listings With Missing Bank Info',
          listings,
        },
      })
    },
  },
  watch: {
    menu(val) {
      if (val && this.dateType === 'yearly') {
        this.$nextTick(() => (this.$refs.datePicker.activePicker = 'YEAR'))
      } else if (val && this.dateType === 'monthly') {
        this.$nextTick(() => (this.$refs.datePicker.activePicker = 'MONTH'))
      }
    },
    dateType(val) {
      if (val === 'monthly') {
        this.date = this.$moment.utc().subtract(1, 'month').format('YYYY-MM')
      } else {
        this.date = this.$moment.utc().subtract(1, 'month').format('YYYY')
      }
      this.fetchData(1)
    },
  },
}
</script>

<style scoped></style>
