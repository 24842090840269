import { render, staticRenderFns } from "./estimates-builder-group-view.vue?vue&type=template&id=177a4357&scoped=true&"
import script from "./estimates-builder-group-view.vue?vue&type=script&lang=js&"
export * from "./estimates-builder-group-view.vue?vue&type=script&lang=js&"
import style0 from "./estimates-builder-group-view.vue?vue&type=style&index=0&id=177a4357&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177a4357",
  null
  
)

export default component.exports