<template>
  <v-card v-if="conversation.guest.id" elevation="0">
    <conversation-info-title title="Guest Information" />
    <v-divider />
    <v-card-text>
      <!-- Guest Header -->
      <div class="d-flex align-center mb-6">
        <user-avatar
          :name="conversation.guest.full_name"
          :size="50"
          :color="stringToHslColor(conversation.guest.full_name)"
          class="flex-shrink-0"
        />
        <div class="ml-4 flex-grow-1">
          <div class="text-subtitle-1 font-weight-medium mb-2">
            {{ conversation.guest.full_name }}
          </div>
          <div class="d-flex align-center">
            <v-btn
              class="text-none pa-0"
              :href="`tel:${displayedPhone}`"
              text
              x-small
              :disabled="displayedPhone === '-'"
            >
              <v-icon class="mr-1" x-small color="primary">mdi-phone</v-icon>
              <span class="text-body-2">{{ displayedPhone }}</span>
            </v-btn>
            <copy-clipboard
              v-if="displayedPhone !== '-'"
              color="secondary"
              :text="displayedPhone"
              class="ml-2"
            />
          </div>
        </div>
      </div>

      <!-- Contact Details -->
      <v-card outlined class="mb-6">
        <v-list dense class="py-0">
          <v-list-item three-line>
            <v-list-item-icon class="mt-3">
              <v-icon color="primary">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle
                class="text-caption text-uppercase font-weight-medium mb-1"
              >
                Registered Email
              </v-list-item-subtitle>
              <v-list-item-title class="d-flex align-center text-body-2">
                {{ displayedEmail }}
                <copy-clipboard
                  v-if="displayedEmail !== '-'"
                  color="secondary"
                  :text="displayedEmail"
                  class="ml-2"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-if="conversation.guest.airbnb2">
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-caption text-uppercase font-weight-medium mb-1"
                >
                  Airbnb Profile
                </v-list-item-subtitle>
                <v-list-item-title>
                  <v-btn
                    class="text-none pa-0"
                    text
                    color="primary"
                    :href="conversation.guest.airbnb2['url']"
                    target="_blank"
                  >
                    <span class="text-body-2">View Profile</span>
                    <v-icon x-small class="ml-1">mdi-open-in-new</v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>

      <!-- Pre Check-in Card -->
      <v-card v-if="reservation.status === 'confirmed'" outlined>
        <v-card-title
          class="text-subtitle-2 py-3 px-4 d-flex align-center justify-space-between"
        >
          <div class="d-flex align-center">
            <span>Pre Check-in Information</span>
            <v-chip
              x-small
              :color="preCheckIn?.status === 'Done' ? 'success' : 'warning'"
              class="ml-2 text-uppercase"
              label
            >
              {{ preCheckIn?.status || 'New' }}
            </v-chip>
          </div>
          <div class="d-flex align-center">
            <v-btn
              v-if="canSave"
              small
              color="primary"
              class="text-none mr-2"
              @click="savePreCheckIn"
              :loading="saving"
            >
              Save
            </v-btn>
            <v-btn
              x-small
              text
              color="primary"
              class="text-none"
              :href="`${portalDomain}/tourist/trips/${reservation.confirmation_code}`"
              target="_blank"
            >
              Portal Link
              <v-icon x-small class="ml-1">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4">
          <pre-check-in-card
            ref="preCheckInCard"
            :flat-view="true"
            :editable="true"
            :pre-check-in="preCheckIn || reservation.pre_check_in"
            :confirmation-code="reservation.confirmation_code"
            @can-save="canSave = $event"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormMixin from 'components/mixins/form-rules-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import PreCheckInCard from 'components/pre-check-in/pre-check-in-card'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import UserAvatar from 'components/common/user-avatar'
import CopyClipboard from 'components/copy-clipboard'

export default {
  name: 'ConversationInfoGuest',
  components: {
    UserAvatar,
    ConversationInfoTitle,
    PreCheckInCard,
    CopyClipboard,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormMixin, ColorsMixin],
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayedPhone() {
      const { guest } = this.conversation
      return (
        guest.primary_phone || guest.pre_checkin_phone || guest.phones[0] || '-'
      )
    },
    displayedEmail() {
      const { guest } = this.conversation
      return (
        this.conversation.registered_email ||
        guest.primary_email ||
        guest.pre_checkin_email ||
        guest.emails[0] ||
        '-'
      )
    },
    portalDomain() {
      return 'https://guest.boomnow.com'
    },
  },
  data() {
    return {
      preCheckIn: null,
      canSave: false,
      saving: false,
    }
  },
  methods: {
    async savePreCheckIn() {
      if (!this.$refs.preCheckInCard) return

      this.saving = true
      try {
        await this.$refs.preCheckInCard.save()
        this.canSave = false
      } catch (error) {
        console.error('Failed to save pre-check-in:', error)
      }
      this.saving = false
    },
  },
  async mounted() {
    this.preCheckIn = await this.$store.dispatch(
      'getPreCheckIn',
      this.reservation.confirmation_code
    )
  },
}
</script>

<style scoped>
:deep(.v-list-item__icon) {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  margin-right: 16px !important;
  align-self: flex-start;
}

:deep(.v-list-item) {
  min-height: unset;
  padding: 12px 16px;
}

:deep(.v-list-item__content) {
  padding: 0;
}

:deep(.v-list-item__subtitle) {
  margin-bottom: 2px;
  color: var(--v-secondary-darken1);
  font-size: 11px;
  letter-spacing: 0.5px;
}

:deep(.v-card.v-card--outlined) {
  border-color: var(--v-secondary-lighten1);
  background-color: var(--v-surface);
}

:deep(.v-card__title) {
  background-color: var(--v-secondary-lighten5);
  border-bottom: 1px solid var(--v-secondary-lighten2);
}

:deep(.v-btn.v-btn--text) {
  opacity: 0.9;
}

:deep(.v-btn.v-btn--text:hover) {
  opacity: 1;
}

:deep(.pre-check-in-card) {
  .v-text-field {
    margin-top: 16px;
  }

  .v-label {
    font-size: 14px;
    color: var(--v-secondary-darken2);
  }

  .v-input__slot {
    margin-bottom: 4px;
  }
}

:deep(.v-chip.v-chip--label) {
  font-size: 10px;
  height: 18px;
  font-weight: 500;
}

:deep(.v-btn.v-btn--text.v-size--x-small) {
  letter-spacing: 0.25px;
  font-size: 11px;
}
</style>
