var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.listingLoading,"outlined":""}},[_c('v-card-title',{staticClass:"font-weight-regular secondary--text"},[_vm._v(_vm._s(_vm.currentPricingMonthly.month_name + ' Pricing Stats'))]),_c('v-card-subtitle',{staticClass:"text-caption font-weight-light"},[_vm._v(" In the pricing stats we only take into consideration the fare accommodation and not the total. ")]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{attrs:{"width":"150"}},[_vm._v(" "+_vm._s(_vm.currentPricingMonthly.current_year - 1)+" ")]),_c('th',{attrs:{"width":"150"}},[_vm._v(" "+_vm._s(_vm.currentPricingMonthly.current_year)+" ")])])]),_c('tbody',_vm._l((_vm.pricingStats),function(item){return _c('tr',{key:item.title},[_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("fas fa-question-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])]),_vm._v(" "+_vm._s(item.title)+" ")],1),_c('td',[(item.title.toLowerCase() === 'occupancy')?_c('span',[_vm._v(" "+_vm._s(item.lastYearValue !== 0 ? `${_vm.round(item.lastYearValue)}%` : 'N/A')+" ")]):_c('span',[_vm._v(" "+_vm._s(item.lastYearValue ? _vm.toMoney(item.lastYearValue) : 'N/A')+" ")])]),_c('td',[(item.title.toLowerCase() === 'occupancy')?_c('span',[_vm._v(" "+_vm._s(item.currentValue !== 0 ? `${_vm.round(item.currentValue)}%` : 'N/A')+" ")]):_c('span',[_vm._v(" "+_vm._s(item.currentValue !== 0 ? _vm.toMoney(item.currentValue) : 'N/A')+" ")]),(
                  !['left to sell adr', 'left to sell sum'].includes(
                    item.title.toLowerCase()
                  )
                )?_c('v-icon',{attrs:{"color":item.level === 'arrow_upward'
                    ? 'success'
                    : item.level === 'arrow_downward'
                    ? 'warning'
                    : ''}},[_vm._v(_vm._s(item.level))]):_c('span',[_vm._v(" "+_vm._s(item.level)+" ")])],1)])}),0)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }