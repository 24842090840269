<template>
  <div>
    <v-card>
      <v-card-title>
        Payments {{ title }}
        <v-progress-circular
          v-show="loading"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <Payment-card></Payment-card>
      </v-card-title>
      <v-row class="ml-3">
        <v-col cols="5" md="auto">
          <date-picker
            v-model="filters.from"
            label="From"
            :max="
              new Date(new Date().setMonth(new Date().getMonth() + 10))
                .toISOString()
                .substr(0, 10)
            "
            @change="getPayments"
          />
        </v-col>
        <v-col cols="5" md="auto">
          <date-picker
            v-model="filters.to"
            label="To"
            :max="
              new Date(new Date().setMonth(new Date().getMonth() + 10))
                .toISOString()
                .substr(0, 10)
            "
            @change="getPayments"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
      >
        <template #item="{ item }">
          <tr>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.amount.toLocaleString() }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.listing_name || listing.nickname }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              <v-icon v-if="item.bank_transaction_id" class="cyan-icon"
                >done_all
              </v-icon>
              <div v-else>
                <v-icon v-if="!item.manual_dismiss" class="purple-icon"
                  >close
                </v-icon>
                <dismiss-reason-modal
                  :dismissed="item.manual_dismiss"
                  :item="item"
                  type="Payment"
                  @after-action="onDismiss"
                />
              </div>
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              <span>{{ nameById(item.user_id) }}</span>
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.title }}
            </td>

            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ parseDate(item.paid_at) }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ parseDate(item.start_period) }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ parseDate(item.end_period) }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.type_of_payment }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.designedvr_account }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.recipient_account }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.recipient_name }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.check_number }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{ item.transaction_id }}
            </td>
            <td class="text-sm-center" :class="isReconciledClass(item)">
              {{
                item.working_capital_rate
                  ? item.working_capital_rate.toLocaleString()
                  : '-'
              }}
            </td>
            <td
              class="justify-center px-0 fill-height"
              :class="isReconciledClass(item)"
            >
              <ConfirmationModal
                v-if="isAdmin || hasAbility('super-accountant')"
                text="Are you sure you want to delete payment?"
                @action="deletePayment(item)"
              >
                <template #activator="slotData">
                  <v-btn
                    small
                    style="cursor: pointer"
                    :disabled="
                      canDeleteFinancialData(item.paid_at, currentUser)
                    "
                    v-on="slotData.on"
                  >
                    delete
                  </v-btn>
                </template>
              </ConfirmationModal>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PaymentCard from './payment-card.vue'
import CommonFunctions from 'components/mixins/common_functions'
import AccountingMixin from 'components/mixins/accounting-mixin'
import axios from 'axios'
import { mapGetters } from 'vuex'
import DismissReasonModal from 'components/dismiss-reason-modal'
import ConfirmationModal from './modals/confirmation-modal'
import DatePicker from 'components/form-fields/date-picker.vue'

export default {
  components: {
    DatePicker,
    ConfirmationModal,
    'Payment-card': PaymentCard,
    DismissReasonModal,
  },
  mixins: [CommonFunctions, AccountingMixin],
  props: ['listing', 'title'],
  data() {
    const date = new Date()
    return {
      Payment: null,
      pagination: {
        itemsPerPage: 10,
      },
      filters: {
        from: new Date(date.getFullYear(), date.getMonth() - 1, 1)
          .toISOString()
          .substr(0, 10),
        to: new Date(date.getFullYear(), date.getMonth(), 1)
          .toISOString()
          .substr(0, 10),
        listing_id: this.listing ? this.listing.id : null,
      },
      headers: [
        { text: 'Amount', align: 'center', sortable: false },
        { text: 'Listing', align: 'center', sortable: false },
        { text: 'Reconciled', align: 'center', sortable: false },
        { text: 'Created by', align: 'center', sortable: false },
        { text: 'Title', align: 'center', sortable: false },
        { text: 'Paid At', align: 'center', sortable: false },
        { text: 'Start Period', align: 'center', sortable: false },
        { text: 'End Period', align: 'center', sortable: false },
        { text: 'Type Of Payment', align: 'center', sortable: false },
        { text: 'From Account', align: 'center', sortable: false },
        { text: 'Recipient Account', align: 'center', sortable: false },
        { text: 'Recipient Name', align: 'center', sortable: false },
        { text: 'Check Number', align: 'center', sortable: false },
        { text: 'Transaction ID', align: 'center', sortable: false },
        { text: 'Working Capital', align: 'center', sortable: false },
        { text: 'Actions', align: 'center', sortable: false },
      ],
    }
  },
  mounted() {
    if (!this.listing) {
      this.$store.dispatch('getPayments', this.filters)
    }
  },
  methods: {
    getPayments() {
      this.$store.dispatch('getPayments', this.filters)
    },
    onDismiss() {
      if (this.listing) {
        this.$store.dispatch('changeEditListingBG', this.listing.id)
      } else {
        this.$store.dispatch('getPayments', this.filters)
      }
    },
    isReconciledClass(item) {
      if (item.bank_transaction_id || item.manual_dismiss) {
        return 'green lighten-5'
      } else {
        return 'super-light-red'
      }
    },
    parseDate: function (date) {
      if (date) {
        return this.$moment(date).format('YYYY/MM/DD')
      }
    },
    updateChosenPayment(Payment) {
      this.$store.commit('updateChosenPayment', Payment)
    },
    deletePayment(payment) {
      axios
        .delete('/api/payments/' + payment.id)
        .then(() => {
          if (this.listing) {
            this.$store.dispatch('changeEditListingBG', this.listing.id)
          } else {
            this.$store.dispatch('getPayments', this.filters)
          }
        })
        .catch(() => ({}))
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'isAdmin']),
    items: {
      get: function () {
        return this.$store.state.recentPayments
      },
    },
    loading: function () {
      return this.$store.state.loading
    },
  },
}
</script>
