<template>
  <v-row>
    <v-col class="pt-1" cols="12" sm="6">
      <v-row class="pt-0">
        <v-col class="pa-2" :cols="12" md="6" lg="3">
          <review-stats-box
            :title="$t('Ops Expense ratio')"
            :value="toPercent(stats.expense_ratio)"
            color="#245ECB"
          />
        </v-col>
        <v-col class="pa-2" :cols="12" md="6" lg="3">
          <review-stats-box
            :title="$t('Avg Health')"
            :value="toPercent(stats.current_health, 0) || '-'"
            color="#B14EC8"
          />
        </v-col>
        <v-col class="pa-2" :cols="12" md="6" lg="3">
          <review-stats-box
            :title="$t('Total Sales YTD')"
            :value="toMoney(stats.sales_amount, { decimals: 0 })"
            color="#C84E9E"
          />
        </v-col>
        <v-col class="pa-2" :cols="12" md="6" lg="3">
          <review-stats-box
            :title="$t('Total Distributed')"
            :value="toMoney(stats.distributed_amount, { decimals: 0 })"
            color="#26A561"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="fancy-card">
            <info-row
              icon="$wallet"
              :label="$t('Total Investment')"
              :value="toMoney(stats.invested_amount, { decimals: 0 })"
              color="warning"
              background="#ffe2e4"
            />
            <info-row
              class="mt-4"
              icon="$wallet"
              :label="$t('Avg. Cash On Cash')"
              :value="toPercent(stats.cash_on_cash, 0)"
              color="#02BC77"
              background="#d4f4e8"
            />
            <info-row
              class="mt-4"
              icon="$wallet"
              :label="$t('Listings')"
              :value="stats.listings_count"
              color="#80bbc1"
              background="#eaf4f5"
            />
          </div>
        </v-col>
        <v-col cols="12" class="pa-2">
          <div class="fancy-card">
            <v-data-table
              mobile-breakpoint="0"
              :headers="headers"
              :items="listingItems"
              :item-class="itemClass"
              disable-pagination
              fixed-header
              hide-default-footer
              height="40vh"
              @click:row="rowClick"
            >
              <template #[`item.nickname`]="{ item }">
                <v-icon
                  class="mr-2"
                  :color="item.active ? 'green' : 'red'"
                  x-small
                  >mdi-circle</v-icon
                >
                <a
                  target="_blank"
                  class="black--text text--darken-4 underline text-decoration-underline"
                  @click.stop="navigateTo(`/dashboard/property/${item.id}`)"
                  >{{ item.nickname }}</a
                >
              </template>
              <template #item.current_health="{ item }">
                {{ toPercent(item.current_health) }}
              </template>
              <template #item.ytd_expense_ratio="{ item }">
                {{ toPercent(item.ytd_expense_ratio) }}
              </template>
              <template #item.ytd_cash_on_cash="{ item }">
                {{ toPercent(item.ytd_cash_on_cash) }}
              </template>
              <template #item.ytd_occupancy="{ item }">
                {{ toPercent(item.ytd_occupancy) }}
              </template>
              <template #item.ytd_adr="{ item }">
                {{ toMoney(item.ytd_adr) }}
              </template>
              <template #item.sold_total="{ item }">
                {{ toMoney(item.sold_total) }}
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="12" class="pa-2">
          <div class="fancy-card pa-2">
            <sources-graph-new
              :stats-object="expensesSources"
              :title="'Expenses Sources'"
              graph-id="expenses-graph"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6">
      <div class="fancy-card pa-2">
        <monthly-stats-graph
          class="mb-4"
          :names="months"
          title="Monthly Revenue"
          :reveune="data.month_revenue"
          graph-id="monthly-graph"
          color="#7ed9d9"
        />
      </div>
      <div class="fancy-card pa-2 mt-4">
        <line-chart
          height="295"
          graph-id="adr"
          title="ADR"
          color="#42A5F5"
          :stats-object="monthlyAdr"
          :months-names="months"
        />
      </div>
      <div class="fancy-card pa-2 mt-4">
        <line-chart
          height="295"
          graph-id="occupancy"
          title="Occupancy"
          color="#71f183"
          :stats-object="monthlyOccupancy"
          :months-names="months"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ReviewStatsBox from 'components/reviews/review-stats-box'
import FormattersMixin from 'components/mixins/formatters-mixin'
import LineChart from 'components/line-chart'
import { MONTHS } from '@/consts'
import MonthlyStatsGraph from 'components/monthly-stats-graph'
import SourcesGraphNew from 'components/sources-graph-new'
import InfoRow from 'components/portfolio/info-row'
import navigationMixin from 'components/mixins/navigation-mixin'
export default {
  name: 'OverviewPortfolio',
  components: {
    InfoRow,
    SourcesGraphNew,
    MonthlyStatsGraph,
    LineChart,
    ReviewStatsBox,
  },
  mixins: [FormattersMixin, navigationMixin],
  props: ['data', 'year'],
  data() {
    return {
      selectedRow: null,
    }
  },
  computed: {
    months() {
      return MONTHS
    },
    stats() {
      return this.data.stats
    },
    headers() {
      return [
        { text: 'Listing', value: 'nickname' },
        {
          text: this.$t('Health'),
          align: 'center',
          value: 'current_health',
          sortable: true,
        },
        {
          text: this.$t('Ops Expense ratio'),
          align: 'center',
          value: 'ytd_expense_ratio',
          sortable: true,
        },
        {
          text: this.$t('Cash'),
          align: 'center',
          value: 'ytd_cash_on_cash',
          sortable: true,
        },
        {
          text: this.$t('Occupancy'),
          align: 'center',
          value: 'ytd_occupancy',
          sortable: true,
        },
        {
          text: this.$t('ADR'),
          align: 'center',
          value: 'ytd_adr',
          sortable: true,
        },
      ]
    },
    expensesSources() {
      return Object.keys(this.data.expenses_sources).length < 12
        ? this.data.expenses_sources
        : this.data.expenses_divisions
    },
    monthlyOccupancy() {
      return this.data.monthly_occupancy.map(o => this.round(o * 100, 0) || 0)
    },
    monthlyAdr() {
      return this.data.monthly_adr.map(a => this.round(a) || 0)
    },
    listingItems() {
      return this.data.listings.map(l => {
        return {
          ...l,
          selected: this.selectedRow && this.selectedRow.id === l.id,
          ytd_expense_ratio:
            (l.statistics[this.year] &&
              l.statistics[this.year]['ytd_expense_ratio']) ||
            0,
          current_health:
            (l.statistics[this.year] && (l.current_health || 0) * 100) || 0,
          ytd_cash_on_cash:
            (l.statistics[this.year] &&
              l.statistics[this.year]['ytd_cash_on_cash']) ||
            0,
          ytd_occupancy:
            (l.statistics[this.year] &&
              l.statistics[this.year]['ytd_occupancy']) ||
            0,
          ytd_adr:
            (l.statistics[this.year] && l.statistics[this.year]['ytd_adr']) ||
            0,
          sold_total:
            (l.statistics[this.year] && l.statistics[this.year]['ytd_price']) ||
            0,
        }
      })
    },
  },
  methods: {
    itemClass(item) {
      return item.selected && 'blue lighten-5'
    },
    rowClick(item) {
      if (!item || (this.selectedRow && this.selectedRow.id === item.id)) {
        this.selectedRow = null
      } else {
        this.selectedRow = item
      }
      this.$emit(
        'listing-changed',
        this.selectedRow ? [this.selectedRow.id] : null
      )
    },
    sortPercent(a, b) {
      return +a.slice(0, -1) > +b.slice(0, -1) ? 1 : -1
    },
  },
}
</script>

<style scoped>
.fancy-card {
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 30px #0000000d;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
}
.secondary-text {
  color: #707070;
  display: flex;
}
</style>
