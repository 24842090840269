<template>
  <div>
    <div class="grey lighten-4">
      <v-container class="pb-0 max-w-1600">
        <v-tabs
          v-model="activeTab"
          slider-color="white"
          center-active
          background-color="#f6f6f7"
          active-class="white"
        >
          <v-tab>
            <v-icon left>mdi-format-list-checks</v-icon>
            {{ $t('Task Catalog') }}
          </v-tab>
          <v-tab>
            <v-icon left>mdi-format-list-bulleted</v-icon>
            {{ $t('Info Definitions') }}
          </v-tab>
        </v-tabs>
      </v-container>
    </div>

    <v-progress-linear
      v-show="listingLoading"
      color="secondary"
      style="margin: 0"
      absolute
      :indeterminate="true"
    />

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <listing-task-catalog-items-table />
      </v-tab-item>
      <v-tab-item>
        <listing-info-definitions-table class="mt-4" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ListingInfoDefinitionsTable from '../info-item-definition/listing-info-definitions-table'
import ListingTaskCatalogItemsTable from '../listing-tasks-catalog/listing-task-catalog-items-table'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'OperationsSettings',

  components: {
    ListingInfoDefinitionsTable,
    ListingTaskCatalogItemsTable,
  },

  mixins: [CommonFunctions],

  data: () => ({
    activeTab: 0,
  }),
}
</script>
