<template>
  <v-tooltip bottom max-width="600">
    <template #activator="{ on }">
      <v-icon class="ml-2" color="primary" v-on="on">mdi-help-circle</v-icon>
    </template>
    <div class="formula-tooltip">
      <div class="tooltip-title">Available Formula Variables</div>
      <div
        v-for="category in groupedVariables"
        :key="category.name"
        class="variables-section"
      >
        <div class="section-title">{{ category.name }} Variables</div>
        <div class="variable-list">
          <div
            v-for="variable in category.variables"
            :key="variable.value"
            class="variable-item"
          >
            <span class="variable-name">{{ variable.value }}</span>
            <span class="variable-desc">{{ variable.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
import { formulaVariables } from '@/consts'

export default {
  name: 'FormulaVariablesToolTip',

  computed: {
    groupedVariables() {
      const grouped = formulaVariables.reduce((acc, variable) => {
        const category = variable.category || 'Other'
        if (!acc[category]) {
          acc[category] = []
        }
        acc[category].push(variable)
        return acc
      }, {})

      return Object.entries(grouped).map(([name, variables]) => ({
        name,
        variables,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.formula-tooltip {
  padding: 16px;

  .tooltip-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 12px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 8px;
  }

  .variables-section {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .section-title {
      font-weight: 600;
      color: #fff;
      margin-bottom: 8px;
      font-size: 0.95em;
    }

    .variable-list {
      .variable-item {
        display: flex;
        margin-bottom: 6px;
        align-items: baseline;

        &:last-child {
          margin-bottom: 0;
        }

        .variable-name {
          color: #8ce99a;
          font-family: monospace;
          font-weight: 600;
          margin-right: 8px;
          min-width: 160px;
        }

        .variable-desc {
          color: rgba(255, 255, 255, 0.9);
          font-size: 0.9em;
          line-height: 1.4;
        }
      }
    }
  }
}
</style>
