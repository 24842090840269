<template>
  <v-card>
    <conversation-info-title title="Issues" />
    <v-divider />
    <v-card-text class="pa-4">
      <!-- KPI Stats -->
      <v-row class="mb-6">
        <div class="d-flex align-center w-100">
          <v-col cols="4" class="text-center pa-3">
            <div class="text-h6 font-weight-bold primary--text">
              {{ (issues || []).length }}
            </div>
            <div
              class="text-caption text-secondary text-uppercase font-weight-medium"
            >
              Total
            </div>
          </v-col>
          <v-divider vertical />
          <v-col cols="4" class="text-center pa-3">
            <div class="text-h6 font-weight-bold warning--text">
              {{ openIssuesCount }}
            </div>
            <div
              class="text-caption text-secondary text-uppercase font-weight-medium"
            >
              Open
            </div>
          </v-col>
          <v-divider vertical />
          <v-col cols="4" class="text-center pa-3">
            <div class="text-h6 font-weight-bold error--text">
              {{ criticalIssuesCount }}
            </div>
            <div
              class="text-caption text-secondary text-uppercase font-weight-medium"
            >
              Critical
            </div>
          </v-col>
        </div>
      </v-row>

      <!-- Service Calls Header -->
      <div class="d-flex align-center justify-space-between mb-6">
        <div class="d-flex align-center">
          <span class="text-subtitle-1 font-weight-medium mr-4">
            {{ isOutStanding ? 'Outstanding issues' : 'Service calls' }}
          </span>
          <listing-task-module
            :pre-filled-listing-id="[reservation.listing_id]"
            :reservation="reservation"
            :after-save-func="afterTaskCreate"
          >
            <template #customBtn="{ on }">
              <v-btn
                color="primary"
                small
                class="text-none"
                elevation="1"
                v-on="on"
              >
                <v-icon left small>mdi-plus</v-icon>
                {{ $t('New SC') }}
              </v-btn>
            </template>
          </listing-task-module>
        </div>
      </div>

      <!-- Service Calls List -->
      <div class="service-calls-list">
        <div
          v-for="issue in issues || []"
          :key="issue.id"
          class="service-call-item mb-4"
        >
          <v-card outlined class="pa-4">
            <!-- Header -->
            <div class="d-flex align-center justify-space-between mb-3">
              <div class="d-flex align-center">
                <v-chip
                  x-small
                  :color="listingTaskStatusColor(issue.status)"
                  class="mr-3 text-uppercase"
                  label
                >
                  {{ issue.status }}
                </v-chip>
                <router-link
                  class="text-decoration-none"
                  target="_blank"
                  :to="`/dashboard/listing-task/${issue.id}`"
                >
                  <span class="text-subtitle-2 primary--text"
                    >#{{ issue.id }}</span
                  >
                </router-link>
                <v-tooltip
                  v-if="
                    issue.extra_data?.created_on_guest?.id ===
                    conversation.guest.id
                  "
                  top
                >
                  <template #activator="{ on }">
                    <v-icon small class="error--text ml-2" v-on="on"
                      >mdi-alert-circle</v-icon
                    >
                  </template>
                  <div class="pa-2">
                    <div>Reported by guest</div>
                    <div class="text-caption">{{ issue.reported_by_name }}</div>
                  </div>
                </v-tooltip>
              </div>
              <div class="d-flex align-center">
                <v-chip
                  x-small
                  :color="priorityChipColor(issue.priority)"
                  class="text-uppercase"
                  label
                >
                  {{ issue.priority }}
                </v-chip>
              </div>
            </div>

            <!-- Content -->
            <div class="mb-3">
              <div class="d-flex align-center text-body-2 text-secondary mb-2">
                <span>{{ issue.task_type }}</span>
                <v-icon x-small class="mx-1">mdi-chevron-right</v-icon>
                <span>{{ issue.sub_topic }}</span>
                <v-icon x-small class="mx-1">mdi-chevron-right</v-icon>
                <span>{{ issue.listing_task_catalog_item_name }}</span>
              </div>
              <div class="black--text">{{ issue.description }}</div>
              <div
                v-if="issue.internal_comments"
                class="mt-2 text-caption font-italic"
              >
                Internal Comments: {{ issue.internal_comments }}
              </div>
            </div>

            <!-- Footer -->
            <div class="d-flex align-center text-caption text-secondary">
              <v-icon x-small class="mr-1">mdi-calendar</v-icon>
              <span>Scheduled: </span>
              <span class="font-weight-medium ml-1">
                {{ parseDateWithTimeIfPresent(issue.scheduled_at) }}
              </span>
            </div>
          </v-card>
        </div>
      </div>

      <v-divider class="my-6" />

      <!-- Personal Section -->
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="px-1">
            <div class="d-flex align-center">
              <v-icon color="secondary" class="mr-2">mdi-account-cog</v-icon>
              <span class="text-subtitle-2">Personal</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="px-1">
            <Personal
              :personal="listing.personal"
              disabled
              :hide-save="true"
              :override-personal-types="[
                'cleaning_supervisor',
                'pool',
                'pest',
                'landscape',
                'host',
                'region_manager',
                'general_maintenance',
                'design_maintenance',
                'appliances',
                'electrician',
                'plumber',
              ]"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import ListingTaskModule from '../listing-task-module'
import Personal from 'components/listing/personal'

export default {
  components: {
    ListingTaskModule,
    ConversationInfoTitle,
    Personal,
  },
  mixins: [CommonFunctions, PermissionsMixin, ColorsMixin],
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
    issuesField: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    reportedByComm: false,
    issuesPri: ['Critical', 'High'],
  }),
  computed: {
    issues() {
      return this.conversation[this.issuesField || 'issues'] || []
    },
    isOutStanding() {
      return this.issuesField == 'outstanding_issues'
    },
    listing() {
      return this.reservation.listing
    },
    openIssuesCount() {
      return this.issues.filter(issue => issue.status !== 'Done').length
    },
    criticalIssuesCount() {
      return this.issues.filter(issue => issue.priority === 'Critical').length
    },
  },
  methods: {
    afterTaskCreate() {
      setTimeout(() => {
        this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
      }, 500)
    },
    priorityChipColor(priority) {
      switch (priority) {
        case 'Critical':
          return 'error'
        case 'High':
          return 'warning'
        case 'Medium':
          return 'info'
        default:
          return 'secondary'
      }
    },
  },
}
</script>

<style scoped>
.service-call-item {
  transition: transform 0.2s ease;
}

.service-call-item:hover {
  transform: translateX(4px);
}

:deep(.v-expansion-panel) {
  background-color: transparent !important;
}

:deep(.v-expansion-panel::before) {
  box-shadow: none !important;
}

:deep(.v-expansion-panel-header) {
  padding: 8px 0;
}

:deep(.v-expansion-panel-content__wrap) {
  padding: 12px 0;
}

:deep(.v-card.v-card--outlined) {
  border-color: var(--v-secondary-lighten2);
  transition: box-shadow 0.2s ease;
}

:deep(.v-card.v-card--outlined:hover) {
  border-color: var(--v-primary-base);
  box-shadow: var(--v-shadow-sm);
}

:deep(.v-chip) {
  font-size: 11px;
  height: 20px;
  font-weight: 500;
}

:deep(.v-icon) {
  font-size: 18px;
}

:deep(.v-icon.v-icon--small) {
  font-size: 16px;
}

:deep(.v-icon.v-icon--x-small) {
  font-size: 14px;
}
</style>
