<template>
  <div>
    <expense-modal :edit="true" :edit-expense="editExpense" />
    <v-card>
      <v-card-title>
        <v-row class="align-center pl-1">
          <v-col col="12" md="auto">
            <span
              :class="
                reservationId ? 'ext-body-2 font-weight-semibold' : 'grey-text'
              "
            >
              Expenses {{ title }}
            </span>
          </v-col>
          <v-col v-if="showFilters" cols="12" md="auto">
            <span class="grey-text">Total Expenses: </span><br />
            <span class="headline-font bolder purple-icon">{{
              toMoney(total)
            }}</span>
          </v-col>
          <v-col v-if="showFilters" cols="12" md="auto">
            <span class="grey-text">Total Hidden: </span><br />
            <span class="headline-font bolder purple-icon">{{
              toMoney(totalHidden)
            }}</span>
          </v-col>
          <v-col v-if="enableGlobalCreate" cols="12" md="auto">
            <expense-modal :central-bank-view="true" />
          </v-col>
        </v-row>
        <v-row v-if="showFilters" class="p-relative" wrap align-baseline>
          <v-col cols="12" md="auto">
            <filters-select
              :label="$t('Categories')"
              :value.sync="filters.catagories"
              :items="expenseTypesNames"
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <filters-select
              :label="$t('Division')"
              :value.sync="filters.divisions"
              :items="budgetDivisions"
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="6" md="auto">
            <date-picker
              v-model="filters.from"
              :label="$t('From')"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10)
              "
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="6" md="auto">
            <date-picker
              v-model="filters.to"
              :label="$t('To')"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10)
              "
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="6" md="auto">
            <v-text-field
              v-model="filters.fromPrice"
              type="number"
              :disabled="isExpensesLoading"
              prepend-inner-icon="money"
              outlined
              dense
              :label="$t('From price')"
              required
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="6" md="auto">
            <v-text-field
              v-model="filters.toPrice"
              :disabled="isExpensesLoading"
              outlined
              dense
              prepend-inner-icon="money"
              :label="$t('To price')"
              required
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              v-model="filters.storeSearch"
              hide-details
              dense
              outlined
              label="Search By store/source"
              clearable
              @input="fetchExpenses()"
            />
          </v-col>

          <v-col v-if="enableGlobalCreate" cols="5" md="auto" class="pl-3">
            <multi-property-picker
              v-model="filters.listingsIds"
              :filter-change="fetchExpenses"
              :disabled="isExpensesLoading"
              :close-on-content-click="true"
            />
          </v-col>
          <v-col v-if="enableGlobalCreate" cols="5" md="auto" class="pl-3">
            <v-select
              v-model="filters.type"
              outlined
              dense
              clearable
              :label="$t('Expense type')"
              :items="[
                { value: 'ClientExpense', text: 'Property' },
                { value: 'BusinessExpense', text: 'Business' },
              ]"
              @change="fetchExpenses"
            />
          </v-col>
          <v-col
            v-if="isPropertyManager || isAdmin"
            cols="6"
            md="auto"
            class="pl-3"
          >
            <v-select
              v-model="filters.paid_by_us"
              outlined
              dense
              clearable
              label="Paid by"
              :items="[
                { value: true, text: 'Paid by us' },
                { value: false, text: 'Paid by owner' },
              ]"
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <filters-select
              :label="$t('Region')"
              :value.sync="filters.regions"
              :items="listingRegions"
              :selection-func="item => item.text"
              @change="fetchExpenses"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-select
              v-model="filters.created_by"
              outlined
              dense
              clearable
              :label="$t('Created By')"
              :items="usersList"
              item-text="value"
              item-value="id"
              @change="fetchExpenses"
            />
          </v-col>
          <v-col v-if="isPropertyManager || isAdmin" cols="12" md="auto">
            <v-switch
              v-model="filters.manual_only"
              class="mt-0"
              :label="$t('Manual only')"
              dense
              @change="fetchExpenses"
            />
          </v-col>
          <v-col v-if="isPropertyManager || isAdmin" cols="12" md="auto">
            <v-switch
              v-model="showRules"
              class="mt-0"
              :label="$t('Show expense rules')"
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :loading="isExpensesLoading"
        :headers="headers"
        :items="tableItems"
        :single-select="false"
        :show-select="!reservationId"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100, 200],
        }"
        class="elevation-1"
        :options="paginationServer"
        :server-items-length="paginationServer.count"
        @pagination="paginationChanged($event)"
      >
        <template #top>
          <div
            v-if="!reservationId"
            class="d-flex pa-2 justify-space-between align-start align-sm-center flex-column flex-sm-row"
          >
            <div class="d-flex flex-wrap">
              <v-radio-group label="Roll Over">
                <v-checkbox
                  v-model="excludeRollOver"
                  class="ma-0"
                  dense
                  hide-details
                  :label="$t('Exclude roll over')"
                  @change="fetchExpenses"
                />
              </v-radio-group>
              <v-divider vertical class="mx-3" />
              <v-radio-group v-model="onlySetup" label="Divisions">
                <div class="d-flex">
                  <div>
                    <v-checkbox
                      v-model="onlySetup"
                      class="ma-0"
                      :label="$t('Only setup')"
                      dense
                      hide-details
                      value="show_setup"
                      @change="fetchExpenses"
                    />
                    <v-checkbox
                      v-model="onlySetup"
                      class="ma-0"
                      :label="$t('Not setup')"
                      value="hide_setup"
                      dense
                      hide-details
                      @change="fetchExpenses"
                    />
                  </div>
                  <div class="mx-3">
                    <v-checkbox
                      v-model="onlySetup"
                      class="ma-0"
                      :label="$t('Cost base')"
                      value="show_cost_base"
                      dense
                      hide-details
                      @change="fetchExpenses"
                    />
                    <v-checkbox
                      v-model="onlySetup"
                      class="ma-0"
                      :label="$t('Not cost base')"
                      value="hide_cost_base"
                      dense
                      hide-details
                      @change="fetchExpenses"
                    />
                  </div>
                </div>
              </v-radio-group>
              <v-divider vertical class="mx-3" />
              <v-radio-group v-model="onlyHidden" label="Hidden">
                <v-checkbox
                  v-model="onlyHidden"
                  class="ma-0"
                  dense
                  value="show"
                  hide-details
                  :label="$t('Only hidden')"
                  @change="fetchExpenses"
                />
                <v-checkbox
                  v-model="onlyHidden"
                  dense
                  hide-details
                  value="hide"
                  class="ma-0"
                  :label="$t('Not hidden')"
                  @change="fetchExpenses"
                />
              </v-radio-group>
            </div>

            <div>
              <expense-actions
                v-if="hasAbility('hide-expense')"
                :disabled="!selected.length"
                @action="updateExpenses"
              />
              <v-menu bottom>
                <template #activator="{ on, attrs }">
                  <v-btn class="mx-3" outlined v-bind="attrs" v-on="on">
                    <v-icon left>mdi-export-variant</v-icon>
                    export
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link>
                    <v-list-item-title @click="exportTo('xero')"
                      >Xero
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="exportTo('excel')"
                      >Excel
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </template>
        <template #item="{ item, isSelected, select }">
          <tr class="text-center" :class="isReconciledClass(item)">
            <td v-if="!reservationId">
              <v-simple-checkbox
                dense
                hide-details
                :value="isSelected"
                @input="select($event)"
              />
            </td>
            <td v-if="managementView">
              {{ item.short_reason }}
            </td>
            <td v-if="managementView">
              <v-tooltip v-if="item.related_to_setup" top>
                <template #activator="{ on }">
                  <v-icon
                    style="color: lightcyan"
                    class="icon alt stats-icon mr-1"
                    v-on="on"
                    >fas fa-truck-loading</v-icon
                  ></template
                >
                <v-layout wrap style="min-width: 200px">
                  This expense is related to the listing setup stage, it will be
                  treated as part of the investment.
                </v-layout>
              </v-tooltip>
              {{ item.related_division }}
            </td>
            <td v-if="managementView">
              <v-icon v-if="item.hidden">check</v-icon>
            </td>
            <td v-if="managementView">
              <v-icon v-if="item.related_to_setup">fas fa-truck-loading</v-icon>
              <v-icon v-if="item.related_cost_base"> fas fa-donate </v-icon>
            </td>
            <td v-if="managementView" class="text-sm-center">
              <span v-if="item.type === 'BusinessExpense'" class="cyan-icon">
                * Company expense *</span
              >
              {{ createdBy(item) }}
            </td>
            <td v-if="managementView" class="text-sm-center">
              <div v-if="!showRules">
                <v-icon v-if="item.bank_transaction_id" class="cyan-icon"
                  >done_all</v-icon
                >
                <div v-else>
                  <v-icon v-if="!item.manual_dismiss" class="purple-icon"
                    >close</v-icon
                  >
                  <dismiss-reason-modal
                    :dismissed="item.manual_dismiss"
                    :item="item"
                    type="Expense"
                    @after-action="onDismiss"
                  />
                </div>
              </div>
            </td>
            <td v-if="managementView" class="text-sm-center">
              {{ getListingName(item) }}
            </td>
            <td v-if="managementView" class="text-sm-center">
              {{ getAccountNumber(item) }}
            </td>
            <td class="text-sm-center">
              {{ round(item.amount, 2).toLocaleString('en') }}
            </td>
            <td class="text-sm-center">
              <span v-if="item.source" class="mr-1">{{ item.source }}</span>
            </td>
            <td class="text-sm-center">
              <span v-if="item.source" class="mr-1">{{
                item.short_reason
              }}</span>
            </td>
            <td>
              <span v-if="item.work_info.done_at">{{
                parseDate(item.work_info.done_at)
              }}</span>
            </td>
            <td class="text-sm-center">
              <v-layout column>
                <v-flex>
                  <span v-if="item.expense_rule_id && isAdmin" class="cyan-icon"
                    >Expense rule generated
                  </span>
                </v-flex>
                <v-flex>
                  <span v-if="item.type === 'MonthlyExpense'" class="cyan-icon"
                    >Repeating from:
                  </span>
                  <span>{{ parseDate(item.paid_at) }}</span>
                </v-flex>
              </v-layout>
              <v-layout
                v-if="item.type === 'MonthlyExpense' && item.deactivated_at"
                column
              >
                <v-flex>
                  <span class="cyan-icon">Until: </span>
                  <span>
                    {{ parseDate(item.deactivated_at) }}
                  </span>
                </v-flex>
              </v-layout>
            </td>
            <td class="justify-center px-0">
              <v-icon small class="mr-2" @click="updateChosenExpense(item)">
                search
              </v-icon>
              <v-icon
                v-if="hasAbility('super-accountant') && !reservationId"
                small
                :disabled="canDeleteFinancialData(item.paid_at, currentUser)"
                class="mr-2"
                @click="deleteExpense(item)"
              >
                delete
              </v-icon>
              <v-icon
                v-if="(isAdmin || isPropertyManager) && !reservationId"
                small
                :disabled="canDeleteFinancialData(item.paid_at, currentUser)"
                @click="updateEditExpense(item)"
              >
                edit
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import AccountingMixin from 'components/mixins/accounting-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ExpenseModal from 'components/expenses/expense-modal'
import { mapGetters, mapState } from 'vuex'
import FiltersSelect from 'components/form-fields/filters-select'
import ExpenseActions from 'components/expenses/expense-actions'
import DismissReasonModal from 'components/dismiss-reason-modal'
import DatePicker from 'components/form-fields/date-picker'
import get from 'lodash/fp/get'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    MultiPropertyPicker,
    DatePicker,
    ExpenseActions,
    FiltersSelect,
    ExpenseModal,
    DismissReasonModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, AccountingMixin, FormattersMixin],
  props: [
    'listing',
    'showFilters',
    'managementView',
    'title',
    'updateMethod',
    'refetchMethod',
    'enableGlobalCreate',
    'expensesItems',
    'reservationId',
  ],
  data() {
    const date = new Date()
    const twoWeeksAgoDate = new Date()
    twoWeeksAgoDate.setDate(twoWeeksAgoDate.getDate() - 14)
    const toDay = date.getDate() + 1
    const toMonth = date.getMonth()
    const fromDay = twoWeeksAgoDate.getDate() + 1
    const fromMonth = twoWeeksAgoDate.getMonth()

    return {
      onlyHidden: '',
      onlySetup: '',
      excludeRollOver: true,
      selected: [],
      search: null,
      showRules: false,
      manualOnly: false,
      expense: null,
      paginationServer: {
        page: 1,
        itemsPerPage: 10,
      },
      total: 0,
      totalHidden: 0,
      filters: {
        from: new Date(twoWeeksAgoDate.getFullYear(), fromMonth, fromDay)
          .toISOString()
          .substr(0, 10),
        to: new Date(date.getFullYear(), toMonth, toDay)
          .toISOString()
          .substr(0, 10),
        fromPrice: 1,
        toPrice: 100000,
        catagories: [],
        manual_only: false,
        storeSearch: null,
        divisions: [],
        listingsIds: this.listing ? this.listing.id : null,
        reservationId: this.reservationId,
        type: null,
        created_by: null,
      },
    }
  },
  methods: {
    ...mapActions('expenseTypes', ['fetchAllExpenseTypes']),
    getAccountNumber(item) {
      return get('bank_account.account_number', item)
    },
    getListingName(item) {
      return get('listing.nickname', item)
    },
    async updateExpenses(data) {
      const ids = this.selected.map(item => item.id)
      await this.$store.dispatch('expenses/updateExpensesBatch', {
        ids,
        data,
      })
      await this.fetchExpenses()
      this.selected = []
    },
    async fetchExpenses() {
      const data = await this.$store.dispatch('getExpensesByFilter', {
        ...this.filters,
        page: this.paginationServer.page,
        per_page: this.paginationServer.itemsPerPage,
        setup_filter: this.onlySetup,
        hidden_filter: this.onlyHidden,
        exclude_rollover: this.excludeRollOver,
      })
      this.paginationServer.count = data.pagination.count
      this.total = data.total
      this.totalHidden = data.total_hidden
    },
    onDismiss() {
      this.$store.dispatch('changeEditListingBG', this.listing.id)
    },
    paginationChanged(pagination) {
      if (this.paginationServer.itemsPerPage !== pagination.itemsPerPage) {
        this.paginationServer.itemsPerPage = pagination.itemsPerPage
        this.paginationServer.page = 1
        this.fetchExpenses()
      } else if (
        this.paginationServer.page !== pagination.page &&
        !this.filters.listingsIds
      ) {
        this.paginationServer.page = pagination.page
        this.fetchExpenses(pagination.page)
      }
    },
    isReconciledClass(item) {
      if (this.managementView) {
        if (this.showRules) {
          return 'light-grey'
        }
        if (item.bank_transaction_id || item.manual_dismiss) {
          return 'green lighten-5'
        } else {
          return 'super-light-red'
        }
      }
    },
    updateEditExpense(expense) {
      this.$store.commit('updateEditExpense', expense)
    },
    parseDate(date) {
      return this.$moment(date).format('YYYY/MM/DD')
    },
    createdBy(expense) {
      if (expense.created_by_name) {
        return expense.created_by_name
      }
      if (expense.user_id) {
        return this.nameById(expense.user_id)
      }
      return 'system'
    },
    updateChosenExpense(expense) {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: { expense },
      })
    },
    deleteExpense: function (expense) {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        this.$store.commit('updateListingLoading', true)

        axios
          .delete('/api/expenses/' + expense.id)
          .then(() => {
            this.fetchExpenses()
            this.$store.dispatch('getManagementStats')
            this.$store.commit('updateListingLoading', false)
          })
          .catch(() => ({}))
      }
    },
    exportTo(format) {
      this.$store.dispatch('getExpensesByFilter', {
        ...this.filters,
        setup_filter: this.onlySetup,
        excel: true,
        hidden_filter: this.onlyHidden,
        exclude_rollover: this.excludeRollOver,
        format,
      })
    },
  },
  mounted() {
    this.fetchAllExpenseTypes()
    if (this.enableGlobalCreate) {
      this.$store.dispatch('getExpensesByFilter', {
        ...this.$store.state.lastExpensesFilter,
        from: this.filters.from,
        to: this.filters.to,
      })
    } else {
      this.fetchExpenses()
    }
  },
  computed: {
    ...mapState([
      'isExpensesLoading',
      'recentExpenses',
      'recentExpensesPagination',
      'recentExpensesRules',
      'editExpense',
    ]),
    ...mapState('regions', ['regions']),
    ...mapGetters(['currentUser']),
    ...mapGetters('expenseTypes', ['expenseTypesNames']),
    ...mapGetters('users', ['activeUsers']),
    pagiLength() {
      if (this.paginationServer.count) {
        return Math.ceil(
          this.paginationServer.count / this.paginationServer.per_page
        )
      }
      return null
    },
    listingRegions() {
      return this.regions.map(r => ({ text: r.name, value: r.id }))
    },
    tableItems() {
      if (this.showRules) {
        return this.recentExpensesRules
      }
      return this.recentExpenses
    },
    headers() {
      let headers = [
        { text: 'Price', align: 'center', value: 'amount' },
        { text: 'Source', align: 'center', sortable: false },
        { text: 'Reason', align: 'center', sortable: false },
        { text: 'Done at', align: 'center', value: 'done_at' },
        { text: 'Paid at', align: 'center', value: 'paid_at' },
        { text: 'Actions', align: 'center', sortable: false },
      ]
      if (this.managementView) {
        headers.unshift({ text: 'Card', align: 'center', sortable: false })
        headers.unshift({ text: 'Listing', align: 'center', sortable: false })
        headers.unshift({
          text: 'Reconciled',
          align: 'center',
          sortable: false,
        })
        headers.unshift({
          text: 'Created by',
          align: 'center',
          sortable: false,
        })
        headers.unshift({
          text: 'Cost Base/Setup',
          align: 'center',
          sortable: false,
        })
        headers.unshift({
          text: 'Hidden',
          align: 'center',
          value: 'hidden',
          sortable: false,
        })
        headers.unshift({
          text: 'Division',
          align: 'center',
          value: 'related_division',
          sortable: false,
        })
        headers.unshift({
          text: 'Type',
          align: 'center',
          value: 'short_reason',
          sortable: false,
        })
      }
      return headers
    },
    usersList() {
      return [
        { id: 'system', value: 'System' },
        ...this.activeUsers
          .filter(this.isManagementRolesUser)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(u => ({
            id: u.id,
            value: u.name,
          })),
      ]
    },
  },
}
</script>
