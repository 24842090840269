<template>
  <v-row class="listing-picker-new align-baseline">
    <v-col md="auto" class="px-3 py-2 d-flex align-end">
      <v-select
        v-model="selectedReservation"
        :items="resOptions"
        hide-details
        :readonly="readonly"
        label="Reservations"
        item-value="reservation_guesty_id"
        @change="reservationChanged"
      >
        <template slot="selection" slot-scope="data">
          <div class="d-flex align-center flex">
            <v-chip small :color="resStatusColor(getDisplayStatus(data.item))">
              <span class="font-weight-medium">
                {{ getDisplayStatus(data.item) }}
              </span>
            </v-chip>
            <div class="text-body-1 black--text mb-0 font-weight-medium ml-2">
              {{ data.item.confirmation_code || 'Reservation' }}
            </div>
          </div>
        </template>
        <template
          slot="item"
          slot-scope="data"
          class="listing-picker purple-icon mb-1 bb-1 bt-1 pb-1"
        >
          <div :key="data.item.confirmation_code" class="ml-3">
            <span class="cyan-icon pr-2 br-1">{{ data.item.source }}</span>
            <span v-if="data.item.confirmation_code" class="pl-2 br-1 pr-2">
              {{ data.item.confirmation_code }}</span
            >
            <span v-if="data.item.price" class="purple-icon pl-2"
              >Price: {{ toMoney(data.item.price) }}</span
            >
            <span
              v-if="data.item.is_merchant"
              class="cyan-icon pl-2"
              :class="data.item.balance_due > 0 ? 'red-warn' : 'green-health'"
              >Balance: {{ toMoney(data.item.balance_due) }}</span
            >
          </div>
        </template>
      </v-select>
      <copy-clipboard
        v-if="selectedReservation && getSelectedReservation?.confirmation_code"
        :text="getSelectedReservation.confirmation_code"
        class="ml-2"
      />
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import CopyClipboard from 'components/copy-clipboard'

export default {
  components: { CopyClipboard },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['preSelected', 'resOptions', 'readonly'],
  data() {
    return {
      selectedReservation: this.preSelected,
    }
  },
  mounted() {
    this.reservationChanged(this.getSelectedReservation.reservation_guesty_id)
  },
  computed: {
    getSelectedReservation() {
      if (this.selectedReservation) {
        return this.resOptions.find(
          res => res.reservation_guesty_id === this.selectedReservation
        )
      }
      const now = new Date()
      const activeReservation = this.resOptions.find(res => {
        const checkIn = new Date(res.check_in)
        const checkOut = new Date(res.check_out)
        return checkIn <= now && now <= checkOut
      })
      if (activeReservation) return activeReservation
      const upcomingReservations = this.resOptions
        .filter(res => new Date(res.check_out) > now)
        .sort((a, b) => new Date(a.check_out) - new Date(b.check_out))
      return upcomingReservations[0] || this.resOptions[0]
    },
  },
  methods: {
    reservationChanged(val) {
      this.selectedReservation = val
      this.$emit('changed', val)
    },
  },
  watch: {
    preSelected() {
      this.selectedReservation = this.preSelected
    },
  },
}
</script>
