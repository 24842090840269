<template>
  <v-row>
    <v-col v-for="field in fields" :key="field.title" cols="6" sm="4" xl="2">
      <review-stats-box
        :loading="fetching"
        :title="field.title"
        :tooltip="field.tooltip"
        :color="field.color"
        :value="formattedValue(field)"
      />
    </v-col>
    <!--    <v-col v-if="!isSmartBnb" cols="6" sm="4" xl="2">-->
    <!--      <stats-item-->
    <!--        :loading="fetching"-->
    <!--        :field="netRevenue"-->
    <!--        :icon-size="65"-->
    <!--        :filled="true"-->
    <!--      />-->
    <!--    </v-col>-->
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import round from 'lodash/fp/round'
import getOr from 'lodash/fp/getOr'
import DeviceMixin from 'components/mixins/device-mixin'
import ReviewStatsBox from 'components/reviews/review-stats-box'
import FormattersMixin from 'components/mixins/formatters-mixin'
import get from 'lodash/fp/get'
import { mapState } from 'vuex'
export default {
  components: {
    ReviewStatsBox,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, FormattersMixin],
  props: ['period', 'stats', 'fetching'],
  methods: {
    formattedValue(field) {
      if (['ADR', 'Owner Income', 'NET'].includes(field.title)) {
        return this.toMoney(field.text, { decimals: 0 })
      }
      return field.text.toLocaleString('en')
    },
  },
  computed: {
    ...mapState(['currentListing']),
    incomeValue() {
      const { owners_income_formula } = this.$store.state.app.configuration
      const formula =
        this.currentListing.owners_income_formula || owners_income_formula
      const VALUES = {
        client_profit: 'total_owner_income',
        fare_accommodation: 'summary.fare_accommodation',
        net_income: 'summary.net_income',
        price_total: 'summary.price_total',
        client_price: 'summary.client_price_total',
      }
      return get(formula ? VALUES[formula] : 'summary.income', this.stats)
    },
    netRevenue() {
      return {
        title: this.$t('NET'),
        icon: '$profits',
        color: 'orange lighten-2',
        text:
          get('summary.income', this.stats) -
          get('summary.expenses', this.stats),
      }
    },
    fields() {
      return [
        {
          title: this.$t('ADR'),
          text: this.stats.daily_adr,
          tooltip: `Average accommodation fare per night for this ${this.period}`,
          icon: '$calendar',
          color: 'orange',
        },
        {
          title: this.$t('Avg Nights'),
          text: this.stats.avg_nights,
          tooltip: `Average nights per reservation for this ${this.period}`,
          icon: '$moon',
          color: 'info',
        },
        {
          title: this.$t('Booked Before'),
          text: round(this.stats.booked_before) + ' Days',
          tooltip: `The amount of time reservations were made prior to the check-in date for this ${this.period}`,
          icon: '$clock',
          color: 'primary',
        },
        {
          title: this.$t('Occupancy'),
          text: round(getOr(0, 'occupancy', this.stats) * 100, 2) + '%',
          tooltip: `The amount of time this listing is occupied divided by the whole ${this.period} or up to the current date`,
          icon: '$home',
          color: 'darkpurple',
        },
        {
          title: this.$t('Income'),
          text: this.toMoney(this.incomeValue, { decimals: 0 }),
          tooltip: `The total income for this ${this.period}`,
          icon: '$money',
          color: 'success',
        },
      ]
    },
  },
}
</script>
