<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div class="text-h6 font-weight-bold primary--text">
          Chart of Accounts
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mr-4"
        ></v-text-field>
        <ledger-account-create-modal @created="fetchAccounts" />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="accounts"
        :search="search"
        :loading="loading"
        class="elevation-1"
        :sort-by="['balance']"
        :sort-desc="[true]"
      >
        <template #item.balance="{ item }">
          <span :class="getBalanceClass(item)">
            {{ $options.filters.currency(item.balance) }}
          </span>
        </template>
        <template #item.account_type="{ item }">
          <v-chip
            small
            :color="getAccountTypeColor(item.account_type)"
            :text-color="getAccountTypeTextColor(item.account_type)"
            label
            outlined
          >
            {{ item.account_type }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <v-btn small text color="primary" @click="viewTransactions(item)">
            View Transactions
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import axios from 'axios'
import LedgerAccountCreateModal from './ledger-account-create-modal'

export default {
  name: 'LedgerAccounts',
  components: {
    LedgerAccountCreateModal,
  },
  mixins: [CommonFunctions],

  filters: {
    currency(value) {
      if (!value) return '$0.00'
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },
  },

  data: () => ({
    search: '',
    loading: false,
    accounts: [],
    headers: [
      { text: 'Code', value: 'code' },
      { text: 'Name', value: 'name' },
      { text: 'Type', value: 'account_type' },
      { text: 'Balance', value: 'balance', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
  }),

  methods: {
    async fetchAccounts() {
      this.loading = true
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts
      } catch (error) {
        console.error('Error fetching accounts:', error)
        this.$store.dispatch('showError', 'Failed to load accounts')
      } finally {
        this.loading = false
      }
    },

    getAccountTypeColor(type) {
      const colors = {
        asset: 'light-blue',
        liability: 'pink',
        equity: 'purple',
        income: 'light-green',
        expense: 'deep-orange',
      }
      return colors[type] ? `${colors[type]} lighten-4` : 'grey lighten-4'
    },

    getAccountTypeTextColor(type) {
      const colors = {
        asset: 'light-blue darken-2',
        liability: 'pink darken-2',
        equity: 'purple darken-2',
        income: 'light-green darken-2',
        expense: 'deep-orange darken-2',
      }
      return colors[type] || 'grey darken-2'
    },

    getBalanceClass(account) {
      const balance = parseFloat(account.balance)
      if (balance === 0) return ''

      switch (account.account_type) {
        case 'asset':
        case 'expense':
        case 'income':
          return balance > 0 ? 'success--text' : 'error--text'
        case 'liability':
        case 'equity':
          return balance > 0 ? 'error--text' : 'success--text'
        default:
          return ''
      }
    },

    viewTransactions(account) {
      this.$router.push(`/dashboard/management/ledger-accounts/${account.id}`)
    },
  },

  created() {
    this.fetchAccounts()
  },
}
</script>
