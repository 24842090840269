<template>
  <form-dialog v-model="dialog" title="Payment" @submit="createPayment">
    <template #activator="{ on }">
      <v-btn
        title="amount"
        :loading="loading"
        color="grey"
        dark
        class="text-capitalize py-4 mx-4"
        depressed
        :disabled="disabled"
        small
        v-on="on"
      >
        Collect</v-btn
      >
    </template>
    <v-btn-toggle
      v-model="paymentType"
      mandatory
      color="primary"
      class="mb-6 d-flex"
    >
      <!--      <v-btn color="flex" value="credit">Credit </v-btn>-->
      <v-btn class="flex" value="track"> Track Payment </v-btn>
    </v-btn-toggle>
    <v-checkbox
      v-model="chargeFull"
      label="Charge full amount"
      @click="fillAmount"
    ></v-checkbox>
    <v-text-field
      v-model.number="amount"
      label="Amount"
      :disabled="chargeFull"
      :rules="[
        required,
        paymentType === 'credit'
          ? max(intentsSum)
          : max(reservation.balance_due),
      ]"
      dense
      outlined
    />
    <v-select
      v-if="paymentType === 'credit'"
      v-model="methodId"
      dense
      :rules="[required]"
      outlined
      :clearable="false"
      label="Payment Method"
      :items="creditCards"
    />
    <v-select
      v-if="paymentType === 'track'"
      v-model="methodName"
      dense
      :rules="[required]"
      outlined
      :clearable="false"
      label="Payment Method"
      :items="[
        { value: 'cash', text: 'Cash' },
        { value: 'credit', text: 'Credit Card' },
        { value: 'debit', text: 'Debit Card' },
        { value: 'wire', text: 'Bank Transfer' },
      ]"
    />
    <v-textarea
      v-if="paymentType === 'track'"
      v-model="note"
      outlined
      :rules="[required]"
      dense
      label="Note"
    />
    <div class="mb-4">
      <v-file-input
        v-model="attachments"
        accept="image/*,.pdf"
        label="Attach Files (Images or PDFs)"
        multiple
        prepend-icon="mdi-paperclip"
        outlined
        dense
        :show-size="true"
        @change="previewFiles"
      ></v-file-input>
      <div v-if="filePreviewUrls.length" class="d-flex flex-wrap gap-2 mt-2">
        <template v-for="(preview, index) in filePreviewUrls">
          <v-img
            v-if="preview.type === 'image'"
            :key="index"
            :src="preview.url"
            max-width="100"
            max-height="100"
            class="ma-1"
          >
            <template #placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
            <div class="text-center image-preview">
              <div
                class="text-caption text-truncate px-2 attachment-name"
                :title="preview.name"
              >
                {{ truncateFilename(preview.name) }}
              </div>
            </div>
          </v-img>
          <v-sheet
            v-else
            :key="index"
            width="100"
            height="100"
            class="ma-1 d-flex flex-column align-center justify-center pdf-preview"
            color="grey lighten-2"
            rounded
          >
            <div class="text-center">
              <v-icon large color="grey darken-2">mdi-file-pdf-box</v-icon>
              <div
                class="text-caption text-truncate px-2 attachment-name"
                :title="preview.name"
              >
                {{ truncateFilename(preview.name) }}
              </div>
            </div>
          </v-sheet>
        </template>
      </div>
    </div>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import axios from '@/axios/config'
import { first, get, isEmpty } from 'lodash/fp'
export default {
  name: 'CollectPayment',
  components: { FormDialog },
  mixins: [FormRules],
  props: ['reservation'],
  data() {
    return {
      amount: null,
      dialog: null,
      paymentType: null,
      note: null,
      methodId: null,
      methodName: null,
      loading: false,
      chargeFull: false,
      attachments: [],
      filePreviewUrls: [],
    }
  },
  computed: {
    intentsSum() {
      return this.intent ? this.intent.amount / 100 : 0
    },
    intent() {
      return this.reservation.payment_intents.find(
        intent =>
          intent.metadata.type === 'accommodation' &&
          intent.status !== 'succeeded' &&
          intent.status !== 'canceled'
      )
    },
    reservationId() {
      return this.reservation.id
    },
    creditCards() {
      return this.reservation.payment_methods.map(method => ({
        value: method.payment_method_id,
        text: `xxxx-${method.last4} (${method.exp_month}/${method.exp_year})`,
      }))
    },
    disabled() {
      return !this.reservation.balance_due
    },
  },
  async mounted() {
    this.amount = this.intentsSum || this.reservation.balance_due
    this.methodId = get('value', first(this.creditCards))
  },
  watch: {
    dialog(val) {
      if (val) {
        this.paymentType = this.intent ? 'credit' : 'track'
      }
    },
    reservationId() {
      this.amount = this.intentsSum
      this.methodId = get('value', first(this.creditCards))
    },
  },
  methods: {
    previewFiles(files) {
      this.filePreviewUrls = []
      if (!files) return

      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader()
          reader.onload = e => {
            this.filePreviewUrls.push({
              type: 'image',
              url: e.target.result,
              name: file.name,
            })
          }
          reader.readAsDataURL(file)
        } else if (file.type === 'application/pdf') {
          this.filePreviewUrls.push({
            type: 'pdf',
            name: file.name,
          })
        }
      })
    },
    truncateFilename(filename) {
      if (!filename) return ''
      return filename.length > 12 ? filename.substring(0, 12) + '...' : filename
    },
    async createPayment() {
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('amount', this.amount)
        formData.append('payment_type', this.paymentType)
        formData.append('method_name', this.methodName)
        formData.append('note', this.note)

        if (this.paymentType === 'credit') {
          formData.append('paymentMethodId', this.methodId)
          formData.append('intent', this.intent?.payment_intent_id)
        }

        if (this.attachments && this.attachments.length) {
          this.attachments.forEach(file => {
            formData.append('attachments[]', file)
          })
        }

        await axios.post(
          `/api/reservations/${this.reservation.id}/payment`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        this.$emit('success')
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },

    fillAmount() {
      this.amount = this.intentsSum || this.reservation.balance_due
    },
  },
}
</script>

<style scoped>
.gap-2 {
  gap: 8px;
}

.pdf-preview {
  position: relative;
  overflow: hidden;
}

.attachment-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 0;
}
</style>
