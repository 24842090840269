<template>
  <div v-if="settings" grid-list-md style="min-height: 60vh">
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn-toggle
          v-model="selectedSettings"
          class="flex-column d-flex"
          color="primary"
          @change="onSelectSettings"
        >
          <v-btn value="profile">Profile</v-btn>
          <v-btn value="channels">Channels</v-btn>
          <v-btn value="financial">Financial</v-btn>
          <v-btn value="billing">Billing</v-btn>
          <v-btn :disabled="!showModule('accounting')" value="accounting"
            >Accounting</v-btn
          >
          <v-btn value="dashboard_settings">Dashboard Settings</v-btn>
          <v-btn value="reviews">Reviews</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="9">
        <v-sheet elevation="2" rounded class="pa-4" height="100%">
          <v-row class="justify-end mr-2">
            <v-btn
              v-if="settingsChange"
              color="primary"
              class="float-right"
              :disabled="invalidVariables.length > 0"
              @click="save"
              >Save
            </v-btn>
          </v-row>
          <v-row v-if="selectedSettings === 'profile'">
            <v-col cols="12" sm="8" class="ml-3">
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Name
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-text-field
                    v-model="settings.company_name"
                    disabled
                    label="Company name"
                    dense
                    outlined
                    class="mr-1"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Email
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-text-field
                    v-model="settings.contact_email"
                    label="Support Email"
                    dense
                    outlined
                    class="mr-1"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Phone
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-col>
                    <v-text-field
                      v-model="settings.contact_phone"
                      label="Support phone"
                      dense
                      outlined
                      class="mr-1"
                      required
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Address
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-col>
                    <v-text-field
                      v-model="settings.contact_address"
                      label="Company Address"
                      dense
                      outlined
                      class="mr-1"
                      required
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Logo
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <media-upload
                    :multiple="false"
                    folder="logos/company"
                    :preview-cols="12"
                    :value.sync="settings.logo_url"
                  />
                </v-col>
              </v-row>
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Currency
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <currency-picker v-model="settings.currency" />
                </v-col>
              </v-row>
              <v-row>
                <v-col> Language </v-col>
                <v-col>
                  <v-select
                    v-model="settings.default_language"
                    outlined
                    item-text="label"
                    item-value="lang"
                    label="Default Language"
                    dense
                    :items="BOOM_LANGUAGES"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col> Ota default language </v-col>
                <v-col>
                  <v-select
                    v-model="settings.ota_language"
                    outlined
                    class="text-capitalize"
                    label="Ota Language"
                    dense
                    :items="otaLanguagesGetter"
                  />
                </v-col>
              </v-row>
              <!-- week starts at  -->
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Week starts at
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-select
                    v-model="settings.week_starts_at"
                    outlined
                    label="Week starts on"
                    dense
                    :items="[
                      {
                        text: 'Sunday',
                        value: 0,
                      },
                      {
                        text: 'Monday',
                        value: 1,
                      },
                    ]"
                  />
                </v-col>
              </v-row>
              <v-row class="justify-space-between align-start">
                <v-col cols="12" md="auto" class="py-0 py-md-1">
                  <div
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Social
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-col v-if="settings.links">
                    <v-text-field
                      v-model="settings.links.facebook"
                      label="Facebook Link"
                      required
                    />
                    <v-text-field
                      v-model="settings.links.google"
                      label="Google Link"
                      required
                    />
                    <v-text-field
                      v-model="settings.links.linkedin"
                      label="Linkedin Link"
                      required
                    />
                    <v-text-field
                      v-model="settings.links.website"
                      label="Website Link"
                      required
                    />
                    <v-text-field
                      v-model="settings.links.booking_site"
                      label="Booking Site Link"
                      required
                    />
                    <v-text-field
                      v-model="settings.links.instagram"
                      label="Instagram Link"
                      required
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="selectedSettings === 'financial'" ml-1 mt-2 wrap>
            <v-col cols="8">
              <v-select
                v-model="settings.tax_payment_method"
                class="mt-2 mb-2"
                label="Taxes are paid by"
                outlined
                dense
                clearable
                :items="['company', 'owner']"
                small-chips
              />
              <v-select
                v-model="settings.reservation_cal_method"
                class="mt-2 mb-2"
                label="Calculation type"
                :disabled="!isAdmin"
                outlined
                dense
                clearable
                :items="['days_in_month', 'check_ins', 'check_outs']"
                small-chips
              />
              <v-text-field
                v-model="settings.processing_rate"
                class="mt-2 mb-2"
                outlined
                dense
                :disabled="!isAdmin"
                label="Processing fee"
                required
              />
              <div class="d-flex align-center my-4">
                <div class="text-h6">Formula Settings</div>
                <formula-variables-tool-tip />
              </div>
              <v-row class="align-baseline">
                <v-col cols="12">
                  <enhanced-formula-builder
                    v-model="settings.net_income_formula"
                    label="Net Income Formula"
                    :variables="formulaVariables"
                  />
                </v-col>
                <v-col cols="12">
                  <enhanced-formula-builder
                    v-model="settings.management_commission_formula"
                    label="Management Commission Formula"
                    :variables="formulaVariables"
                  />
                </v-col>
                <v-col cols="12">
                  <enhanced-formula-builder
                    v-model="settings.owner_revenue_formula"
                    label="Owner Profit Formula"
                    :variables="formulaVariables"
                  />
                </v-col>
                <v-col cols="12">
                  <enhanced-formula-builder
                    v-model="settings.owners_income_formula"
                    label="Owners Income Formula"
                    :variables="formulaVariables"
                    :hint="
                      !settings.owners_income_formula
                        ? `Using system default (fare_accommodation)`
                        : null
                    "
                  />
                </v-col>
                <v-col cols="12">
                  <enhanced-formula-builder
                    v-model="settings.management_total_formula"
                    label="Management Total Formula"
                    :variables="formulaVariables"
                  />
                </v-col>
                <v-col cols="12">
                  <enhanced-formula-builder
                    v-model="settings.mgmt_tax_formula"
                    label="Management Fee VAT Formula"
                    :variables="formulaVariables"
                  />
                </v-col>
              </v-row>
              <div class="text-subtitle-1 font-weight-medium">Tax settings</div>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="settings.booking_tax_rate"
                    class="mt-2 mb-2"
                    label="Total Booking Tax Rate"
                    :disabled="!isAdmin"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="settings.store_tax"
                    class="mt-2 mb-2"
                    outlined
                    dense
                    label="Store tax"
                    required
                  />
                </v-col>
              </v-row>
              <div class="text-subtitle-1 font-weight-medium">
                Expenses settings
              </div>
              <v-switch
                v-model="settings.hide_channel_fee_expense"
                label="Hide Channel fee expense on owners p&l"
              />
              <v-switch
                v-model="settings.custom.business_supplies_expenses"
                label="Mark Storage supplies expenses as Business Expenses"
                class="mb-4"
              />
              <div class="text-subtitle-1 font-weight-medium">
                Vat settings (EU only)
              </div>
              <v-row>
                <!--                <v-col cols="6">-->
                <!--                  <v-text-field-->
                <!--                    v-model="settings.income_vat_rate"-->
                <!--                    class="mt-2 mb-2"-->
                <!--                    outlined-->
                <!--                    append-icon="mdi-percent-outline"-->
                <!--                    dense-->
                <!--                    label="Income VAT Rate"-->
                <!--                    required-->
                <!--                  />-->
                <!--                </v-col>-->
                <!--                <v-col cols="6">-->
                <!--                  <v-select-->
                <!--                    v-model="settings.income_vat_formula"-->
                <!--                    class="mt-2 mb-2"-->
                <!--                    outlined-->
                <!--                    dense-->
                <!--                    :items="INCOME_VAT_FORMULA"-->
                <!--                    label="Income VAT Formula"-->
                <!--                    required-->
                <!--                  />-->
                <!--                </v-col>-->
                <v-col cols="6">
                  <v-text-field
                    v-model="settings.mgmt_fee_vat_rate"
                    class="mt-2 mb-2"
                    outlined
                    append-icon="mdi-percent-outline"
                    dense
                    label="Management Fee VAT Rate"
                    required
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="settings.expense_vat_rate"
                    class="mt-2 mb-2"
                    outlined
                    append-icon="mdi-percent-outline"
                    dense
                    label="Expense VAT Rate"
                    required
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="settings.channel_fee_vat_rate"
                    class="mt-2 mb-2"
                    outlined
                    append-icon="mdi-percent-outline"
                    dense
                    label="Channel Fee VAT Rate"
                    required
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'channels'" ml-1 mt-2 wrap>
            <v-col cols="12">
              <v-sheet
                v-for="(val, key) in settings.channels"
                :key="key"
                outlined
                class="pa-2 rounded my-2"
              >
                <h4 class="text-h6 primary--text">
                  {{ key }}
                </h4>
                <v-switch
                  v-model="settings.channels[key].override"
                  label="Override pms settings"
                />
                <v-row no-gutters>
                  <v-col cols="auto">
                    <v-text-field
                      v-show="settings.channels[key].override"
                      v-model="settings.channels[key].default_commission"
                      label="Commission"
                      dense
                      outlined
                      hide-details
                    />
                  </v-col>
                  <v-col cols="auto" class="ml-3">
                    <v-select
                      v-show="settings.channels[key].override"
                      v-model="settings.channels[key].out_of"
                      label="Out of"
                      dense
                      outlined
                      hide-details
                      :items="[
                        {
                          text: 'Total Price (Inc Fees)',
                          value: 'client_price',
                        },
                        {
                          text: 'Fare Accommodation + Cleaning Fee',
                          value: 'total',
                        },
                        {
                          text: 'Fare Accommodation',
                          value: 'fare_accommodation',
                        },
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-model="settings.channels[key].processing_fee_override"
                  outlined
                  class="mt-5"
                  dense
                  hide-details
                  type="number"
                  label="Owner Processing %"
                />
                <h4
                  v-if="
                    settings.channels[key] && settings.channels[key].override
                  "
                  class="v-label theme--light mt-5 mb-5"
                >
                  Merchant of record:
                </h4>
                <v-radio-group
                  v-if="
                    settings.channels[key] && settings.channels[key].override
                  "
                  v-model="settings.channels[key].processing_fee"
                  class="pb-2 ma-0"
                  hide-details
                  row
                >
                  <v-radio label="Channel" :value="false" />
                  <v-radio label="PMC" :value="true" />
                </v-radio-group>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row
            v-if="selectedSettings === 'accounting' && showModule('accounting')"
            ml-1
            mt-2
            wrap
          >
            <v-col xs12 offset-sm4 sm4 mt-2>
              <h3>Accounts</h3>
              <v-flex v-if="settings.accounts">
                <v-text-field
                  v-model="settings.accounts.main_ach_recipient_name"
                  label="Main ACH Recipient Name"
                  required
                />
                <v-text-field
                  v-model="settings.accounts.main_ach_routing_number"
                  label="Main ACH Routing Number"
                  required
                />
                <v-text-field
                  v-model="settings.accounts.main_ach_account_number"
                  label="Main ACH Account Number"
                  required
                />
              </v-flex>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'reviews'">
            <v-col cols="6">
              <v-switch
                v-model="settings.checkup_task"
                label="Create service call after checkup"
              />
              <v-switch
                v-show="settings.checkup_task"
                v-model="settings.checkup_email"
                label="Send email to guest for ticket tracking for checkups"
              />
              <v-switch
                v-model="settings.review_task"
                label="Create service call after bad review"
              />
              <v-switch
                v-model="settings.auto_review"
                label="Enable automatic guest review (Airbnb only)"
              />
              <v-textarea
                v-if="settings.auto_review"
                v-model="settings.guest_review"
                :label="AUTO_REVIEW_LABEL"
                outlined
                dense
                rows="4"
                :messages="AUTO_REVIEW_MESSAGE"
                :error-messages="invalidVariables"
              />
            </v-col>
          </v-row>
          <div v-if="selectedSettings === 'dashboard_settings'">
            <dashboard-settings-tab :settings="settings" />
          </div>
          <div v-if="selectedSettings === 'billing'">
            <billing-tab
              :key="settings.tenant_id"
              :tenant-settings="settings"
            />
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formulaVariables, BOOM_LANGUAGES } from '@/consts'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import isEmpty from 'lodash/isEmpty'
import MediaUpload from 'components/common/media-upload'
import BillingTab from '@/components/billing/billing-tab.vue'
import DashboardSettingsTab from '@/components/dashboard-settings-tab.vue'

import {
  NET_INCOME_FORMULAS,
  OWNER_REVENUE_FORMULAS,
  MANAGEMENT_COMMISSION_FORMULAS,
  OWNERS_INCOME_FORMULAS,
  INCOME_VAT_FORMULA,
  MANAGEMENT_TOTAL_FORMULA,
} from '@/consts'
import CurrencyPicker from 'components/common/currency-picker.vue'
import EnhancedFormulaBuilder from 'components/common/enhanced-formula-builder.vue'
import FormulaVariablesToolTip from '@/components/common/formula-variables-tooltip.vue'

export default {
  components: {
    CurrencyPicker,
    MediaUpload,
    BillingTab,
    DashboardSettingsTab,
    EnhancedFormulaBuilder,
    FormulaVariablesToolTip,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      settingsChange: false,
      settings: {
        ota_language: '',
      },
      selectedSettings: null,
      BOOM_LANGUAGES,
      NET_INCOME_FORMULAS,
      OWNER_REVENUE_FORMULAS,
      MANAGEMENT_COMMISSION_FORMULAS,
      OWNERS_INCOME_FORMULAS,
      INCOME_VAT_FORMULA,
      MANAGEMENT_TOTAL_FORMULA,
      AUTO_REVIEW_LABEL: 'Hello {{guest_name}}',
      AUTO_REVIEW_MESSAGE:
        'Add {{guest_name}} as a variable. e.g. Hello {{guest_name}}!',
    }
  },
  watch: {
    settings: {
      handler(newValue, oldValue) {
        if (!isEmpty(oldValue)) {
          this.settingsChange = true
        }
      },
      deep: true,
    },
    '$route.query': {
      immediate: true,
      handler(query) {
        this.selectedSettings = query.section || 'financial'
      },
    },
  },
  mounted() {
    this.settings = { ...this.settingsGetter }
  },
  computed: {
    settingsGetter() {
      return this.$store.state.settings
    },
    otaLanguagesGetter() {
      return this.$store.state.ota_languages
    },
    formulaVariables() {
      return formulaVariables
    },
    invalidVariables() {
      const { guest_review } = this.settings
      if (!guest_review) return []
      if (!guest_review.includes('{') && !guest_review.includes('}')) return []
      return !guest_review.includes('{{guest_name}}')
        ? ['Guest name variable is malformed - variable must be {{guest_name}}']
        : []
    },
  },
  methods: {
    onSelectSettings(val) {
      this.$router.push({ query: { section: val } })
    },
    save() {
      this.$store.dispatch('updateSettings', this.settings)
      this.settingsChange = false
    },
  },
}
</script>
