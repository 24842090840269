<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center my-3 px-3">
      <div class="text-h6">Calculation Formulas</div>

      <v-dialog v-model="dialog" persistent max-width="500px">
        <template #activator="{ on }">
          <v-btn
            small
            depressed
            color="success"
            :disabled="disabledSave"
            v-on="on"
            >Save</v-btn
          >
        </template>
        <v-card class="pa-8">
          <div class="text-center">
            <div class="font-weight-medium">
              This will affect all reservations starting from
            </div>
            <div class="d-inline-flex ma-6">
              <v-text-field
                v-model="commissionRecalcFrom"
                :max="$moment().utc().format('YYYY-MM-DD')"
                outlined
                dense
                hide-details
                type="date"
                @focus="e => e.target.showPicker()"
              />
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-btn color="secondary" text @click="dialog = false">Cancel</v-btn>
            <v-btn
              color="success"
              class="font-weight-bold"
              text
              @click="updateListing"
              >Save</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-sheet max-height="700" class="overflow-y-auto px-3 pb-3">
      <v-row v-if="settings" no-gutters>
        <v-col cols="12">
          <h5 class="mb-4">PMC</h5>
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-text-field
                v-model.number="managementCommissionAbove"
                :label="$t('Commission Percent')"
                outlined
                type="number"
                dense
                :clearable="isHotel && !isDisabledManagementCommissionAbove"
                :hint="$t(managementCommissionAboveHint)"
                persistent-hint
                :min="0"
                :max="1"
                step="0.01"
                :rules="[
                  v =>
                    (v >= 0 && v <= 1) || $t('Value must be between 0 and 1'),
                ]"
                :disabled="isDisabledManagementCommissionAbove"
                @input="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Management commission percent</b> - The percentage
                        that the company takes from each reservation. This value
                        will be automatically applied in the management
                        commission formula.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-divider></v-divider>
        <div class="my-4">
          <div class="text-h6">Formula Settings</div>
          <div v-if="listing.business_model_id" class="warning--text">
            *
            {{
              `This property financial settings is managed via business model - ${listing.business_model_name}`
            }}
          </div>
          <formula-variables-tool-tip v-else />
        </div>
        <v-row v-if="!listing.business_model_id" class="align-baseline">
          <v-col cols="12">
            <enhanced-formula-builder
              v-model="netIncomeFormula"
              label="Net Income Formula"
              :variables="formulaVariables"
              :hint="
                defaultNetIncomeFormula && !netIncomeFormula
                  ? `Using system default (${defaultNetIncomeFormula})`
                  : null
              "
              :disabled="listing.business_model_id"
              @change="disabledSave = false"
            />
          </v-col>
          <v-col cols="12">
            <enhanced-formula-builder
              v-model="managementCommissionFormula"
              :hint="
                defaultManagementCommissionFormula &&
                !managementCommissionFormula
                  ? `Using system default (${defaultManagementCommissionFormula})`
                  : null
              "
              label="Management Commission Formula"
              :variables="formulaVariables"
              :disabled="listing.business_model_id"
              @change="disabledSave = false"
            />
          </v-col>
          <v-col cols="12">
            <enhanced-formula-builder
              v-model="ownerRevenueFormula"
              label="Owner Profit Formula"
              :variables="formulaVariables"
              :hint="
                defaultOwnerRevenueFormula && !ownerRevenueFormula
                  ? `Using system default (${defaultOwnerRevenueFormula})`
                  : null
              "
              :disabled="listing.business_model_id"
              @change="disabledSave = false"
            />
          </v-col>
          <v-col cols="12">
            <enhanced-formula-builder
              v-model="ownerIncomeFormula"
              label="Owners Income Formula"
              :variables="formulaVariables"
              :hint="
                !ownerIncomeFormula
                  ? `Using system default (${
                      settings.owners_income_formula || 'fare_accommodation'
                    })`
                  : null
              "
              :disabled="listing.business_model_id"
              @change="disabledSave = false"
            />
          </v-col>
        </v-row>
        <v-col v-if="isOptima" cols="12">
          <v-text-field
            v-model="cleaningFee"
            class="mt-3"
            :label="$t('Cleaning Fee')"
            dense
            outlined
            hide-details
            @input="disabledSave = false"
          />
        </v-col>
        <v-col cols="12" class="mb-4">
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-text-field
                v-model.number="workingCapital"
                :label="$t('Working Capital')"
                outlined
                type="number"
                dense
                :hint="$t('Amount of working capital')"
                persistent-hint
                :min="0"
                step="0.01"
                @input="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Working Capital</b> - The amount of working capital
                        allocated for this property. This will be tracked with
                        each payment to maintain historical records.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="align-baseline">
            <v-col cols="6">
              <v-switch
                v-model="ownerCleaning"
                :label="$t('Display cleaning as owner income')"
                hide-details
                class="mb-3"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Owner cleaning</b> - cleaning would appear as income
                        on the owner p&l.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="align-baseline">
            <v-col cols="6">
              <date-picker
                v-model="cutoffDate"
                :hide-details="false"
                label="Cutoff date"
                :clearable="true"
                @change="disabledSave = false"
              />
            </v-col>
            <v-col cols="auto">
              <v-tooltip bottom max-width="600">
                <template #activator="{ on }">
                  <v-icon class="purple-icon" v-on="on">info</v-icon>
                </template>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <b>Cutoff date</b> - Defines the starting point for
                        applying a new set of commission changes from a specific
                        date. This feature is useful for scenarios like
                        onboarding new owners to a listing or switching between
                        different calculation methods, such as moving from
                        check-in based to pro-rated commission models.
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8">
          <v-expansion-panels class="mt-2">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="mt-1">Channel Settings</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-card-text>
                    <v-sheet
                      v-for="(val, key) in channels"
                      :key="key"
                      outlined
                      class="pa-2 rounded my-2"
                    >
                      <h5 class="text-h6 primary--text">
                        {{ val }}
                      </h5>
                      <v-switch
                        v-model="channel_settings[val].override"
                        label="Override pms settings"
                      />
                      <v-row no-gutters>
                        <v-col cols="auto">
                          <v-text-field
                            v-if="
                              channel_settings[val] &&
                              channel_settings[val].override
                            "
                            v-model="channel_settings[val].default_commission"
                            label="Commission"
                            dense
                            outlined
                            hide-details
                          />
                        </v-col>
                        <v-col cols="auto" class="ml-3">
                          <v-select
                            v-if="
                              channel_settings[val] &&
                              channel_settings[val].override
                            "
                            v-model="channel_settings[val].out_of"
                            label="Out of"
                            dense
                            outlined
                            hide-details
                            :items="[
                              {
                                text: 'Total Price (Inc Fees)',
                                value: 'client_price',
                              },
                              {
                                text: 'Fare Accommodation + Cleaning Fee',
                                value: 'total',
                              },
                              {
                                text: 'Fare Accommodation',
                                value: 'fare_accommodation',
                              },
                            ]"
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex">
                          <v-col cols="5" class="px-0">
                            <v-text-field
                              v-model.number="
                                channel_settings[val].processing_fee_override
                              "
                              outlined
                              dense
                              label="Owner Processing %"
                            />
                          </v-col>
                        </v-col>
                      </v-row>
                      <h5
                        v-if="
                          channel_settings[val] &&
                          channel_settings[val].override
                        "
                        class="v-label theme--light"
                      >
                        Merchant of record:
                      </h5>
                      <v-radio-group
                        v-if="
                          channel_settings[val] &&
                          channel_settings[val].override
                        "
                        v-model="channel_settings[val].processing_fee"
                        class="pb-2 ma-0"
                        hide-details
                        row
                      >
                        <v-radio label="Channel" :value="false" />
                        <v-radio label="PMC" :value="true" />
                      </v-radio-group> </v-sheet
                  ></v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import isEmpty from 'lodash/fp/isEmpty'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DatePicker from 'components/form-fields/date-picker'

import {
  NET_INCOME_FORMULAS,
  OWNER_REVENUE_FORMULAS,
  MANAGEMENT_COMMISSION_FORMULAS,
  OWNERS_INCOME_FORMULAS,
  formulaVariables,
} from '@/consts'
import Common_functions from 'components/mixins/common_functions'
import FormulaVariablesToolTip from 'components/common/formula-variables-tooltip.vue'
import EnhancedFormulaBuilder from 'components/common/enhanced-formula-builder.vue'

export default {
  name: 'ListingCommissions',
  props: ['listing'],
  components: { EnhancedFormulaBuilder, FormulaVariablesToolTip, DatePicker },
  mixins: [PermissionsMixin, Common_functions],
  data() {
    return {
      NET_INCOME_FORMULAS,
      OWNER_REVENUE_FORMULAS,
      MANAGEMENT_COMMISSION_FORMULAS,
      OWNERS_INCOME_FORMULAS,
      channel_settings: this.listing.channel_settings,
      cutoffDate: this.listing.cutoff_date,
      netIncomeFormula: this.listing.net_income_formula,
      ownerRevenueFormula: this.listing.owner_revenue_formula,
      managementCommissionFormula: this.listing.management_commission_formula,
      managementCommissionAbove: this.listing.management_commission_above,
      ownerIncomeFormula: this.listing.owners_income_formula,
      cleaningFee: this.listing.actual_cleaning,
      ownerCleaning: this.listing.owner_cleaning,
      channels: [],
      disabledSave: true,
      dialog: false,
      commissionRecalcFrom: this.$moment()
        .utc()
        .startOf('month')
        .format('YYYY-MM-DD'),
      workingCapital: this.listing.working_capital || 0,
    }
  },
  computed: {
    ...mapState(['settings']),
    zone() {
      return this.listing.zone || {}
    },
    defaultNetIncomeFormula() {
      return this.zone.net_income_formula || this.settings.net_income_formula
    },
    defaultOwnerRevenueFormula() {
      return (
        this.zone.owner_revenue_formula || this.settings.owner_revenue_formula
      )
    },
    defaultManagementCommissionFormula() {
      return (
        this.zone.management_commission_formula ||
        this.settings.management_commission_formula
      )
    },
    managementCommissionAboveHint() {
      if (this.isHotel) {
        return "Commission applies to all hotel units. If empty, it's based on unit commission."
      } else if (this.isDisabledManagementCommissionAbove) {
        return `Management commission is set at ${this.parentManagementCommissionAbove}, inherited from parent settings. To change, adjust the parent commission.`
      } else {
        return null
      }
    },
    parentManagementCommissionAbove() {
      return this.listing.parent_management_commission_above
    },
    isDisabledManagementCommissionAbove() {
      return (
        this.isUnit &&
        this.parentManagementCommissionAbove !== null &&
        this.parentManagementCommissionAbove >= 0
      )
    },
    isHotel() {
      return this.listing.listing_type === 'hotel'
    },
    isUnit() {
      return !!this.listing.hotel_id
    },
    formulaVariables() {
      return formulaVariables
    },
  },
  async mounted() {
    await this.$store.dispatch('getSettings')
    this.channels = Object.keys(this.settings.channels || {})
    this.channel_settings = this.channels.reduce((acc, val) => {
      if (isEmpty(acc[val])) {
        acc[val] = { processing_fee: true, default_commission: 0 }
      }
      return acc
    }, this.channel_settings)
  },
  methods: {
    async updateListing() {
      this.$store.commit('updateLoading', true)
      try {
        await this.$store.dispatch('listings/updateListing', {
          id: this.$route.params.id,
          payload: {
            channel_settings: this.channel_settings,
            net_income_formula: this.netIncomeFormula,
            cutoff_date: this.cutoffDate,
            management_commission_formula: this.managementCommissionFormula,
            owner_revenue_formula: this.ownerRevenueFormula,
            management_commission_above: this.managementCommissionAbove,
            owners_income_formula: this.ownerIncomeFormula,
            owner_cleaning: this.ownerCleaning,
            actual_cleaning: this.cleaningFee,
            commission_recalc_from: this.commissionRecalcFrom,
            working_capital: this.workingCapital,
          },
        })

        this.$store.dispatch('changeEditListing', this.$route.params.id)
      } finally {
        this.$store.commit('updateLoading', false)
        this.disabledSave = true
        this.dialog = false
      }
    },
  },
  watch: {
    channel_settings: {
      deep: true,
      handler(val, old) {
        if (!isEmpty(old)) {
          this.disabledSave = false
        }
      },
    },
  },
}
</script>

<style scoped>
::v-deep .v-messages__message {
  color: darkblue;
  font-weight: 500;
}
::v-deep .v-select__selection {
  font-size: 14px;
}
.input-container {
  border: 1px var(--v-light400-darken3) solid;
  border-radius: 4px;
  padding: 10px;
}
</style>
