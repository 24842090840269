<template>
  <form-dialog
    v-model="dialog"
    :fullscreen="isMobileOrIPad"
    :title="$t('Create Expense')"
    persistent
    :disable-submit="uploading || (quickMode && !files.length)"
    max-width="560px"
    @close="close"
    @submit="save"
  >
    <template #activator="{ on }">
      <div v-if="!edit">
        <v-btn
          v-if="!quickMode"
          dark
          color="info"
          @click="$emit('open')"
          v-on="on"
          >Create Expense
        </v-btn>
        <v-list-item v-else link v-on="on">
          <div @click="$emit('open')">
            <v-list-item-title class="text-body-2">
              <v-icon class="mr-2">$accounting</v-icon>

              {{ $t('New expense') }}
            </v-list-item-title>
          </div>
        </v-list-item>
      </div>
    </template>
    <v-tabs
      v-if="!edit"
      v-model="expenseType"
      color="info"
      slider-color="info"
      class="mb-4"
      fixed-tabs
    >
      <v-tab href="#ClientExpense">Property</v-tab>
      <v-tab v-if="centralBankView || quickMode" href="#BusinessExpense">
        {{ $t('Business') }}
      </v-tab>
      <v-tab
        v-if="isAdmin && managementPage && !quickMode"
        href="#MonthlyExpense"
      >
        {{ $t('Monthly') }}
      </v-tab>
      <v-tab
        v-if="isAdmin && managementPage && !quickMode"
        href="#ReservationExpense"
      >
        {{ $t('Per Reservation') }}
      </v-tab>
    </v-tabs>
    <multi-property-picker
      v-if="isMonthlyExpense || isClientExpense || isReservationExpense"
      v-model="listingId"
      :value="fixedListingId"
      :rules="[required]"
      :readonly="Boolean(fixedListingId || edit)"
    />
    <listing-select
      v-if="listingId && selectedHotel"
      label="Choose Unit"
      item-text="nickname"
      :hide-title="true"
      :hide-details="false"
      :hotel-id="listingId"
      :pre-selected.sync="unitID"
    />
    <v-autocomplete
      v-if="isDesignedVr && (quickMode || (edit && expense.bank_account_id))"
      v-model="bankAccountId"
      :label="$t('Card owner')"
      dense
      outlined
      clearable
      :allow-overflow="false"
      :disabled="edit"
      :items="accountNumbers"
    />
    <expenses-types-autocomplete
      v-model="expense.shortDescription"
      :is-business-expense="isBusinessExpense"
      :outlined="true"
      :hint="!showAmount ? amountText : ''"
      :rules="[required]"
      :label="$t('Expense Type')"
    />
    <v-text-field
      v-if="showAmount"
      v-model="expense.amount"
      :rules="[required]"
      :label="$t('Amount')"
      type="number"
      :disabled="disableAmount"
      required
      outlined
      dense
    />
    <div
      v-if="
        listingId &&
        Array.isArray(listingId) &&
        listingId.length > 1 &&
        expense.amount
      "
      class="mt--1 mb-3 blue--text"
    >
      <v-icon class="mr-2">info</v-icon>
      {{ toMoney(expense.amount / listingId.length) }} per listing
    </div>

    <v-combobox
      v-model="expense.source"
      :items="sources"
      :rules="[required]"
      :label="$t('Store/Source')"
      outlined
      dense
      placeholder="Amazon/Home depot etc.."
      required
      clearable
      small-chips
      :allow-overflow="false"
      :hide-selected="false"
      autocomplete="off"
      :search-input.sync="sourceSearch"
      :loading="isLoadingSources"
      :filter="() => true"
      @click:clear="clearSource"
    />
    <date-picker
      v-model="expense.date"
      :hide-details="false"
      :rules="[required]"
      :label="
        isMonthlyExpense || isReservationExpense
          ? $t('Starting At')
          : $t('Paid At')
      "
      :max="$moment().endOf('month').toISOString()"
      :min="
        hasAbility('super-accountant')
          ? null
          : new Date().toISOString().substr(0, 10)
      "
    />
    <date-picker
      v-if="isMonthlyExpense || isReservationExpense"
      v-model="expense.deactivated_at"
      :hide-details="false"
      :label="$t('Until (leave empty for endless)')"
      :max="$moment().add(2, 'years').toISOString()"
      min="2017-01-01"
    />
    <v-text-field
      v-model="expense.description"
      outlined
      dense
      color="deep-purple"
      :label="$t('Description')"
      rows="2"
    />
    <contractor-select
      v-model="expense.contractor_id"
      label="Payable contractor"
      hide-details
      hint="By choosing contractor it will be payable to the contractor via the contractor payments"
    />
    <v-checkbox
      v-if="!quickMode && isDesignedVr"
      v-model="expense.related_to_setup"
      :label="$t('Related to setup')"
      class="checkbox-txt"
    />
    <v-row v-if="config.has_xero">
      <v-col cols="12">
        <v-autocomplete
          v-model="defaultExpenseAccount"
          item-text="account_name"
          item-value="id"
          :label="$t('Xero Account')"
          dense
          outlined
          hide-details
          clearable
          :allow-overflow="false"
          :disabled="edit"
          :items="xeroAccounts"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="invoice.invoice_number"
          outlined
          dense
          hide-details
          color="deep-purple"
          :label="$t('Invoice Number')"
          rows="2"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="invoice.reference"
          outlined
          hide-details
          dense
          color="deep-purple"
          :label="$t('Reference')"
          rows="2"
        />
      </v-col>
      <v-col cols="12">
        <date-picker
          v-model="invoice.due_date"
          :value="parseDate(invoice.due_date)"
          details
          :hide-details="false"
          :label="$t('Due Date')"
          :max="$moment().add(2, 'years').toISOString()"
          min="2017-01-01"
        />
      </v-col>
    </v-row>
    <v-checkbox
      v-if="!quickMode && isDesignedVr"
      v-model="expense.paid_by_us"
      label="Paid by company"
      class="checkbox-txt"
    />
    <media-upload
      :key="fileKey"
      class="d-flex flex-column"
      folder="expenses"
      :preview="true"
      :multiple="true"
      :required="true"
      :thumbnail="true"
      :compress="false"
      :loader="true"
      :preview-cols="6"
      :uploading.sync="uploading"
      :removeable-image="true"
      :value.sync="files"
    />
    <span v-show="!files.length && quickMode" class="warning--text"
      >* Upload at least 1 picture</span
    >
  </form-dialog>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Toaster from '@/utils/toaster'
import DatePicker from 'components/form-fields/date-picker'
import MediaUpload from 'components/common/media-upload'
import ListingSelect from 'components/listing-select'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'
import { mapGetters, mapState, mapActions } from 'vuex'
import { get } from 'lodash'
import DeviceMixin from 'components/mixins/device-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ExpensesTypesAutocomplete from 'components/expenses-types-autocomplete.vue'
import ContractorSelect from 'components/contractor-select'
import debounce from 'lodash/debounce'

export default {
  components: {
    MultiPropertyPicker,
    FormDialog,
    ListingSelect,
    MediaUpload,
    DatePicker,
    ExpensesTypesAutocomplete,
    ContractorSelect,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    FormRulesMixin,
    DeviceMixin,
    FormattersMixin,
  ],
  props: [
    'type',
    'title',
    'allowToggle',
    'quickMode',
    'fixedListingId',
    'centralBankView',
    'preFilledAmount',
    'preFilledDate',
    'preFilledDesc',
    'edit',
    'connectedTransactionId',
    'relatedToSetup',
    'editExpense',
  ],
  data() {
    let setDate = this.preFilledDate
      ? new Date(this.preFilledDate).toISOString().substr(0, 10)
      : new Date().toISOString().substr(0, 10)
    return {
      myId: Math.floor(Math.random() * 100000) + 1,
      search: null,
      date: setDate,
      files: [],
      fileKey: Math.random(),
      bankAccountId: null,
      xeroAccountId: null,
      uploading: false,
      listingId: this.fixedListingId,
      dialog: false,
      unitID: null,
      expenseType: 'ClientExpense',
      defaultExpenseAccount: this.$store.state.app.xeroDefaultExpenseId,
      showAmount: true,
      disableAmount: false,
      expense: {
        amount: this.preFilledAmount,
        shortDescription: '',
        date: setDate,
        deactivated_at: '',
        description: this.preFilledDesc,
        paid_by_us: true,
        related_to_setup: this.relatedToSetup ? true : false,
        source: '',
        contractor_id: null,
      },
      selectedExpenseType: null,
      invoice: {
        xero_contact_id: null,
        invoice_number: '',
        reference: '',
        due_date: setDate,
        bank_account_id: null,
        amount: null,
      },
      sourceSearch: null,
      isLoadingSources: false,
    }
  },
  computed: {
    ...mapState('expenseTypes', ['expensesTypes']),
    ...mapGetters('users', ['usersList', 'usersMap']),
    config() {
      return this.$store.state.app.configuration
    },
    source() {
      return this.expense.source
    },
    selectedHotel() {
      if (this.listingId) {
        return this.getListingById(this.listingId).listing_type === 'hotel'
      }
      return false
    },
    accountNumbers() {
      return this.$store.state.app.bankAccounts.map(ba => ({
        value: ba.id,
        text: `${
          ba.first_name || ba.last_name
            ? `${ba.first_name} ${ba.last_name}`
            : 'Company'
        } (${ba.account_number})`,
      }))
    },
    xeroAccounts() {
      return this.$store.state.app.xeroAccounts.filter(
        c => c.department === 'expense'
      )
    },
    amount() {
      return this.expense.amount
    },
    expenseDate() {
      return this.expense.date
    },
    shortDescription() {
      return this.expense.shortDescription
    },
    managementPage() {
      return this.$route.name === 'manage-property'
    },
    isClientExpense() {
      return this.expenseType === 'ClientExpense'
    },
    isMonthlyExpense() {
      return this.expenseType === 'MonthlyExpense'
    },
    isReservationExpense() {
      return this.expenseType === 'ReservationExpense'
    },
    isBusinessExpense() {
      return this.expenseType === 'BusinessExpense'
    },
    amountText() {
      return `* Amount will be calculated as: ${this.selectedExpenseType.quantifier}`
    },
    ...mapState('expenses', ['sources']),
  },
  mounted() {
    this.$store.dispatch('getXeroAccounts')
    window.$store.state.expenseModal = this
    this.defaultExpenseAccount = this.$store.state.app.xeroDefaultExpenseId
    if (this.editExpense) {
      this.setExpense(this.editExpense)
    }
  },
  created() {
    if (!this.expensesTypes.length) {
      this.fetchAllExpenseTypes()
    }
    this.fetchSources()
    this.debouncedSearch = debounce(async val => {
      this.isLoadingSources = true
      try {
        await this.fetchSources(val)
      } finally {
        this.isLoadingSources = false
      }
    }, 300)
  },
  beforeDestroy() {
    this.debouncedSearch.cancel()
  },
  watch: {
    editExpense(newVal) {
      if (newVal) {
        this.setExpense(newVal)
      }
    },
    expenseType(newVal) {
      if (newVal === 'BusinessExpense') {
        this.listingId = null
      }
    },
    defaultExpenseAccountId(newVal) {
      if (newVal) {
        this.invoice.bank_account_id = newVal
      }
    },
    shortDescription(newVal) {
      this.selectedExpenseType = this.expensesTypes.find(a => a.name === newVal)
      if (
        this.selectedExpenseType?.units === 'dynamic' &&
        this.isReservationExpense
      ) {
        this.showAmount = false
        this.disableAmount = false
        this.expense.amount = 0
      } else if (
        this.selectedExpenseType?.units === 'fixed' &&
        this.isReservationExpense
      ) {
        this.expense.amount = this.selectedExpenseType.amount
        this.disableAmount = true
        this.showAmount = true
      } else {
        this.disableAmount = false
        this.showAmount = true
      }
    },
    sourceSearch(val) {
      this.debouncedSearch(val || '')
    },
  },
  methods: {
    ...mapActions('expenseTypes', ['fetchAllExpenseTypes']),
    ...mapActions('expenses', ['fetchSources']),
    listingChange(listingId) {
      this.listingId = listingId
    },
    setExpense(expense) {
      if (expense) {
        this.dialog = true
        this.expenseType = expense.type
        let deactivatedAt = null
        if (expense.deactivated_at) {
          deactivatedAt = new Date(expense.deactivated_at)
            .toISOString()
            .substr(0, 10)
        }
        this.listingId = expense.listing_id
        this.unitID = expense.unit_id
        this.bankAccountId = expense.bank_account_id
        this.xeroContactId = expense.xero_contact_id
        this.files = expense.images || []

        this.expense = {
          shortDescription: expense.short_reason,
          description: expense.description,
          bank_account_id: expense.bank_account_id,
          xero_contact_id: expense.xero_contact_id,
          listing_id: expense.listing_id,
          unit_id: expense.unit_id,
          type: expense.type,
          date: new Date(expense.paid_at).toISOString().substr(0, 10),
          deactivated_at: deactivatedAt,
          amount: expense.amount,
          paid_by_us: expense.paid_by_us,
          source: expense.source,
          id: expense.id,
          related_to_setup: expense.related_to_setup,
          contractor_id: expense.contractor_id,
        }

        this.invoice = {
          xero_contact_id: expense.contractor_id,
          invoice_number: expense.xero_invoice.invoice_number,
          bank_account_id: this.defaultExpenseAccount,
          amount: expense.amount,
          reference: expense.xero_invoice.reference,
          due_date: new Date(expense.xero_invoice.due_date)
            .toISOString()
            .substr(0, 10),
        }
      }
    },
    setDueDate(value) {
      this.invoice.due_date = value
    },
    close() {
      this.expense = {}
      this.files = []
      this.fileKey = Math.random()
      this.listingId = this.fixedListingId
      this.bankAccountId = null
      this.xeroContactId = null
      this.xeroAccountId = null
      this.expenseType = 'ClientExpense'
      this.$store.commit('updateEditExpense', null)
    },
    save() {
      if (this.edit) {
        this.expense.short_reason = this.expense.shortDescription
        this.expense.paid_at = this.expense.date
        this.expense.type = this.expenseType
        this.expense.unit_id = this.unitID
        this.expense.images = this.files
        this.invoice.amount = this.expense.amount
        axios
          .post('/api/expenses/' + this.expense.id, this.expense)
          .then(() => {
            this.expense = {}
            this.files = []
            this.$store.dispatch(
              'getExpensesByFilter',
              this.$store.state.lastExpensesFilter
            )
          })
          .catch(() => {
            this.$store.commit('updateLoading', false)
          })
          .finally(() => {
            this.close()
          })
      } else {
        let data = {}
        data.amount = this.expense.amount
        data.short_reason = this.expense.shortDescription
        data.description = this.expense.description
        data.unit_id = this.unitID
        data.source = this.expense.source
        data.bank_account_id = this.bankAccountId
        data.xero_contact_id = this.expense.contractor_id
        data.contractor_id = this.expense.contractor_id
        this.invoice.amount = this.expense.amount
        data.xero_invoice = this.invoice
        data.xero_invoice.bank_account_id = this.defaultExpenseAccount
        data.xero_invoice.xero_contact_id = this.expense.contractor_id
        if (this.expense.related_to_setup) {
          data.related_to_setup = this.expense.related_to_setup
        }
        data.type = this.expenseType
        data.paid_by_us = this.expense.paid_by_us
        if (
          this.isClientExpense ||
          this.isMonthlyExpense ||
          this.isReservationExpense
        ) {
          data.listing_id = this.listingId
        }
        if (this.connectedTransactionId) {
          data.connected_transaction_id = this.connectedTransactionId
        }
        data.paid_at = this.expense.date
        data.deactivated_at = this.expense.deactivated_at

        if (this.files.length) {
          data.images = this.files
        }
        this.$store.commit('updateListingLoading', true)
        axios
          .post('/api/expenses/', data)
          .then(res => {
            const expId = get(res, 'data.payload.data.id')
            const link = expId ? `/dashboard/expenses/${expId}` : undefined
            Toaster.show([
              { type: 'success', text: 'Expense Created successfully', link },
            ])
            this.$store.dispatch(
              'getExpensesByFilter',
              this.$store.state.lastExpensesFilter
            )
            this.$store.commit('updateListingLoading', false)
            if (this.connectedTransactionId) {
              this.$store.dispatch('refreshBankTransaction')
            }
            this.close()
          })
          .catch(() => {
            Toaster.show([{ type: 'error', text: 'Failed to create expense' }])
            this.$store.commit('updateLoading', false)
            this.close()
          })
      }

      this.$emit('on-submit')
    },
    async clearSource() {
      this.expense.source = null
      this.sourceSearch = null
      this.isLoadingSources = true
      try {
        await this.fetchSources('')
      } finally {
        this.isLoadingSources = false
      }
    },
  },
}
</script>
