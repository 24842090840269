<template>
  <v-card class="pa-3 overflow-y-auto">
    <v-card-title class="pa-3">
      <h2>Edit Catalog</h2>
      <span v-if="isAdmin" class="float-right ml-4">
        ID: {{ item.id }}
        <span v-if="item.system_key" class="ml-3">
          Key: {{ item.system_key }}</span
        >
      </span>
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <h3 class="my-3">Basic settings</h3>

        <div class="px-2">
          <v-select
            v-if="isSystemAdmin"
            v-model="item.system_key"
            :items="system_keys"
            class="small-select"
            outlined
            dense
            label="System key"
          />
          <v-select
            v-model="item.zone_id"
            :label="$t('Zone')"
            outlined
            dense
            :items="zoneItems"
          />
          <v-select
            v-model="item.division"
            :items="divisions"
            class="small-select"
            :rules="[required]"
            outlined
            dense
            label="Division"
          />
          <v-combobox
            v-model="item.department"
            outlined
            dense
            :rules="[required]"
            :items="listingTaskTypes"
            class="small-select"
            label="Topic"
          />
          <v-combobox
            v-model="item.sub_topic"
            outlined
            dense
            :rules="[required]"
            :items="subTopics(item.department)"
            class="small-select"
            label="Category"
          />
          <v-text-field
            v-if="isDesignedVr"
            v-model="item.description"
            outlined
            dense
            class="small-text-input"
            label="action description"
          />
          <v-textarea
            v-model="item.long_description"
            rows="3"
            outlined
            dense
            label="Long description"
          />
          <v-switch
            v-model="item.bounce_daily"
            class="pa-1 pb-0 mt-0 rounded small-text"
            hide-details
            :label="$t('Auto reschedule task to today if not done')"
          />
          <lt-taskim-sections-select
            v-model="item.attach_to_taskim_sections_ids"
            label="Auto Attach Task To Taskim Sections"
            class="mb-3"
          />
          <div class="comp-wrapper">
            <div class="text-subtitle-1">Assigned person:</div>
            <v-radio-group v-model="byContractor" row>
              <v-radio label="Contractor" value="contractor">{{
                $t('By Contractor')
              }}</v-radio>
              <v-radio label="Personnel" value="personnel">{{
                $t('By personnel')
              }}</v-radio>
            </v-radio-group>
            <v-select
              v-if="byContractor === 'personnel'"
              v-model="item.by_personnel"
              dense
              clearable
              :hint="personnelNames"
              persistent-hint
              hide-details
              :placeholder="$t('Choose Personnel')"
              outlined
              :items="[
                { text: 'Cleaning Supervisor', value: 'cleaning_supervisor' },
                { text: 'Pool', value: 'pool' },
                { text: 'Pest', value: 'pest' },
                { text: 'Landscape', value: 'landscape' },
                { text: 'Host', value: 'host' },
                { text: 'Region Manager', value: 'region_manager' },
              ]"
            />
            <div v-if="byContractor === 'personnel'">
              <span
                v-for="(person, ind) in personnelNames"
                :key="ind"
                class="secondary--text d-inline text-caption"
              >
                <span class="font-weight-medium">{{ person.listing }} -> </span>
                <span
                  :class="
                    person.name === 'Missing personnel' && 'warning--text'
                  "
                  >{{ person.name }}</span
                >
                <span v-if="ind + 1 !== personnelNames.length" class="mx-2">
                  |</span
                >
              </span>
            </div>
            <div v-if="byContractor === 'contractor'">
              <contractor-select :value.sync="item.user_id" />
              <div>
                <v-switch
                  v-model="item.require_accept"
                  class="pa-1 pb-0 mt-0 rounded small-text"
                  hide-details
                  :label="$t('Auction mode')"
                />
              </div>
              <div v-if="item.require_accept" class="text-caption error--text">
                ** In auction mode you will need to choose up to 4 potential
                contractors in a prioritize order. The system will send every
                hour notification on this task to the next contractor in the
                list until one of the contractors accepts the task. After the
                task is accepted all other contractors will be removed from the
                list
              </div>
            </div>
          </div>
        </div>
        <div class="px-2">
          <v-checkbox
            v-model="item.expose_to_owner"
            dense
            class="checkbox-txt mt-0"
            label="Expose to owner?"
          />
        </div>
        <h3 class="my-3">Approval settings</h3>
        <div class="px-2">
          <v-select
            v-model="item.approver_id"
            class="mt-4"
            clearable
            label="Approver"
            :items="approvers"
            item-text="name"
            item-value="id"
            dense
            outlined
          />
          <div class="font-weight-medium">{{ $t('Charged to') }}:</div>
          <v-radio-group v-model="item.charged_by" row>
            <v-radio value="client" label="Owner" />
            <v-radio value="business" label="Company" />
          </v-radio-group>

          <expenses-types-autocomplete
            v-model="item.auto_expense_type"
            :is-business-expense="item.charged_by === 'business'"
            outlined
            :label="$t('Expense Type')"
          />

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="item.min_amount"
                outlined
                hide-details
                dense
                type="number"
                class="small-text-input"
                label="Min amount for approval"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.max_amount"
                hide-details
                outlined
                dense
                type="number"
                class="small-text-input"
                label="Max amount for approval"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="item.auto_approve"
                :disabled="
                  !item.auto_expense_type || item.auto_done_and_approved
                "
                dense
                class="checkbox-txt"
                label="Auto approve"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="item.require_media_for_auto_approve"
                :disabled="!item.auto_approve"
                dense
                class="checkbox-txt"
                label="Require media for auto approve"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="item.auto_done_and_approved"
                dense
                :disabled="!item.auto_expense_type || item.auto_approve"
                class="checkbox-txt"
                :label="$t('Auto move to done and approve')"
              />
            </v-col>
            <v-col v-if="item.auto_done_and_approved" cols="6">
              <v-checkbox
                v-model="item.hide_from_cal"
                dense
                class="checkbox-txt"
                :label="$t('Hide from task management (cal/area)')"
              />
            </v-col>
          </v-row>
        </div>

        <h3 class="my-3">Check list</h3>

        <div class="px-2">
          <v-checkbox
            v-model="item.inspection_mode"
            dense
            class="checkbox-txt mt-0"
            label="Inspection Mode"
          />
          <lt-sub-task-list-item
            :after-change="checkListChange"
            :existing-items="item.checklist"
          />
        </div>
        <h3 class="my-3 mt-6">Financial</h3>

        <div class="px-2">
          <v-row class="align-baseline">
            <v-col>
              <v-text-field
                v-model="item.avg_price_total"
                outlined
                dense
                type="number"
                label="Price per visit"
              />
            </v-col>
            OR
            <v-col>
              <v-text-field
                v-model="item.avg_price_per_hour"
                type="number"
                outlined
                dense
                label="Hourly charge"
              />
            </v-col>
          </v-row>
        </div>
        <h3 v-if="isDesignedVr" class="my-3">Estimates</h3>

        <div v-if="isDesignedVr" class="px-2">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="item.labor_time_per_unit"
                :readonly="!managementRoles"
                outlined
                dense
                type="number"
                :rules="[nonNegative]"
                label="Labor Time Per Unit"
                @input="
                  item.labor_cost_per_unit =
                    Math.round(Number($event || 0) * 30 * 100) / 100
                "
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.labor_cost_per_unit"
                :readonly="!managementRoles"
                outlined
                dense
                type="number"
                :rules="[nonNegative]"
                label="Labor Cost Per Unit"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="item.materials_cost_per_unit"
                :readonly="!managementRoles"
                outlined
                dense
                type="number"
                :rules="[nonNegative]"
                label="Materials Cost Per Unit"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="item.measurement_unit"
                :readonly="!managementRoles"
                outlined
                dense
                :items="[
                  'Cubic Foot',
                  'Linear Foot',
                  'Square Foot',
                  'Quantity',
                ]"
                label="Measurement Unit"
              />
            </v-col>
            <v-col v-if="isAdmin && hasAbility('super-admin')" cols="12">
              <v-text-field
                v-model="item.key"
                outlined
                dense
                class="small-text-input"
                label="key"
              />
            </v-col>
          </v-row>
        </div>
        <h3 class="my-3">Additional info</h3>

        <div class="px-2">
          <media-upload
            class="my-2"
            :preview="true"
            folder="catalogs"
            :value.sync="item.media"
          />
          <v-text-field
            v-model="item.avg_duration"
            outlined
            dense
            type="number"
            label="duration hours"
          />
          <v-combobox
            v-model="item.possible_causes"
            :items="catalogItem.possible_causes"
            chips
            multiple
            outlined
            label="Possible causes"
          />
          <v-combobox
            v-model="item.quick_fixes"
            :items="catalogItem.quick_fixes"
            chips
            multiple
            outlined
            dense
            label="Quick fixes"
          />
          <v-combobox
            v-model="item.needed_actions"
            :items="catalogItem.needed_actions"
            chips
            multiple
            outlined
            dense
            label="Needed actions"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import MediaUpload from 'components/common/media-upload'
import ContractorSelect from 'components/contractor-select'
import { mapActions, mapGetters, mapState } from 'vuex'
import LtSubTaskListItem from 'components/listing-tasks/lt-sub-task-list-item'
import LtTaskimSectionsSelect from 'components/taskim/lt-taskim-sections-select'
import ExpensesTypesAutocomplete from 'components/expenses-types-autocomplete.vue'

export default {
  name: 'EditCatalogItemModal',
  components: {
    LtTaskimSectionsSelect,
    LtSubTaskListItem,
    ContractorSelect,
    MediaUpload,
    ExpensesTypesAutocomplete,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['catalogItem', 'onChange'],
  mounted() {
    this.getTaskTypes()
  },
  data() {
    return {
      byContractor:
        this.catalogItem && this.catalogItem.by_personnel
          ? 'personnel'
          : 'contractor',
      personnelNames: [],
      item: { ...this.catalogItem },
      media: [],
    }
  },
  computed: {
    ...mapGetters(['listingTaskTypes']),
    ...mapGetters('users', ['approvers']),
    ...mapState('zones', ['zones']),
    zoneItems() {
      return this.zones.map(a => ({ text: a.name, value: a.id }))
    },
  },
  watch: {
    byContractor(val) {
      if (val === 'personnel') {
        this.item.user_id = null
      } else {
        this.item.by_personnel = null
      }
    },
  },
  methods: {
    ...mapActions(['getTaskTypes']),
    checkListChange(val) {
      this.item.checklist = val
    },
    closeModal() {
      this.$store.commit('hideModal')
    },
    async save() {
      if (this.media.length > 0) {
        this.item.media = this.item.media.concat(this.media)
      }
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('updateListingTaskCatalogItem', {
          id: this.item.id,
          payload: this.item,
        })
        if (this.onChange) {
          this.onChange()
        }
        this.closeModal()
        console.log('test log')
      }
    },
  },
}
</script>

<style scoped></style>
