<template>
  <div class="contactor-listing-task-items">
    <v-row
      v-if="layout !== 'paid' && !isEmpty(list) && !isContractor"
      justify="space-between"
      align="center"
    >
      <v-col cols="auto">
        <v-btn class="text-capitalize" text @click="$emit('select-all')">
          <v-icon :color="allSelected ? 'primary' : ''">
            {{
              allSelected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
          <span class="ml-2">
            {{ allSelected ? 'Deselect all' : 'Select all' }}
          </span>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <span class="secondary--text mr-4">
          {{ selectedTasks.size }} Selected
        </span>
        <v-tooltip
          v-if="
            (managementRoles && hasAbility('generate-payments-nacha')) ||
            isAdmin
          "
          bottom
          open-delay="100"
          :disabled="!selectedTasks.size || haveAchAccount"
        >
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                class="text-capitalize"
                color="secondary"
                elevation="0"
                :disabled="!selectedTasks.size || !haveAchAccount"
                @click="$emit('open-payments-actions')"
              >
                Payments Actions
              </v-btn>
            </span>
          </template>
          <span>Contractor doesn't have full account info</span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto">
        <v-btn small color="success" @click="downloadExcel">
          <v-icon small left>fas fa-table</v-icon> Export
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="layout === 'paid' && !isEmpty(list) && !isContractor"
      justify="space-between"
      align="center"
    >
      <v-col cols="auto"></v-col>
      <v-col cols="auto"></v-col>
      <v-col cols="auto">
        <v-btn small color="success" @click="downloadExcel">
          <v-icon small left>fas fa-table</v-icon> Export
        </v-btn>
      </v-col>
    </v-row>
    <v-list>
      <template #default>
        <v-sheet
          v-for="(group, key) in orderedList"
          :key="key"
          class="mb-1"
          outlined
          rounded
        >
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title class="px-3">
                <v-row align="center">
                  <v-col cols="12" sm="7" md="7" class="pa-2">
                    {{ key }}
                  </v-col>
                  <v-col
                    cols="5"
                    sm="2"
                    md="2"
                    :class="{ 'red--text': !groupAmount(group) }"
                  >
                    {{ toMoney(groupAmount(group)) }}
                  </v-col>
                  <v-col cols="5" sm="2" md="1">
                    {{ groupHours(group) }} h</v-col
                  >
                  <v-spacer />

                  <v-col cols="auto">
                    <v-btn icon small @click="$emit('on-expand', key)">
                      <v-icon small>
                        {{ expand[key] ? 'mdi-minus' : 'mdi-plus' }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-expand-transition>
                  <v-card v-show="expand[key]">
                    <v-list class="py-0">
                      <v-list-item v-for="task in group" :key="task.id">
                        <v-list-item-content class="py-0">
                          <v-list-item-subtitle class="px-3">
                            <v-row align="center">
                              <v-col cols="12" sm="4" class="pa-2">
                                <div class="d-flex align-center">
                                  <v-btn
                                    v-if="layout !== 'paid' && !isContractor"
                                    icon
                                    @click="$emit('update-selected', task.id)"
                                  >
                                    <v-icon
                                      v-if="selectedTasks.has(task.id)"
                                      color="primary"
                                    >
                                      mdi-checkbox-marked
                                    </v-icon>
                                    <v-icon v-else>
                                      mdi-checkbox-blank-outline
                                    </v-icon>
                                  </v-btn>
                                  <div>
                                    <div v-if="taskHaveHotelNickname(task)">
                                      {{ taskListingNickname(task) }}
                                    </div>
                                    <div v-if="task.expense">
                                      {{ task.expense.short_reason }}
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="3" sm="1">
                                <v-tooltip v-if="task.expense" bottom>
                                  <template #activator="{ on, attrs }">
                                    <router-link
                                      v-if="isContractor"
                                      text
                                      small
                                      class="text-decoration-underline secondary--text pointer"
                                      v-bind="attrs"
                                      :target="isMobile ? '_self' : '_blank'"
                                      :to="{
                                        path: `/dashboard/listing-task/${task.id}`,
                                      }"
                                      v-on="on"
                                    >
                                      {{ task.id }}
                                    </router-link>
                                    <span
                                      v-else
                                      text
                                      small
                                      v-bind="attrs"
                                      class="text-decoration-underline secondary--text pointer"
                                      v-on="on"
                                      @click="showExpense(task)"
                                    >
                                      {{ task.expense.id }}
                                    </span>
                                  </template>
                                  <span>{{
                                    isContractor
                                      ? 'View service call'
                                      : 'View expense'
                                  }}</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="3" sm="2">
                                {{ parseDate(task.scheduled_at) }}
                              </v-col>
                              <v-col cols="4" sm="2">
                                <span
                                  :class="{ 'red--text': !task.payable_amount }"
                                >
                                  {{ toMoney(task.payable_amount) }}
                                </span>
                                <span
                                  v-if="task.deposit"
                                  class="font-italic text-caption secondary--text"
                                >
                                  (
                                  {{ toMoney(task.deposit) }} deposit)
                                </span>
                              </v-col>
                              <v-col cols="4" sm="2" md="1">
                                {{ task.total_hours }} h
                              </v-col>
                              <v-col
                                v-if="task.paid_at"
                                md="2"
                                class="d-none d-md-block"
                              >
                                <span>
                                  {{ parseDate(task.paid_at) }} /
                                  {{ task.transfer_identifier }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-expand-transition>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import ContractorPayments from 'components/mixins/contractor-payments-mixin'
import sumBy from 'lodash/sumBy'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ContractorListingTaskItems',
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    ContractorPayments,
    DeviceMixin,
    FormattersMixin,
  ],
  props: {
    layout: { type: String, default: '' },
    name: { type: String, default: '' },
    dates: { type: Object, default: () => ({}) },
    allSelected: { type: Boolean, default: false },
    haveAchAccount: { type: Boolean, default: false },
    selectedTasks: {
      type: Set,
      default: () => new Set(),
    },
    list: { type: Object, default: () => ({}) },
    expand: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(['isContractor']),
    orderedList() {
      return Object.keys(this.list)
        .sort()
        .reduce((obj, key) => {
          obj[key] = this.list[key]
          return obj
        }, {})
    },
  },
  methods: {
    ...mapActions(['getListingTaskContractorPayments']),
    ...mapMutations(['showModal']),
    showExpense(task) {
      this.showModal({
        name: 'ExpenseCard',
        props: { expense: task.expense, listingTask: task },
      })
    },
    async downloadExcel() {
      try {
        const listings = Object.entries(this.orderedList).map(
          ([listing, group]) => {
            return [listing, group.map(t => t.id)]
          }
        )
        await this.getListingTaskContractorPayments({
          listings: listings,
          name: `${this.name}_${this.dates.from} - ${this.dates.to}`,
        })
      } catch (e) {
        console.log(e)
      }
    },
    groupAmount(group) {
      return sumBy(group, 'payable_amount') || 0
    },
    groupHours(group) {
      return sumBy(group, 'total_hours') || 0
    },
  },
}
</script>
