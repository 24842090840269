import axios from 'axios'

export default {
  data() {
    return {
      tags: [],
    }
  },
  methods: {
    async fetchTags(sort = false) {
      try {
        const { data } = await axios.get('/api/listings/tags')
        this.tags = Array.from(new Set(data.filter(tag => tag && tag.trim())))
        if (sort) this.tags.sort()
      } catch (error) {
        console.error('Error fetching tags:', error)
      }
    },
  },
}
