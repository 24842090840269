<template>
  <div class="grey lighten-4">
    <v-progress-linear v-show="loadingGuestExpInfo" indeterminate />
    <conversation-drawer @update-conversations="updateConversations" />
    <v-row v-if="stats && !isMobile">
      <!--      <stat-card-->
      <!--        label="Today Sales"-->
      <!--        :value="toMoney(stats.marked_sales_amount, {decimals: 0})"-->
      <!--        :secondary="`${stats.marked_sales_count} Sales`"-->
      <!--        tooltip="Marked as sales in the last day"-->
      <!--      />-->
      <!--      <stat-card-->
      <!--        label="Potential Sales"-->
      <!--        :value="toMoney(stats.potential_sales_amount, {decimals: 0})"-->
      <!--        :secondary="`${stats.potential_sales_count} inquires`"-->
      <!--        tooltip="Total price of inquires in the last day"-->
      <!--      />-->
      <!--      <stat-card-->
      <!--        label="Conversion Rate"-->
      <!--        :value="`${toPercent(stats.conversation_rate, 1) || '-'}`"-->
      <!--        :secondary="`${stats.marked_sales_count}/${stats.potential_sales_count}`"-->
      <!--        tooltip="Conversion in sales in the last day"-->
      <!--      />-->
      <!--      <stat-card-->
      <!--        :label="`Top seller - ${$moment().format('MMM DD')}`"-->
      <!--        :value="toMoney(topSellerDailyAmount, {decimals: 0}) || '-'"-->
      <!--        :tooltip="sellersDailyText"-->
      <!--      >-->
      <!--        <template #secondary>-->
      <!--          <div v-if="topSellerDailyName" class="d-flex justify-center">-->
      <!--            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>-->
      <!--            <span-->
      <!--              class="text-capitalize text-subtitle-2 teal&#45;&#45;text text&#45;&#45;darken-0 font-weight-medium"-->
      <!--              >{{ topSellerDailyName }}</span-->
      <!--            >-->
      <!--            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>-->
      <!--          </div>-->
      <!--          <div v-else class="d-flex justify-center">-->
      <!--            <span-->
      <!--              class="text-capitalize text-subtitle-2 teal&#45;&#45;text text&#45;&#45;darken-0 font-weight-medium"-->
      <!--              >-</span-->
      <!--            >-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </stat-card>-->
      <!--      <stat-card-->
      <!--        :label="`Total sales - ${$moment().format('MMMM')}`"-->
      <!--        :value="toMoney(totalSales, {decimals: 0}) || '-'"-->
      <!--        :tooltip="sellersText"-->
      <!--      >-->
      <!--        <template #secondary>-->
      <!--          <div v-if="topSellerName" class="d-flex justify-center">-->
      <!--            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>-->
      <!--            <span-->
      <!--              class="text-capitalize text-subtitle-2 teal&#45;&#45;text text&#45;&#45;darken-0 font-weight-medium"-->
      <!--              >{{ topSellerName }}</span-->
      <!--            >-->
      <!--            <v-icon class="mx-1" small color="yellow">fas fa-star</v-icon>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </stat-card>-->
    </v-row>
    <v-tabs
      v-if="stats"
      v-model="tab"
      grow
      center-active
      slider-color="grey"
      :class="{ 'mt-0 mt-sm-2': true, 'hide-arrows': isMobile }"
      background-color="teal lighten-5"
      @change="tabChange"
    >
      <dashboard-tab
        href="#hot_leads"
        label="Unanswered"
        :value="stats.hot_leads_count"
      />
      <dashboard-tab
        v-if="showModule('ai')"
        href="#ai-escalations"
        :label="isMobile ? 'AI' : 'AI Escalations'"
        :value="stats.ai_escalations_count"
      />
      <dashboard-tab
        v-if="showModule('ai')"
        href="#ai-answered"
        :label="isMobile ? 'AI Active' : 'AI Active (12h)'"
        :value="stats.ai_answered_count"
      />
      <dashboard-tab
        href="#active_leads"
        :label="isMobile ? 'Leads' : 'Active Leads'"
        :value="stats.active_leads_count"
      />
      <dashboard-tab
        href="#follow_ups"
        :label="isMobile ? 'FollowUp' : 'Follow Ups'"
        :value="stats.follow_ups_count"
      />
      <dashboard-tab
        href="#my_tickets"
        :label="isMobile ? 'Mine' : 'My Tickets'"
        :value="stats.my_tickets_count"
      />
      <dashboard-tab
        href="#awaiting_payment"
        :label="isMobile ? 'Unpaid' : 'Awaiting Payment'"
        :value="stats.awaiting_payment_count"
      />
      <dashboard-tab
        href="#recently_confirmed"
        :label="isMobile ? 'Confirmed' : 'Recently Confirmed'"
        :value="stats.recently_confirmed_count"
      />
      <dashboard-tab href="#all" label="All" />
    </v-tabs>
    <div>
      <div>
        <v-expansion-panels v-if="isMobile" accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>Filters</v-expansion-panel-header>
            <v-expansion-panel-content class="px-3">
              <conversation-filters
                :filters="filters"
                @filter-change="updateFilter"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <conversation-filters
          v-else
          :filters="filters"
          @filter-change="updateFilter"
        />
      </div>
      <div v-if="isMobile && conversations">
        <v-divider />
        <conversations-mobile
          :items="conversations"
          :pagination="pagination"
          @paginate="paginate"
        />
      </div>
      <conversations-table
        v-else
        :headers="headers"
        :items="conversations"
        :options="pagination"
        @update-conversations="refreshSales"
        @row-click="rowClick"
        @paginate="paginate"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import dashboardTab from 'components/common/dashboard-tab.vue'
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'
import ConversationsTable from 'components/crm/conversations-table.vue'
import ConversationFilters from './conversation-filters.vue'
import ConversationDrawer from './conversation-drawer.vue'
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'
import ConversationsMobile from 'components/crm/conversations-mobile.vue'
import CrmMixin from 'components/mixins/crm-mixin'

export default {
  components: {
    dashboardTab,
    ConversationsTable,
    ConversationFilters,
    ConversationDrawer,
    ConversationsMobile,
  },
  mixins: [
    formattersMixin,
    colorsMixin,
    CommonFunctions,
    DeviceMixin,
    CrmMixin,
  ],
  computed: {
    ...mapState(['loadingGuestExpInfo']),
    // topSeller() {
    //   if (!isEmpty(this.stats.sales_board)) {
    //     return this.stats.sales_board[0]
    //   }
    //   return null
    // },
    // topSellerDaily() {
    //   if (!isEmpty(this.stats.sales_board_daily)) {
    //     return this.stats.sales_board_daily[0]
    //   }
    //   return null
    // },
    // topSellerDailyAmount() {
    //   if (this.topSellerDaily) {
    //     return this.topSellerDaily[1]
    //   }
    //   return null
    // },
    // totalSales() {
    //   return this.stats.marked_sales_month
    // },
    // topSellerName() {
    //   if (this.topSeller) {
    //     return this.nameById(this.topSeller[0])
    //   }
    //   return null
    // },
    // topSellerDailyName() {
    //   if (this.topSellerDaily) {
    //     return this.nameById(this.topSellerDaily[0])
    //   }
    //   return null
    // },
    // sellersText() {
    //   let txt = '<div>'
    //   txt += '<h4>This month stats </h4>'
    //   if (!isEmpty(this.stats.sales_board)) {
    //     this.stats.sales_board.forEach((seller, idx) => {
    //       txt += `<p class="mt-1 mb-1 pa-0"><span class="bolder mr-2">${
    //         idx + 1
    //       }.</span>${this.nameById(seller[0])}: ${this.toMoney(
    //         seller[1],
    //         0
    //       )}</p>`
    //     })
    //   }
    //   txt += '</div>'
    //   return txt
    // },
    // sellersDailyText() {
    //   let txt = '<div>'
    //   txt += '<h4>This day stats </h4>'
    //   if (!isEmpty(this.stats.sales_board_daily)) {
    //     this.stats.sales_board_daily.forEach((seller, idx) => {
    //       txt += `<p class="mt-1 mb-1 pa-0"><span class="bolder mr-2">${
    //         idx + 1
    //       }.</span>${this.nameById(seller[0])}: ${this.toMoney(
    //         seller[1],
    //         0
    //       )}</p>`
    //     })
    //   }
    //   txt += '</div>'
    //   return txt
    // },
  },
  data() {
    return {
      crmMode: 'sales',
      conversations: null,
      tab: this.$route.query.conversation ? 'all' : 'hot_leads',
      stats: null,
      pagination: { page: 1 },
      filters: {},
      headers: [
        {
          text: '',
          value: 'reservation_status',
          sortable: false,
        },
        {
          text: 'Guest',
          value: 'guest_full_name',
          sortable: false,
        },
        {
          text: 'Dates',
          value: 'extra_data.reservation_check_in',
          sortable: false,
        },
        {
          text: 'Property',
          value: 'extra_data.listing_nickname',
          sortable: false,
        },
        {
          text: 'Price',
          value: 'reservation_price',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Last Msg',
          value: 'last_message_from_guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Assignee',
          value: 'assigned_user_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Source',
          value: 'extra_data.reservation_source',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      items: [{}],
    }
  },
  methods: {
    ...mapActions(['getSalesInfo']),
    async getSales(filters = {}) {
      const { data } = await this.getSalesInfo({ ...filters })
      this.stats = data.stats

      if (filters.stats_only) return
      this.conversations = data.conversations
      this.pagination = {
        page: +data.pagination.page,
        itemsPerPage: data.pagination.per_page,
        totalItems: data.pagination.conversations_count,
      }
    },
    tabChange(tab) {
      this.filters = {}
      this.getSales({ [tab]: true })
    },
    updateFilter(field, val) {
      this.filters = { ...this.filters, [field]: val }
      this.getSales({ [this.tab]: true, ...this.filters })
    },
    paginate(page) {
      if (page === this.pagination.page) return
      this.getSales({ [this.tab]: true, ...this.filters, page })
    },
    refreshSales(options = {}) {
      this.getSales({
        [this.tab]: true,
        ...this.filters,
        page: this.pagination.page,
        ...options,
      })
    },
    updateConversations() {
      this.getSales({ [this.tab]: true, ...this.filters, page: 1 })
    },
    rowClick({ guesty_id }) {
      this.$router.push({ query: { conversation: guesty_id } })
    },
  },
}
</script>
<style scoped>
::v-deep .v-data-footer {
  justify-content: end;
}
::v-deep .v-tabs-bar {
  height: 100%;
}
::v-deep .hide-arrows .v-slide-group__prev {
  display: none !important;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
