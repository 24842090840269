export default {
  filters: {
    currency(value) {
      if (!value) return '$0.00'
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },
  },

  methods: {
    getTransactionTypeColor(type) {
      return type === 'debit' ? 'blue lighten-4' : 'green lighten-4'
    },

    getTransactionTypeTextColor(type) {
      return type === 'debit' ? 'blue darken-2' : 'green darken-2'
    },

    getAmountClass(transaction) {
      const isPositive =
        transaction.ledger_account.normal_balance_type ===
        transaction.transaction_type
      return isPositive ? 'success--text' : 'error--text'
    },

    formatTransactionableType(type) {
      return type?.split('::').pop() || type
    },

    getReferenceText(transaction) {
      switch (transaction.transactionable_type) {
        case 'Reservation':
          return 'View Reservation'
        case 'Expense':
          return 'View Expense'
        default:
          return transaction.reference_id
      }
    },

    viewReference(transaction) {
      if (transaction.transactionable_type === 'Reservation') {
        this.$router.push(`/dashboard/reservation/${transaction.reference_id}`)
      } else if (transaction.transactionable_type === 'Expense') {
        this.$router.push(`/dashboard/expenses/${transaction.reference_id}`)
      }
    },

    viewAccount(account) {
      this.$router.push(`/dashboard/management/ledger-accounts/${account.id}`)
    },
  },
}
