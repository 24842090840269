<template>
  <v-container>
    <v-row class="align-center">
      <v-col cols="2">
        <date-picker v-model="date" />
      </v-col>
      <v-col cols="auto">
        <v-btn depressed color="primary" @click="todayHandler">Today</v-btn>
      </v-col>
      <v-col cols="auto">
        <filters-select
          label="Show Only"
          :value.sync="filters.onlyItems"
          :selection-func="item => item.text"
          :items="FILTER_OPTIONS"
          @change="updateFilters({ key: 'onlyItems' }, $event)"
        />
      </v-col>
      <v-col cols="auto">
        <v-select
          v-model="filters.region_id"
          label="Regions"
          outlined
          hide-details
          dense
          clearable
          :items="listingRegions"
          small-chips
          @change="updateFilters({ key: 'region_id' }, $event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <stat-card
        :small="true"
        label="Check-In"
        :value="todayCheckins"
        @click.native="statClicked(['isCheckin'])"
      />
      <stat-card
        :small="true"
        label="Check-Out"
        :value="todayCheckouts"
        @click.native="statClicked(['isCheckout'])"
      />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredReport"
      :loading="tableLoading"
      disable-pagination
      disable-sort
      hide-default-footer
      :item-class="itemRowBackground"
      fixed-header
    >
      <template v-for="header in headers" #[getSlotName(header)]="{ item }">
        <v-simple-checkbox
          v-if="header.cellType === CELL_TYPES.CHECKBOX && item[header.value]"
          :key="`checkbox_${header.value}_${item.id}`"
          :value="item[header.value]"
          color="primary"
        />
        <v-text-field
          v-else-if="header.cellType === CELL_TYPES.TEXT_FIELD"
          :key="`textfield_${header.value}_${item.id}`"
          :placeholder="header.text"
          dense
          width="10"
          hide-details
        />
        <a
          v-else-if="header.cellType === CELL_TYPES.LINK"
          :key="`link_${header.value}_${item.id}`"
          target="_blank"
          :href="`dashboard/listing-task/${item[header.value]}`"
          class="black--text"
        >
          {{ item[header.cleaningTaskStatus] }}</a
        >
        <template v-else-if="header.value === 'is_dirty'">
          <div
            :key="`switch_${header.value}_${item.id}`"
            class="d-flex align-center justify-center"
          >
            <v-icon
              small
              class="mr-3"
              :color="item.is_dirty === true ? 'error' : 'primary'"
            >
              fas fa-broom
            </v-icon>
            <confirmation-modal
              :text="`Are you sure you want to mark this listing as ${
                item.is_dirty ? 'clean' : 'dirty'
              }?`"
              @action="updateIsClean(item, !item.is_dirty)"
            >
              <template #activator="{ on }">
                <v-switch
                  :loading="item.isLoading"
                  :input-value="!item.is_dirty"
                  class="ma-0"
                  dense
                  inset
                  hide-details
                  v-on="on"
                />
              </template>
            </confirmation-modal>
          </div>
        </template>
        <span
          v-else
          :key="`span_${header.value}_${item.id}_modal`"
          class="black--text"
        >
          {{ formattedField(item, header) }}</span
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  defaultReport,
  FILTER_OPTIONS,
} from 'components/hotel-dashboard/config.js'
import CommonFunctions from 'components/mixins/common_functions'
import FormatterMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker'
import FiltersSelect from 'components/form-fields/filters-select'
import { mapActions, mapGetters, mapState } from 'vuex'
import StatCard from 'components/common/stat-card'
import ConfirmationModal from 'components/modals/confirmation-modal'
import toNumber from 'lodash/fp/toNumber'

export default {
  components: { StatCard, DatePicker, FiltersSelect, ConfirmationModal },
  mixins: [CommonFunctions, FormatterMixin],
  data() {
    return {
      report: [],
      date: this.parseDate(new Date()),
      tableLoading: false,
      FILTER_OPTIONS,
      filters: { onlyItems: [] },
      filteredReport: [],
      ...defaultReport(),
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState('regions', ['regions']),
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id,
      }))
    },
  },
  watch: {
    date() {
      this.getReport()
    },
  },
  mounted() {
    this.getReport()
    if (this.currentUser.region) {
      this.filters.region_id = toNumber(this.currentUser.region)
    }
  },
  methods: {
    ...mapActions('housekeeping', ['createReport', 'fetchReport', 'markClean']),
    getSlotName(header) {
      return `item.${header.value}`
    },
    formattedField(item, header) {
      const val = item[header.value]
      return val || '-'
    },
    itemRowBackground(item) {
      return item.isOccupied ? 'white' : 'grey lighten-4'
    },
    todayHandler() {
      this.date = this.parseDate(new Date())
    },
    focusHandler() {
      this.dirty = true
    },
    statClicked(filters) {
      this.filters.onlyItems = filters
      this.updateFilters()
    },
    updateFilters() {
      if (this.filters.region_id) {
        this.filteredReport = this.report.filter(row => {
          return row.region_id === this.filters.region_id
        })
        return
      }
      if (!this.filters.onlyItems.length) {
        return (this.filteredReport = this.report)
      }
      this.filteredReport = this.report.filter(row => {
        return this.filters.onlyItems.some(v => row[v])
      })
    },
    getReport() {
      this.tableLoading = true
      this.fetchReport({ date: this.date }).then(({ data }) => {
        this.tableLoading = false
        if (!data) return
        const report = data.report || {}
        this.moves = report.moves
        this.wPatio = report.w_patio
        this.aPatio = report.a_patio
        this.vvPatio = report.vv_patio

        this.todayCheckouts = data.today_check_outs_count
        this.todayCheckins = data.today_check_ins_count
        this.todayStayOvers = data.today_stayovers_count
        this.tomorrowCheckouts = data.tomorrow_check_outs_count
        this.tomorrowCheckins = data.tomorrow_check_ins_count
        this.tomorrowStayOvers = data.tomorrow_stayovers_count
        this.todayRoomsMade = this.toPercent(
          (data.today_stayovers_count + data.today_check_ins_count) /
            data.listings.length
        )
        this.tomorrowRoomsMade = this.toPercent(
          (data.tomorrow_stayovers_count + data.tomorrow_check_ins_count) /
            data.listings.length
        )
        this.report = data.listings.map(listing => {
          const {
            stayover,
            today_check_in,
            today_check_out,
            cleaning_task,
            today_avialability_text,
          } = listing
          const reservation = stayover || today_check_in
          return {
            isCheckin: !!today_check_in,
            isCheckout: !!today_check_out,
            isStayOver: !!stayover,
            freeToday: today_avialability_text == 'Free today',
            guestsCount: reservation && reservation.guests_count,
            checkInTime: today_check_in && today_check_in.check_in_time_string,
            checkOutTime:
              today_check_out && today_check_out.check_out_time_string,
            isOccupied: !!reservation,
            cleaningTaskId: cleaning_task && cleaning_task.id,
            cleaningTaskStatus: cleaning_task && cleaning_task.status,
            cleaningIncomplete: cleaning_task,
            ...listing,
          }
        })
        this.updateFilters()
        this.dirty = false
      })
    },
    async saveChanges() {
      this.dirty = false
      this.createReport({
        report_date: this.date,
        moves: this.moves,
        w_patio: this.wPatio,
        a_patio: this.aPatio,
        vv_patio: this.vvPatio,
      })
    },
    async updateIsClean(item, isDirty) {
      item.isLoading = true
      await this.markClean({ listing_id: item.id, is_dirty: isDirty })
      await this.getReport()
      item.isLoading = false
    },
  },
}
</script>

<style></style>
