<template>
  <div>
    <carousel
      :space-padding="4"
      :per-page-custom="[
        [600, 2],
        [1000, 4],
      ]"
    >
      <slide class="pa-1">
        <data-filter-button
          tooltip="Monthly Ratio of Income/Cost"
          :disabled="true"
          :title="$t('Ops Expense ratio')"
          :delta="deltaValues.expenses"
          icon="$expenses"
          :delta-inverted="true"
          :value="toPercent(stats.current_month_expenses_ratio) || '-'"
          secondary-text="Prev:"
          :secondary-value="toPercent(stats.last_month_expenses_ratio)"
        />
      </slide>
      <slide class="pa-1">
        <data-filter-button
          tooltip="Last 30 days Avg review score"
          :title="$t('Review Score')"
          icon="$account_alt"
          :disabled="true"
          :delta="deltaValues.reviews"
          :value="`${round(stats.recent_reviews_avg, 1) || '-'}`"
          secondary-text="Prev:"
          :active="selectedStat === 'all-reviews'"
          :secondary-value="round(stats.prev_recent_reviews_avg, 1)"
        />
      </slide>
      <slide class="pa-1">
        <data-filter-button
          tooltip="Monthly customer refunds amount"
          :title="$t('Refunds')"
          icon="$wallet"
          :disabled="true"
          :value="toMoney(stats.refunds_amount, { decimals: 0 })"
          secondary-text="Prev:"
          :delta="deltaValues.refunds"
          :delta-inverted="true"
          :secondary-value="toMoney(stats.prev_refunds_amount, { decimals: 0 })"
          :active="selectedStat === 'client-refunds'"
        />
      </slide>
      <!--      <slide v-if="!isMobile" class="pa-1">-->
      <!--        <data-filter-button-->
      <!--          tooltip="Critical tasks done under 12 hours"-->
      <!--          :title="$t('SLA Critical')"-->
      <!--          :disabled="true"-->
      <!--          icon="fas fa-exclamation-triangle"-->
      <!--          :value="toPercent(stats.sla_critical_rate, 0) || '-'"-->
      <!--          secondary-text="Prev:"-->
      <!--          :delta="deltaValues.slaCritical"-->
      <!--          :secondary-value="toPercent(stats.prev_sla_critical_rate, 0) || '-'"-->
      <!--          :active="selectedStat === 'sla-critical'"-->
      <!--        />-->
      <!--      </slide>-->
    </carousel>
  </div>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import DataFilterButton from 'components/accounting/data-filter-button.vue'
import round from 'lodash/round'
import { mapState } from 'vuex'
export default {
  name: 'ReviewStats',
  mixins: [FormattersMixin, DeviceMixin, PermissionsMixin],
  components: { DataFilterButton },
  props: ['stats', 'listings', 'showGauge'],
  computed: {
    ...mapState('listingReport', ['fetchingReportData']),
    selectedStat() {
      return this.$route.query.filter
    },

    deltaValues() {
      const {
        current_month_expenses_ratio,
        last_month_expenses_ratio,
        recent_reviews_avg,
        prev_recent_reviews_avg,
        refunds_amount,
        prev_refunds_amount,
        supplies_cost,
        prev_supplies_cost,
        sla_critical_rate,
        prev_sla_critical_rate,
      } = this.stats
      return {
        expenses:
          (round(current_month_expenses_ratio / last_month_expenses_ratio, 2) -
            1) *
          100,
        reviews:
          (round(recent_reviews_avg / prev_recent_reviews_avg, 2) - 1) * 100,
        refunds: (round(refunds_amount / prev_refunds_amount, 2) - 1) * 100,
        supplies: (round(supplies_cost / prev_supplies_cost, 2) - 1) * 100,
        slaCritical:
          (round(sla_critical_rate / prev_sla_critical_rate, 2) - 1) * 100,
      }
    },
  },
  methods: {
    addToQuery(filter) {
      const currentQuery = this.$route.query
      this.$router.push({ query: { ...currentQuery, filter } })
    },
    emptyStatsObject(object) {
      if (object) {
        return Object.values(object).every(val => val == 0)
      }
    },
  },
}
</script>

<style>
.VueCarousel-dot-container {
  margin-top: 0 !important;
}
</style>
