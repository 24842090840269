<template>
  <div v-show="!loading">
    <div class="grey lighten-4">
      <v-container class="max-w-1600 pb-0">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <!--          <v-tab-->
          <!--            v-if="isDesignedVr && isAdmin"-->
          <!--            :disabled="!showModule('accounting')"-->
          <!--            href="#dash"-->
          <!--          >-->
          <!--            <v-icon class="mr-md-2">$activity</v-icon>-->
          <!--            <span class="d-none d-md-inline text-capitalize">Dash</span>-->
          <!--          </v-tab>-->
          <v-tab href="#expenses">
            <v-icon class="mr-md-2">$accounting</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Expenses')
            }}</span>
          </v-tab>
          <v-tab v-if="hasBankAccounts || isAdmin || isAccountant" href="#res">
            <v-icon class="mr-md-2">$money</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Reservations')
            }}</span>
          </v-tab>
          <v-tab
            v-if="hasBankAccounts || isAdmin || isAccountant"
            href="#payments"
          >
            <v-icon class="mr-md-2">$finance</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Payments')
            }}</span>
          </v-tab>
          <v-tab href="#expense-types">
            <v-icon class="mr-md-2">view_timeline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Expense types')
            }}</span>
          </v-tab>
          <v-tab v-if="isAdmin" href="#business-model">
            <v-icon class="mr-md-2">mdi-briefcase-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Business Model')
            }}</span>
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <div>
      <div>
        <v-tabs-items v-model="paramsTab" touchless>
          <v-tab-item value="expenses" class="pl-4 pr-4 main-background">
            <expenses
              v-if="paramsTab === 'expenses'"
              class="mt-3"
              :show-filters="true"
              :management-view="true"
              :enable-global-create="true"
            />
          </v-tab-item>
          <v-tab-item value="expense-types" class="pl-4 pr-4 main-background">
            <expense-types v-if="paramsTab === 'expense-types'" />
          </v-tab-item>
          <v-tab-item value="payments" class="pl-4 pr-4 main-background">
            <payments
              class="mt-3"
              :show-filters="true"
              :management-view="true"
              :enable-global-create="true"
            />
          </v-tab-item>
          <v-tab-item value="res" class="main-background">
            <reservation-manager />
          </v-tab-item>
          <v-tab-item value="business-model" class="pl-4 pr-4 main-background">
            <business-model-tab v-if="paramsTab === 'business-model'" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import BusinessModelTab from './business-model/business-model-tab'
import Expenses from 'components/expenses/expenses'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import ReservationManager from 'components/reservation-manager'
import Payments from 'components/payments'
import DeviceMixin from 'components/mixins/device-mixin'
import ExpenseTypes from 'components/expense-types'

export default {
  components: {
    Payments,
    ReservationManager,
    Expenses,
    ExpenseTypes,
    BusinessModelTab,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  computed: {
    hasBankAccounts() {
      return !isEmpty(get('bank_accounts_numbers', this.$store.state.user))
    },
    config() {
      return this.$store.state.app.configuration
    },
  },
}
</script>
