<template>
  <div v-if="reservation" class="pa-2 pa-sm-8 pt-2 pt-sm-4 reservation-page">
    <v-row>
      <v-col cols="12" sm="4">
        <div class="reservation-card title-black pa-4">
          <div class="p-relative py-1">
            <div class="d-flex justify-center">
              <div
                class="text-capitalize font-weight-semibold py-1 px-4 rounded-pill text-caption"
                :style="`background-color: ${STATUS_COLORS[statusDisplay]?.background}; color: ${STATUS_COLORS[statusDisplay]?.text};`"
              >
                {{ statusDisplay }}
              </div>
            </div>
            <div class="menu-position">
              <v-menu v-model="menu">
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list-item-group v-model="menuItem">
                  <v-list>
                    <v-list-item @click="viewInvoice">
                      View Reservation Details
                    </v-list-item>
                    <v-list-item @click="viewInvoice">
                      <reservation-email-modal
                        :key="reservation.id"
                        :invoice-email="touristEmail"
                        :reservation-id="reservation.reservation_guesty_id"
                        @close="closeMenu"
                      />
                    </v-list-item>
                  </v-list>
                </v-list-item-group>
              </v-menu>
            </div>
          </div>
          <div class="text-center font-weight-semibold text-h6">
            {{ listing.nickname }}
          </div>
          <div
            class="d-flex justify-space-between text-center mt-6 text-body-1"
          >
            <div class="flex-1">
              <div class="text-caption">CHECK IN</div>
              <div class="font-weight-semibold py-1">
                {{ checkInDate }}
              </div>
              <div class="font-weight-medium">{{ checkInTime }}</div>
            </div>
            <div class="flex-1">
              <div class="text-caption">CHECK OUT</div>
              <div class="font-weight-semibold py-1">
                {{ checkOutDate }}
              </div>
              <div class="font-weight-medium">{{ checkOutTime }}</div>
            </div>
          </div>
          <div class="d-flex justify-center button-gap mt-6 mb-4">
            <router-link
              v-if="guest.conversation && $route.name !== 'guests-chat'"
              :to="{
                name: 'guests-chat',
                params: { id: guest.conversation.guesty_id },
              }"
              :target="isMobile ? '_self' : '_blank'"
            >
              <v-btn depressed icon class="btn-border">
                <v-icon color="info" small>mdi-message-text</v-icon>
              </v-btn>
            </router-link>
            <reservation-edit-modal
              :key="reservation.id"
              :show-btn-border="true"
              :reservation="reservation"
              :listing="reservation.listing"
              @change="getReservation({ refresh: true })"
            />
            <router-link
              v-if="reservation.cleaning_listing_task_id"
              :to="{
                name: 'lt-page',
                params: { id: reservation.cleaning_listing_task_id },
              }"
              class="text-decoration-none white--text"
              target="_blank"
            >
              <v-btn depressed icon class="btn-border">
                <v-icon color="info" small>mdi-broom</v-icon>
              </v-btn>
            </router-link>
            <a
              v-if="reservation.reservation_guesty_id && appIntegrationGuesty"
              class="text-decoration-none white--text"
              :href="`https://app.guesty.com/reservations/${reservation.reservation_guesty_id}/summary`"
              target="_blank"
            >
              <v-btn depressed icon class="btn-border">
                <v-icon color="info" small>mdi-open-in-new</v-icon>
              </v-btn>
            </a>
            <raw-ru-data-dialog
              v-if="isSystemAdmin && reservation.external_id"
              :reservation="reservation"
              @refresh="getReservation"
            />
          </div>
          <v-divider />
          <v-row>
            <v-col cols="6">
              <div :class="labelClass">Guests</div>
              <div :class="valueClass">{{ reservation.guests_count }}</div>
            </v-col>
            <v-col cols="6">
              <div :class="labelClass">Nights</div>
              <div :class="valueClass">{{ reservation.nights_count }}</div>
            </v-col>
            <v-col cols="6">
              <div :class="labelClass">Channel</div>
              <div :class="valueClass">{{ reservation.source }}</div>
            </v-col>
            <v-col cols="6">
              <div :class="labelClass">PreCheck In</div>
              <div :class="valueClass">
                {{
                  reservation.pre_check_in?.status === 'Done'
                    ? 'Filled'
                    : 'Not Filled'
                }}
              </div>
            </v-col>
            <v-col cols="6">
              <div :class="labelClass">Price</div>
              <div :class="valueClass">
                {{ toMoney(reservation.price, { currency }) }}
              </div>
            </v-col>
            <v-col
              v-if="reservation.created_by_id || reservation.grand_confirmation"
              cols="6"
            >
              <div :class="labelClass">{{ $t('Created By') }}</div>
              <div :class="valueClass">
                {{
                  reservation.created_by_id
                    ? nameById(reservation.created_by_id)
                    : 'Excel'
                }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider />
              <v-row class="d-flex align-center p-relative">
                <div class="menu-position ma-3">
                  <guest-edit
                    v-if="guest.id && !reservationLoading"
                    :guest="guest"
                    @onsuccess="getReservation"
                  />
                </div>
                <v-col cols="5" :class="labelClass">Guest Name</v-col>
                <v-col cols="7" :class="valueClass">{{
                  guest.full_name
                }}</v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col cols="5" :class="labelClass">Phone</v-col>
                <v-col cols="7" :class="valueClass">{{ guestPhone }}</v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col cols="5" :class="labelClass">Email</v-col>
                <v-col cols="7" :class="valueClass">
                  <v-tooltip v-if="guestEmail" bottom>
                    <template #activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{
                          guestEmail.length > 28
                            ? guestEmail.substring(0, 28) + '...'
                            : guestEmail
                        }}
                      </span>
                    </template>
                    <span>{{ guestEmail }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col cols="5" :class="labelClass">Receives Marketing</v-col>
                <v-col cols="7" :class="valueClass">{{
                  guest.marketing_enabled ? 'Yes' : 'No'
                }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div :class="labelClass">Notes</div>
              <div class="my-2" v-html="reservationNotes" />
              <v-textarea
                autogrow
                outlined
                label="Internal Notes"
                hide-details
                dense
                light
                rows="2"
                :value="reservation.dvr_notes"
                @blur="updateNotes"
              />
            </v-col>
            <v-col cols="12">
              <confirmation-modal
                class="mt-2"
                :text="`Are you sure you want to stop automated messages this reservation?`"
                @action="toggleAutomation"
              >
                <template #activator="{ on }">
                  <v-btn
                    small
                    :color="reservation.stop_automation ? 'yellow' : 'green'"
                    outlined
                    v-on="on"
                  >
                    <v-icon x-small class="me-1">mdi-lock-open</v-icon>
                    {{
                      reservation.stop_automation
                        ? 'Automated messages paused'
                        : 'Automated messages running'
                    }}
                  </v-btn>
                </template>
              </confirmation-modal>
            </v-col>
          </v-row>
          <div class="text-caption secondary--text">
            Reservation ID: {{ reservation.id }} |
            <span v-if="reservation.external_id"
              >{{ reservation.external_id }} |
            </span>
            <span v-if="reservation.confirmation_code"
              >{{ reservation.confirmation_code }} | Confirmed:
              {{ parseDate(reservation.confirmed_at) }}</span
            >
            <span>
              | {{ $t('Made') }}: {{ parseDate(reservation.created_at) }}
            </span>
          </div>
          <div
            v-if="reservation.xero_external_invoice_id"
            class="text-caption secondary--text"
          >
            Xero Invoice ID: {{ reservation.xero_external_invoice_id }}
          </div>
        </div>
        <div class="reservation-card title-black pa-4 mt-4">
          <reservation-calendar
            v-if="$store.state.currentListing.days_rates"
            :add-on-res="reservation"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <div class="reservation-card">
          <div>
            <v-tabs
              v-model="reservationTab"
              background-color="white"
              slider-color="primary"
            >
              <v-tab
                href="#finance"
                class="text-capitalize font-weight-medium tab-blue"
                ><v-icon small class="me-2">mdi-cash</v-icon> Finance</v-tab
              >
              <v-tab
                href="#store"
                class="text-capitalize font-weight-medium tab-blue"
                ><v-icon small class="me-2">mdi-store</v-icon> Store</v-tab
              >
              <v-tab
                href="#precheckin"
                class="text-capitalize font-weight-medium tab-blue"
                ><v-icon small class="me-2">mdi-calendar-check</v-icon>
                Portal</v-tab
              >
              <v-tab
                v-if="isAdmin"
                href="#logs"
                class="text-capitalize font-weight-medium tab-blue"
                ><v-icon small class="me-2">mdi-history</v-icon> Logs</v-tab
              >
            </v-tabs>
            <v-divider />
          </div>
          <div>
            <v-tabs-items v-model="reservationTab">
              <v-tab-item value="finance">
                <div>
                  <div class="d-flex pa-4 ma-4 balance-card flex-wrap">
                    <div class="flex-1">
                      <div :class="labelClass">Balance</div>
                      <div
                        class="font-weight-semibold title-black text-h6 d-flex"
                      >
                        {{ toMoney(reservation.balance_due, { currency }) }}
                        <tag
                          v-if="
                            (reservation.source == 'Airbnb' ||
                              reservation.source == 'Booking.com') &&
                            !reservation.is_merchat
                          "
                          color="primary"
                          :inverted="true"
                          size="sm"
                          class="ms-2"
                          >Payment program</tag
                        >
                      </div>
                    </div>
                    <div class="d-flex align-center mt-4 mt-sm-0">
                      <confirmation-modal
                        v-if="
                          isAdmin &&
                          $store.state.app.configuration.has_xero &&
                          !reservation.xero_external_invoice_id
                        "
                        :text="`Are you sure you want to push this reservation as invoice to Xero?`"
                        @action="pushToXero"
                      >
                        <template #activator="{ on }">
                          <v-btn small color="info" outlined v-on="on">
                            Send to Xero
                          </v-btn>
                        </template>
                      </confirmation-modal>
                    </div>
                    <div class="d-flex align-center mt-4 mt-sm-0">
                      <card-info-modal
                        v-if="isAdmin && reservation.tokenizer_id"
                        :reservation="reservation"
                      />
                      <collect-payment
                        :loading="reservationLoading"
                        :reservation="reservation"
                        @success="getReservation"
                      />
                      <add-invoice-modal
                        v-if="isBoomRes(reservation)"
                        :reservation="reservation"
                        @add-invoice="addInvoice"
                      />
                    </div>
                  </div>
                  <reservation-invoices-table
                    v-if="invoiceItems.length"
                    :items="invoiceItems"
                    @remove-invoice="removeInvoice"
                  />
                  <v-data-table
                    v-if="paymentItems.length"
                    class="text-capitalize mb-4"
                    disable-sort
                    hide-default-footer
                    :headers="paymentItemsHeaders"
                    :items="paymentItems"
                  >
                    <template #[`item.type`]="{ item }">
                      <div>
                        {{ item.type }}
                        <info-tooltip v-if="item.note"
                          >{{ item.note }}
                        </info-tooltip>
                      </div>
                    </template>
                    <template #[`item.attachments`]="{ item }">
                      <payment-attachments-modal
                        v-if="item.attachments && item.attachments.length"
                        :attachments="item.attachments"
                        @uploaded="getReservation"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            small
                            outlined
                            color="primary"
                            class="text-none"
                            v-on="on"
                          >
                            <v-icon small left>mdi-paperclip</v-icon>
                            Attachments
                          </v-btn>
                        </template>
                      </payment-attachments-modal>
                    </template>
                  </v-data-table>
                  <div
                    v-if="reservation && reservation.payment_intents.length"
                    class="text-body-2 font-weight-semibold black--text mx-4 mt-8"
                  >
                    Payment attempts
                  </div>
                  <reservation-intents-table
                    v-if="reservation && reservation.payment_intents.length"
                    :reservation="reservation"
                    :get-reservation="getReservation"
                  />
                  <div class="reservation-card title-black mt-4">
                    <expenses
                      :listing="listing"
                      :reservation-id="reservation.id"
                      :show-filters="false"
                      :management-view="false"
                      :title="''"
                    />
                  </div>
                  <div class="reservation-card title-black mt-4">
                    <reservation-finances :reservation="reservation" />
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item value="store">
                <div class="d-flex justify-end align-center mx-4">
                  <quick-charge
                    :reservation="reservation"
                    :tourist-email="touristEmail"
                    @get-purchases="getPurchases"
                  />
                </div>
                <purchase-table
                  v-if="reservation"
                  :hide-picker="true"
                  :guest-id="reservation.guest_id"
                />
              </v-tab-item>
              <v-tab-item value="precheckin" class="ma-4">
                <v-card
                  elevation="0"
                  class="d-flex py-3 px-3 mb-3 flex-wrap balance-card align-center justify-space-between"
                >
                  <div
                    class="font-weight-medium error--text label-black title-black text-body-2 d-flex"
                  >
                    {{ notPaidText }}
                  </div>

                  <confirmation-modal
                    v-if="preCheckInEdit"
                    :text="`Are you sure you want to unlock the portal for this reservation?`"
                    @action="unlockPortal"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        small
                        color="info"
                        outlined
                        :disabled="preCheckInEdit.unlock_portal_access"
                        v-on="on"
                      >
                        <v-icon x-small class="me-1">mdi-lock-open</v-icon>
                        {{
                          preCheckInEdit.unlock_portal_access
                            ? 'Portal Unlocked'
                            : 'Unlock Portal'
                        }}
                      </v-btn>
                    </template>
                  </confirmation-modal>
                </v-card>
                <div
                  v-if="reservation.pre_check_in?.status === 'New'"
                  class="label-black text-caption font-weight-medium text-center"
                >
                  Not Filled
                  <!-- enable_tourist_access -->
                </div>
                <v-row v-else>
                  <v-col
                    v-for="item in preCheckInValues"
                    :key="item.label"
                    :cols="isMobile ? 12 : 4"
                  >
                    <div :class="labelClass">{{ item.label }}</div>
                    <div :class="valueClass">
                      {{ item.value || '-' }}
                    </div>
                  </v-col>
                  <v-col v-if="preCheckInEdit" cols="12">
                    <div>
                      <v-text-field
                        v-model="billingAddress"
                        :readonly="billingAddressReadOnly"
                        label="Guest Billing address"
                        outlined
                        dense
                        small
                        class="mr-2"
                        @input="billingAddressDirty = true"
                      />
                    </div>
                    <div class="d-flex justify-end">
                      <v-btn
                        v-if="billingAddressDirty"
                        small
                        outlined
                        color="primary"
                        @click="updateBillingAddress"
                        >Save</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col
                    v-if="
                      reservation.pre_check_in &&
                      reservation.pre_check_in.id_pic
                    "
                    cols="3"
                  >
                    <img
                      class="rounded-lg"
                      width="100%"
                      :src="reservation.pre_check_in.id_pic"
                    />
                  </v-col>
                </v-row>
                <v-card class="pa-4 mb-4" outlined>
                  <div
                    class="text-body-2 font-weight-semibold mb-4 d-flex align-center"
                  >
                    <v-icon color="primary" class="me-2"
                      >mdi-door-closed-lock</v-icon
                    >
                    Door Codes
                  </div>
                  <template v-if="hasDoorCodes">
                    <div
                      v-for="(code, name) in reservation.available_door_codes"
                      :key="name"
                      class="mb-2"
                    >
                      <div
                        v-if="code"
                        class="d-flex justify-space-between align-center"
                      >
                        <span class="font-weight-medium">{{ name }}:</span>
                        <span class="text-body-1">{{ code }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-else class="text-center text-caption grey--text">
                    No door codes available yet
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item value="logs">
                <v-col cols="12" class="overflow-auto" style="height: 600px">
                  <div class="mx-4">
                    <div
                      class="text-body-2 font-weight-semibold black--text mb-4"
                    >
                      Logs ({{ paperTrail.length }})
                    </div>
                    <div v-for="row in paperTrail" :key="row.id">
                      <div class="font-weight-bold text-caption mb-1">
                        {{ parseDate(row.created_at, 'YYYY-MM-DD HH:mm') }}
                      </div>
                      <div>
                        <div
                          v-for="(change, i) in row.object_changes"
                          :key="i"
                          class="text-caption ms-2"
                        >
                          <div v-if="i != 'updated_at'">
                            <span class="font-weight-medium">{{ i }}:</span>
                            {{ String(change[0]) }}
                            <v-icon x-small>mdi-arrow-right</v-icon>
                            {{ String(change[1]) }}
                          </div>
                        </div>
                      </div>
                      <v-divider class="my-4" />
                    </div>
                  </div>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  summaryValues,
  paymentItems,
  invoiceItems,
  invoiceHeaders,
  paymentItemsHeaders,
  preCheckInValues,
} from 'components/reservation/config.js'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import PurchaseTable from 'components/store/purchase-table'
import QuickCharge from 'components/store/quick-charge'
import { mapActions, mapGetters } from 'vuex'
import ReservationEditModal from './reservation-edit-modal'
import AddInvoiceModal from 'components/reservation/add-invoice-modal'
import CardInfoModal from 'components/reservation/card-info-modal'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CollectPayment from 'components/reservation/collect-payment'
import ReservationEmailModal from 'components/reservation/reservation-email-modal.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import GuestEdit from 'components/reservation/guest-edit'
import ReservationIntentsTable from 'components/reservation/reservation-intents-table'
import ReservationInvoicesTable from 'components/reservation/reservation-invoices-table'
import ReservationFinances from 'components/reservation/reservation-finances'
import { last } from 'lodash'
import NavigationMixin from 'components/mixins/navigation-mixin'
import Tag from 'components/common/tag.vue'
import InfoTooltip from 'components/common/info-tooltip.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import { STATUS_COLORS } from '@/consts'
import RawRuDataDialog from './raw-ru-data-dialog'
import Expenses from 'components/expenses/expenses'
import PaymentAttachmentsModal from './payment-attachments-modal'

export default {
  components: {
    Tag,
    ReservationIntentsTable,
    GuestEdit,
    CollectPayment,
    ReservationEditModal,
    ReservationCalendar,
    PurchaseTable,
    QuickCharge,
    AddInvoiceModal,
    ReservationEmailModal,
    ReservationInvoicesTable,
    CardInfoModal,
    InfoTooltip,
    ConfirmationModal,
    ReservationFinances,
    RawRuDataDialog,
    Expenses,
    PaymentAttachmentsModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin, NavigationMixin],
  data() {
    return {
      STATUS_COLORS,
      labelClass: 'label-black text-caption font-weight-medium',
      valueClass: 'text-body-1 black--text font-weight-medium',
      reservationTab: 'finance',
      headerValues: null,
      summaryValues: null,
      reservation: null,
      paymentItems: [],
      paymentItemsHeaders,
      expanded: [],
      invoiceItems: [],
      preCheckInValues: null,
      invoiceHeaders,
      preCheckInEdit: null,
      guest: { emails: [], phones: [] },
      listing: {},
      reservationLoading: false,
      menuItem: null,
      menu: false,
      billingAddress: null,
      billingAddressReadOnly: true,
      billingAddressDirty: false,
      paperTrail: [],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    guestEmail() {
      return this.guest.primary_email || last(this.guest.emails)
    },
    hasDoorCodes() {
      if (!this.reservation.available_door_codes) return false
      return Object.values(this.reservation.available_door_codes).some(
        value => value && value.toString().trim() !== ''
      )
    },
    touristEmail() {
      return this.reservation?.guest?.emails?.[0]
    },
    statusDisplay() {
      return this.getDisplayStatus(this.reservation)
    },
    guestPhone() {
      return this.guest.primary_phone || last(this.guest.phones)
    },
    reservationNotes() {
      return this.$domPurify.sanitize(this.reservation.notes, {
        ALLOWED_TAGS: ['p', 'div'],
      })
    },
    appIntegrationGuesty() {
      return (
        !!this.reservation.app_integration_name &&
        this.reservation.app_integration_name === 'guesty'
      )
    },
    checkInTime() {
      if (this.reservation.planned_arrival) {
        return this.reservation.planned_arrival
      }
      return this.parseDateTZ(
        this.reservation.check_in,
        'HH:mm',
        this.listing.timezone
      )
    },
    checkOutTime() {
      if (this.reservation.planned_departure) {
        return this.reservation.planned_departure
      }
      return this.parseDateTZ(
        this.reservation.check_out,
        'HH:mm',
        this.listing.timezone
      )
    },
    checkInDate() {
      return this.parseDateTZ(
        this.reservation.check_in,
        'YYYY-MM-DD',
        this.listing.timezone
      )
    },
    checkOutDate() {
      return this.parseDateTZ(
        this.reservation.check_out,
        'YYYY-MM-DD',
        this.listing.timezone
      )
    },
    notPaidText() {
      const { deposit_not_paid, not_paid } =
        this.reservation.portal_access_status
      const notPaid = []
      if (deposit_not_paid) notPaid.push('Deposit')
      if (not_paid) notPaid.push('Reservation')

      return notPaid.length ? `${notPaid.join(' & ')} not paid` : ''
    },
    currency() {
      return this.reservation.currency
    },
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getReservation()
      },
    },
  },
  mounted() {
    this.getReservation()
  },
  methods: {
    ...mapActions(['getTouristInfo', 'getStorePurchases']),
    updateNotes(e) {
      if (this.reservation.dvr_notes === e.target.value) return
      this.$store.dispatch('reservation/updateReservation', {
        id: this.reservation.id,
        dvr_notes: e.target.value,
      })
    },
    toggleAutomation(e) {
      this.$store.dispatch('reservation/updateReservation', {
        id: this.reservation.id,
        stop_automation: !this.reservation.stop_automation,
      })
      this.reservation.stop_automation = !this.reservation.stop_automation
    },
    async getReservation(options = {}) {
      this.reservationLoading = true
      this.reservation = await this.$store.dispatch(
        'reservation/getReservation',
        { id: this.$route.params.id, include_cal: true, ...options }
      )
      if (
        this.reservation.confirmation_code &&
        this.reservation.status === 'confirmed'
      ) {
        this.preCheckInEdit = await this.$store.dispatch(
          'getPreCheckIn',
          this.reservation.confirmation_code
        )
        const { address } = this.preCheckInEdit.client_info
        this.billingAddressReadOnly =
          !this.isString(address) && !this.isEmpty(address)

        if (this.isEmpty(address)) this.billingAddress = ''
        else {
          if (this.isString(address)) this.billingAddress = address
          else this.billingAddress = Object.values(address).join(' ')
        }
      }

      this.guest = { ...this.reservation.guest }
      this.listing = this.reservation.listing
      this.summaryValues = summaryValues(this.reservation)
      this.paymentItems = paymentItems(this.reservation)
      this.invoiceItems = invoiceItems(this.reservation)
      this.preCheckInValues = preCheckInValues(this.reservation)
      this.reservationLoading = false
      if (this.isAdmin) {
        this.fetchPaperTrail()
      }
    },
    async getPurchases() {
      this.reservationLoading = true
      await this.getStorePurchases({
        guest_id: this.reservation.guest_id,
        page: 1,
      })
      this.reservationLoading = false
    },
    async addInvoice(invoice) {
      this.reservationLoading = true
      await this.$store.dispatch('reservation/addInvoice', {
        reservation_id: this.reservation.reservation_guesty_id,
        ...invoice,
      })
      this.getReservation()
    },
    async removeInvoice(invoice) {
      this.reservationLoading = true
      await this.$store.dispatch('reservation/removeInvoice', {
        reservation_id: this.reservation.reservation_guesty_id,
        invoice,
      })
      this.getReservation()
    },
    viewInvoice() {
      window.open(
        `/api/reservations/${this.reservation.reservation_guesty_id}/invoice`,
        '_blank'
      )
    },
    closeMenu() {
      this.menu = false
    },
    async updateBillingAddress() {
      const { id } = this.preCheckInEdit
      await this.$store.dispatch('updatePreCheckIn', {
        id,
        address: this.billingAddress,
      })
      this.billingAddressDirty = false
    },
    async fetchPaperTrail() {
      const { data } = await this.$store.dispatch('getPaperTrail', {
        item_type: 'Reservation',
        item_id: this.reservation.id,
        per_page: -1,
        parse_changes: true,
      })
      this.paperTrail = data.items
    },
    async unlockPortal() {
      const { id } = this.preCheckInEdit
      await this.$store.dispatch('updatePreCheckIn', {
        id,
        unlock_portal_access: true,
      })
      this.preCheckInEdit = await this.$store.dispatch(
        'getPreCheckIn',
        this.reservation.confirmation_code
      )
    },
    async pushToXero() {
      await this.$store.dispatch('pushToXero', {
        id: this.reservation.id,
        item: 'reservation',
      })
    },
  },
}
</script>
<style scoped>
.reservation-page {
  background-color: #f6f6f7;
}
.balance-card {
  background-color: #f6f6f7;
  border-radius: 10px;
}
.reservation-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000000d;
  border-radius: 10px;
  opacity: 1;
  letter-spacing: 0;
  overflow: hidden;
}

.label-black {
  color: #707070;
}
.title-black {
  color: #3e3f59;
}
.tab-blue {
  color: #42526e !important;
}
.menu-position {
  position: absolute;
  right: 0;
  top: 0;
}
.btn-border {
  border: solid 1px #dfe1e6;
}
.button-gap {
  grid-gap: 15px;
}
:deep() .v-data-table-header > tr > th {
  font-weight: 600;
  font-size: 14px !important;
  color: black !important;
}
</style>
