<template>
  <div>
    <v-divider />
    <div v-if="items.length" :class="{ 'sticky-header': paginationLength }">
      <div v-for="item in items" :key="item.id" @click="rowClick(item)">
        <v-card class="my-1 mx-2 pa-3" elevation="0" rounded>
          <div class="d-flex justify-space-between align-center">
            <div>
              <div
                :class="`${resStatusColor(item.reservation_status)} conv-tag`"
              >
                {{ item.reservation_status }}
              </div>
              <v-icon v-if="hasSeller(item)" class="mx-2">$sold</v-icon>
            </div>
            <span class="d-flex text-caption"
              ><span>{{
                parseDate(item.extra_data.reservation_check_in)
              }}</span>
              <v-icon small class="mx-1">mdi-arrow-right-thick </v-icon>
              <span>
                {{ parseDate(item.extra_data.reservation_check_out) }}</span
              >
            </span>
          </div>
          <div class="d-flex mt-5 mb-4">
            <div class="font-weight-semibold text-body-1">
              {{ item.guest_full_name }}
            </div>
          </div>
          <div class="d-flex text--secondary">
            <div class="text-center text-caption font-weight-semibold">
              <div>{{ item.extra_data.listing_nickname }}</div>
              <div class="text-caption">Property</div>
            </div>
            <v-divider class="mx-3" vertical />
            <div class="text-center text-caption font-weight-semibold">
              <div>{{ toMoney(item.reservation_price) || '-' }}</div>
              <div class="text-caption">Price</div>
            </div>
            <v-divider class="mx-3" vertical />
            <div class="text-center text-caption font-weight-semibold">
              <div>{{ item.extra_data.reservation_source }}</div>
              <div class="text-caption">Source</div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else class="pt-6 text-center white text--secondary">
      <div class="">No messages</div>
    </div>
    <div class="ma-2">
      <v-pagination
        v-if="paginationLength"
        :value="pagination.page"
        :length="paginationLength"
        @input="paginate"
        @next="paginate(pagination.page + 1)"
        @previous="paginate(pagination.page - 1)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import colorsMixin from 'components/mixins/colors-mixin'
import formattersMixin from 'components/mixins/formatters-mixin'

export default {
  mixins: [colorsMixin, formattersMixin],
  props: ['items', 'pagination'],
  computed: {
    paginationLength() {
      return Math.ceil(
        this.pagination.totalItems / this.pagination.itemsPerPage
      )
    },
  },
  methods: {
    hasSeller(item) {
      return item.reservation_sellers && item.reservation_sellers.length > 0
    },
    rowClick({ guesty_id }) {
      this.$router.push({ query: { conversation: guesty_id } })
    },
    paginate(val) {
      this.$emit('paginate', val)
      console.log(val)
    },
  },
}
</script>

<style scoped>
.conv-tag {
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 10px;
  display: inline-flex;
}
.sticky-header {
  height: calc(100vh - 228px);
  overflow: auto;
}
</style>
