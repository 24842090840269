var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('accounting.manual_transfer.title'))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.accounts,"item-text":"name","item-value":"id","label":_vm.$t('accounting.manual_transfer.from_account'),"rules":[v => !!v || _vm.$t('common.required')],"outlined":"","required":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"grey--text text--darken-1 ml-2"},[_vm._v(" ("+_vm._s(item.code)+") ")])])]}}]),model:{value:(_vm.form.fromAccountId),callback:function ($$v) {_vm.$set(_vm.form, "fromAccountId", $$v)},expression:"form.fromAccountId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.accounts,"item-text":"name","item-value":"id","label":_vm.$t('accounting.manual_transfer.to_account'),"rules":[
                v => !!v || _vm.$t('common.required'),
                v =>
                  v !== _vm.form.fromAccountId ||
                  _vm.$t('accounting.manual_transfer.different_account'),
              ],"outlined":"","required":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"grey--text text--darken-1 ml-2"},[_vm._v(" ("+_vm._s(item.code)+") ")])])]}}]),model:{value:(_vm.form.toAccountId),callback:function ($$v) {_vm.$set(_vm.form, "toAccountId", $$v)},expression:"form.toAccountId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('accounting.manual_transfer.amount'),"type":"number","step":"0.01","rules":[
                v => !!v || _vm.$t('common.required'),
                v =>
                  v > 0 || _vm.$t('accounting.manual_transfer.positive_amount'),
              ],"outlined":"","required":""},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", _vm._n($$v))},expression:"form.amount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('accounting.manual_transfer.posted_at'),"readonly":"","outlined":"","rules":[v => !!v || _vm.$t('common.required')],"required":""},model:{value:(_vm.form.postedAt),callback:function ($$v) {_vm.$set(_vm.form, "postedAt", $$v)},expression:"form.postedAt"}},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.form.postedAt),callback:function ($$v) {_vm.$set(_vm.form, "postedAt", $$v)},expression:"form.postedAt"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('accounting.manual_transfer.description'),"rules":[v => !!v || _vm.$t('common.required')],"outlined":"","required":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('common.submit'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }