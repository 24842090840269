<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{
        $t(`Publish ${selectedVersion.version_number}`)
      }}</span>
    </v-card-title>

    <v-card-text>
      <v-form>
        <v-select
          v-model="selectedTenants"
          :disabled="!tenants.length"
          class="mb-4"
          :label="$t('Select Tenants')"
          item-text="name"
          item-value="id"
          hide-details
          :items="tenants"
          outlined
          dense
          multiple
          chips
          deletable-chips
          @change="handleTenantsChange"
        >
          <template #prepend-item>
            <v-list-item ripple @click="toggleSelectAll">
              <v-list-item-action>
                <v-checkbox
                  :input-value="isAllSelected"
                  :indeterminate="isIndeterminate"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Select All') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>

        <v-select
          v-if="showListingSelect"
          v-model="selectedListing"
          :disabled="!isSingleTenantSelected"
          class="mb-4"
          :label="$t('Listing (optional)')"
          item-text="nickname"
          return-object
          hide-details
          :items="listingsNoScope"
          outlined
          dense
        >
          <template #prepend>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon small color="info" v-bind="attrs" v-on="on">
                  mdi-information
                </v-icon>
              </template>
              <span>{{
                $t(
                  'Listing selection is only available when a single tenant is selected'
                )
              }}</span>
            </v-tooltip>
          </template>
        </v-select>

        <v-card-actions class="justify-end">
          <v-btn color="primary" outlined @click="close">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="primary" :disabled="!isSubmitEnabled" @click="submit">
            {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['selectedVersion'],

  data() {
    return {
      selectedTenants: [],
      selectedListing: null,
      tenants: [],
      isAllSelected: false,
      isIndeterminate: false,
    }
  },

  computed: {
    listingsNoScope() {
      return this.$store.state.listingsNoScope
    },

    isSingleTenantSelected() {
      return this.selectedTenants.length === 1
    },

    showListingSelect() {
      return this.isSingleTenantSelected
    },

    isSubmitEnabled() {
      return this.selectedTenants.length > 0
    },
  },

  watch: {
    selectedTenants(val) {
      if (val.length !== 1) {
        this.selectedListing = null
      }
      this.isAllSelected = val.length === this.tenants.length
      this.isIndeterminate = val.length > 0 && val.length < this.tenants.length
    },
  },

  mounted() {
    this.fetchTenants()
  },

  methods: {
    ...mapActions('tenants', ['getTenants']),

    async submit() {
      this.$store.commit('hideModal')
      const params = {
        ai_version_id: this.selectedVersion.id,
        tenant_id: this.selectedTenants,
        listing_id: this.isSingleTenantSelected
          ? this.selectedListing?.id
          : null,
      }
      this.$store.dispatch('deployAiVersionControl', params)
    },

    close() {
      this.$store.commit('hideModal')
      this.selectedTenants = []
      this.selectedListing = null
    },

    async fetchTenants() {
      const { data } = await this.getTenants()
      this.tenants = data
    },

    async handleTenantsChange() {
      this.$store.commit('updatelistingsNoScope', [])
      if (this.isSingleTenantSelected) {
        await this.fetchListingsPerTenant()
      }
    },

    async fetchListingsPerTenant() {
      if (this.isSingleTenantSelected) {
        this.$store.dispatch('getlistingsNoScope', {
          tenant_id: this.selectedTenants[0],
        })
      }
    },

    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedTenants = []
      } else {
        this.selectedTenants = this.tenants.map(tenant => tenant.id)
      }
    },
  },
}
</script>

<style scoped></style>
