<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>

    <v-card>
      <v-card-title class="text-h6">
        Payment Attachments
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div v-if="attachments && attachments.length">
          <v-row>
            <v-col
              v-for="(attachment, index) in attachments"
              :key="index"
              cols="12"
              sm="6"
              md="4"
            >
              <v-card outlined class="mx-auto">
                <div class="preview-container">
                  <v-img
                    v-if="isImage(attachment.url)"
                    :src="attachment.url"
                    height="150"
                    contain
                    class="grey lighten-2"
                  />
                  <v-sheet
                    v-else
                    height="150"
                    class="d-flex align-center justify-center grey lighten-2"
                  >
                    <v-icon size="64" color="grey darken-2">
                      mdi-file-document-outline
                    </v-icon>
                  </v-sheet>
                </div>

                <v-card-text class="py-2">
                  <div class="text-truncate">{{ attachment.filename }}</div>
                </v-card-text>

                <v-card-actions class="py-2 justify-center">
                  <v-btn
                    text
                    small
                    color="primary"
                    :href="attachment.url"
                    target="_blank"
                    class="text-none px-2"
                  >
                    <v-icon small left>mdi-open-in-new</v-icon>
                    Open
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else class="text-center pa-4 grey--text">
          No attachments available
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PaymentAttachmentsModal',
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    isImage(url) {
      if (!url) return false
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp']
      const extension = url.split('.').pop()?.toLowerCase() || ''
      return imageExtensions.includes(extension)
    },
  },
}
</script>

<style scoped>
.preview-container {
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
}
</style>
