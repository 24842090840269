<template>
  <v-expansion-panels
    v-if="relatedConversationsSelect.length"
    class="mt-3"
    flat
    accordion
    border
  >
    <v-expansion-panel key="final" class="">
      <v-expansion-panel-header class="pa-0">
        <span class="font-weight-medium">
          <v-icon class="me-1" small>mdi-message</v-icon>
          Related Conversations
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list max-height="400px" class="overflow-auto">
          <v-list-item
            v-for="item in relatedConversationsSelect"
            :key="item.id"
            link
            class="w-100 py-4 px-2 d-block"
            @click="openConversation(item.conversation_guesty_id)"
          >
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex font-weight-medium text-body-2 me-8">
                <div>
                  {{ parseDate(item.check_in) }}
                  <v-icon x-small>mdi-arrow-right</v-icon>
                  {{ parseDate(item.check_out) }}
                </div>
              </div>
              <div
                class="text-capitalize font-weight-semibold py-1 px-3 rounded-pill status-pill"
                :style="`background-color: ${
                  STATUS_COLORS[item.status]?.background
                }; color: ${STATUS_COLORS[item.status]?.text};`"
              >
                {{ item.status }}
              </div>
            </div>
            <div class="font-weight-medium text-caption text--secondary">
              {{ item.listing?.nickname }}
            </div>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import commonFunctions from 'components/mixins/common_functions'
import { STATUS_COLORS } from '@/consts'

export default {
  props: ['conversation'],
  mixins: [commonFunctions],
  data() {
    return { STATUS_COLORS }
  },
  computed: {
    relatedConversationsSelect() {
      const { related_conversations } = this.conversation
      const reservations = []
      related_conversations.forEach(conversation => {
        if (conversation.id === this.conversation.id) return
        conversation.reservations.forEach(reservation => {
          if (reservation.conversation_id !== conversation.id) return
          reservations.push({
            ...reservation,
            conversation_id: conversation.id,
            conversation_guesty_id: conversation.guesty_id,
          })
        })
      })
      return reservations
    },
  },
  methods: {
    openConversation(guestyId) {
      const url = `/dashboard/guest-experience/all/${guestyId}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.status-pill {
  font-size: 0.65rem;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
