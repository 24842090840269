<template>
  <v-menu v-model="menu" bottom max-height="500" nudge-bottom="60">
    <template #activator="{}">
      <v-text-field
        v-model="searchTerm"
        outlined
        dense
        prepend-inner-icon="$search"
        hide-details
        label="Search guest..."
        @input="onSearchTerm"
      >
        <template #append>
          <v-progress-circular
            v-show="loadingItems"
            size="25"
            :width="2"
            color="info"
            indeterminate
          />
        </template>
      </v-text-field>
    </template>
    <v-list class="pa-0" nav max-width="340">
      <v-list-item-group v-if="list.length" color="info">
        <div v-for="(item, index) in list" :key="item.confirmation_code">
          <v-list-item class="mb-0 px-4" @click="guestClick(item)">
            <v-list-item-content>
              <v-list-item-title class="mb-2">
                {{ item.guest_name }}
                <em v-show="item.confirmation_code" class="text-caption">
                  ({{ item.confirmation_code }})
                </em>
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption mb-2">
                {{ parseDate(item.reservation_check_in, 'MMM DD, YYYY') }}
                &ndash;
                {{ parseDate(item.reservation_check_out, 'MMM DD, YYYY') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-chip
                  :color="resStatusColor(getDisplayStatus(item))"
                  label
                  small
                >
                  {{ getDisplayStatus(item) }}
                </v-chip>
                <span class="text-caption ml-2">{{ item.listing }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < list.length - 1" />
        </div>
      </v-list-item-group>
      <v-list-item v-if="!searchTerm" class="px-4">
        <em class="text-caption secondary--text">
          Enter name or confirmation code
        </em>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import debounce from 'lodash/debounce'
import axios from 'axios'
import Common_functions from 'components/mixins/common_functions'

export default {
  name: 'ReservationGuestSearch',
  mixins: [Common_functions],
  data() {
    return {
      menu: false,
      searchTerm: '',
      loadingItems: false,
      list: [],
    }
  },
  mounted() {
    this.debouncer = debounce(async () => {
      const { data } = await axios.get('/api/conversations/search', {
        params: { term: this.searchTerm },
      })
      this.menu = true
      this.loadingItems = false
      this.list = data
    }, 500)
  },
  methods: {
    guestClick(guest) {
      this.$emit('change', guest)
      this.menu = false
      this.$router.push({
        query: {
          conversation: guest.conversation_id,
          reservation: guest.reservation_id,
        },
      })
    },
    onSearchTerm() {
      this.menu = false
      this.loadingItems = true
      this.debouncer.call(this)
    },
  },
}
</script>

<style scoped></style>
