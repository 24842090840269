<template>
  <div class="inventory-list">
    <h3 class="text-center mb-4">Current shopping list</h3>
    <v-checkbox
      v-if="!returnTypeMode"
      v-model="showZero"
      label="Show 0 amount"
      dense
    />
    <v-autocomplete
      v-if="!returnTypeMode"
      v-model="item"
      :search-input.sync="searchTerm"
      :items="inventoryList"
      :loading="loadingItems"
      :loader-height="3"
      item-text="title"
      prepend-inner-icon="$search"
      outlined
      return-object
      @input="searchTerm = ''"
      @change="addItem"
    >
      <template #selection>
        <div />
      </template>
      <template #prepend-item>
        <v-list-item>
          <v-row class="text-body-2">
            <v-col cols="10" sm="5">Title</v-col>
            <v-col sm="2">Amount</v-col>
            <v-col sm="3">Category</v-col>
            <v-col sm="2">Price</v-col>
          </v-row>
        </v-list-item>
      </template>
      <template #item="{ item }" :disabled="item.disabled">
        <v-row align="center" class="text-body-2">
          <v-col cols="10" sm="5">
            <div class="d-flex align-center">
              <v-img
                class="rounded mr-2"
                :src="item.images[0]"
                max-width="50"
                height="50"
              />
              <span>{{ item.title }}</span>
            </div>
          </v-col>
          <v-col class="d-none d-sm-block" sm="2">
            <span>{{ item.currentAmount }} left</span>
          </v-col>
          <v-col class="d-none d-sm-block" sm="3">
            {{ item.catagory }}
          </v-col>
          <v-col class="d-none d-sm-block" sm="2">
            {{ toMoney(item.price) }}
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
    <div v-if="shoppingList.length">
      <v-row
        v-for="(item, index) in shoppingList"
        :key="index"
        no-gutters
        class="align-start secondary lighten-1 p-relative mb-2"
      >
        <v-col cols="auto" class="pa-2">
          <v-img
            :src="item.images[0]"
            class="rounded"
            max-width="60"
            height="60"
          />
        </v-col>
        <v-col class="d-flex flex flex-column px-2">
          <div>{{ item.title }}</div>
          <div class="text-caption secondary--text">
            {{ getItemSubtitle(item) }}
          </div>
          <div class="d-flex no-gutters align-center">
            <v-btn
              icon
              x-small
              :disabled="amountLowerCheck(item)"
              @click="updateAmount(item.requested_amount - 1, index)"
            >
              <v-icon small>mdi-minus-circle-outline</v-icon>
            </v-btn>
            <v-col cols="9" sm="4">
              <span v-if="returnTypeMode">amount to return</span>
              <v-text-field
                v-model.number="item.requested_amount"
                dense
                flat
                solo
                type="number"
                hide-details="auto"
                :rules="
                  returnTypeMode
                    ? [required, nonNegative]
                    : [required, isPositive]
                "
                :min="
                  !amountLowerCheck(item) ? undefined : item.requested_amount
                "
                :max="
                  !amountUpperCheck(item) ? undefined : item.requested_amount
                "
              />
            </v-col>
            <v-btn
              icon
              x-small
              :disabled="amountUpperCheck(item)"
              @click="updateAmount(item.requested_amount + 1, index)"
            >
              <v-icon small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-btn
          class="p-absolute close-request-btn"
          small
          icon
          @click="removeItem(index)"
        >
          <v-icon small color="warning">$close</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import debounce from 'lodash/debounce'
import { cloneDeep, find } from 'lodash'
import FormattersMixin from 'components/mixins/formatters-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  name: 'InventoryList',
  mixins: [CommonFunctions, FormattersMixin, FormRulesMixin],
  props: {
    actionType: {
      type: String,
      default: '',
    },
    storageNickname: {
      type: String,
      default: 'All',
    },
    shoppingList: {
      type: Array,
      default: () => [],
    },
    returnTypeMode: {
      type: Boolean,
      default: false,
    },
    onlyTake: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: null,
      searchTerm: '',
      showZero: false,
      shoppingListHeader: {
        title: 'Title',
        amount: 'Amount',
        category: 'Category',
        price: 'Price',
      },
      loadingItems: false,
      shopModel: this.shoppingList,
      initialShoppingList: cloneDeep(this.shoppingList),
    }
  },
  computed: {
    ...mapState(['inventoryItems']),
    ...mapState('storages', ['storages']),
    currentStorage() {
      return this.storages.find(s => s.nickname === this.storageNickname)
    },
    inventoryList() {
      return this.inventoryItems.map(i => ({
        ...i,
        currentAmount: i.amount_per_storage[this.currentStorage.id],
        disabled:
          this.onlyTake && i.amount_per_storage[this.currentStorage.id] === 0,
      }))
    },
    inventoryMap() {
      const amm = this.inventoryList.reduce((acc, curr) => {
        acc[curr.id] = curr.currentAmount
        return acc
      }, {})
      return amm
    },
  },
  watch: {
    searchTerm(val) {
      if ((this.item && val === this.item.title) || !val) {
        return
      }
      this.debouncer.call(this)
    },
  },
  mounted() {
    if (this.returnTypeMode) {
      this.shoppingList.forEach((item, index) => {
        this.updateAmount(0, index)
      })
    }
    this.debouncer = debounce(this.getInventoryItems, 750)
  },
  methods: {
    async getInventoryItems() {
      this.loadingItems = true
      await this.$store.dispatch('getInventoryItems', {
        search: this.searchTerm,
        storage: this.storageNickname,
        showZeroAmounts: this.showZero,
      })
      this.loadingItems = false
    },
    addItem(item) {
      this.shopModel = this.shopModel.concat({
        inventory_item_id: item.inventory_item_id || item.id,
        title: item.title,
        requested_amount: 1,
        images: item.images,
        current_amount: item.currentAmount,
        price_to_client: item.price * (1 + item.mark_up),
      })
      this.$emit('update:shopping-list', this.shopModel)
    },
    removeItem(index) {
      this.shopModel.splice(index, 1)
      this.$emit('update:shopping-list', this.shopModel)
    },
    updateAmount(amount, index) {
      this.shopModel[index].requested_amount = amount
      this.$set(this.shopModel, index, this.shopModel[index])
      this.$emit('update:shopping-list', this.shopModel)
    },
    amountLowerCheck(item) {
      switch (this.actionType) {
        case 'Return':
          return item.requested_amount <= 0
      }
      return item.requested_amount <= 1
    },
    amountUpperCheck(item) {
      if (!this.onlyTake) return false
      switch (this.actionType) {
        case 'Rent':
        case 'Take':
          return (
            item.requested_amount >= this.inventoryMap[item.inventory_item_id]
          )
        default:
          return true
      }
    },
    getItemSubtitle(item) {
      switch (this.actionType) {
        case 'Rent':
        case 'Take':
          return `Amount max: ${this.inventoryMap[item.inventory_item_id]}`
        case 'Return': {
          const takenItem = find(this.initialShoppingList, {
            inventory_item_id: item.inventory_item_id,
          })
          return `Amount taken: ${takenItem.requested_amount}`
        }
        default:
          return `Amount available: ${item.currentAmount}`
      }
    },
  },
}
</script>

<style scoped>
.close-request-btn {
  top: 0;
  right: 0;
}
</style>
