<template>
  <v-sheet
    elevation="2"
    rounded
    class="ba-1 pa-3 my-3 mx-0 pointer"
    @click="openExpenseModal(expense)"
  >
    <div class="d-flex justify-space-between text-left">
      <div class="d-flex">
        <span
          class="ba-1 text-caption px-2 font-weight-medium rounded text-capitalize"
        >
          <span class="secondary--text">{{ expense.related_division }}</span>
        </span>
      </div>
    </div>
    <div class="text-subtitle-2 font-weight-medium text-left mt-1">
      {{ nickname }}
    </div>
    <div class="text-body-2 text-left">
      {{ expense.description }}
    </div>
    <v-sheet height="2px" width="100%" rounded class="grey lighten-2 my-2" />
    <div class="d-flex align-center">
      <tag :inverted="true" size="sm" color="black">
        {{ expense.listing_task ? 'Done At:' : 'Paid At:' }}
        {{
          parseDate(
            expense.listing_task
              ? expense.listing_task.scheduled_at
              : expense.paid_at
          )
        }}
      </tag>
      <div class="d-flex align-center ml-auto">
        <div class="font-weight-bold black--text mr-4">
          {{ toMoney(expense.amount) }}
        </div>
        <v-btn
          v-if="validatable"
          depressed
          small
          color="green lighten-5"
          class="green--text font-weight-bold mr-2"
          @click.stop="$emit('validate-expense', expense.id)"
        >
          <v-icon class="mr-2" small>mdi-check-circle-outline</v-icon>
          Validate</v-btn
        >
        <inquiry-expense-modal
          v-if="validatable && isDesignedVr"
          :expense-id="expense.id"
          :disabled="false || !expense.user_id"
          @on-inquiry="$emit('on-inquiry', 'pending-expenses')"
        />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import formattersMixin from 'components/mixins/formatters-mixin'
import InquiryExpenseModal from 'components/expenses/inquiry-expense-modal'
import accountingMixin from 'components/mixins/accounting-mixin'
import { mapGetters } from 'vuex'
import Tag from 'components/common/tag'

export default {
  props: ['expense', 'nickname', 'validatable', 'deleteEnabled', 'editEnabled'],
  mixins: [formattersMixin, accountingMixin],
  components: { Tag, InquiryExpenseModal },
  methods: {
    openExpenseModal(expense) {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: {
          expense,
          fetchExpenses: () => this.$emit('validate-expense', expense.id),
          deleteEnabled: true,
          editEnabled: true,
          onDelete: () => this.$emit('on-change', expense.id),
          onUpdate: () => this.$emit('on-change', expense.id),
        },
      })
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>

<style></style>
