<template>
  <div>
    <v-container v-if="selectedDeviceId">
      <v-btn color="primary" outlined small class="mb-5" @click="moveBack">
        <v-icon small>mdi-arrow-left</v-icon>
        Devices list
      </v-btn>

      <v-card border="opacity-50 sm">
        <v-card-text>
          <seam-device-details
            :device-id="selectedDeviceId"
            :publishable-key="publishableKey"
            :user-identifier-key="seamComponentUuid"
          />
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-else fluid>
      <div v-if="publishableKey" class="mb-5">
        <seam-connect-account-button
          :publishable-key="publishableKey"
          :user-identifier-key="seamComponentUuid"
        ></seam-connect-account-button>
      </div>

      <v-row>
        <v-col cols="12" md="6">
          <multi-property-picker v-model="listingsIds" :hide-btns="true" />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="filters.entry_point"
            :items="entryPointOptions"
            :label="`Entry Point`"
            dense
            clearable
            outlined
            @change="onFilterChange"
          />
        </v-col>
      </v-row>

      <v-alert
        type="info"
        text
        dense
        class="mb-4 font-weight-medium text-body-2"
      >
        Please note that activating or deactivating a device will automatically
        update door codes for all future reservations. Make sure to verify the
        entry point settings before making changes.
      </v-alert>

      <v-data-table
        :headers="headers"
        :items="filteredDevices"
        class="elevation-1"
        item-value="device_id"
        :loading="loading"
        @click:row="onRowClick"
      >
        <template #item.image="{ item }">
          <div class="rounded-circle pa-2">
            <v-img
              :src="item.properties.image_url"
              :alt="item.properties.image_alt_text"
              max-width="60"
              height="60"
            ></v-img>
          </div>
        </template>

        <template #item.properties.locked="{ item }">
          <span v-if="typeof item.properties.locked === 'boolean'">
            <v-icon>{{
              item.properties.locked
                ? 'mdi-lock-outline'
                : 'mdi-lock-open-outline'
            }}</v-icon>
          </span>
          <span v-else> - </span>
        </template>

        <template #item.properties.online="{ item }">
          <span v-if="typeof item.properties.online === 'boolean'">
            <v-icon
              small
              :color="item.properties.online ? 'success' : 'warning'"
              >circle</v-icon
            >
          </span>
          <span v-else>No Data</span>
        </template>

        <template #item.properties.battery_level="{ item }">
          <BatteryLevel
            v-if="item.properties.battery_level"
            :percent="
              parseFloat(item.properties.battery_level).toFixed(2) * 100
            "
          />
          <span v-else>No data</span>
        </template>

        <template #item.custom_metadata.listing_id="{ item }">
          <listing-picker
            :value="item.custom_metadata.listing_id"
            @input="onFieldChange(item, $event)"
          />
        </template>

        <template #item.custom_metadata.entry_point="{ item }">
          <v-select
            v-if="item.capabilities_supported?.includes('access_code')"
            :value="item.custom_metadata.entry_point"
            :items="entryPointOptions"
            dense
            @input="onEntryPointChange(item, $event)"
            @click.stop
          />
        </template>

        <template #item.boom.active="{ item }">
          <v-switch
            v-model="item.boom.active"
            :loading="item.updating"
            dense
            @click.stop
            @change="toggleActive(item)"
          ></v-switch>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListingPicker from 'components/listing/listing-picker'
import BatteryLevel from 'components/iot/battery-level.vue'
import MultiPropertyPicker from 'components/multi-property-picker.vue'

export default {
  components: {
    ListingPicker,
    BatteryLevel,
    MultiPropertyPicker,
  },
  data() {
    return {
      dialog: false,
      selectedDeviceId: null,
      loading: false,
      listingsIds: [],
      filters: {},
      headers: [
        { text: 'Image', value: 'image', align: 'center', sortable: false },
        { text: 'Device Name', value: 'display_name' },
        { text: 'Locked', align: 'center', value: 'properties.locked' },
        { text: 'Online', align: 'center', value: 'properties.online' },
        { text: 'Battery Level', value: 'properties.battery_level' },
        { text: 'Listing', value: 'custom_metadata.listing_id' },
        { text: 'Entry point', value: 'custom_metadata.entry_point' },
        { text: 'Active', value: 'boom.active', align: 'center' },
      ],
      entryPointOptions: [
        'Main door guests #1',
        'Main door guests #2',
        'Main door private',
        'Main door cleaning',
        'Main Gate',
        'Secondary Door',
        'Laundry Door',
        'Additional door',
      ],
    }
  },
  computed: {
    ...mapState('seamIot', [
      'allIotDevices',
      'publishableKey',
      'seamComponentUuid',
    ]),
    filteredDevices() {
      if (this.listingsIds.length === 0) {
        return this.allIotDevices
      }

      const iotDevices = this.allIotDevices.filter(iot => {
        return this.listingsIds.includes(Number(iot.custom_metadata.listing_id))
      })

      return iotDevices
    },
  },
  methods: {
    ...mapActions('seamIot', [
      'getAllDevices',
      'updateDevice',
      'getPublishableKey',
      'toggleDeviceActive',
    ]),
    onFilterChange() {
      this.getAllDevices(this.filters)
    },
    onFieldChange(item, value) {
      if (!value) return
      const payload = {
        device_id: item.device_id,
        listing_id: value,
      }
      const index = this.filteredDevices.findIndex(
        device => device.device_id === item.device_id
      )
      if (index !== -1) {
        this.$set(this.filteredDevices, index, { ...item, listing_id: value })
      }
      this.updateDevice(payload)
    },
    onEntryPointChange(item, value) {
      if (!value) return
      const payload = {
        device_id: item.device_id,
        entry_point: value,
      }
      const index = this.filteredDevices.findIndex(
        device => device.device_id === item.device_id
      )
      if (index !== -1) {
        this.$set(this.filteredDevices, index, { ...item, entry_point: value })
      }
      this.updateDevice(payload)
    },
    onRowClick(item) {
      this.selectedDeviceId = item.device_id
      this.dialog = true
    },
    moveBack() {
      this.loading = true
      setTimeout(() => {
        this.getAllDevices(this.filters)
        this.loading = false
      }, 8000)
      this.selectedDeviceId = null
    },
    async toggleActive(item) {
      this.$set(item, 'updating', true)
      try {
        await this.toggleDeviceActive(item.device_id)
      } catch (error) {
        console.error('Failed to toggle device status:', error)
        item.boom.active = !item.boom.active
      } finally {
        this.$set(item, 'updating', false)
      }
    },
  },
  mounted() {
    this.getPublishableKey()
    this.getAllDevices(this.filters)
  },
}
</script>

<style scoped></style>
