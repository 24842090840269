<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row align="center" class="flex-wrap-reverse">
          <v-col cols="12" sm="3">
            <v-select
              v-model="estimate.division"
              outlined
              hide-details
              :items="['Construction', 'Maintenance', 'Design']"
              :label="haveGroups ? 'Division *' : 'Choose Division *'"
              :clearable="!haveGroups"
              :readonly="isReadonly || haveGroups"
              @input="fetchGroups"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <listing-select
              :pre-selected.sync="estimate.listing_id"
              label="Listing"
              :clearable="true"
              :dense="false"
              :hide-details="true"
              :readonly="isReadonly"
            />
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="auto" class="d-flex justify-end">
            <v-btn
              v-if="!isReadonly && editEstimate"
              :disabled="!haveEditChanges"
              color="primary"
              class="ml-3"
              @click="saveEditEstimate"
            >
              Save Changes
            </v-btn>
            <v-btn
              color="secondary"
              :href="estimateSynPdfUrl"
              target="_blank"
              class="ml-3"
            >
              Syndication PDF
            </v-btn>
            <v-btn
              color="secondary"
              :href="estimateOwnerPdfUrl"
              target="_blank"
              class="ml-3"
            >
              Owner PDF
            </v-btn>
            <ConfirmationModal
              v-if="!isReadonly && editEstimate && !estimate.approved"
              :text="`Are you sure you want to approve this estimate ?\nIt cannot be undone.`"
              @action="approveEditEstimate"
            >
              <template #activator="{ on }">
                <v-btn
                  :disabled="haveEditChanges"
                  color="secondary"
                  class="ml-3"
                  v-on="on"
                >
                  Approve
                </v-btn>
              </template>
            </ConfirmationModal>
            <ConfirmationModal
              v-if="!isReadonly && editEstimate"
              :text="`Are you sure you want to delete this estimate ?`"
              @action="deleteEditEstimate"
            >
              <template #activator="{ on }">
                <v-btn color="secondary" outlined class="ml-3" v-on="on">
                  Delete
                </v-btn>
              </template>
            </ConfirmationModal>
            <v-btn
              v-if="!isReadonly && !editEstimate && haveGroups"
              color="primary"
              class="ml-3"
              @click="createNewEstimate"
            >
              Create
            </v-btn>
            <ConfirmationModal
              v-if="!isReadonly && !editEstimate && haveGroups"
              :text="`Are you sure you want to reset this estimate ?`"
              @action="resetBuilder"
            >
              <template #activator="{ on }">
                <v-btn color="secondary" outlined class="ml-3" v-on="on">
                  Reset
                </v-btn>
              </template>
            </ConfirmationModal>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="estimate.title"
          label="Title *"
          outlined
          hide-details
          :rules="[required]"
          :readonly="isReadonly"
        />
      </v-col>
      <v-col cols="12" sm="4" class="text-h6 d-flex">
        <v-text-field
          v-model="estimate.duration"
          label="Duration in weeks"
          outlined
          hide-details
          :readonly="isReadonly"
          type="number"
        />
      </v-col>
      <v-col cols="12" sm="8" class="text-h6 d-flex">
        <v-text-field
          v-model="estimate.sig_titles"
          label="Signatures Titles (Comma seperated)"
          placeholder="ex. Head of GC, Project Manager, Owner"
          outlined
          hide-details
          :readonly="isReadonly"
        />
      </v-col>
      <v-col cols="12" class="text-h6 d-flex">
        <div>Total Materials: {{ toMoney(totalMaterialsCost) }}</div>
        <div class="ml-8">Total Labor: {{ toMoney(totalLaborCost) }}</div>
        <div class="ml-12">Total Price: {{ toMoney(totalPrice) }}</div>
      </v-col>
      <v-col cols="12" class="text-no-wrap overflow-y-auto">
        <v-btn
          v-for="(group, index) in estimate.groups"
          :key="index"
          color="secondary"
          :outlined="index !== selectedGroupIndex"
          height="100"
          class="mr-5"
          @click="selectedGroupIndex = index"
        >
          <div>
            <div class="text-h6">{{ group.title }}</div>
            <div class="text-body-2">{{ group.name }}</div>
            <div class="text-body-2">
              {{ toMoney(calcGroupTotalCost(group)) }}
            </div>
            <div class="text-body-2">
              {{ calcGroupTasksCount(group) }} Tasks
            </div>
          </div>
        </v-btn>
        <form-dialog
          v-if="!isReadonly"
          title="Add New Group"
          @submit="addNewGroup"
          @open="newGroup = {}"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="!estimate.division"
              color="secondary"
              text
              height="100"
              v-on="on"
            >
              <div>
                <v-icon>mdi-plus-circle-outline</v-icon>
                <div>Add Group</div>
              </div>
            </v-btn>
          </template>
          <v-text-field
            v-model="newGroup.title"
            label="Title *"
            outlined
            :rules="[required]"
          />
          <v-autocomplete
            v-model="newGroup.id"
            outlined
            item-value="id"
            item-text="name"
            :items="groups"
            clearable
            :rules="[required]"
            label="Choose Group Template"
          />
        </form-dialog>
      </v-col>

      <v-card class="mt-3 full" elevation="5">
        <estimates-builder-group-view
          v-if="haveGroups"
          v-model="estimate.groups[selectedGroupIndex]"
          :division="estimate.division"
          :readonly="isReadonly"
          @delete="removeGroup(selectedGroupIndex)"
        />
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import LocalStorage from '@/utils/localStorage'
import ConfirmationModal from 'components/modals/confirmation-modal'
import FormDialog from 'components/common/form-dialog'
import { cloneDeep, isEqual, orderBy } from 'lodash'
import EstimatesBuilderGroupView from 'components/estimates/estimates-builder-group-view'
import ListingSelect from 'components/listing-select'
import EstimatesMixin from 'components/mixins/estimates-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'EstimatesBuilder',
  components: {
    ListingSelect,
    EstimatesBuilderGroupView,
    FormDialog,
    ConfirmationModal,
  },
  mixins: [CommonFunctions, formRules, EstimatesMixin, FormattersMixin],
  props: ['editEstimate'],
  data() {
    return {
      editEstimateCache: cloneDeep(this.editEstimate),
      estimate:
        cloneDeep(this.editEstimate) ||
        LocalStorage.get('EstimatesBuilder') ||
        {},
      selectedGroupIndex: 0,
      newGroup: {},
    }
  },
  mounted() {
    this.fetchGroups()
  },
  computed: {
    ...mapState('estimates', ['groups']),
    ...mapGetters('estimates', ['groupsMap']),
    isReadonly() {
      return Boolean(this.estimate && this.estimate.approved)
    },
    haveGroups() {
      return Boolean(
        this.estimate && this.estimate.groups && this.estimate.groups.length
      )
    },
    haveEditChanges() {
      return !isEqual(this.estimate && this.estimate, this.editEstimateCache)
    },
  },
  methods: {
    ...mapActions('estimates', [
      'getGroups',
      'createEstimate',
      'updateEstimate',
      'approveEstimate',
      'deleteEstimate',
    ]),
    resetBuilder() {
      this.estimate = {}
    },
    fetchGroups() {
      if (this.estimate.division) {
        this.getGroups({
          division: this.estimate.division,
          withQuestions: true,
        })
      }
    },
    addNewGroup() {
      const estimate = cloneDeep(this.estimate)
      const group = this.groupsMap[this.newGroup.id]
      this.newGroup.name = group.name
      this.newGroup.checkList = orderBy(group.questions, 'order').map(
        question => ({
          text: question.text,
          catalog_id: question.catalog_id,
          measurement_unit: question.catalog.measurement_unit,
          labor_time_per_unit: Number(
            question.catalog.labor_time_per_unit || 0
          ),
          labor_cost_per_unit: Number(
            question.catalog.labor_cost_per_unit || 0
          ),
          materials_cost_per_unit: Number(
            question.catalog.materials_cost_per_unit || 0
          ),
        })
      )
      estimate.groups = [...(estimate.groups || []), this.newGroup]
      this.estimate = cloneDeep(estimate)
      this.selectedGroupIndex = estimate.groups.length - 1
    },
    async createNewEstimate() {
      const estimate = await this.createEstimate(this.estimate)
      this.resetBuilder()
      this.$nextTick(() =>
        this.$router.push({
          name: 'Estimates',
          params: { paramsTab: 'list', estimateId: estimate.id },
        })
      )
    },
    removeGroup(groupIndex) {
      if (groupIndex === this.estimate.groups.length - 1) {
        this.selectedGroupIndex = groupIndex - 1
      }
      this.estimate.groups.splice(groupIndex, 1)
    },
    afterUpdate() {
      this.editEstimateCache = cloneDeep(this.estimate)
      this.$emit('update:edit-estimate', cloneDeep(this.estimate))
      this.$emit('after-update')
    },
    async saveEditEstimate() {
      await this.updateEstimate(this.estimate)
      this.afterUpdate()
    },
    async approveEditEstimate() {
      await this.approveEstimate(this.estimate.id)
      this.estimate.approved = true
      this.afterUpdate()
    },
    async deleteEditEstimate() {
      await this.deleteEstimate(this.estimate.id)
      this.$emit('after-delete')
    },
  },
  watch: {
    estimate: {
      deep: true,
      handler: function (value) {
        if (!this.editEstimate) {
          LocalStorage.set('EstimatesBuilder', value)
        }
      },
    },
  },
}
</script>

<style scoped></style>
