<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header> PriceLabs Sync Tool </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat class="sync-steps pa-4">
          <v-btn
            v-if="!syncStarted"
            color="primary"
            class="mb-4"
            @click="startSync"
          >
            Start Sync
          </v-btn>

          <v-stepper
            v-show="syncStarted"
            v-model="currentStep"
            vertical
            class="pricelabs-stepper"
          >
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="`pricelabs-step-${step.name}`"
                :step="index + 1"
                complete-icon="check"
                :complete="step.status === 'success'"
                :error="step.status === 'error'"
              >
                {{ step.label }}
                <small
                  v-if="step.message"
                  :class="getMessageClass(step.status)"
                  class="d-block mt-1"
                >
                  {{ formatStepMessage(step.message) }}
                </small>
              </v-stepper-step>

              <v-stepper-content
                :key="`content-${step.name}`"
                :step="index + 1"
              >
                <v-card flat color="transparent">
                  <v-card-text class="py-2">
                    <v-progress-linear
                      v-if="step.status === 'loading'"
                      indeterminate
                      color="primary"
                    />
                    <template v-else>
                      <v-icon
                        v-if="step.status === 'success'"
                        color="success"
                        class="mr-2"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon
                        v-else-if="step.status === 'error'"
                        color="error"
                        class="mr-2"
                      >
                        mdi-alert-circle
                      </v-icon>
                      <span
                        v-else-if="step.status === 'pending'"
                        class="grey--text"
                      >
                        Waiting to start...
                      </span>
                      <span :class="getMessageClass(step.status)">
                        {{ formatStepMessage(step.message) }}
                      </span>
                    </template>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </template>
          </v-stepper>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import axios from '@/axios/config'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [PermissionsMixin],
  props: {
    listingId: {
      type: [String, Number],
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentStep: 1,
      syncStarted: false,
      steps: [
        {
          name: 'put_listings',
          label: 'Sync Listing Details',
          status: 'pending',
          message: null,
        },
        {
          name: 'put_calendar',
          label: 'Sync Calendar',
          status: 'pending',
          message: null,
        },
        {
          name: 'get_prices',
          label: 'Sync Prices',
          status: 'pending',
          message: null,
        },
      ],
    }
  },
  methods: {
    getMessageClass(status) {
      const statusClasses = {
        success: 'success--text',
        error: 'error--text',
        pending: 'grey--text',
        loading: 'primary--text',
      }
      return statusClasses[status] || 'grey--text'
    },

    formatStepMessage(message) {
      if (!message) return ''
      if (typeof message === 'object') {
        if (message.errors) {
          return message.errors
        }
        return JSON.stringify(message)
      }
      return message
    },

    async startSync() {
      this.syncStarted = true
      const firstStep = this.steps[0]
      await this.executeStep(firstStep)
    },

    async executeStep(step) {
      try {
        step.status = 'loading'
        const response = await axios.get(
          `/api/listings/${this.listingId}/force-sync-prices?step=${step.name}`
        )
        const { status, message, next_step, listing_error } = response.data

        step.status = status
        step.message = listing_error || message
        if (status === 'success' && next_step) {
          const nextStep = this.steps.find(s => s.name === next_step)
          if (nextStep) {
            this.currentStep += 1
            await this.executeStep(nextStep)
          }
        }
      } catch (error) {
        step.status = 'error'
        step.message =
          error.response?.data?.message ||
          error.response?.data?.errors ||
          'An error occurred'
      }
    },
  },
}
</script>

<style scoped>
.sync-steps {
  max-width: 800px;
}

.pricelabs-stepper :deep(.v-stepper__step small) {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>
