import axios from '@/axios/config'
import Websocket from '@/utils/websocket'
import filter from 'lodash/fp/filter'
export default {
  state: {
    subApplicationChannel: null,
    showVersionAlert: false,
    cities: [],
    configuration: {
      links: {},
      currency: '',
      tax_method: 'company',
      timezone: 'utc',
      general_alert: '',
    },
    listingCities: [],
    bankAccounts: [],
    xeroAccounts: [],
    pmsData: {},
    xeroDefaultExpenseId: null,
    xeroDefaultPaymentId: null,
  },
  mutations: {
    updateSubApplicationChannel(state, data) {
      state.subApplicationChannel = data
    },
    updateListingCities(state, data) {
      state.listingCities = data
    },
    updateCities(state, data) {
      state.cities = data
    },
    updateAiConfigs(state, data) {
      state.AiConfigs = data
    },
    showVersionAlert(state, value) {
      state.showVersionAlert = value
      state.configuration.general_alert = null
    },
    updateConfiguration(state, value) {
      state.configuration = value
    },
    updateBankAccounts(state, value) {
      state.bankAccounts = value
    },
    updatePmsData(state, value) {
      state.pmsData = value
    },
    updateXeroAccounts(state, value) {
      state.xeroAccounts = value
      state.xeroDefaultExpenseId = value.filter(
        acc => acc.is_default_expense
      )[0]?.id
      state.xeroDefaultPaymentId = value.filter(
        acc => acc.is_default_payment
      )[0]?.id
    },
  },
  actions: {
    async subscribeToApplicationChannel({ commit }) {
      const handler = event => {
        if (event === 'ws-new-app-version') {
          // commit('showVersionAlert', true)
        }
      }
      const sub = Websocket.subscribeToApplicationChannel(handler)
      commit('updateSubApplicationChannel', sub)
    },
    async unsubscribeFromApplicationChannel({ state, commit }) {
      Websocket.unsubscribe(state.subNotificationsChannel)
      commit('updateSubApplicationChannel', null)
    },
    async getListingsCities({ commit }) {
      const { data } = await axios.get(`/api/listings/cities`)
      commit('updateListingCities', data)
    },
    async getConfiguration({ commit, state }) {
      const { data } = await axios.get(`/api/config`)
      window.config = Object.assign({}, state.configuration, data)
      commit('updateConfiguration', data)
      if (data.syncing) {
        commit('showVersionAlert', true)
      }
      return data
    },
    async getBankAccount({ commit }) {
      const { data } = await axios.get(`/api/bank-accounts/all`)
      commit('updateBankAccounts', data)
    },
    async getXeroAccounts({ commit }) {
      const { data } = await axios.get(`/api/xero-api/accounts`)
      commit('updateXeroAccounts', data)
    },
    async getAllCities(context) {
      try {
        const { data } = await axios.get('/api/cities')
        context.commit('updateCities', data)
      } catch (e) {
        console.log(e)
      }
    },
    async getSettings(context) {
      context.commit('updateListingLoading', true)
      const { data } = await axios.get('/api/settings').catch(e => {
        context.commit('updateListingLoading', false)
      })
      context.commit('updateListingLoading', false)
      context.commit('updateSettings', data.settings)
      context.commit('setOtaLanguages', data.ota_languages)
      return data
    },
    async getAiConfigs(context) {
      context.commit('updateListingLoading', true)
      const { data } = await axios.get('/api/ai-configs')
      context.commit('updateListingLoading', false)
      context.commit('updateAiConfigs', data)
      return data
    },
    async getAiLogs(context, payload) {
      context.commit('updateListingLoading', true)
      const { data } = await axios.get('/api/ai-logs', {
        params: payload,
      })
      context.commit('updateListingLoading', false)
      context.commit('updateAiLogs', data)
      return data
    },
    async updateSettings(context, payload) {
      const { data } = await axios.patch('/api/settings', payload)
      context.commit('updateSettings', data)
    },
    async getMarketplaceIntegrations(context, payload) {
      const { data } = await axios.get('/api/marketplace', payload)
      context.commit('updateMarketplace', data)
    },
    async getPmsData(context) {
      const { data } = await axios.get('/api/channel-manager/dictionaries')
      context.commit('updatePmsData', data)
    },
    async updateAiConfigs(context, payload) {
      const { data } = await axios.patch('/api/ai-configs', payload)
      context.commit('updateAiConfigs', data)
    },
    async getPaperTrail(_context, payload) {
      return axios.get('/api/dev-tools/paper-trail', { params: payload })
    },
  },
  getters: {
    showVersionAlert(state) {
      return state.showVersionAlert
    },
    companyAccounts(state) {
      return state.bankAccounts.filter(ba => ba.company_account)
    },
    listingCities(state) {
      return state.listingCities
    },
    cmAmenities(state) {
      return state.pmsData.amenities
    },
    otaTypes(state) {
      return state.pmsData.ota_property_types
    },
    roomTypes(state) {
      return state.pmsData.room_types
    },
    bedTypes(state) {
      return state.pmsData.bed_types
    },
    depositTypes(state) {
      return state.pmsData.deposit_types
    },
    integrations(state, _getters, rootState) {
      return filter(
        ai =>
          (ai.name !== 'boom' && ai.name !== 'boomcm') ||
          (state.configuration.is_channel_manager && ai.name === 'boomcm'),
        rootState.settings.app_integrations
      )
    },
    marketplaceIntegrations(state, _getters, rootState) {
      return filter(
        ai =>
          (ai.name !== 'boom' && ai.name !== 'boomcm') ||
          (state.configuration.is_channel_manager && ai.name === 'boomcm'),
        rootState.marketplace
      )
    },
    weekStartsAt(state) {
      return state.configuration.week_starts_at || 0
    },
  },
}
