<template>
  <v-treeview
    v-if="showTree"
    v-model="tree"
    class="profit-tree"
    dense
    :open="initiallyOpen"
    open-on-click
    :items="items"
    item-key="name"
    :color="color"
  >
    <template #label="{ item, open }">
      <div
        v-if="item.children"
        class="d-flex justify-space-between align-center pointer"
        :class="!item.root ? 'text-caption' : 'font-weight-medium'"
      >
        <div>{{ $t(item.name) }}</div>
        <v-skeleton-loader
          v-if="loading"
          :height="20"
          :width="40"
          type="text"
        />
        <span v-else-if="!open" :class="`${color}--text ma-2`">{{
          toMoney(item.total)
        }}</span>
      </div>
      <div
        v-else
        class="d-flex justify-space-between align-center text-caption pointer"
        @click="item.expense ? updateChosenExpense(item.expense) : noop"
      >
        <div class="pointer">
          <span
            v-if="item.expense && item.expense.unit_number"
            class="ml-2 small-text"
            >{{ item.expense.unit_number }}</span
          >
          {{ $t(item.name) }} {{ item.dateDone }}
          <v-tooltip v-if="item.comment" top>
            <template #activator="{ on }">
              <v-icon x-small class="ml-2" v-on="on"
                >fas fa-question-circle</v-icon
              >
            </template>
            <pre>{{ item.comment }}</pre>
          </v-tooltip>
        </div>
        <v-skeleton-loader v-if="loading" type="text" />
        <span v-else class="ml-auto" :class="`${color}--text ma-2`">{{
          toMoney(item.total)
        }}</span>
      </div>
    </template>
  </v-treeview>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import noop from 'lodash/fp/noop'
import isEmpty from 'lodash/fp/isEmpty'
export default {
  name: 'PnlTree',
  props: ['color', 'items', 'showZero', 'open', 'loading'],
  mixins: [FormattersMixin],
  data() {
    return {
      tree: [],
      initiallyOpen: this.open ? [this.items[0].name] : [],
    }
  },
  computed: {
    showTree() {
      return (
        this.items[0].total ||
        (this.showZero && !isEmpty(this.items[0].children))
      )
    },
  },
  methods: {
    noop,
    updateChosenExpense(expense) {
      this.$store.commit('showModal', {
        name: 'ExpenseCard',
        props: { expense },
        isPersistent: false,
      })
    },
  },
}
</script>

<style scoped>
.profit-tree >>> .v-treeview-node__level {
  width: 7px !important;
}
</style>
