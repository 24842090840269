import { TASK_STATUSES } from '@/consts'

export default {
  data() {
    return {
      typeToColor: {
        accounting: '#F7D1A1',
        pool: '#82a4e8',
        cleaning: '#AC86EB',
        design: '#F8C0DD',
        experience: '#43ac27',
        development: '#438F76',
        inspection: '#8FAFBA',
        landscape: '#7ADE55',
        administration: '#979C5B',
        'shift-manager-requests': '#F0ABA0',
        finance: '#F0ABA0',
        iot: '#CECEF0',
        marketing: '#CECEF0',
        realestate: '#41C58E',
        repair: '#64BBAA',
        runner: '#98C182',
        setup: '#8D739C',
        'waste management': '#DDAE82',
        'purchase-replacement': '#9598EE',
        'security patrol': '#DDB7EE',
        'garbage patrol': '#F2C295',
        'pest-control': '#D4B28C',
        'back office': '#FBB478',
        'channel management': '#C29890',
        'day patrol': '#bdc0fb',
        'field-activity': '#bcc9f7',
      },
      divisionToColor: {
        Accounting: '#F7D1A1',
        Administration: '#979C5B',
        Construction: '#64BBAA',
        Communication: '#F0ABA0',
        'Customer Support': '#F0ABA0',
        Sales: '#F0ABA0',
        Design: '#F8C0DD',
        Marketing: '#CECEF0',
        Maintenance: '#64BBAA',
        Setup: '#8D739C',
        Realestate: '#41C58E',
        Warehouse: '#bcc9f7',
        Development: '#F8C0DD',
        'Channel Management': '#F0ABA0',
      },
    }
  },
  methods: {
    priorityColor(priority) {
      if (!priority) {
        priority = ''
      }
      switch (priority.toLowerCase()) {
        case 'critical':
          return 'warning'
        case 'high':
          return 'error'
        case 'med':
          return 'indigo'
        case 'low':
          return 'info'
        default:
          return 'grey'
      }
    },
    listingTaskStatusColor(status) {
      if (status === TASK_STATUSES.NEW) {
        return 'secondary'
      }
      if (status === TASK_STATUSES.IN_PROGRESS) {
        return 'info'
      }
      if (status === TASK_STATUSES.REJECTED) {
        return 'error'
      }
      if (status === TASK_STATUSES.PENDING_APPROVAL) {
        return 'yellow'
      }
      if (status === TASK_STATUSES.INVESTIGATION) {
        return 'warning'
      }
      if (status === TASK_STATUSES.OWNER_APPROVAL) {
        return 'yellow'
      }
      if (status === TASK_STATUSES.DONE) {
        return 'success'
      }
      if (status === TASK_STATUSES.CANT_DO) {
        return 'deep-orange'
      }
      if (status === TASK_STATUSES.CLOSED) {
        return 'warning'
      }
      if (status === TASK_STATUSES.PAUSED) {
        return 'blue-grey'
      }
      if (status === TASK_STATUSES.IN_REVIEW) {
        return 'indigo'
      }
      return 'purple'
    },
    stringToColour(str) {
      if (str) {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        let colour = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 0xff
          colour += ('00' + value.toString(16)).substr(-2)
        }
        return colour
      } else {
        return '#fff'
      }
    },
    resStatusColor(status) {
      if (status == 'confirmed') {
        return 'green lighten-1 white--text'
      } else if (status == 'reserved') {
        return 'yellow lighten-1 purple-icon'
      } else if (status == 'canceled') {
        return 'red lighten-1 white--text'
      } else if (status == 'inquiry') {
        return 'white--text grey'
      } else if (status == 'awaiting_payment') {
        return 'yellow lighten-1 grey--text'
      } else {
        return ''
      }
    },

    stringToHslColor: str => {
      const COLORS = [
        'red darken-4',
        'red accent-4',
        'red',
        'pink darken-4',
        'pink accent-4',
        'pink',
        'purple darken-4',
        'purple accent-4',
        'purple',
        'deep-purple darken-4',
        'deep-purple accent-4',
        'deep-purple',
        'indigo darken-4',
        'indigo accent-4',
        'indigo',
        'blue darken-4',
        'blue accent-4',
        'blue',
        'light-blue darken-4',
        'light-blue accent-4',
        'light-blue',
        'cyan darken-4',
        'cyan accent-4',
        'cyan',
        'teal darken-4',
        'teal accent-4',
        'teal',
        'green darken-4',
        'green accent-4',
        'green',
        'light-green darken-4',
        'light-green accent-4',
        'light-green',
        'lime darken-4',
        'lime accent-4',
        'lime',
        'yellow darken-4',
        'yellow accent-4',
        'yellow',
        'amber darken-4',
        'amber accent-4',
        'amber',
        'orange darken-4',
        'orange accent-4',
        'orange',
        'deep-orange darken-4',
        'deep-orange accent-4',
        'deep-orange',
        'brown darken-4',
        'brown accent-4',
        'brown',
        'grey darken-4',
        'grey accent-4',
        'grey',
        'blue-grey darken-4',
        'blue-grey accent-4',
        'blue-grey',
      ]
      if (!str) return 'transparent'
      const hash = Array.from(str).reduce(
        (hash, char) => 0 | (31 * hash + char.charCodeAt(0)),
        0
      )
      const h = Math.abs(hash) % COLORS.length
      return COLORS[h]
    },
    getGuestScoreColor(score) {
      if (!score) return 'grey'
      switch (score) {
        case 1:
          return 'error'
        case 2:
          return 'warning'
        case 3:
          return 'info'
        case 4:
          return 'primary'
        case 5:
          return 'success'
        default:
          return 'grey'
      }
    },
    getGuestScoreLabel(score) {
      if (!score) return 'No Score'
      const labels = {
        1: 'Guest Score: Bad',
        2: 'Guest Score: Below Average',
        3: 'Guest Score: Average',
        4: 'Guest Score: Good',
        5: 'Guest Score: Great',
      }
      return labels[score] || 'No Score'
    },
    guestScoreDotValue(score) {
      if (!score) return null
      const labels = {
        1: 'Bad',
        2: 'Below Average',
        3: 'Average',
        4: 'Good',
        5: 'Great',
      }
      return labels[score] || null
    },
  },
}
