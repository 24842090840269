<template>
  <div>
    <v-card flat>
      <v-card-title
        class="text-h6 font-weight-bold primary--text d-flex justify-space-between align-center"
      >
        <div>{{ $t('Ledger Account Lines') }}</div>
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="filters.lineCode"
              label="Search Line Type"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="filters.objectType"
              :items="objectTypes"
              label="Source"
              outlined
              dense
              clearable
              hide-details
              class="mb-4"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <v-select
              v-model="filters.accountId"
              :items="accounts"
              item-text="name"
              item-value="id"
              label="Assigned Account"
              outlined
              dense
              clearable
              hide-details
              class="mb-4 account-select"
            >
              <template #item="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
              <template #selection="{ item }">
                <div class="account-text">
                  {{ item.name }}
                  <span class="grey--text text--darken-1 ml-2">
                    ({{ item.code }})
                  </span>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="filteredLedgerLines"
        :loading="loading"
        :items-per-page="15"
      >
        <template #item.line_code="{ item }">
          {{ item.line_code_display }}
        </template>

        <template #item.object_type="{ item }">
          <v-chip
            small
            :color="item.object_type === 'Reservation' ? 'success' : 'error'"
            text-color="white"
          >
            {{ item.object_type }}
          </v-chip>
        </template>

        <template #item.default_type="{ item }">
          <v-chip
            small
            :color="item.default_type === 'credit' ? 'success' : 'error'"
            text-color="white"
          >
            {{ item.default_type }}
          </v-chip>
        </template>

        <template #item.ledger_account="{ item }">
          <v-select
            v-model="item.ledger_account_id"
            :items="accounts"
            item-text="name"
            item-value="id"
            dense
            outlined
            hide-details
            :loading="updatingLine === item.id"
            @change="updateAccount(item)"
          >
            <template #item="{ item: account }">
              <div>
                <strong>{{ account.name }}</strong>
                <span class="grey--text text--darken-1 ml-2">
                  ({{ account.code }})
                </span>
              </div>
            </template>
            <template #selection="{ item: account }">
              <div class="d-flex align-center">
                {{ account.name }}
                <span class="grey--text text--darken-1 ml-2">
                  ({{ account.code }})
                </span>
              </div>
            </template>
          </v-select>
        </template>

        <template #item.recognised_at="{ item }">
          <v-select
            v-model="item.recognised_at"
            :items="getRecognisedAtOptions(item.object_type)"
            dense
            outlined
            hide-details
            @change="updateRecognisedAt(item)"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import Toaster from '@/utils/toaster'

export default {
  name: 'LedgerAccountLines',

  data: () => ({
    loading: false,
    ledgerLines: [],
    accounts: [],
    updatingLine: null,
    filters: {
      lineCode: '',
      objectType: null,
      accountId: null,
    },
    headers: [
      {
        text: 'Line Type',
        value: 'line_code',
        sortable: true,
      },
      {
        text: 'Source',
        value: 'object_type',
        sortable: true,
      },
      {
        text: 'Type',
        value: 'default_type',
        sortable: true,
      },
      {
        text: 'Assigned Account',
        value: 'ledger_account',
        sortable: false,
      },
      {
        text: 'Recognised At',
        value: 'recognised_at',
        sortable: true,
      },
    ],
    recognisedAtOptionsByType: {
      Reservation: [
        { text: 'At Confirmation', value: 'confirmed_at' },
        { text: 'At Check-in', value: 'check_in' },
        { text: 'At Check-out', value: 'check_out' },
      ],
      Expense: [{ text: 'At Payment', value: 'paid_at' }],
    },
  }),

  mounted() {
    this.fetchLedgerLines()
    this.fetchAccounts()
  },

  computed: {
    objectTypes() {
      return [...new Set(this.ledgerLines.map(line => line.object_type))]
    },

    filteredLedgerLines() {
      let filtered = [...this.ledgerLines]

      if (this.filters.lineCode) {
        const search = this.filters.lineCode.toLowerCase()
        filtered = filtered.filter(line =>
          line.line_code_display.toLowerCase().includes(search)
        )
      }

      if (this.filters.objectType) {
        filtered = filtered.filter(
          line => line.object_type === this.filters.objectType
        )
      }

      if (this.filters.accountId) {
        filtered = filtered.filter(
          line => line.ledger_account_id === this.filters.accountId
        )
      }

      return filtered.sort((a, b) => {
        if (a.ledger_account && !b.ledger_account) return -1
        if (!a.ledger_account && b.ledger_account) return 1
        return a.line_code.localeCompare(b.line_code)
      })
    },
  },

  methods: {
    async fetchAccounts() {
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts || []
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    async fetchLedgerLines() {
      this.loading = true
      try {
        const response = await axios.get('/api/ledger-account-lines')
        this.ledgerLines = response.data.map(line => ({
          ...line,
          ledger_account_id: line.ledger_account?.id,
        }))
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.loading = false
      }
    },

    async updateAccount(item) {
      this.updatingLine = item.id
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          ledger_account_id: item.ledger_account_id,
        })
        Toaster.show([{ type: 'success', text: 'Account mapping updated' }])
        await this.fetchLedgerLines()
        this.$emit('line-updated')
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.updatingLine = null
      }
    },

    getRecognisedAtOptions(objectType) {
      return this.recognisedAtOptionsByType[objectType] || []
    },

    async updateRecognisedAt(item) {
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          ledger_account_line: {
            recognised_at: item.recognised_at,
          },
        })
        Toaster.show([{ type: 'success', text: 'Recognition timing updated' }])
        await this.fetchLedgerLines()
        this.$emit('line-updated')
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },
  },
}
</script>

<style scoped>
.account-text {
  font-size: 13px;
}

.account-select :deep(.v-select__selection) {
  font-size: 13px !important;
}
</style>
