<template>
  <v-expansion-panels mt-3 flat accordion class="pa-2">
    <v-expansion-panel key="final">
      <v-expansion-panel-header class="pa-2 pink lighten-5">
        <div
          class="d-flex align-center font-weight-medium pink--text text--darken-2"
        >
          <v-icon small class="me-2" color="pink">mdi-gift-outline</v-icon>
          <span class="font-weight-semibold pink--text text--darken-2"
            >Addons Store</span
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="store-items-container">
          <v-col
            v-for="storeItem in reservationStoreItems"
            :key="storeItem.id"
            cols="12"
            class="pa-2"
          >
            <div class="store-item-row">
              <div class="store-item-info">
                <div
                  v-if="storeItem.status === 'not_available'"
                  class="grey--text"
                >
                  <v-icon small class="me-1">remove</v-icon>
                  <span class="bolder">{{ storeItem.item.name }}</span>
                  <br />
                  <span>not available.</span>
                </div>
                <div v-else-if="storeItem.status === 'available'">
                  <div class="d-inline-flex align-center flex-wrap">
                    <a
                      :href="storeItemUrl(storeItem)"
                      target="_blank"
                      class="text-decoration-underline pink--text text--darken-2"
                    >
                      <span class="bolder">{{ storeItem.item.name }}</span>
                    </a>
                    <copy-clipboard :text="storeItemUrl(storeItem)" />
                  </div>
                  <br />
                  <span class="text-caption">Available to purchase</span>
                </div>
                <div
                  v-else-if="
                    storeItem.status === 'purchased' && storeItem.granted
                  "
                >
                  <v-icon small class="green--text me-1"
                    >mdi-hand-heart-outline
                  </v-icon>
                  <span class="bolder">{{ storeItem.item.name }}</span>
                  <br />
                  <span>granted.</span>
                </div>
                <div v-else-if="storeItem.status === 'purchased'">
                  <v-icon small class="green--text me-1">money</v-icon>
                  <span class="bolder">{{ storeItem.item.name }}</span>
                  <br />
                  <span>purchased.</span>
                </div>
                <div v-else-if="storeItem.status === 'future'">
                  <v-icon small class="yellow-health me-1">mdi-lock</v-icon>
                  <span class="bolder">{{ storeItem.item.name }}</span>
                  <br />
                  <span class="future-date">
                    will be available to purchase from<br />
                    {{ parseDateAndHourInEst(storeItem.item.available_from) }}
                    EST
                  </span>
                </div>
              </div>
              <div class="store-item-actions">
                <div class="d-flex flex-column align-end">
                  <span v-if="storeItem.price.total" class="price">
                    ({{ toMoney(storeItem.price.total) }})
                  </span>
                  <span v-else-if="storeItem.price.start_from" class="price">
                    (from {{ toMoney(storeItem.price.start_from) }})
                  </span>
                </div>
                <div class="action-buttons">
                  <store-item-dialog
                    v-if="showGrantItemButton(storeItem)"
                    :reservation-id="reservation.id"
                    :store-item="storeItem.item"
                    :price="storeItem.price"
                    :options="storeItem.options"
                    :status="storeItem.status"
                    @after-grant="conversationRefresh"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        color="primary"
                        outlined
                        x-small
                        class="my-2 me-2"
                        v-on="on"
                      >
                        Grant
                      </v-btn>
                    </template>
                  </store-item-dialog>
                  <confirmation-modal
                    v-if="showActivateButton(storeItem)"
                    :text="`Are you sure you want to activate ${storeItem.item.name}?`"
                    @action="activateStoreItem(storeItem)"
                  >
                    <template #activator="{ on }">
                      <v-btn
                        color="warning"
                        outlined
                        x-small
                        class="my-2"
                        v-on="on"
                      >
                        Activate
                      </v-btn>
                    </template>
                  </confirmation-modal>
                </div>
              </div>
            </div>
            <v-divider />
          </v-col>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { orderBy } from 'lodash'
import StoreItemDialog from 'components/store/store-item-dialog'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import CopyClipboard from 'components/copy-clipboard'
import ConfirmationModal from 'components/modals/confirmation-modal'
import axios from 'axios'

export default {
  name: 'ConversationInfoStoreItems',
  components: {
    StoreItemDialog,
    CopyClipboard,
    ConfirmationModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    conversation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reservationStoreItems() {
      return orderBy(
        this.reservation.store_items_options || [],
        'item.is_manual',
        'desc'
      )
    },
  },
  methods: {
    showGrantItemButton(storeItem) {
      return (
        (this.isAdmin || this.isCommunicationManager) &&
        !['purchased', 'not_available'].includes(storeItem.status) &&
        this.reservation.status === 'confirmed' &&
        !storeItem.item.is_manual &&
        ['PoolHeater', 'EarlyCheckin', 'LateCheckout'].includes(
          storeItem.item.type
        )
      )
    },
    async conversationRefresh() {
      await this.$store.dispatch(
        'getChatConversation',
        this.conversation.guesty_id
      )
    },
    storeItemUrl(storeItem) {
      return `https://guest.boomnow.com/tourist/store/reservation/${this.reservation.confirmation_code}?selectedItem=${storeItem.item.id}`
    },
    showActivateButton(storeItem) {
      return (
        (this.isAdmin || this.isCommunicationManager) &&
        ['EarlyCheckin', 'LateCheckout'].includes(storeItem.item.type) &&
        storeItem.status !== 'purchased' &&
        storeItem.status !== 'available'
      )
    },
    async activateStoreItem(storeItem) {
      try {
        await axios.post(`/api/store/enable`, {
          reservation_id: this.reservation.id,
          item_id: storeItem.item.id,
        })
        await this.conversationRefresh()
      } catch (error) {
        console.error('Failed to activate store item:', error)
      }
    },
  },
}
</script>

<style scoped>
.bolder {
  font-weight: 500;
}

:deep(.v-expansion-panels) {
  position: static !important;
  margin-right: 60px;
}

:deep(.v-expansion-panel) {
  position: static !important;
}

:deep(.v-expansion-panel-content__wrap) {
  position: static !important;
}

.store-items-container {
  width: 100%;
  overflow: hidden;
}

.store-item-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.store-item-info {
  flex: 1;
  min-width: 200px;
}

.store-item-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 100px;
}

.action-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.price {
  white-space: nowrap;
}

.future-date {
  word-break: break-word;
}

@media (max-width: 600px) {
  :deep(.v-expansion-panels) {
    margin-right: 48px;
  }

  .store-item-row {
    flex-direction: column;
  }

  .store-item-actions {
    width: 100%;
    align-items: flex-start;
  }

  .action-buttons {
    margin-top: 8px;
  }
}
</style>
