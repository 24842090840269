<template>
  <v-main>
    <v-snackbar
      v-model="showAlert"
      absolute
      :timeout="7000"
      color="warning"
      bottom
    >
      Oops! Our service is temporary unavailable, we will back in few minutes...
    </v-snackbar>
    <v-container class="pa-0 login-wrapper" :fluid="!isMobileOrIPad">
      <v-row class="mx-0 login-wrapper">
        <v-col
          v-if="!isMobileOrIPad"
          cols="6"
          :style="{ 'background-color': '#FAFAFA' }"
          class="d-flex align-center justify-center px-12 py-11"
        >
          <v-img
            max-width="600"
            class="flex-shrink-1"
            contain
            :aspect-ratio="1"
            :src="require(`../assets/images/${loginImage}`)"
            alt=""
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center">
          <v-row justify="center">
            <v-col cols="8" lg="7" xl="6" class="p-relative" elevation="0">
              <div v-if="!forgotPassword" class="text-center">
                <img :src="logo" height="64" />
                <h2
                  class="text-h4 font-weight-bold text-center text-capitalize mb-6"
                >
                  Dashboard login
                </h2>
                <v-form @submit="login">
                  <v-text-field
                    v-model="email"
                    name="email"
                    tabindex="1"
                    label="Email"
                    type="text"
                    outlined
                    dense
                  />
                  <v-text-field
                    v-model="password"
                    tabindex="2"
                    name="password"
                    label="Password"
                    type="password"
                    outlined
                    dense
                  />
                  <v-select
                    v-if="tenants.length"
                    v-model="selectedTenant"
                    :items="tenants"
                    item-text="name"
                    item-value="id"
                    :rules="[required]"
                    label="Select Tenant"
                    outlined
                    dense
                  />
                  <div
                    class="d-flex flex-wrap justify-space-between align-center"
                  >
                    <v-checkbox
                      v-model="rememberUser"
                      class="login-remember my-0"
                      label="Remember me"
                      color="primary"
                      hide-details
                      dense
                    ></v-checkbox>
                    <button
                      type="button"
                      class="info--text mt-1"
                      @click.prevent="forgotPassword = true"
                    >
                      Forgot Password?
                    </button>
                  </div>
                  <v-btn
                    tabindex="3"
                    type="submit"
                    color="primary"
                    :loading="listingLoading"
                    class="mt-8"
                    elevation="0"
                    block
                    x-large
                  >
                    <span class="text-capitalize">Login</span>
                  </v-btn>
                  <div
                    v-if="error && !listingLoading"
                    class="text-caption error--text"
                  >
                    {{ error }}
                  </div>
                </v-form>
              </div>
              <ForgotPassword
                v-else
                @on-forgot-password-close="forgotPassword = false"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="text-caption grey--text mt-2 bottom-left">
        {{ currentUrl }}
      </div>
    </v-container>
  </v-main>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from './mixins/device-mixin'
import ForgotPassword from './forgot-password'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  components: {
    ForgotPassword,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, FormRulesMixin],
  mounted() {
    if (origin.includes('smartbnb.designedvr.com')) {
      location.reload()
    }
  },
  data() {
    return {
      email: '',
      password: '',
      showAlert: false,
      error: null,
      loginImage: `login${Math.floor(Math.random() * 3) + 1}.png`,
      rememberUser: false,
      forgotPassword: this.$route.query.forgotPassword === 'true',
      tenants: [],
      selectedTenant: null,
    }
  },
  computed: {
    currentUrl() {
      return window.location.href
    },
    logo() {
      return this.$store.state.app.configuration.logo_url
    },
    errorMsg: {
      get() {
        return this.error
      },
      set(value) {
        this.error = value
      },
    },
  },
  methods: {
    login(e) {
      e.preventDefault()
      const { email, password } = this
      this.$store.commit('updateListingLoading', true)

      axios
        .post('/api/login', { email, password, tenant_id: this.selectedTenant })
        .then(({ data }) => {
          this.$store.commit('updateListingLoading', false)
          this.$store.commit('updateLoading', false)

          if (data.email) {
            this.$store.commit('updateUser', data)
            this.$router.push(this.defaultRouteByRole)
          } else if (data.tenants) {
            this.tenants = data.tenants
          }
        })
        .catch(error => {
          this.$store.commit('updateListingLoading', false)
          if (error.response.status >= 500) {
            this.showAlert = true
          } else if (error.response) {
            this.errorMsg = error.response.data.error
          } else {
            console.log(error)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  &-wrapper {
    min-height: 100%;
  }
  &-divider {
    &::before {
      position: absolute;
      background-color: #dfe1e6;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      content: '';
    }
    &-text {
      z-index: 1;
    }
  }
}
.md-field:last-child {
  margin-bottom: 40px;
}
.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
