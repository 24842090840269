<template>
  <tr>
    <td class="text-center">
      <a class="text-decoration-underline" @click="updateBookingEngine(item)">{{
        item.name
      }}</a>
    </td>
    <td class="text-center justify-center">
      <v-col>
        <div v-if="item.whitelist.length === 0">
          <tag color="primary" :inverted="true" size="sm">All Listings</tag>
        </div>
        <div v-else>
          <tag color="primary" :inverted="true" size="sm"
            >{{ item.whitelist.length }} listings</tag
          >
        </div>
      </v-col>
      <v-col v-if="item.blacklist.length !== 0">
        <div>
          <tag color="secondary" :inverted="true" size="sm"
            >{{ item.blacklist.length }} Exluded</tag
          >
        </div></v-col
      >
    </td>
    <td class="text-center">
      <confirmation-modal
        :text="`Are you sure you want to ${
          isActive ? 'deactivate' : 'activate'
        } this booking site?`"
        :title="`${item.name} booking site`"
        @action="itemChange"
      >
        <template #activator="{ on }">
          <v-switch readonly :input-value="isActive" v-on="on" />
        </template>
      </confirmation-modal>
    </td>
    <td v-if="isSystemAdmin" class="text-center">
      <div v-if="item.client_id" class="mb-2">{{ item.client_id }}</div>
      <v-btn small color="primary" @click="showApiCredentialsDialog = true">
        {{ item.client_id ? 'Regenerate API Keys' : 'Generate API Keys' }}
      </v-btn>
    </td>

    <v-dialog v-model="showApiCredentialsDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{
            item.client_id
              ? 'Regenerate API Credentials'
              : 'Generate API Credentials'
          }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="recipientEmail"
            label="Email address"
            placeholder="Enter email to send credentials"
            outlined
            dense
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showApiCredentialsDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            @click="generateApiCredentials"
          >
            Generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </tr>
</template>

<script>
import axios from '@/axios/config'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Tag from 'components/common/tag.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  components: { ConfirmationModal, Tag },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'afterChange', 'listings'],
  data() {
    return {
      singleSelect: false,
      menu: false,
      menu2: false,
      isActive: this.item.active,
      showApiCredentialsDialog: false,
      recipientEmail: '',
      loading: false,
    }
  },
  methods: {
    updateBookingEngine(item) {
      if (!item.sections.header) {
        item.sections.header = {
          bg_image: null,
        }
      }
      if (!item.sections.favorites) {
        item.sections.favorites = {}
      }
      if (!item.sections.footer) {
        item.sections.footer = {}
      }
      if (!item.theme.main_color) {
        item.theme.main_color = '#44a2a2'
      }
      if (!item.theme.secondary_color) {
        item.theme.secondary_color = '#8F90A6'
      }
      if (!item.theme.actions_color) {
        item.theme.actions_color = '#464673'
      }
      this.$store.commit('showModal', {
        name: 'BookingEngineModal',
        props: {
          editMode: true,
          afterSaveFunc: this.afterChange,
          currentBookingEngineSetting: Object.assign({}, item),
          listings: this.listings,
        },
        isPersistent: false,
      })
    },
    deleteBookingEngine() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        axios
          .delete(`/api/booking-engine-settings/${this.item.id}`)
          .then(() => {
            this.$store.commit('updateListingLoading', false)
            if (this.afterChange) {
              this.afterChange()
            } else {
              this.$store.dispatch('getBookingEngineSettings', {
                id: this.listingManageContext
                  ? this.$router.currentRoute.path.split('/')[3]
                  : 'All',
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    itemChange() {
      this.$store.commit('updateListingLoading', true)
      axios
        .patch(`/api/booking-engine-settings/${this.item.id}`, {
          active: !this.isActive,
        })
        .then(() => {
          this.$store.commit('updateListingLoading', false)
          this.isActive = !this.isActive
          if (this.afterChange) {
            this.afterChange()
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    async generateApiCredentials() {
      this.loading = true
      try {
        const { data } = await axios.post(
          `/api/booking-engine-settings/${this.item.id}/generate_api_credentials`,
          { email: this.recipientEmail || null }
        )

        this.showApiCredentialsDialog = false
        this.recipientEmail = ''
      } catch (error) {
        console.error(error)
        this.$store.commit('showSnackbar', {
          text: 'Error generating API credentials',
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
