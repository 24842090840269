<template>
  <v-card v-if="listingTask" class="service-call-edit">
    <v-card-title>
      <div class="p-relative flex">
        <!-- <v-progress-circular
          v-show="!listingTask.extra_data"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        /> -->
        <v-btn
          v-if="!isInvestor || hasAbility('investor_operator')"
          color="info"
          :target="isMobile ? '_self' : '_blank'"
          :to="addressFor(listingTask.id)"
          class="text-capitalize"
          text
        >
          <v-icon small>$external_link</v-icon>
          <span class="ml-2">Open Service Call</span>
        </v-btn>
        <copy-clipboard v-if="!isInvestor" color="info" :text="ltLink" />
        <lt-taskim-btn
          v-if="managementRoles"
          :listing-task-id="listingTask.id"
          :small="true"
        />

        <v-icon class="close-button" @click="close">close</v-icon>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text class="pb-0">
      <v-row align="center">
        <v-col v-if="listingTask.extra_data" cols="12" sm="8">
          <div class="text-caption secondary--text">Listing</div>
          <div class="font-weight-regular text-h6 d-flex justify-space-between">
            <div>
              {{ listingTask.extra_data.listing_name }}
            </div>
            <v-btn
              class="px-2"
              x-small
              fab
              color="yellow"
              depressed
              @click="directionsTo(listingTask)"
            >
              <v-icon>mdi-google-maps</v-icon>
            </v-btn>
            <v-icon
              v-if="listingTask.listing_task_template_id"
              class="ml-3 pa-1 rounded purple-grey-br"
            >
              account_tree
            </v-icon>
            <v-icon
              v-if="
                listingTask.listing_task_template &&
                listingTask.listing_task_template.loop
              "
              class="ml-3 pa-1 rounded purple-grey-br"
              >mdi-sync
            </v-icon>
          </div>
        </v-col>
        <v-col
          v-if="!(isInvestor || isContractor)"
          cols="12"
          sm="4"
          class="text-right"
        >
          <v-btn
            v-if="
              listingTask.extra_data &&
              listingTask.extra_data.current_guest_conversation_id
            "
            icon
            @click="
              openChat({
                id: listingTask.extra_data.current_guest_conversation_id,
              })
            "
          >
            <v-icon color="primary">message</v-icon>
          </v-btn>
          <v-btn
            v-show="phoneNumber(listingTask)"
            color="primary"
            class="ml-2"
            :href="`tel:+${phoneNumber(listingTask)}`"
            icon
          >
            <v-icon>call</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="!managementRoles">
        <div class="text-caption secondary--text">Description</div>
        <p>
          {{ listingTask.description }}
        </p>
      </div>
      <v-row v-if="isContractor || isCleaningSupervisor || isCleaningManager">
        <v-col cols="12">
          <contractor-select
            icon="$account_alt"
            label="Additional assignee"
            hint="Up to 4 assignees"
            :persistent-hint="true"
            :filter-change="additionalContractorsChanged"
            :allow-multiple="true"
            :input="limited"
            :enable-sug="true"
            :value.sync="additionalContractors"
          />
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col class="mb-2" cols="auto">
          <p class="text-caption mb-0">Created by</p>
          <span class="font-weight-medium">{{
            listingTask.created_by_name || 'Auto'
          }}</span>
        </v-col>
        <v-col v-if="listingTask.amount" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Total cost</p>
          <span class="font-weight-medium">{{
            toMoney(listingTask.amount)
          }}</span>
        </v-col>
        <v-col v-if="listingTask.created_at" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Created at</p>
          <span class="font-weight-medium">{{
            parseDate(listingTask.created_at)
          }}</span>
        </v-col>
        <v-col v-if="listingTask.scheduled_at" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Scheduled at</p>
          <span class="font-weight-medium">
            <v-avatar
              v-if="taskOrderMap"
              class="mb-1"
              :color="stringToHslColor(listingTask.assigned_to_name)"
              size="18"
              ><span class="white--text font-weight-bold">{{
                getTaskOrder(listingTask, taskOrderMap)
              }}</span></v-avatar
            >
            {{ parseDate(listingTask.scheduled_at) }}</span
          >
        </v-col>
        <v-col v-if="listingTask.total_hours" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Hours</p>
          <span class="font-weight-medium">{{ listingTask.total_hours }}</span>
        </v-col>
        <v-col v-if="materialsSum" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Materials</p>
          <span class="font-weight-medium">{{ toMoney(materialsSum) }}</span>
        </v-col>
        <v-col v-if="listingTask.status" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Status</p>
          <v-chip :color="listingTaskStatusColor(listingTask.status)" small>
            {{ listingTask.status }}
          </v-chip>
        </v-col>
        <v-col v-if="listingTask.priority" class="mb-2" cols="auto">
          <p class="text-caption mb-0">Priority</p>
          <span>
            <v-icon :color="priorityColor(listingTask.priority)" x-small
              >mdi-circle</v-icon
            >
            {{ listingTask.priority }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text v-if="managementRoles" class="pt-6">
      <v-textarea
        v-model="listingTask.description"
        label="Description"
        :rows="3"
        outlined
        dense
        @change="descChanged"
      />
      <v-row align="center" class="mb-2">
        <v-col cols="12" sm="4">
          <v-menu
            ref="menu"
            v-model="menu2"
            :disabled="listingTask.cant_be_moved"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="from"
                :disabled="listingTask.cant_be_moved"
                label="Scheduled at"
                prepend-inner-icon="event"
                readonly
                outlined
                dense
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="from"
              :events="allowedDates"
              :event-color="eventDateColor"
              :reactive="true"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10)
              "
              min="2018-01-01"
              @change="saveFrom"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4">
          <DvrTimePicker :key="timeKey" :value.sync="time" @change="saveHour" />
        </v-col>
        <v-col cols="12" :sm="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-model="followup"
                outlined
                dense
                hide-details
                label="Follow-up at"
                prepend-inner-icon="event"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="followup"
              @input="menu1 = false"
              @change="followupChange"
            />
          </v-menu>
        </v-col>
        <v-col v-if="followup" cols="12" sm="4">
          <contractor-select
            label="Follow-up on"
            icon="$account_alt"
            :enable-sug="true"
            :filter-change="followupByChange"
            :allow-multiple="false"
            :value.sync="listingTask.follow_up_by_id"
          />
        </v-col>
        <v-col cols="12">
          <contractor-select
            icon="$account_alt"
            :value="listingTask.assigned_contractor_id"
            class="mb-2"
            :hide-details="true"
            :filter-change="contractorChange"
          />
        </v-col>
        <v-col cols="12">
          <contractor-select
            icon="$account_alt"
            label="Additional assignee"
            hint="Up to 4 assignees"
            :persistent-hint="true"
            :filter-change="additionalContractorsChanged"
            :allow-multiple="true"
            :input="limited"
            :enable-sug="true"
            :value.sync="additionalContractors"
          />
        </v-col>
        <v-col cols="12" sm="12">
          <v-checkbox
            v-model="listingTask.cant_be_moved"
            hide-details
            label="Can't be moved"
            class="mt-0"
            @change="cantMoveChange($event)"
          ></v-checkbox>
        </v-col>
        <v-divider />
        <v-row
          v-if="listingTask.when_service_needed"
          class="bt-1 bb-1 pt-2 pb-2"
        >
          <v-col
            :cols="
              ['During his stay', 'ASAP!'].includes(
                listingTask.when_service_needed
              )
                ? 6
                : 12
            "
          >
            <v-select
              v-model="listingTask.when_service_needed"
              :items="[
                'ASAP!',
                'During his stay',
                'After checkout',
                'Guest didn\'t reply',
              ]"
              label="When guest request to come"
              disabled
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col
            v-if="
              ['During his stay', 'ASAP!'].includes(
                listingTask.when_service_needed
              )
            "
            cols="12"
            sm="6"
          >
            <v-select
              v-model="listingTask.req_hour_slot"
              :items="['9:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00']"
              label="Preferred time"
              disabled
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col
            v-if="
              ['During his stay', 'ASAP!'].includes(
                listingTask.when_service_needed
              )
            "
            cols="12"
          >
            <v-checkbox
              v-model="listingTask.can_enter_on_absence"
              disabled
              class="mt-0"
              label="Approve to serivce on absence"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="mb-4">
        <v-col v-if="listingTask.extra_data" cols="12" sm="6">
          <property-picker
            :not-top-header="true"
            :after-change="listingChanged"
            :value="listingTask.extra_data.listing"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <listing-priority
            :value="listingTask.priority"
            @change="priorityTaskChange"
          />
        </v-col>
      </v-row>
      <v-textarea
        v-model="listingTask.internal_comments"
        :label="$t('Internal Comments')"
        :rows="3"
        outlined
        dense
        @change="internalCommentsChanged"
      />
      <v-select
        v-model="listingTask.project_id"
        class="mb-6"
        :items="activeProjects"
        item-value="id"
        label="Assign To Project"
        outlined
        dense
        hide-details
        @change="assignedProjectChanged"
      >
        <template #selection="{ item }">
          <span class="bolder mr-1">{{ item.name }} </span>
          <span v-if="item.description" class="font-weight-regular">
            - {{ item.description }}</span
          >
        </template>
        <template #item="{ item }">
          <span class="bolder mr-1">{{ item.name }} </span>
          <span v-if="item.description" class="font-weight-regular">
            - {{ item.description }}</span
          >
        </template>
      </v-select>
      <catalog-quick-select
        v-if="!isCommunicationManager"
        class="mb-6"
        :value="listingTask.listing_task_catalog_item_id"
        :filter-change="quickPickChange"
      />
      <v-expansion-panels
        v-if="
          !listingTask.auto_listing_task_rule_id &&
          listingTaskCreationInfo.past_lt
        "
        class="mb-6"
        flat
      >
        <v-expansion-panel class="red lighten-5">
          <v-expansion-panel-header>
            Similar service calls ({{ listingTaskCreationInfo.past_lt.length }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <LtSimilarExpand :tasks="listingTaskCreationInfo.past_lt" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-checkbox
        v-model="listingTask.is_outstanding"
        class="mb-4"
        label="Is outstanding issue"
        hide-details
        @change="outstandingChange"
      />
      <div v-if="listingTask.is_outstanding">
        <v-textarea
          v-model="listingTask.outstanding_description"
          placeholder="Describe the issue..."
          class="mt-6"
          rows="3"
          outlined
          dense
          @change="outstandingDescChange"
        />
        <v-menu
          ref="menu3"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          required
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              :value="parsedShouldBeFixed"
              label="Scheduled be fixed by"
              readonly
              outlined
              dense
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker3"
            v-model="expectedDate"
            :reactive="true"
            @change="shouldBeFixedChange"
          />
        </v-menu>
      </div>
      <div
        v-if="
          listingTask.desc_images &&
          !listingTask.desc_images.length &&
          !listingTask.desc_images_uploading &&
          managementRoles
        "
      >
        <h3 class="text-h6 font-weight-regular my-4">Attachments</h3>
        <dvr-media-upload :on-change="onUpload" folder="service-calls" />
      </div>
      <v-expansion-panels flat>
        <v-expansion-panel
          v-if="
            !isEmpty(listingTask.desc_images) ||
            !isEmpty(listingTask.before_images)
          "
          key="ini3"
          active-class="grey lighten-4"
        >
          <v-expansion-panel-header>
            Work Description
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Comments: {{ listingTask.before_comments || 'None' }}</p>
            <div v-if="!isEmpty(listingTask.desc_images)">
              <media-smart-display
                v-for="(image, index) in listingTask.desc_images"
                :key="index"
                :media="image"
              />
            </div>
            <div v-if="!isEmpty(listingTask.before_images)" class="mt-4">
              <media-smart-display
                v-for="(image, index) in listingTask.before_images"
                :key="index"
                :media="image"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="
            !isEmpty(listingTask.after_images) ||
            !isEmpty(listingTask.receipt_images)
          "
          active-class="grey lighten-4"
        >
          <v-expansion-panel-header> After Work</v-expansion-panel-header>
          <v-expansion-panel-content
            ><p>Comments: {{ listingTask.after_comments || 'None' }}</p>
            <div v-if="!isEmpty(listingTask.after_images)">
              <media-smart-display
                v-for="(image, index) in listingTask.after_images"
                :key="index"
                :media="image"
              ></media-smart-display>
            </div>
            <div v-if="!isEmpty(listingTask.receipt_images)" class="mt-4">
              <p>Receipt images</p>
              <media-smart-display
                v-for="(image, index) in listingTask.receipt_images"
                :key="index"
                :media="image"
              ></media-smart-display>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="!isEmpty(listingTask.sub_tasks)"
          active-class="grey lighten-4"
        >
          <v-expansion-panel-header> Tasks</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item
                v-for="(task, index) in listingTask.sub_tasks"
                :key="`task-${index}`"
              >
                <v-expansion-panels multiple flat>
                  <v-expansion-panel class="mb-2">
                    <v-expansion-panel-header>
                      <div>
                        {{ task.description }}
                        <div
                          v-show="task.started_at"
                          class="text-caption font-weight-light"
                        >
                          {{ task.started_at }}
                        </div>
                      </div>
                      <template #actions>
                        <div>
                          <div class="font-weight-bold">
                            {{ toMoney(task.price) }}
                          </div>
                          <div v-show="task.hours">{{ task.hours }} hr</div>
                        </div>
                        <gallery
                          v-if="!isMobile && !isEmpty(task.after_images)"
                          :images="task.after_images"
                          :readonly="true"
                          :thumbnails="false"
                          icon-color="black"
                          icon="mdi-paperclip"
                        />
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="!isEmpty(task.before_images)">
                        <p>{{ $t('Before job') }}</p>
                        <media-smart-display
                          v-for="(image, index) in task.before_images"
                          :key="index"
                          :media="image"
                        ></media-smart-display>
                      </div>
                      <div v-if="!isEmpty(task.after_images)">
                        <p>{{ $t('After job') }}</p>
                        <media-smart-display
                          v-for="(image, index) in task.after_images"
                          :key="index"
                          :media="image"
                        ></media-smart-display>
                      </div>
                      <div
                        v-if="
                          isEmpty(task.before_images) &&
                          isEmpty(task.after_images)
                        "
                      >
                        <span class="secondary--text">
                          {{ $t('No details') }}
                        </span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="!isEmpty(listingTask.shadow_sub_tasks)"
          active-class="grey lighten-4"
        >
          <v-expansion-panel-header>
            Tasks In Other Service Calls
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item
                v-for="(task, index) in listingTask.shadow_sub_tasks"
                :key="`task-${index}`"
              >
                <v-expansion-panels multiple flat>
                  <v-expansion-panel class="mb-2">
                    <v-expansion-panel-header>
                      <div>
                        <v-btn
                          color="info"
                          :target="isMobile ? '_self' : '_blank'"
                          :to="addressFor(task.listing_task_id)"
                          class="text-capitalize"
                          text
                          @click.stop
                        >
                          <v-icon small>$external_link</v-icon>
                          <span class="ml-1">SC</span>
                        </v-btn>
                        {{ task.description }}
                        <div
                          v-show="task.started_at"
                          class="text-caption font-weight-light"
                        >
                          {{ task.started_at }}
                        </div>
                      </div>
                      <template #actions>
                        <div>
                          <div class="font-weight-bold">
                            {{ toMoney(task.price) }}
                          </div>
                          <div v-show="task.hours">{{ task.hours }} hr</div>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="!isEmpty(task.before_images)">
                        <p>{{ $t('Before job') }}</p>
                        <media-smart-display
                          v-for="(image, index) in task.before_images"
                          :key="index"
                          :media="image"
                        ></media-smart-display>
                      </div>
                      <div v-if="!isEmpty(task.after_images)">
                        <p>{{ $t('After job') }}</p>
                        <media-smart-display
                          v-for="(image, index) in task.after_images"
                          :key="index"
                          :media="image"
                        ></media-smart-display>
                      </div>
                      <div
                        v-if="
                          isEmpty(task.before_images) &&
                          isEmpty(task.after_images)
                        "
                      >
                        <span class="secondary--text"> No details </span>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <p class="text-right mt-4">
        <v-btn
          color="warning"
          class="text-capitalize"
          elevation="0"
          @click="deleteTask"
          >Delete
        </v-btn>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import axios from 'axios'
import ContractorSelect from 'components/contractor-select'
import CatalogQuickSelect from 'components/catalog-quick-select'
import { get, sumBy, compact } from 'lodash/fp'
import LtSimilarExpand from 'components/listing-tasks/lt-similar-expand'
import PropertyPicker from 'components/property-picker'
import MediaSmartDisplay from 'components/media-smart-display'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import ListingPriority from 'components/listing-priority'
import CopyClipboard from 'components/copy-clipboard'
import DvrTimePicker from 'components/form-fields/dvr-time-picker'
import { getTaskOrder } from 'components/calendar/utils'
import LtTaskimBtn from 'components/taskim/lt-taskim-btn'
import { mapActions } from 'vuex'
import Gallery from 'components/common/gallery.vue'

export default {
  components: {
    Gallery,
    DvrTimePicker,
    LtTaskimBtn,
    CopyClipboard,
    ListingPriority,
    DvrMediaUpload,
    MediaSmartDisplay,
    PropertyPicker,
    LtSimilarExpand,
    CatalogQuickSelect,
    ContractorSelect,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    DeviceMixin,
    ColorsMixin,
    LtMixin,
    FormattersMixin,
  ],
  props: ['listingTask', 'close', 'event', 'calendar', 'taskOrderMap'],
  data() {
    return {
      getTaskOrder,
      menu: false,
      menu1: false,
      menu2: false,
      timeKey: 0,
      additionalContractors: compact([
        this.listingTask.assigned_contractor1_id,
        this.listingTask.assigned_contractor2_id,
        this.listingTask.assigned_contractor3_id,
        this.listingTask.assigned_contractor4_id,
      ]),
      time: this.listingTask.scheduled_at
        ? this.$moment(this.listingTask.scheduled_at).utc().format('HH:mm')
        : null,
      hours: [
        '7a',
        '8a',
        '9a',
        '10a',
        '11a',
        '12p',
        '1p',
        '2p',
        '3p',
        '4p',
        '5p',
        '6p',
        '7p',
        '8p',
        '9p',
        '10p',
      ],
    }
  },
  computed: {
    materialsSum() {
      return sumBy('amount', this.listingTask.material_expenses)
    },
    parsedFrom() {
      const parsedDate = this.$moment(this.from).utc()
      if (this.from) {
        return parsedDate.format('YYYY-MM-DD')
      }
      return this.$moment().format('YYYY-MM-DD')
    },
    from: {
      get() {
        if (this.listingTask && this.listingTask.scheduled_at) {
          return this.parseDate(this.listingTask.scheduled_at)
        }
        return this.$moment().utc().format('YYYY-MM-DD')
      },
      set(value) {
        this.listingTask.scheduled_at = value
      },
    },
    followup: {
      get() {
        if (this.listingTask && this.listingTask.follow_up_at) {
          return this.parseDate(this.listingTask.follow_up_at)
        }
        return null
      },
      set(value) {
        this.listingTask.follow_up_at = value
      },
    },
    expectedDate: {
      get() {
        if (this.listingTask && this.listingTask.expected_resolved_by) {
          return new Date(this.listingTask.expected_resolved_by)
            .toISOString()
            .substr(0, 10)
        } else {
          return null
        }
      },
      set(value) {
        this.listingTask.expected_resolved_by = value
      },
    },
    parsedShouldBeFixed() {
      const parsedDate = this.$moment(this.listingTask.expected_resolved_by)
      if (parsedDate.isValid()) {
        return parsedDate.format('MM-DD-YYYY')
      }
      return this.$moment().format('MM-DD-YYYY')
    },
    listingTaskCreationInfo() {
      return this.$store.state.listingTaskCreationInfo || { extra_data: {} }
    },
    ltLink() {
      if (this.listingTask) {
        return `${this.origin}/dashboard/listing-task/${this.listingTask.id}`
      }
      return ''
    },
    activeProjects() {
      return this.$store.state.projects.activeProjects
    },
  },
  watch: {
    listingTask() {
      this.timeKey++
      this.time = this.listingTask.scheduled_at
        ? this.$moment(this.listingTask.scheduled_at).utc().format('HH:mm')
        : null
      this.additionalContractors = compact([
        this.listingTask.assigned_contractor1_id,
        this.listingTask.assigned_contractor2_id,
        this.listingTask.assigned_contractor3_id,
        this.listingTask.assigned_contractor4_id,
      ])
    },
  },
  mounted() {
    if (!this.isInvestor) {
      this.getCreationInfo({
        listingsIds: [this.listingTask.listing_id],
        id: this.listingTask.id,
      })
    }
  },
  methods: {
    ...mapActions(['getCreationInfo']),
    deleteTask: function () {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        this.$store.commit('updateListingLoading', true)
        axios
          .delete('/api/listing-tasks/' + this.listingTask.id)
          .then(() => {
            this.$emit('change-to-task')
            this.close()
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    async descChanged(description) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        description,
      })
      this.event.setExtendedProp('task', task)
    },
    async followupChange(value) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        follow_up_at: value,
      })
      this.event.setExtendedProp('task', task)
    },
    async followupByChange(value) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        follow_up_by_id: value,
      })
      this.event.setExtendedProp('task', task)
    },
    internalCommentsChanged(val) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        internal_comments: val,
      })
    },
    async priorityTaskChange(priority) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        priority,
      })
      this.event.setExtendedProp('task', task)
      this.$emit('change-to-task')
    },
    async cantMoveChange(val) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        cant_be_moved: val,
      })
      this.event.setExtendedProp('staticTime', task.cant_be_moved)
    },
    async contractorChange(contractorID) {
      this.$emit('change-to-task')

      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        assigned_contractor_id: contractorID,
      })
      this.event.setExtendedProp('task', task)
    },
    async additionalContractorsChanged(contractorsIds) {
      this.$emit('change-to-task')

      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        additional_contractors: contractorsIds,
      })
      this.event.setExtendedProp('task', task)
    },
    async listingChanged(listingID) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        listing_id: listingID,
      })
      this.event.setExtendedProp('task', task)
    },
    limited(e) {
      if (e.length > 4) {
        e.pop()
      }
    },
    assignedProjectChanged(projectId) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        project_id: projectId,
      })
    },
    phoneNumber(task) {
      return get('extra_data.effected_guest.phone_number', task)
    },
    async saveFrom(date) {
      const hour = (this.time || '00:00') + 'Z'
      this.$emit('change-to-task')
      if (date) {
        const task = await this.$store.dispatch('updateListingTask', {
          scheduled_at: this.$moment(this.from + ' ' + hour).format(),
          id: this.listingTask.id,
        })
        this.event.setDates(task.scheduled_at, task.scheduled_at_end)
        this.event.setExtendedProp('task', task)
      }
    },
    async saveHour() {
      if (this.time) {
        const task = await this.$store.dispatch('updateListingTask', {
          scheduled_at: this.$moment(
            this.from + ' ' + this.time + 'Z'
          ).format(),
          id: this.listingTask.id,
        })
        this.$emit('change-to-task')
        this.event.setDates(task.scheduled_at, task.scheduled_at_end)
        this.event.setExtendedProp('task', task)
      }
    },
    allowedDates(val) {
      const parsedDate = this.$moment(val).utc().add(1, 'days')
      const timeNow = this.$moment().utc()
      if (
        timeNow <= parsedDate &&
        this.listingTaskCreationInfo &&
        (!this.listingTaskCreationInfo.house_vacant_dates[val] ||
          this.listingTaskCreationInfo.house_check_in_dates[val] ||
          this.listingTaskCreationInfo.house_check_out_dates[val])
      ) {
        return true
      } else {
        return false
      }
    },
    onUpload(images) {
      if (images.length > 0) {
        this.$store.dispatch('updateListingTask', {
          id: this.listingTask.id,
          desc_images: images,
        })
      }
    },
    addressFor(id) {
      return { path: `/dashboard/listing-task/${id}` }
    },
    async quickPickChange(change) {
      const task = await this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        listing_task_catalog_item_id: change.id,
      })
      await this.getCreationInfo({
        listingsIds: [this.listingTask.listing_id],
        id: this.listingTask.id,
      })
      this.event.setExtendedProp('task', task)
    },
    outstandingChange(value) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        is_outstanding: value,
      })
    },
    outstandingDescChange(value) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        outstanding_description: value,
      })
    },
    shouldBeFixedChange(value) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        expected_resolved_by: value,
      })
    },
  },
}
</script>

<style lang="scss">
.service-call-edit {
  .vue__time-picker {
    display: block;
    position: relative;
    font-size: 1em;
    width: 100%;
    font-family: sans-serif;
    vertical-align: middle;
    height: 40px;

    input.display-time {
      width: 100%;
      height: 100%;
      border-color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      transition: all 0.3s;

      &:focus {
        border-color: #7ed9d9;
        border-width: 2px;
        outline: 0;
      }
    }

    .dropdown {
      width: 100%;

      .select-list {
        width: 100%;
      }
    }
  }
}
</style>
