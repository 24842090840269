var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-dialog',{attrs:{"title":"Payment"},on:{"submit":_vm.createPayment},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"text-capitalize py-4 mx-4",attrs:{"title":"amount","loading":_vm.loading,"color":"grey","dark":"","depressed":"","disabled":_vm.disabled,"small":""}},on),[_vm._v(" Collect")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-btn-toggle',{staticClass:"mb-6 d-flex",attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.paymentType),callback:function ($$v) {_vm.paymentType=$$v},expression:"paymentType"}},[_c('v-btn',{staticClass:"flex",attrs:{"value":"track"}},[_vm._v(" Track Payment ")])],1),_c('v-checkbox',{attrs:{"label":"Charge full amount"},on:{"click":_vm.fillAmount},model:{value:(_vm.chargeFull),callback:function ($$v) {_vm.chargeFull=$$v},expression:"chargeFull"}}),_c('v-text-field',{attrs:{"label":"Amount","disabled":_vm.chargeFull,"rules":[
      _vm.required,
      _vm.paymentType === 'credit'
        ? _vm.max(_vm.intentsSum)
        : _vm.max(_vm.reservation.balance_due),
    ],"dense":"","outlined":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}}),(_vm.paymentType === 'credit')?_c('v-select',{attrs:{"dense":"","rules":[_vm.required],"outlined":"","clearable":false,"label":"Payment Method","items":_vm.creditCards},model:{value:(_vm.methodId),callback:function ($$v) {_vm.methodId=$$v},expression:"methodId"}}):_vm._e(),(_vm.paymentType === 'track')?_c('v-select',{attrs:{"dense":"","rules":[_vm.required],"outlined":"","clearable":false,"label":"Payment Method","items":[
      { value: 'cash', text: 'Cash' },
      { value: 'credit', text: 'Credit Card' },
      { value: 'debit', text: 'Debit Card' },
      { value: 'wire', text: 'Bank Transfer' },
    ]},model:{value:(_vm.methodName),callback:function ($$v) {_vm.methodName=$$v},expression:"methodName"}}):_vm._e(),(_vm.paymentType === 'track')?_c('v-textarea',{attrs:{"outlined":"","rules":[_vm.required],"dense":"","label":"Note"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('v-file-input',{attrs:{"accept":"image/*,.pdf","label":"Attach Files (Images or PDFs)","multiple":"","prepend-icon":"mdi-paperclip","outlined":"","dense":"","show-size":true},on:{"change":_vm.previewFiles},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}}),(_vm.filePreviewUrls.length)?_c('div',{staticClass:"d-flex flex-wrap gap-2 mt-2"},[_vm._l((_vm.filePreviewUrls),function(preview,index){return [(preview.type === 'image')?_c('v-img',{key:index,staticClass:"ma-1",attrs:{"src":preview.url,"max-width":"100","max-height":"100"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"text-center image-preview"},[_c('div',{staticClass:"text-caption text-truncate px-2 attachment-name",attrs:{"title":preview.name}},[_vm._v(" "+_vm._s(_vm.truncateFilename(preview.name))+" ")])])]):_c('v-sheet',{key:index,staticClass:"ma-1 d-flex flex-column align-center justify-center pdf-preview",attrs:{"width":"100","height":"100","color":"grey lighten-2","rounded":""}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"large":"","color":"grey darken-2"}},[_vm._v("mdi-file-pdf-box")]),_c('div',{staticClass:"text-caption text-truncate px-2 attachment-name",attrs:{"title":preview.name}},[_vm._v(" "+_vm._s(_vm.truncateFilename(preview.name))+" ")])],1)])]})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }