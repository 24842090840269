<template>
  <div class="mobile-tasks">
    <v-card
      v-for="item in items"
      :key="item.id"
      class="task-card mb-3"
      elevation="2"
      rounded="lg"
    >
      <div class="task-header pa-3">
        <div class="d-flex align-center mb-2">
          <div class="status-priority-container d-flex align-center">
            <lt-status
              :small="true"
              :listing-task="item"
              class="ml-4 status-field"
              @after-change="$emit('lt-after-change')"
              @change="$emit('lt-status-update', { id: item.id, ...$event })"
            />
            <span class="mr-3"></span>
            <lt-priority
              :value="item.priority"
              class="priority-field mr-3"
              @input="onFieldChange(item, 'priority', $event)"
            />
          </div>
          <v-spacer />
          <v-btn color="info" icon small @click="$emit('show-feed', item)">
            <v-icon
              v-if="
                item.internal_comments ||
                (item.listing_task_comments &&
                  item.listing_task_comments.length)
              "
              small
            >
              mdi-comment-text
            </v-icon>
            <v-icon v-else small color="info lighten-2">
              mdi-comment-text-outline
            </v-icon>
          </v-btn>
        </div>
        <taskim-text-field
          v-model="item.description"
          :disabled="Boolean(projectId)"
          class="task-description"
          @change="onFieldChange(item, 'description', $event)"
        />
      </div>

      <v-divider />

      <div class="task-footer pa-3">
        <div class="d-flex align-center">
          <div class="mr-auto">
            <assignee-select
              :key="item.assigned_contractor_id"
              :users="activeUsers"
              :value="usersMap[item.assigned_contractor_id]"
              dense
              class="assignee-select"
              @change="onFieldChange(item, 'assigned_contractor_id', $event.id)"
            />
          </div>
          <div>
            <listing-picker
              :value="item.listing_id"
              dense
              class="listing-picker"
              @input="onFieldChange(item, 'listing_id', $event)"
            />
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import TaskimTextField from 'components/taskim/taskim-text-field.vue'
import LtPriority from 'components/listing-tasks/lt-priority.vue'
import AssigneeSelect from 'components/common/assignee-select.vue'
import ListingPicker from 'components/listing/listing-picker.vue'
import LtStatus from 'components/listing-tasks/lt-status.vue'

export default {
  name: 'TaskimSectionMobile',
  components: {
    TaskimTextField,
    LtPriority,
    AssigneeSelect,
    ListingPicker,
    LtStatus,
  },
  props: ['items', 'projectId', 'activeUsers', 'usersMap'],
  methods: {
    onFieldChange(item, field, value) {
      this.$emit('listingTaskFieldChange', item, field, value)
    },
  },
}
</script>

<style scoped>
.mobile-tasks {
  padding: 12px;
  background: #f5f7fa;
}

.task-card {
  border-radius: 16px !important;
  background: white;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.task-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.task-header {
  background: white;
  border-radius: 16px 16px 0 0;
}

.task-footer {
  background: #fafbfc;
  border-radius: 0 0 16px 16px;
}

.task-description {
  font-size: 1rem;
}

.status-priority-container {
  padding: 4px;
}

:deep(.status-field),
:deep(.priority-field) {
  transform: scale(0.9);
  transform-origin: left center;
}

:deep(.v-text-field.v-text-field--enclosed .v-text-field__details),
:deep(.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot) {
  padding: 0;
  margin: 0;
}

:deep(.v-input__slot) {
  min-height: unset !important;
}

:deep(.v-text-field input) {
  padding: 4px 0 4px 8px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

:deep(.assignee-select),
:deep(.listing-picker) {
  max-width: 160px;
}

:deep(.v-input--dense .v-input__slot) {
  min-height: 32px !important;
}

:deep(.v-text-field--outlined.v-input--dense .v-label) {
  top: 8px !important;
}

:deep(.v-text-field--outlined.v-input--dense .v-label--active) {
  transform: translateY(-16px) scale(0.75) !important;
}
</style>
