<template>
  <section class="contractor-payments secondary lighten-3 pa-4">
    <v-progress-linear
      v-if="listingLoading"
      color="secondary"
      style="margin: 0"
      :indeterminate="true"
    ></v-progress-linear>
    <div :class="{ 'overlay-custom': listingLoading }">
      <v-card class="mb-2 pa-4" elevation="0">
        <div class="d-flex align-center">
          <user-avatar
            :image="details.picture"
            :name="details.name"
            size="65"
          />
          <div class="ml-3">
            <h4>{{ details.name }}</h4>
            <h5 v-if="details.ach_recipient_name">
              {{ details.ach_recipient_name }}
            </h5>
          </div>
        </div>
      </v-card>
      <v-card class="mb-2 px-4" elevation="0">
        <v-row justify="space-between">
          <v-col cols="12" sm="auto">
            <contractor-payments-filter />
          </v-col>
          <v-col cols="12" sm="auto">
            <contractor-payments-date-range
              :contractor-id="details.id"
              @change="getStats()"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="0">
        <v-card-title class="pb-0">
          <v-tabs v-model="tab">
            <v-tab
              v-for="item in tabs"
              :key="`tab-${item.name}`"
              :href="`#${item.value}`"
            >
              <span class="text-capitalize">
                {{ item.name }}
              </span>
            </v-tab>
          </v-tabs>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <p class="text-h6 black--text">
            {{ parseDate(dateRange.from, 'MMM DD') }} -
            {{ parseDate(dateRange.to, 'MMM DD') }}
          </p>
          <contractor-listing-task-details-summary
            :layout="tab"
            :hours="totalHours"
            :charge="totalCharge"
            :deposit="totalDeposit"
          />
        </v-card-text>
        <v-divider />
        <v-card-text>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              v-for="item in tabs"
              :key="`tab-content-${item.value}`"
              :value="item.value"
            >
              <contractor-listing-task-items
                :layout="item.value"
                :all-selected="allSelected"
                :have-ach-account="
                  config.is_natcha_support ? details.have_ach_account : true
                "
                :name="details.name"
                :dates="dateRange"
                :list="tasksStackedList"
                :selected-tasks="selectedTasks"
                :expand="expand"
                @select-all="selectHandler"
                @update-selected="updateSelected"
                @on-expand="handleExpand"
                @open-payments-actions="paymentActions"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import CommonFunctions from 'components/mixins/common_functions'
import ContractorPayments from './mixins/contractor-payments-mixin'
import UserAvatar from 'components/common/user-avatar'
import ContractorListingTaskDetailsSummary from 'components/contractor-listing-task-details-summary'
import ContractorListingTaskItems from 'components/contractor-listing-task-items'
import ContractorPaymentsFilter from 'components/contractor-payments-filter'
import ContractorPaymentsDateRange from 'components/contractor-payments-date-range'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ContractorListingTaskDetails',
  components: {
    ContractorPaymentsDateRange,
    ContractorPaymentsFilter,
    ContractorListingTaskItems,
    ContractorListingTaskDetailsSummary,
    UserAvatar,
  },
  mixins: [CommonFunctions, ContractorPayments, FormattersMixin],
  data: () => ({
    tab: 'unpaid',
    tabs: [
      { name: 'Unpaid', value: 'unpaid' },
      { name: 'Processing', value: 'processing' },
      { name: 'Paid', value: 'paid' },
    ],
    expand: {},
    tasksStackedList: {},
  }),
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapState({
      details: state => state.contractorPaymentsDetails,
      selectedTasks: state => state.ltdSelectedIDS,
      dateRange: state => state.contractorPaymentsDateRange,
      paymentsType: state => state.contractorPaymentsType,
    }),
    tasks() {
      if (!isEmpty(this.details.done_tasks)) {
        return filter(this.details.done_tasks, task => {
          if (this.tab === 'paid') {
            return task.paid
          } else if (this.tab === 'processing') {
            return !task.paid && task.ach_transaction_id
          } else {
            return !task.paid && !task.ach_transaction_id
          }
        })
      }
      return []
    },
    totalHours() {
      return this.calcTotalHours(this.tasks, this.paymentsType)
    },
    totalCharge() {
      return this.calcTotalCharge(this.tasks, this.paymentsType)
    },
    totalDeposit() {
      return this.calcTotalDeposit(this.tasks, this.paymentsType)
    },
    allSelected() {
      return (
        this.contractorPayments(this.tasks, this.paymentsType).length ===
        this.selectedTasks.size
      )
    },
    config() {
      return this.$store.state.app.configuration
    },
    markAsProcessingSum() {
      return this.tasks.reduce((total, item) => {
        if (this.selectedTasks.has(item.id)) {
          total += item.payable_amount - Number(item.deposit)
        }
        return total
      }, 0)
    },
  },
  watch: {
    tab() {
      this.stackContractorTasks(
        this.contractorPayments(this.tasks, this.paymentsType)
      )
    },
    details() {
      this.expand = {}
      this.updateLtdSelectedIDS(new Set())
      this.stackContractorTasks(
        this.contractorPayments(this.tasks, this.paymentsType)
      )
    },
    paymentsType() {
      this.expand = {}
      this.updateLtdSelectedIDS(new Set())
      this.stackContractorTasks(
        this.contractorPayments(this.tasks, this.paymentsType)
      )
    },
  },
  methods: {
    ...mapMutations(['updateLtdSelectedIDS']),
    ...mapActions([
      'getListingTaskPaymentsStats',
      'getContractorPaymentsDetails',
    ]),
    getStats() {
      this.getContractorPaymentsDetails({
        id: this.details.id || this.currentUserId,
        from: this.dateRange.from,
        to: this.dateRange.to,
      })
      this.getListingTaskPaymentsStats({
        id: this.details.id || this.currentUserId,
        from: this.dateRange.from,
        to: this.dateRange.to,
      })
    },
    handleExpand(index) {
      this.$set(this.expand, index, !this.expand[index])
    },
    updateSelected(id) {
      const selected = new Set(this.selectedTasks)
      if (selected.has(id)) {
        selected.delete(id)
      } else {
        selected.add(id)
      }
      this.updateLtdSelectedIDS(selected)
    },
    selectAll(tasks) {
      this.updateLtdSelectedIDS(new Set())
      const selected = tasks.map(t => t.id)
      this.updateLtdSelectedIDS(new Set(selected))
    },
    selectHandler() {
      if (
        this.contractorPayments(this.tasks, this.paymentsType).length ===
        this.selectedTasks.size
      ) {
        this.updateLtdSelectedIDS(new Set())
      } else {
        this.selectAll(this.contractorPayments(this.tasks, this.paymentsType))
      }
    },
    stackContractorTasks(tasks) {
      this.tasksStackedList = {}
      tasks.forEach(task => {
        const listingName = this.taskListingNickname(task, true)
        if (!this.tasksStackedList[listingName]) {
          this.$set(this.tasksStackedList, listingName, [])
        }
        this.tasksStackedList[listingName].push(task)
      })
    },
    paymentActions() {
      this.$store.commit('showModal', {
        name: 'ContractorsPaymentsModal',
        props: {
          contractor: this.details,
          natchaSupport: this.config.is_natcha_support,
          dateRange: this.dateRange,
          selectedIds: this.selectedTasks,
          totalAmount: this.toMoney(this.markAsProcessingSum),
          afterAction: () => {
            this.getStats()
            this.$emit('marked-as-processing', this.details.id)
          },
        },
      })
    },
  },
}
</script>
