<template>
  <v-card>
    <conversation-info-title title="Payment" />
    <v-divider />
    <v-card-text class="pa-4">
      <!-- Balance Card -->
      <v-card outlined class="mb-6">
        <v-card-text class="pa-4">
          <div class="d-flex align-center justify-space-between flex-wrap">
            <div class="d-flex flex-column">
              <span class="text-caption text-secondary mb-1">Balance</span>
              <span class="text-h6 font-weight-bold">{{
                toMoney(reservation.balance_due)
              }}</span>
            </div>
            <div class="d-flex align-center">
              <tag
                v-if="
                  (reservation.source == 'Airbnb' ||
                    reservation.source == 'Booking.com') &&
                  !reservation.is_merchat
                "
                color="primary"
                :inverted="true"
                size="sm"
                class="mr-2"
              >
                Payment program
              </tag>
              <add-invoice-modal
                v-if="isBoomRes(reservation)"
                :reservation="reservation"
                @add-invoice="addInvoice"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>

      <!-- Invoice Items -->
      <div v-if="reservation.invoice_items.length" class="mb-6">
        <div class="text-subtitle-2 font-weight-medium mb-3">Invoice Items</div>
        <v-card outlined>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left font-weight-medium">Description</th>
                  <th class="text-right font-weight-medium">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in reservation.invoice_items" :key="item.id">
                  <td>{{ item.title }}</td>
                  <td class="text-right">{{ toMoney(item.amount) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>

      <!-- Payment Items -->
      <div v-if="reservation.payment_items.length" class="mb-6">
        <div class="text-subtitle-2 font-weight-medium mb-3">Payment Items</div>
        <v-card outlined>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left font-weight-medium">Description</th>
                  <th class="text-left font-weight-medium">Type</th>
                  <th class="text-right font-weight-medium">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in reservation.payment_items" :key="item.id">
                  <td>{{ item.is_auth ? 'Auth' : 'Payment' }}</td>
                  <td>{{ item.status.replace('AUTHORIZATION_HOLD_', '') }}</td>
                  <td class="text-right">{{ toMoney(item.amount) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>

      <!-- Payment Intents -->
      <div v-if="reservation.payment_intents.length" class="mb-6">
        <div class="text-subtitle-2 font-weight-medium mb-3">
          Payment Attempts
        </div>
        <reservation-intents-table
          :reservation="reservation"
          :get-reservation="refreshReservation"
        />
      </div>

      <!-- Disputes -->
      <div v-if="disputes.length" class="mb-6">
        <div class="text-subtitle-2 font-weight-medium mb-3">Disputes</div>
        <v-card outlined>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-right font-weight-medium">Amount</th>
                  <th class="text-left font-weight-medium">Status</th>
                  <th class="text-center font-weight-medium">Has Evidence</th>
                  <th class="text-left font-weight-medium">Created</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in disputes" :key="item.id">
                  <td class="text-right">{{ toMoney(item.amount / 100) }}</td>
                  <td>
                    <v-chip
                      x-small
                      :color="
                        item.status === 'won'
                          ? 'success'
                          : item.status === 'lost'
                          ? 'error'
                          : 'warning'
                      "
                      text-color="white"
                      class="font-weight-medium"
                    >
                      {{ item.status }}
                    </v-chip>
                  </td>
                  <td class="text-center">
                    <v-icon
                      v-if="item.evidence_details.has_evidence"
                      color="success"
                      small
                    >
                      mdi-check
                    </v-icon>
                    <v-icon v-else color="error" small>mdi-close</v-icon>
                  </td>
                  <td>{{ item.created | moment('DD/MM/YYYY HH:mm') }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>

      <!-- Refunds -->
      <div v-if="refunds.length" class="mb-6">
        <div class="text-subtitle-2 font-weight-medium mb-3">Refunds</div>
        <v-card outlined>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-right font-weight-medium">Amount</th>
                  <th class="text-left font-weight-medium">Done By</th>
                  <th class="text-left font-weight-medium">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in refunds" :key="item.id">
                  <td class="text-right">{{ toMoney(item.amount / 100) }}</td>
                  <td>{{ item.metadata?.done_by }}</td>
                  <td>{{ item.created | moment('DD/MM/YYYY') }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import get from 'lodash/fp/get'
import Tag from 'components/common/tag.vue'
import AddInvoiceModal from 'components/reservation/add-invoice-modal'
import ReservationIntentsTable from 'components/reservation/reservation-intents-table'

export default {
  components: {
    ConversationInfoTitle,
    Tag,
    AddInvoiceModal,
    ReservationIntentsTable,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['reservation', 'conversationId'],
  data() {
    return {
      copied: {},
      intentError: '',
    }
  },
  computed: {
    guestEmail() {
      return get('emails.0', this.guest)
    },
    isMerchantInquiry() {
      return (
        this.reservation.status === 'inquiry' && this.reservation.is_merchant
      )
    },
    portalDomain() {
      return 'https://guest.boomnow.com'
    },
    refunds() {
      return this.getReservationRefunds(this.reservation.payment_intents)
    },
    disputes() {
      return this.getReservationDisputes(this.reservation.payment_intents)
    },
  },
  methods: {
    isRefundable(intent) {
      const { amount_captured, amount_refunded } = intent.charges.data[0]
      return amount_refunded < amount_captured
    },
    onCopy(el) {
      this.$copyText(`${this.portalDomain}/checkout/${el}`)
    },
    onChargeSuccess() {
      this.$store.dispatch('getChatConversation', this.conversationId)
    },
    createIntent() {
      this.$store
        .dispatch('createReservationIntent', {
          reservationId: this.reservation.id,
        })
        .then(({ data }) => {
          if (data.message) {
            this.intentError = data.message
          }
        })
    },
    isBoomRes(reservation) {
      return (
        reservation.source === 'Designedvr.com' ||
        reservation.source === 'Website'
      )
    },
    addInvoice(invoice) {
      this.$store.dispatch('reservation/addInvoice', {
        reservation_id: this.reservation.reservation_guesty_id,
        ...invoice,
      })
      this.$emit('refresh')
    },
    refreshReservation() {
      this.$emit('refresh')
    },
  },
}
</script>

<style scoped>
.balance-card {
  background-color: #f6f6f7;
  border-radius: 10px;
}

.title-black {
  color: var(--v-secondary-darken4);
}

:deep(.v-data-table) {
  background-color: transparent !important;
}

:deep(.v-data-table-header) th {
  font-size: 0.8125rem !important;
  color: var(--v-secondary-darken2) !important;
  font-weight: 500 !important;
  text-transform: none;
  letter-spacing: 0;
  white-space: nowrap;
}

:deep(.v-data-table) td {
  font-size: 0.875rem !important;
  color: var(--v-secondary-darken4);
  height: 48px;
}

:deep(.v-btn) {
  text-transform: none;
  letter-spacing: 0;
}

:deep(.v-chip) {
  font-size: 0.75rem;
}

:deep(.v-card) {
  box-shadow: none !important;
}

:deep(.v-card--outlined) {
  border-color: var(--v-secondary-lighten3) !important;
}
</style>
