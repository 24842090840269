<template>
  <v-card
    class="pa-4 overflow-y-auto"
    :height="formData.units === 'dynamic' ? 700 : 'auto'"
    style="border-radius: 10px"
  >
    <div class="d-flex pa-4">
      <div
        class="flex text-center justify-center font-weight-medium text-subtitle-1"
      >
        {{ title }}
      </div>
    </div>
    <v-form ref="form">
      <v-text-field
        v-model.trim="formData.name"
        dense
        outlined
        :rules="[required]"
        :label="$t('Type')"
      />
      <v-combobox
        v-model="formData.category"
        :items="categories"
        dense
        outlined
        :rules="[required]"
        :label="$t('Category')"
      />
      <v-select
        v-if="!isEditMode"
        v-model="formData.expense_role"
        label="Expense role"
        outlined
        :rules="[required]"
        :items="expenseRoles"
        item-text="name"
        item-value="value"
        dense
      />
      <v-select
        v-model="formData.units"
        outlined
        dense
        :items="expenseUnits"
        label="Unit"
        :rules="unitsRules"
      />
      <v-text-field
        v-if="formData.units !== 'dynamic'"
        v-model="formData.amount"
        type="number"
        outlined
        dense
        :label="$t('Amount')"
        required
      />
      <enhanced-formula-builder
        v-else
        v-model="formData.quantifier"
        label="Quantifier Formula"
        :variables="formulaVariables"
        :rules="[required]"
      />
      <filters-select
        :value.sync="formData.channels"
        label="Channels"
        :items="channelsItems"
        :selection-func="item => item.text"
        :messages="['Leave empty to apply to all channels']"
      />
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="onClose">Cancel</v-btn>
        <v-btn color="primary" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import EnhancedFormulaBuilder from 'components/common/enhanced-formula-builder.vue'
import { formulaVariables } from '@/consts'
import FiltersSelect from 'components/form-fields/filters-select'

export default {
  name: 'ExpenseTypeModal',
  components: { EnhancedFormulaBuilder, FiltersSelect },
  mixins: [FormRulesMixin, FormattersMixin],
  props: [
    'categories',
    'expenseRoles',
    'afterSave',
    'expenseType',
    'businessModelId',
  ],
  data: function () {
    return {
      formData: this.expenseType || {},
    }
  },
  computed: {
    ...mapGetters('reservation', ['channelsItems']),
    title() {
      return this.isEditMode
        ? this.$t('Edit Expense Type')
        : this.$t('New Expense Type')
    },
    isEditMode() {
      return !!this.expenseType
    },
    formulaVariables() {
      return formulaVariables
    },
    expenseUnits() {
      return [
        { text: `Fixed (${this.currencySign})`, value: 'fixed' },
        { text: 'Dynamic', value: 'dynamic' },
      ]
    },
    unitsRules() {
      return [
        v => {
          if (this.formData.amount !== '' && this.formData.amount !== null) {
            return v ? true : 'Unit is required when amount is specified'
          }
          return true
        },
      ]
    },
  },
  methods: {
    ...mapActions('expenseTypes', [
      'createNewExpenseType',
      'updateExpenseType',
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        const action = this.isEditMode
          ? 'updateExpenseType'
          : 'createNewExpenseType'
        this[action](
          Object.assign(this.formData, {
            business_model_id: this.businessModelId,
          })
        ).then(() => {
          this.afterSave()
          this.onClose()
        })
      }
    },
    onClose() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
