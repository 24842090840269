<template>
  <v-container fluid>
    <v-alert type="info" text dense class="mb-4 font-weight-medium text-body-2">
      {{ explanationMessage }}
    </v-alert>

    <v-card
      v-if="!isMobile"
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg"
    >
      <v-row>
        <v-col v-for="(value, key) in metaData" :key="key" cols="2">
          <div>
            <div class="font-weight-semibold dark600--text">
              {{ formatKey(key) }}
            </div>
            <div class="border-left pl-4 text-h5">
              {{ formatValue(key, value) }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-row align="center">
      <v-col cols="12" sm="auto">
        <v-menu v-model="menu" transition="scale-transition" offset-y>
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              :value="date"
              :label="$t('Month')"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="datePicker"
            v-model="date"
            :max="$moment.utc().endOf('month').toISOString()"
            type="month"
            no-title
            reactive
            @change="dateChanged"
            @click:year="dateChanged"
          />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="filters.search"
          outlined
          clearable
          dense
          prepend-inner-icon="mdi-magnify"
          label="Search"
          hide-details
          @keyup="fetchDataDebounce(1)"
        />
      </v-col>
    </v-row>

    <v-row class="d-flex">
      <v-btn
        v-if="managementRoles && hasAbility('listings-taxes-payments')"
        class="text-capitalize ml-3"
        color="secondary"
        elevation="0"
        @click="exportToExcel"
      >
        Export To Excel
      </v-btn>
    </v-row>

    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      />
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/axios/config'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce } from 'lodash'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'TaxesPayments',
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin, DeviceMixin],
  data: function () {
    return {
      menu: false,
      date: this.$moment.utc().format('YYYY-MM'),
      dateType: 'monthly',
      filters: {
        type: 'All',
        search: '',
        from: '',
        to: '',
      },
      metaData: {
        total_taxes: 0,
        reservations_count: 0,
      },
      tableHeaders: [],
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
      idsInAction: new Set(),
    }
  },
  computed: {
    explanationMessage() {
      return 'Reservations are included if their check-in date fall within the selected month'
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page

      this.from = this.$moment(this.date).startOf('month').format('YYYY-MM-DD')
      this.to = this.$moment(this.date).endOf('month').format('YYYY-MM-DD')

      axios
        .get('/api/taxes-payments', {
          params: {
            page: this.pagination.page,
            per_page: this.pagination.itemsPerPage,
            search: this.filters.search,
            from: this.from,
            to: this.to,
          },
        })
        .then(res => {
          this.items = res.data.reservations.map(item => {
            const formattedItem = { ...item }
            const remainingHeaders = Object.keys(item).filter(
              key =>
                ![
                  'listing_nickname',
                  'id',
                  'listing_id',
                  'total_nights',
                  'reservations_count',
                ].includes(key)
            )
            remainingHeaders.forEach(key => {
              if (formattedItem[key]) {
                formattedItem[key] = this.toMoney(formattedItem[key])
              }
            })
            return formattedItem
          })

          if (this.items.length > 0) {
            const prioritizedHeaders = [
              'listing_nickname',
              'reservations_count',
              'total_nights',
              'total_taxes',
            ]
            const filteredHeaders = new Set(['id', 'listing_id'])

            this.tableHeaders = prioritizedHeaders
              .map(key => {
                if (
                  Object.prototype.hasOwnProperty.call(this.items[0], key) &&
                  !filteredHeaders.has(key)
                ) {
                  return {
                    text: this.formatHeaderText(key),
                    value: key,
                    sortable: false,
                  }
                }
              })
              .filter(header => header !== undefined)

            const remainingHeaders = Object.keys(this.items[0]).filter(
              key =>
                !prioritizedHeaders.includes(key) && !filteredHeaders.has(key)
            )

            this.tableHeaders = this.tableHeaders.concat(
              remainingHeaders.map(key => {
                return {
                  text: this.formatHeaderText(key),
                  value: key,
                  sortable: false,
                }
              })
            )
          }

          this.metaData = res.data.meta

          this.pagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    formatHeaderText(key) {
      return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    exportToExcel() {
      this.from = this.$moment(this.date).startOf('month').format('YYYY-MM-DD')
      this.to = this.$moment(this.date).endOf('month').format('YYYY-MM-DD')

      axios
        .get('/api/taxes-payments/export', {
          params: {
            search: this.filters.search,
            from: this.from,
            to: this.to,
          },
          responseType: 'blob',
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'taxes_payments_export.xlsx')
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          console.error('Error exporting to Excel:', error)
        })
    },
    dateChanged(val) {
      this.date = val.toString()
      this.fetchData(1)
    },
    formatKey(key) {
      return key.replace('_', ' ').toUpperCase()
    },
    formatValue(key, value) {
      return ['total_taxes', 'average_tax'].includes(key)
        ? this.toMoney(value)
        : value || '-'
    },
  },
}
</script>

<style scoped></style>
