<template>
  <v-card :loading="listingLoading" elevation="0">
    <v-card-title class="grey-text">
      <requests
        v-if="requestId"
        :related-request="returnRequestFrom"
        @state="requestsDialogStateChanged"
      />
      <v-row v-else>
        <v-col cols="12" md="auto">
          <v-sheet color="grey lighten-4" class="rounded-pill">
            <v-chip-group
              v-model="status"
              class="pl-2"
              multiple
              center-active
              @change="statusChanged(status)"
            >
              <v-chip
                v-for="item in statuses"
                :key="item"
                :value="item"
                :color="
                  status.includes(item) ? 'secondary darken-1' : 'transparent'
                "
                class="flex-grow-1 justify-center"
                small
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="auto">
          <v-sheet color="grey lighten-4" class="rounded-pill">
            <v-chip-group
              v-model="actionType"
              class="pl-2"
              multiple
              center-active
              @change="actionTypeChanged($event)"
            >
              <v-chip
                v-for="item in actionTypes"
                :key="item"
                :value="item"
                :color="
                  actionType.includes(item)
                    ? 'secondary darken-1'
                    : 'transparent'
                "
                class="flex-grow-1 justify-center"
                small
              >
                {{ item }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="auto" class="pb-0">
          <listing-select
            :clearable="true"
            :filter-change="listingChanged"
            item-text="nickname"
          />
        </v-col>
        <v-col cols="12" sm="6" md="auto">
          <v-text-field
            v-model="searchTerm"
            type="search"
            label="Search..."
            prepend-inner-icon="search"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="auto">
          <v-sheet class="d-flex align-center px-2 py-1" outlined rounded>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template #activator="{ on }">
                <div class="d-inline-flex align-center pointer" v-on="on">
                  <v-icon color="accent" small>$calendar_fill</v-icon>
                  <span class="secondary--text ml-2">
                    {{ parsedFrom || 'From' }}
                  </span>
                </div>
              </template>
              <v-date-picker
                ref="picker"
                v-model="from"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                min="2018-01-01"
                @change="saveFrom"
              />
            </v-menu>
            <span class="mx-4">&ndash;</span>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              required
              min-width="290px"
            >
              <template #activator="{ on }">
                <div class="d-inline-flex align-center pointer" v-on="on">
                  <v-icon color="accent" small>$calendar_fill</v-icon>
                  <span class="secondary--text ml-2">
                    {{ parsedTo || 'To' }}
                  </span>
                </div>
              </template>
              <v-date-picker
                ref="picker"
                v-model="to"
                :min="new Date(from).toISOString().substr(0, 10)"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                @change="saveTo"
              />
            </v-menu>
          </v-sheet>
        </v-col>
        <v-col class="text-center text-sm-right text-md-left text-xl-right">
          <requests
            :related-request="returnRequestFrom"
            @state="requestsDialogStateChanged"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredByAddress"
      :options.sync="pagination"
      :server-items-length="pagination.itemsLength"
      :hide-default-header="isMobile"
      @pagination="paginationChanged($event)"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <div class="d-flex align-center">
              <div class="d-inline-flex align-center">
                <span v-if="item.private_req" style="font-size: 20px">⭐</span>
                <v-chip class="pa-1" color="grey lighten-4" label small>
                  <v-icon small>
                    {{
                      item.storage_action_type === 'Add'
                        ? 'mdi-plus'
                        : item.storage_action_type === 'Return'
                        ? 'mdi-history'
                        : item.storage_action_type === 'Rent'
                        ? 'mdi-hand-extended-outline'
                        : item.storage_action_type === 'Move'
                        ? 'mdi-swap-horizontal'
                        : 'mdi-minus'
                    }}
                  </v-icon>
                </v-chip>
                <v-icon class="ml-2" small>
                  {{ requestIcon(item.type) }}
                </v-icon>
                <div class="d-lg-none ml-2">
                  <v-chip
                    :color="storageRequestStatusColor(item.status).bg"
                    label
                    x-small
                  >
                    <span
                      :class="[
                        storageRequestStatusColor(item.status).text,
                        'text-uppercase',
                      ]"
                    >
                      {{ item.status }}
                    </span>
                  </v-chip>
                </div>
              </div>
              <span v-if="storageAddRequest(item)" class="text-no-wrap ml-2">
                {{ storageNicknameById(item.storage_id) }}
              </span>
              <span
                v-else-if="storageMoveRequest(item)"
                class="text-no-wrap ml-2"
              >
                {{ storageNicknameById(item.storage_id) }} →
                {{ storageNicknameById(item.destination_storage_id) }}
              </span>
              <router-link
                v-else
                class="text-no-wrap ml-2"
                :to="'/dashboard/edit/' + item.listing_id"
              >
                {{ item.listing_nickname }}
              </router-link>
              <v-tooltip v-if="item.is_setup" bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="ml-2"
                    small
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-wrench</v-icon
                  >
                </template>
                <span>Listing in setup</span>
              </v-tooltip>
              <v-tooltip v-if="item.note" bottom>
                <template #activator="{ on }">
                  <v-icon class="ml-2 yellow--text text--darken-1" v-on="on"
                    >mdi-note</v-icon
                  >
                </template>
                <span>{{ item.note }}</span>
              </v-tooltip>
              <span v-if="isRentRequestHasReturned(item)" class="ml-3">
                <v-chip color="success" label x-small>Returned</v-chip>
              </span>
              <span v-if="item.unit_number" class="ml-2">
                Unit: {{ item.unit_number }}
              </span>
            </div>
            <div class="d-md-none text-caption">
              <span class="secondary--text">Cost:</span>
              <span class="font-weight-medium">
                {{ toMoney(requestTotalSum(item.requests_inventory_items)) }}
              </span>
            </div>
            <div class="d-lg-none text-caption secondary--text">
              <span>Created: {{ parseDate(item.created_at) }}</span>
            </div>
            <div class="d-lg-none text-caption secondary--text">
              by {{ item.created_by }}
            </div>
            <div class="d-md-none text-caption secondary--text">
              <span class="text-caption">
                {{
                  item.storage_action_type === 'Add'
                    ? 'Arrival at:'
                    : item.storage_action_type === 'Return'
                    ? 'Returned at:'
                    : item.storage_action_type === 'Rent'
                    ? 'Rented at:'
                    : item.storage_action_type === 'Move'
                    ? 'Moved at:'
                    : 'Picked at:'
                }}
              </span>
              {{ parseDate(item.done_until) }}
            </div>
          </td>
          <td class="d-none d-lg-table-cell">
            <div class="font-weight-medium">
              {{ parseDate(item.created_at) }}
            </div>
            <div v-if="item.created_by" class="text-caption">
              By {{ item.created_by }}
            </div>
          </td>
          <td class="d-none d-md-table-cell">
            <span class="font-weight-medium">
              {{ toMoney(requestTotalSum(item.requests_inventory_items)) }}
            </span>
          </td>
          <td class="d-none d-md-table-cell">
            <span
              :class="[
                item.storage_action_type === 'Add'
                  ? 'info--text'
                  : 'warning--text',
                'text-caption',
              ]"
            >
              {{
                item.storage_action_type === 'Add'
                  ? 'Arrival at:'
                  : item.storage_action_type === 'Return'
                  ? 'Returned at:'
                  : item.storage_action_type === 'Rent'
                  ? 'Rented at:'
                  : item.storage_action_type === 'Move'
                  ? 'Moved at:'
                  : 'Picked at:'
              }}
            </span>
            <span class="font-weight-medium text-no-wrap">
              {{ parseDate(item.done_until) }}
            </span>
          </td>
          <td class="d-none d-sm-table-cell">
            <v-btn
              class="d-flex justify-space-between"
              :width="isIPad ? 120 : 160"
              elevation="0"
              :small="isIPad"
              @click="toggleRequestListDialog(item)"
            >
              <span class="text-capitalize">
                Items: {{ item.requests_inventory_items.length }}
              </span>
              <div>
                <v-tooltip v-if="hasWarnings" bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon color="warning" v-bind="attrs" small v-on="on">
                      mdi-alert
                    </v-icon>
                  </template>
                  <span>The request has warnings</span>
                </v-tooltip>
                <v-tooltip bottom min-width="240">
                  <template #activator="{ on, attrs }">
                    <v-icon color="secondary" v-bind="attrs" small v-on="on">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <div v-if="item.description">
                    <p>Description:</p>
                    <div>{{ item.description }}</div>
                  </div>
                  <div v-if="item.log" class="mb-2 pa-2">
                    <div v-for="(note, key) in item.log" :key="key">
                      <div class="d-flex justify-space-between">
                        <span>{{ parseDate(key) }}</span>
                        <span class="ml-4">{{ note.user }}</span>
                      </div>
                      <div>{{ note.msg }}</div>
                      <v-divider class="mt-1" />
                    </div>
                  </div>
                </v-tooltip>
              </div>
            </v-btn>
          </td>
          <td class="d-none d-lg-table-cell">
            <v-chip
              :color="storageRequestStatusColor(item.status).bg"
              label
              small
            >
              <span
                :class="[
                  storageRequestStatusColor(item.status).text,
                  'text-uppercase',
                ]"
              >
                {{ item.status }}
              </span>
            </v-chip>
            <div
              v-if="item.status === 'Done' && item.approved_by_id"
              class="text-caption"
            >
              Approved by: {{ nameById(item.approved_by_id) }}
            </div>
          </td>
          <td class="text-center pa-0">
            <gallery
              v-if="!isEmpty(item.images)"
              :images="item.images"
              :remove-img="onImageRemove.bind(null, item)"
              icon="mdi-image"
            />
            <v-btn v-else icon @click="openFileUploadModal(item.id)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
          <td>
            <div class="d-flex justify-space-between align-center">
              <div
                v-if="
                  !hasMedia(item) && !(isAdmin || hasAbility('super-storage'))
                "
                class="d-inline-flex align-center"
              >
                <v-icon color="orange" small>mdi-alert-circle</v-icon>
                <span class="d-none d-sm-inline text-caption orange--text ml-1">
                  Upload images/video
                </span>
              </div>
              <v-spacer />
              <div v-if="canMarkAsConfirmed(item)">
                <confirmation-modal
                  :text="
                    isExpensiveRequest(item.requests_inventory_items)
                      ? `<b>This request has HIGH cost: ${toMoney(
                          requestTotalSum(item.requests_inventory_items)
                        )}</b>.<br>Are you sure you want to approve this request that means it has been fully taken?`
                      : 'Are you sure you want to approve this request that means it has been fully taken?'
                  "
                  @action="markAsConfirmed(item)"
                >
                  <template #activator="slotData">
                    <v-btn
                      color="info"
                      class="text-capitalize me-2"
                      :disabled="!(isAdmin || hasAbility('super-storage'))"
                      elevation="0"
                      small
                      v-on="slotData.on"
                      >Mark as {{ getActionText(item) }}
                    </v-btn>
                  </template>
                </confirmation-modal>
              </div>
              <v-btn
                :color="hasWarnings ? 'warning' : 'secondary'"
                class="d-sm-none"
                icon
                small
                @click="toggleRequestListDialog(item)"
              >
                <v-tooltip bottom :disabled="!hasWarnings">
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on">
                      {{ hasWarnings ? 'mdi-eye-remove' : 'mdi-eye' }}
                    </v-icon>
                  </template>
                  <span class="text-caption">The request has warnings</span>
                </v-tooltip>
              </v-btn>
              <v-btn
                v-if="isAdmin || isPropertyManager"
                icon
                small
                :disabled="item.status === 'Done'"
                @click="deleteRequest(item)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  item.status === 'Done' &&
                  ['Take', 'Rent'].includes(item.storage_action_type)
                "
                icon
                small
                @click="createReturnRequestFrom(item)"
              >
                <v-icon small>restore</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="isFilesUpload" max-width="600">
      <v-card>
        <v-card-title>Upload media</v-card-title>
        <v-card-text>
          <dvr-media-upload :value.sync="media" folder="inventory" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="text-capitalize"
            text
            @click="closeFileUploadModal"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            elevation="0"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isRequestListDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keyup.esc="toggleRequestListDialog()"
    >
      <v-card tile>
        <v-toolbar color="primary" elevation="0" dark>
          <v-toolbar-title class="text-body-1 font-weight-medium">
            <span v-if="currentRequest.storage_action_type === 'Add'">
              Add items to {{ storageNicknameById(currentRequest.storage_id) }}
            </span>
            <span v-else-if="currentRequest.storage_action_type === 'Return'">
              Return items to
              {{ storageNicknameById(currentRequest.storage_id) }} from
              {{ currentRequest.listing_nickname }}
            </span>
            <span v-else-if="currentRequest.storage_action_type === 'Take'">
              Take items from
              {{ storageNicknameById(currentRequest.storage_id) }} to
              {{ currentRequest.listing_nickname }}
            </span>
            <span v-else-if="currentRequest.storage_action_type === 'Rent'">
              Rent items from
              {{ storageNicknameById(currentRequest.storage_id) }} to
              {{ currentRequest.listing_nickname }}
            </span>
            <span v-else-if="currentRequest.storage_action_type === 'Move'">
              Move items from
              {{ storageNicknameById(currentRequest.storage_id) }} to
              {{ storageNicknameById(currentRequest.destination_storage_id) }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleRequestListDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-container class="pa-0 pa-sm-4">
            <v-row justify="center">
              <v-col cols="12" md="7" lg="8" xl="6">
                <h2 class="text-h5 mb-4">Request list</h2>
                <v-alert
                  v-if="hasWarnings"
                  class="d-md-none"
                  type="warning"
                  icon="mdi-alert"
                  text
                >
                  <span>This request has warnings. Please, pay attention.</span>
                </v-alert>
                <v-list dense>
                  <template
                    v-for="(
                      item, index
                    ) in currentRequest.requests_inventory_items"
                  >
                    <v-list-item :key="item.id" class="px-0">
                      <v-list-item-avatar
                        v-if="item.images.length"
                        tile
                        width="60"
                        height="60"
                      >
                        <v-img :src="item.images[0]" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="d-flex justify-space-between flex-column"
                        >
                          <span
                            class="text-subtitle-1 font-weight-medium text-wrap"
                          >
                            {{ item.title }}
                          </span>
                          <v-spacer />
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        class="d-flex flex-row ma-2 align-center"
                      >
                        <v-sheet
                          v-if="
                            isAdmin ||
                            isCommunicationManager ||
                            isPropertyManager ||
                            hasAbility('super-storage')
                          "
                          width="50"
                        >
                          <v-text-field
                            v-model="item.requested_amount"
                            type="number"
                            outlined
                            dense
                            hide-details
                            :disabled="
                              item.status === 'Done' ||
                              (!hasAbility('super-storage') && !isAdmin)
                            "
                            @change="
                              amountChange(
                                $event,
                                item.inventory_item_id,
                                currentRequest
                              )
                            "
                          />
                        </v-sheet>
                        <span
                          v-if="
                            !isAdmin &&
                            !isCommunicationManager &&
                            !isPropertyManager
                          "
                        >
                          Amount: {{ item.requested_amount }}
                        </span>
                        <v-btn
                          v-if="currentRequest.status !== 'Done'"
                          class="ms-1"
                          icon
                          @click="
                            deleteItem(currentRequest, item.inventory_item_id)
                          "
                        >
                          <v-icon>$trash</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-show="
                        index <
                        currentRequest.requests_inventory_items.length - 1
                      "
                      :key="`line-${index}`"
                    />
                  </template>
                </v-list>
              </v-col>
              <v-col v-if="hasWarnings" cols="12" md="5" lg="4">
                <h2 class="text-h5 warning--text mb-4">Warnings</h2>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in currentRequest.is_eligible.warnings"
                    :key="`warning-${index}`"
                    class="mb-4 px-0"
                  >
                    <span class="warning--text">
                      {{ item }}
                    </span>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import sum from 'lodash/fp/sum'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'

import Requests from 'components/inventory/requests/requests.vue'
import Gallery from 'components/common/gallery'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import { remove, find } from 'lodash'
import ListingSelect from 'components/listing-select'
import filter from 'lodash/filter'
import ConfirmationModal from 'components/modals/confirmation-modal'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, FormattersMixin],
  props: ['storageTableMode'],
  data() {
    return {
      requestType: 'Storage',
      FreeOnly: false,
      ownerRequests: false,
      pagination: {
        page: 1,
        itemsPerPage: 15,
        itemsLength: null,
      },
      menu: false,
      menu2: false,
      from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        .toISOString()
        .substr(0, 10),
      to: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1)
        .toISOString()
        .substr(0, 10),
      imageDialog: false,
      chosenImages: [],
      onlyOpenToday: false,
      listingId: null,
      status: ['New', 'In Progress'],
      statuses: ['New', 'In Progress', 'Done'],
      actionType: ['Add', 'Take', 'Rent', 'Return', 'Move'],
      actionTypes: ['Add', 'Take', 'Rent', 'Return', 'Move'],
      priorities: ['All', 'Critical', 'High', 'Med', 'Low'],
      prioritiesOptions: ['Critical', 'High', 'Med', 'Low'],

      picker: new Date().toISOString().substr(0, 10),

      searchTerm: '',
      isFilesUpload: false,
      media: [],
      currentItemId: null,
      returnRequestFrom: null,
      isRequestListDialog: false,
      currentRequest: {},
      freeAvailabilities: [
        'Free today',
        'Check-in today',
        'Checkout today',
        'Check-in and Checkout today',
      ],
      priceThreshold: 1000,
    }
  },
  mounted() {
    this.getRequestsByFilter()
  },
  computed: {
    ...mapState(['listingFieldManage', 'requestsPagination']),
    requestId() {
      return this.$route.query.request_id
    },
    parsedFrom() {
      return this.from ? this.$moment(this.from).format('YYYY/MM/DD') : ''
    },
    parsedTo() {
      return this.to ? this.$moment(this.to).format('YYYY/MM/DD') : ''
    },
    headers() {
      return this.isMobile
        ? [
            { text: 'Listing', value: 'listing_nickname', sortable: false },
            { text: 'Media', align: 'center', sortable: false },
            { text: 'Actions', sortable: false },
          ]
        : this.isIPad
        ? [
            { text: 'Listing', value: 'listing_nickname', sortable: false },
            { text: 'Items', sortable: false },
            { text: 'Media', align: 'center', sortable: false },
            { text: 'Actions', sortable: false },
          ]
        : this.isLaptop
        ? [
            { text: 'Listing', value: 'listing_nickname', sortable: false },
            { text: 'Cost', value: '', sortable: false },
            { text: 'Scheduled at', value: 'created_at', sortable: false },
            { text: 'Items', sortable: false },
            { text: 'Media', align: 'center', sortable: false },
            { text: 'Actions', sortable: false },
          ]
        : [
            { text: 'Listing', value: 'listing_nickname', sortable: false },
            { text: 'Created at', value: 'created_at', sortable: false },
            { text: 'Cost', value: '', sortable: false },
            { text: 'Scheduled at', value: 'created_at', sortable: false },
            { text: 'Items', sortable: false },
            { text: 'Status', sortable: false },
            { text: 'Media', align: 'center', sortable: false },
            { text: 'Actions', sortable: false },
          ]
    },
    filteredByAddress() {
      if (this.searchTerm) {
        return orderBy(
          filter(this.listingFieldManage, request => {
            if (request.listing_id) {
              return this.FreeOnly
                ? request.listing_nickname
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase()) &&
                    this.freeAvailabilities.includes(request.today_availability)
                : request.listing_nickname
                    .toLowerCase()
                    .includes(this.searchTerm.toLowerCase())
            }
          }),
          'listing_nickname',
          'desc'
        )
      }
      return orderBy(this.listingFieldManage, 'listing_nickname', 'desc')
    },
    hasWarnings() {
      return Boolean(get(this.currentRequest, 'is_eligible.warnings'))
    },
  },
  methods: {
    getActionText(item) {
      switch (item.storage_action_type) {
        case 'Add':
          return 'Entered'
        case 'Return':
          return 'Returned'
        case 'Take':
          return 'Taken'
        case 'Rent':
          return 'Rented'
        case 'Move':
          return 'Moved'
      }
    },
    hasMedia(request) {
      return request.images.length > 0 || request.video_url
    },
    isRentRequestHasReturned(request) {
      return (
        request.storage_action_type === 'Rent' &&
        request.created_by_request &&
        request.created_by_request.storage_action_type === 'Return' &&
        request.created_by_request.status === 'Done'
      )
    },
    canMarkAsConfirmed(item) {
      return (
        (this.hasMedia(item) ||
          this.isAdmin ||
          this.hasAbility('super-storage')) &&
        (this.isAdmin ||
          this.isPropertyManager ||
          this.hasAbility('super-storage')) &&
        item.status !== 'Done' &&
        (!['Take', 'Rent'].includes(item.storage_action_type) ||
          item.is_eligible.eligible)
      )
    },
    amountChange(amount, itemId, req) {
      const userConfirm = confirm('Are you sure you want to change amount?')
      if (userConfirm) {
        let editReq = req
        this.$store.commit('updateListingLoading', true)

        const requests_inventory_item = find(editReq.requests_inventory_items, {
          inventory_item_id: itemId,
        })
        requests_inventory_item.requested_amount = amount
        axios
          .post(`/api/inventory/requests/${editReq.id}`, {
            item_id: itemId,
            requested_amount: amount,
          })
          .then(() => {
            this.$store.commit('updateListingLoading', false)
            this.getRequestsByFilter()
          })
          .catch(error => {
            this.$store.commit('updateListingLoading', false)
            alert(error)
          })
      }
    },
    onImageRemove(item, img) {
      this.media = item.images.filter(i => i !== img)
      this.currentItemId = item.id
      this.save()
    },
    deleteItem(req, itemId) {
      const userConfirm = confirm('Are you sure you want to remove?')
      if (userConfirm) {
        let editReq = req
        this.$store.commit('updateListingLoading', true)

        remove(editReq.requests_inventory_items, {
          inventory_item_id: itemId,
        })
        if (!this.isEmpty(editReq.requests_inventory_items)) {
          axios
            .post(`/api/inventory/requests/${editReq.id}`, {
              item_id: itemId,
            })
            .then(() => {
              this.$store.commit('updateListingLoading', false)
              this.getRequestsByFilter()
            })
            .catch(error => {
              this.$store.commit('updateListingLoading', false)
              alert(error)
            })
        } else {
          axios
            .delete('/api/requests/' + editReq.id)
            .then(() => {
              this.getRequestsByFilter()
            })
            .catch(() => ({}))
        }
      }
    },
    saveTo(date) {
      this.to = date
      this.$refs.menu2.save(date)
      if (this.to) {
        this.getRequestsByFilter()
      }
    },
    saveFrom(date) {
      this.from = date
      this.$refs.menu.save(date)

      if (this.from) {
        this.getRequestsByFilter()
      }
    },
    deleteRequest(request) {
      const userConfirm = confirm(
        'Are you sure you want to delete this request ?'
      )
      if (userConfirm) {
        this.$store.commit('updateListingLoading', true)

        axios
          .delete('/api/requests/' + request.id)
          .then(() => {
            this.getRequestsByFilter()
          })
          .catch(() => ({}))
      }
    },
    storageAddRequest(req) {
      return req.storage_action_type === 'Add' && req.type === 'StorageRequest'
    },
    storageMoveRequest(req) {
      return req.storage_action_type === 'Move' && req.type === 'StorageRequest'
    },
    markAsConfirmed(request) {
      if (request.type === 'StorageRequest') {
        this.$store.commit('updateListingLoading', true)
        axios
          .post(`/api/inventory/requests/${request.id}/confirm`)
          .then(() => {
            this.getRequestsByFilter()
            this.$store.commit('updateListingLoading', false)
            this.$store.dispatch('getInventoryItems', {
              catagory: [],
              search: null,
              storage: 'All',
            })
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    requestTypeChange(newVal) {
      this.requestType = newVal
      this.getRequestsByFilter()
    },
    async getRequestsByFilter() {
      const res = await this.$store.dispatch(
        'getRequestsByFilter',
        this.requestId
          ? { request_id: this.requestId }
          : {
              request_type: this.requestType,
              status: this.status,
              storage_action_type: this.actionType,
              only_open_today: this.onlyOpenToday,
              page: this.pagination.page,
              per_page: this.pagination.itemsPerPage,
              from: this.from,
              to: this.to,
              listing_id: this.listingId,
            }
      )
      this.pagination = {
        page: parseInt(res.pagi_info.page),
        itemsPerPage: parseInt(res.pagi_info.per_page),
        itemsLength: parseInt(res.pagi_info.count),
      }
    },
    statusChanged(newVal) {
      this.status = newVal
      this.getRequestsByFilter()
    },
    actionTypeChanged(newVal) {
      this.actionType = newVal
      this.getRequestsByFilter()
    },
    listingChanged(listingId) {
      this.listingId = listingId
      this.getRequestsByFilter()
    },
    paginationChanged(pagination) {
      this.pagination = pagination
      this.getRequestsByFilter()
    },
    statusColor(status) {
      if (status === 'New') {
        return 'red-health'
      } else if (status === 'In Progress') {
        return 'yellow-health'
      } else {
        return 'green-health'
      }
    },
    requestIcon(requestType) {
      if (requestType === 'RepairRequest') {
        return 'fas fa-hammer'
      } else if (requestType === 'ConsumablesRequest') {
        return 'fas fa-toilet-paper'
      } else if (requestType === 'ClaimsRequest') {
        return 'fas fa-house-damage'
      } else if (requestType === 'StorageRequest') {
        return 'fas fa-warehouse'
      } else if (requestType === 'SetupRequest') {
        return 'fas fa-box'
      } else {
        return 'fas fa-shopping-cart'
      }
    },
    openFileUploadModal(itemId) {
      this.media = []
      this.currentItemId = itemId
      this.isFilesUpload = true
    },
    closeFileUploadModal() {
      this.isFilesUpload = false
      this.media = []
      this.currentItemId = null
    },
    save() {
      if (this.media) {
        axios
          .post(`/api/requests/${this.currentItemId}/storage/update-images`, {
            files: this.media,
          })
          .then(() => {
            this.closeFileUploadModal()
            this.getRequestsByFilter()
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    toggleRequestListDialog(request) {
      this.currentRequest = request || {}
      this.isRequestListDialog = Boolean(request)
    },
    requestsDialogStateChanged(val) {
      if (!val) this.returnRequestFrom = null
    },
    createReturnRequestFrom(request) {
      this.returnRequestFrom = request
    },
    requestTotalSum(items) {
      return sum(items.map(i => i.price_to_client * i.requested_amount))
    },
    isExpensiveRequest(items) {
      return this.requestTotalSum(items) >= this.priceThreshold
    },
  },
  components: {
    ConfirmationModal,
    ListingSelect,
    DvrMediaUpload,
    Gallery,
    Requests,
  },
}
</script>
