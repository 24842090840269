<template>
  <div class="grey lighten-4 pa-3 rounded">
    <div class="text-body-2 font-weight-bold">
      <v-icon color="success" class="mr-1">mdi-check-decagram</v-icon> Payment
      Approved
    </div>
    <div class="mt-3">
      <div class="info-row">
        <div>Amount</div>
        <div>{{ toMoney(payment.amount) }}</div>
      </div>
      <div class="info-row">
        <div>Confirmation Code</div>
        <div>{{ payment.confirmation_code }}</div>
      </div>
      <div class="info-row">
        <div>Contractor</div>
        <div>{{ payment.contractor.full_name }}</div>
      </div>
      <div class="info-row">
        <div>Confirmed By</div>
        <div>{{ payment.confirmed_by.full_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  props: ['payment'],
  mixins: [FormattersMixin],
}
</script>
<style scoped>
.info-row {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.info-row div:nth-child(2) {
  font-weight: 500;
}
</style>
