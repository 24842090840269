<template>
  <v-card min-width="360" elevation="0">
    <conversation-info-title :guesty-id="reservation.reservation_guesty_id">
      <template #actions>
        <div class="d-flex align-center w-100">
          <div class="flex-grow-1">
            <conversation-status
              v-model="currentStatus"
              width="230"
              @change="updateSalesStatus"
            />
          </div>
          <div class="d-flex align-center">
            <v-btn
              v-if="reservation.reservation_guesty_id"
              :to="`/dashboard/reservation/${reservation.reservation_guesty_id}`"
              :target="isMobile ? '_self' : '_blank'"
              icon
              small
              class="mx-1"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>

            <v-btn
              v-if="pmsLink"
              :href="pmsLink"
              :target="isMobile ? '_self' : '_blank'"
              small
              class="mx-1"
              icon
            >
              <v-icon>mdi-database</v-icon>
            </v-btn>

            <v-btn
              v-if="reservation.cleaning_listing_task_id"
              :to="{
                name: 'lt-page',
                params: { id: reservation.cleaning_listing_task_id },
              }"
              target="_blank"
              icon
              small
              class="mx-1"
            >
              <v-icon>mdi-broom</v-icon>
            </v-btn>
            <v-btn
              v-if="$route.name !== 'guests-chat'"
              :target="isMobile ? '_self' : '_blank'"
              icon
              small
              class="mx-1"
              :to="{
                name: 'guests-chat',
                params: { id: conversation.guesty_id },
              }"
            >
              <v-icon>$chat</v-icon>
            </v-btn>
            <reservation-edit-modal
              :key="reservation.id"
              :reservation="reservation"
              :listing="listing"
              @change="onReservationEdit"
            />
          </div>
        </div>
      </template>
    </conversation-info-title>
    <v-card-text>
      <!-- Listing Image Banner -->
      <div class="d-flex align-center mb-6">
        <v-img
          :src="listing.picture"
          width="120"
          height="80"
          class="rounded-lg flex-shrink-0"
          cover
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div class="ml-4 flex-grow-1">
          <div class="d-flex align-center mb-1">
            <v-chip
              x-small
              label
              :color="sensitivityColor(reservation.listing.sensitivity)"
              class="mr-2"
            >
              {{ reservation.listing.sensitivity }}
            </v-chip>
            <span class="text-caption text-secondary">Listing Risk</span>
          </div>
          <div class="text-subtitle-1 font-weight-medium mb-1">
            <span v-if="region" class="text-capitalize secondary--text">
              {{ region.name }} -
            </span>
            {{ listing.nickname }}
            <copy-clipboard :text="listing.nickname" class="ml-1" />
          </div>
          <div class="text-body-2 text--secondary">
            Category -
            {{ listingNicknameById(reservation.original_listing_id) }}
          </div>
        </div>
      </div>

      <!-- Guest Info Section -->
      <v-card outlined class="mb-6">
        <div class="d-flex align-center pa-4">
          <user-avatar
            class="align-self-start"
            :name="conversation.guest.full_name"
            :size="50"
            :color="stringToHslColor(conversation.guest.full_name)"
          />
          <div class="ml-4 flex-grow-1">
            <div class="d-flex align-center mb-1">
              <span
                v-if="isAgent"
                class="text-caption primary--text font-weight-medium"
                >Agent</span
              >
              <span
                v-else
                class="text-caption text-secondary d-flex align-center"
              >
                <v-tooltip v-if="conversation.guest.guest_score" top>
                  <template #activator="{ on }">
                    <v-chip
                      x-small
                      label
                      class="mr-2 font-weight-medium"
                      :color="
                        getGuestScoreColor(conversation.guest.guest_score)
                      "
                      v-on="on"
                    >
                      {{ guestScoreDotValue(conversation.guest.guest_score) }}
                    </v-chip>
                  </template>
                  {{ getGuestScoreLabel(conversation.guest.guest_score) }}
                </v-tooltip>
                Guest
              </span>
            </div>
            <div class="d-flex align-center mb-1">
              <span class="text-subtitle-1 font-weight-medium">
                {{ conversation.guest.full_name }}
              </span>
              <v-btn
                v-if="config.has_twilio_voice"
                x-small
                outlined
                color="primary"
                class="ml-2"
                @click="
                  updateVoiceModal({
                    dialog: true,
                    phone: displayedPhone,
                    name: conversation.guest.full_name,
                  })
                "
              >
                <v-icon x-small class="mr-1">mdi-phone</v-icon>
                Call
              </v-btn>
            </div>
            <div v-if="displayedPhone" class="d-flex align-center mb-1">
              <v-btn class="pa-0" :href="`tel:${displayedPhone}`" text x-small>
                <v-icon class="mr-1" x-small>mdi-phone</v-icon>
                {{ displayedPhone }}
              </v-btn>
              <copy-clipboard
                color="secondary"
                :text="displayedPhone"
                class="ml-2"
              />
            </div>
            <div v-if="displayedEmail" class="d-flex align-center">
              <v-icon x-small class="mr-2">mdi-email</v-icon>
              <span class="text-caption">{{ displayedEmail }}</span>
              <copy-clipboard
                color="secondary"
                :text="displayedEmail"
                class="ml-2"
              />
            </div>
          </div>
          <div class="text-center">
            <v-tooltip
              v-if="reservation.risk_analysis"
              left
              max-width="350px"
              color="white"
              top
            >
              <template #activator="{ on }">
                <div class="d-flex flex-column align-center" v-on="on">
                  <v-progress-circular
                    :rotate="-90"
                    :size="45"
                    :width="8"
                    :value="reservation.risk_analysis.risk_score * 100"
                    :color="getRiskColor(reservation.risk_analysis.risk_score)"
                  >
                    {{ round(reservation.risk_analysis.risk_score * 100) }}
                  </v-progress-circular>
                  <div class="text-caption mt-1">Guest Risk</div>
                </div>
              </template>
              <v-card flat class="pa-4">
                <template
                  v-if="!isEmpty(reservation.risk_analysis.bad_reasons)"
                >
                  <div class="text-subtitle-2 error--text mb-2">
                    Risk Factors:
                  </div>
                  <div
                    v-for="badReason in reservation.risk_analysis.bad_reasons"
                    :key="badReason"
                    class="d-flex align-center mb-1"
                  >
                    <v-icon small color="error" class="mr-2"
                      >mdi-alert-circle</v-icon
                    >
                    {{ badReason }}
                  </div>
                </template>
                <template
                  v-if="!isEmpty(reservation.risk_analysis.good_reasons)"
                >
                  <div class="text-subtitle-2 success--text mt-3 mb-2">
                    Positive Indicators:
                  </div>
                  <div
                    v-for="goodReason in reservation.risk_analysis.good_reasons"
                    :key="goodReason"
                    class="d-flex align-center mb-1"
                  >
                    <v-icon small color="success" class="mr-2"
                      >mdi-check-circle</v-icon
                    >
                    {{ goodReason }}
                  </div>
                </template>
              </v-card>
            </v-tooltip>
          </div>
        </div>
      </v-card>

      <div
        v-if="reservation.multi_reservation_id"
        class="d-flex align-center info--text pointer mb-4"
        @click="
          goToReservation(reservation.parent_reservation.reservation_guesty_id)
        "
      >
        <v-icon small class="mr-2">mdi-link-variant</v-icon>
        <span class="text-subtitle-2">Parent Reservation</span>
        <v-icon x-small class="ml-1">mdi-open-in-new</v-icon>
      </div>

      <reservations-select
        :readonly="singleReservation"
        :pre-selected="$route.query.reservation"
        :res-options="conversation.related_reservations"
        class="mb-4"
        @changed="reservationChanged"
      />

      <conversation-select :conversation="conversation" class="mb-4" />

      <v-divider class="mb-6" />

      <div
        class="d-flex align-center justify-center font-weight-semibold text-body-2 blue-grey--text text--darken-4 w-100 mb-6"
      >
        <div>{{ checkInDate }}</div>
        <div class="mx-2"><v-icon small>mdi-arrow-right</v-icon></div>
        <div>{{ checkOutDate }}</div>
      </div>

      <v-divider />
      <v-row class="my-1 text-center">
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Price</div>
          <div class="black--text text-body-1 font-weight-medium">
            {{ toMoney(calcReservationPrice(reservation)) }}
          </div>
        </v-col>
        <v-divider vertical />
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Channel</div>
          <div class="black--text text-body-1 font-weight-medium">
            <span v-if="reservation.agent_guesty_conversation_id">
              <a
                target="_blank"
                :href="`dashboard/guest-experience/agents?conversation=${reservation.agent_guesty_conversation_id}`"
              >
                {{ reservation.source }}
              </a>
            </span>
            <span v-else>
              {{ reservation.source }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="my-1 text-center">
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Guests</div>
          <div class="black--text text-body-1 font-weight-medium">
            {{ reservation.guests_count }}
          </div>
        </v-col>
        <v-divider vertical />
        <v-col cols="6" class="font-weight-medium mb-1">
          <div class="text-caption secondary-text text--darken-2">Nights</div>
          <div class="black--text text-body-1 font-weight-medium">
            {{ reservation.nights_count }}
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-2" />

      <v-divider class="mb-6" />

      <div class="d-flex flex-wrap gap-2 mb-6">
        <v-btn
          v-if="
            !reservation.pre_approved &&
            reservation.status === 'inquiry' &&
            reservation.source === 'Airbnb' &&
            !reservation.airbnb_reserved
          "
          color="success"
          class="text-none mr-2 mb-2"
          depressed
          @click="markPreApproved"
        >
          <v-icon left>mdi-check-circle</v-icon>
          Pre-approve
        </v-btn>

        <v-btn
          v-if="
            isAdmin &&
            reservation.balance_due > 0 &&
            (reservation.is_merchant || isAgentReserved)
          "
          color="success"
          outlined
          class="text-none mr-2 mb-2"
          @click="markAspaid(reservation.id)"
        >
          <v-icon left>mdi-cash-check</v-icon>
          Mark as paid
        </v-btn>

        <div v-if="isAgentReserved" class="flex-grow-1">
          <conversation-info-agent-confirm
            :reservation="reservation"
            :is-owner-vacation="false"
            :disabled="!!reservation.balance_due"
            @after-confirm="conversationRefresh"
          />
        </div>
      </div>

      <v-row no-gutters class="justify-space-between mb-6">
        <v-col cols="auto">
          <listing-task-module
            :pre-filled-listing-id="[reservation.listing_id]"
            button-text="SC"
            :reservation="reservation"
            :after-save-func="afterTaskCreate"
          >
            <template #customBtn="{ on }">
              <v-btn
                color="primary"
                outlined
                small
                class="text-none mr-2"
                v-on="on"
              >
                <v-icon left small>mdi-plus</v-icon>
                {{ $t('New SC') }}
              </v-btn>
            </template>
          </listing-task-module>
        </v-col>

        <v-col
          v-if="reservation.status === 'confirmed' && isDesignedVr"
          cols="auto"
        >
          <v-btn
            color="primary"
            outlined
            small
            class="text-none mr-2"
            @click="sendInfo"
          >
            <v-icon left small>mdi-key</v-icon>
            Send Codes
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn
            color="primary"
            outlined
            small
            class="text-none mr-2"
            @click="openInfo"
          >
            <v-icon left small>mdi-information</v-icon>
            Info
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-dialog v-model="dialog" width="1200">
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                small
                class="text-none mr-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left small>mdi-magnify</v-icon>
                Listings
              </v-btn>
            </template>
            <v-card class="pa-4">
              <listings-search v-if="dialog" :pre-config="preConfig" />
            </v-card>
          </v-dialog>
        </v-col>

        <v-col v-if="isDesignedVr" cols="auto">
          <v-btn
            color="primary"
            outlined
            small
            class="text-none mr-2"
            @click="showCatalogInfo"
          >
            <v-icon left small>mdi-book-open-variant</v-icon>
            Knowledge center
          </v-btn>
        </v-col>

        <v-col
          v-if="
            reservation.status === 'confirmed' &&
            !isEmpty(reservation.door_codes) &&
            isEmpty(reservation.backup_door_codes)
          "
          cols="auto"
        >
          <v-btn
            color="primary"
            outlined
            small
            class="text-none"
            @click="generateBackupDoorCodes"
          >
            <v-icon left small>mdi-key-variant</v-icon>
            Generate Backup Codes
          </v-btn>
        </v-col>
      </v-row>
      <v-layout column>
        <span
          v-if="
            showModule('comm') &&
            reservation &&
            !reservation.pre_check_in &&
            reservation.status === 'confirmed'
          "
          class="text-center d-flex justify-center align-center mt-4"
          ><v-icon small class="red-icon pr-1">warning</v-icon> Pre-check-in:
          <span class="red--text ms-1 font-weight-medium">Not filled</span>
          <span>
            <CopyClipboard :text="preCheckInLink" />
          </span>
        </span>
        <span
          v-if="
            reservation &&
            reservation.pre_check_in &&
            reservation.status === 'confirmed'
          "
          class="text-center mt-4"
          :class="
            reservation.pre_check_in.status !== 'Done'
              ? 'red--text'
              : 'green-text'
          "
        >
          <v-icon
            v-if="reservation.pre_check_in.status !== 'Done'"
            class="red-icon me-1"
            small
            >mdi-alert-outline</v-icon
          >
          Pre-check-in:
          <span class="font-weight-bold">
            {{
              STATUS_LABELS[reservation.pre_check_in.status] ||
              reservation.pre_check_in.status
            }}
          </span></span
        >

        <v-layout v-if="!reservation.agent_id" wrap mt-2 mb-2>
          <v-flex
            v-if="
              reservation.status === 'reserved' || reservation.airbnb_reserved
            "
            xs6
            pr-1
          >
            <v-btn
              color="success"
              class="full-width white--text"
              :loading="approving"
              @click="approveRes"
              >approve
            </v-btn>
          </v-flex>
          <v-flex
            v-if="
              reservation.status === 'reserved' || reservation.airbnb_reserved
            "
            xs6
            pl-1
          >
            <v-btn
              color="error"
              :loading="declining"
              class="full-width white--text"
              @click="declineRes"
              >decline
            </v-btn>
          </v-flex>
          <span v-if="conversation.extra_data['approval_request']">
            Request has been
            {{ conversation.extra_data['approval_request'] }}, might take few
            min to update.
          </span>
        </v-layout>
      </v-layout>
      <v-row no-gutters>
        <v-col cols="12" class="pl-0">
          <check-in-out-form
            :reservation="reservation"
            @change="updateCheckInOutTimes"
          />
        </v-col>
        <v-col cols="12" v-if="reservationNotes">
          <h4>Additional Notes</h4>
          <v-sheet max-height="100" class="small-text pa-2 overflow-y-auto">
            <div v-html="reservationNotes"></div>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row wrap no-gutters class="my-2">
        <v-col
          v-if="!reservation.is_merchant && reservation.status === 'confirmed'"
          cols="12"
        >
          <div class="d-flex text-capitalize text-body-1">
            <span>
              <span class="bolder">Accommodation:</span>
              <span class="text-body-2 green--text"
                >{{ `Paid via channel (${toMoney(reservation.price)})` }}
              </span>
            </span>
          </div>
        </v-col>
        <v-col
          v-for="(intent, idx) in paymentIntents"
          :key="idx"
          cols="12"
          class=""
        >
          <div class="d-flex justify-space-between text-capitalize text-body-1">
            <span>
              <span class="bolder"
                >{{
                  intent.metadata['type'] === 'accommodation'
                    ? 'Reservation'
                    : 'Deposit'
                }}:</span
              >
              <span :class="['body-2', textClass(intent)]"
                >{{ ` ${paymentStatus(intent)} (${intent.amount / 100})` }}
              </span>
            </span>
            <span
              v-show="
                intent.metadata['type'] === 'accommodation' ||
                (intent.metadata['type'] === 'deposit' &&
                  reservation.should_pay_deposit)
              "
            >
              <a
                v-show="isAdmin"
                class="text-decoration-none"
                target="_blank"
                :href="`https://dashboard.stripe.com/payments/${intent.payment_intent_id}`"
                ><v-icon small>fab fa-stripe-s</v-icon></a
              >
              <a
                class="text-decoration-none"
                target="_blank"
                :href="`${portalDomain}/checkout/${intent.payment_intent_id}`"
                ><v-icon small>fas fa-external-link-alt</v-icon></a
              >
              <CopyClipboard
                description="Copy payment link"
                :text="`${portalDomain}/checkout/${intent.payment_intent_id}`"
                >Copy Link</CopyClipboard
              ></span
            >
          </div>
        </v-col>
        <v-col v-if="hasRefunds" cols="12">
          <div class="notification--text font-weight-medium text-body-2">
            Has Refunds
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small v-on="on">info</v-icon>
              </template>
              <div>More information can be found in payment tab</div>
            </v-tooltip>
          </div>
        </v-col>
        <v-col v-if="disputes.length" cols="12">
          <div class="font-weight-medium warning--text text-body-2">
            Has Disputes
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon small v-on="on">info</v-icon>
              </template>
              <v-sheet> More information can be found in payment tab</v-sheet>
            </v-tooltip>
          </div>
        </v-col>
        <v-col v-if="noticeMessage" cols="12">
          <div class="d-flex text-capitalize text-body-1">
            <span>
              <span class="bolder">Res Notification:</span>
              <span class="red--text text-body-2">
                {{ `${noticeMessage} notice` }}
              </span>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row wrap justify-center class="mt--1">
        <v-col cols="12">
          <reservation-calendar
            v-if="currentListing && currentListing.days_rates && !loading"
            :add-on-res="reservation"
            :slim-version="true"
            listing-type="currentEditListing"
          />
        </v-col>
        <v-col
          v-if="listing.has_assigned_parking || listing.has_parking_pass"
          cols="12"
        >
          <v-expansion-panels mt-3>
            <v-expansion-panel key="final">
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="green--text">local_parking</v-icon>
                  Parking info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="column med-font">
                  <p v-if="listing.has_parking_pass">Has parking pass</p>
                  <p v-if="listing.has_assigned_parking">
                    Has assigned parking:
                    {{ listing.assigned_parking_desc }}
                  </p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels mt-3 flat accordion>
            <v-expansion-panel key="final">
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="cyan-icon">house</v-icon>
                  House public info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-2">
                <v-row>
                  <v-col v-if="listing.room_composition" cols="12">
                    <h4 class="mb-2">Sleeping Arrangements</h4>
                    <div
                      v-for="(room, index) in listing.room_composition"
                      :key="index"
                      class="mb-3"
                    >
                      <div v-if="room.amenities.length">
                        <div class="font-weight-medium d-flex align-center">
                          <v-icon small class="mr-2">
                            {{
                              room.integration_type === 'Bedroom'
                                ? 'mdi-bed'
                                : 'weekend'
                            }}
                          </v-icon>
                          {{ room.bedroomName }}
                        </div>
                        <div
                          v-for="(amenity, aIndex) in room.amenities"
                          :key="aIndex"
                          class="text-caption ml-4 dark-grey--text d-flex align-center"
                        >
                          <v-icon x-small class="mr-2 dark-">king_bed</v-icon>
                          {{ amenity.quantity }}x {{ amenity.bedType }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-divider v-if="listing.room_composition" class="my-2" />

                  <v-col v-if="listing.marketing_content.space" cols="12">
                    <h4>The space</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.space"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.notes" cols="12">
                    <h4>Notes</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.notes"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.access" cols="12">
                    <h4>Access</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.access"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.summary" cols="12">
                    <h4>Summary</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.summary"
                    ></span>
                  </v-col>
                  <v-col v-if="listing.marketing_content.houseRules" cols="12">
                    <h4>House Rules</h4>
                    <span
                      class="small-text"
                      v-html="listing.marketing_content.houseRules"
                    ></span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
        </v-col>
        <v-col v-if="listing.terms" cols="12" class="pa-0">
          <v-expansion-panels mt-3 flat>
            <v-expansion-panel
              v-if="
                listing.terms.cancellation || reservation.cancellation_policy
              "
              key="final"
            >
              <v-expansion-panel-header class="pa-2">
                <span class="small-text">
                  <v-icon class="red-warn">cancel_presentation</v-icon>
                  Cancellation info
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col v-if="listing.terms.cancellation" cols="12">
                    <h4 class="red-warn">House Cancellation</h4>
                    <span
                      class="small-text"
                      v-html="listing.terms.cancellation"
                    ></span>
                  </v-col>
                  <v-col v-if="reservation.cancellation_policy" cols="12">
                    <h4 class="red-warn">Reservation Cancellation</h4>
                    <span
                      class="small-text"
                      v-html="reservation.cancellation_policy"
                    ></span>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import ColorsMixin from 'components/mixins/colors-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingTaskModule from './listing-task-module'
import ReservationCalendar from 'components/calendar/reservation-calendar'
import CopyClipboard from 'components/copy-clipboard'
import ReservationsSelect from 'components/chat/reservations-select'
import ListingsSearch from 'components/listings-search'
import get from 'lodash/fp/get'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import CheckInOutForm from 'components/common/check-in-out-form'
import ReservationEditModal from 'components/reservation/reservation-edit-modal'
import { mapMutations, mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import UserAvatar from 'components/common/user-avatar.vue'
import NavigationMixin from 'components/mixins/navigation-mixin'
import ConversationInfoAgentConfirm from 'components/conversation-info-agent-confirm.vue'
import ConversationSelect from 'components/chat/conversation-select'
import ConversationStatus from 'components/conversations/conversation-status'

const STATUS_LABELS = {
  New: 'Not Filled',
  'In Progress': 'In Progress',
  Done: 'Filled',
}

export default {
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    FormattersMixin,
    ColorsMixin,
    NavigationMixin,
  ],
  props: ['conversation', 'singleReservation'],
  watch: {
    'reservation.listing_id': {
      handler(newVal) {
        if (newVal) this.loadListing()
      },
      immediate: true,
    },
    'conversation.sales_status': {
      immediate: true,
      handler(val) {
        this.currentStatus = val || null
      },
    },
  },
  computed: {
    ...mapState('regions', ['regions']),
    displayedPhone() {
      return (
        get('guest.primary_phone', this.conversation) ||
        get('guest.phones[0]', this.conversation) ||
        get('active_reservation.primary_phone', this.conversation)
      )
    },
    displayedEmail() {
      return (
        this.conversation.registered_email ||
        this.conversation.active_reservation.primary_email
      )
    },
    config() {
      return this.$store.state.app.configuration
    },
    preConfig() {
      return {
        guestAmount: get('beds', this.listing) ? this.listing.beds * 2 : 2,
        from: this.$moment(this.reservation.check_in)
          .toISOString()
          .substr(0, 10),
        to: this.$moment(this.reservation.check_out)
          .toISOString()
          .substr(0, 10),
      }
    },
    region() {
      return this.regions.find(r => r.id === this.listing.region_id)
    },
    portalDomain() {
      return 'https://guest.boomnow.com'
    },
    listing() {
      return this.reservation.listing
    },
    reservation() {
      return this.$store.getters.chosenChatReservation
    },
    currentListing() {
      return this.$store.state.currentListing
    },
    noticeMessage() {
      const messages = this.conversation.guest.automated_messages || {}
      if (messages['24_h_payment_warn']) {
        return '3rd'
      }
      if (messages['6_h_payment_warn']) {
        return '2nd'
      }
      if (messages['3_h_payment_warn']) {
        return '1st'
      }
      return ''
    },
    reservationNotes() {
      return this.$domPurify.sanitize(this.reservation.notes, {
        ALLOWED_TAGS: ['p', 'div'],
      })
    },
    preCheckInLink() {
      return `${this.portalDomain}/tourist/trips/${this.reservation.confirmation_code}`
    },
    hasRefunds() {
      return this.getReservationRefunds(this.reservation.payment_intents).length
    },
    paymentIntents() {
      return (this.reservation.payment_intents || []).filter(
        pi =>
          pi.metadata.type === 'accommodation' || pi.metadata.type === 'deposit'
      )
    },
    disputes() {
      return this.getReservationDisputes(this.reservation.payment_intents)
    },
    isAgentReserved() {
      return (
        this.reservation.agent_id &&
        this.reservation.status === 'agent-reserved'
      )
    },
    isAgent() {
      return (
        this.reservation.agent_id &&
        ['agent-reserved', 'agent-confirmed'].includes(this.reservation.status)
      )
    },
    pmsLink() {
      const integrationName = this.reservation.app_integration_name
      const reservationId = this.reservation.reservation_guesty_id

      switch (integrationName) {
        case 'hostaway':
          return `https://dashboard.hostaway.com/reservations/${reservationId}`
        default:
          return null
      }
    },
    checkInDate() {
      return this.parseDateTZ(
        this.reservation.check_in,
        'YYYY-MM-DD',
        this.reservation.listing.timezone
      )
    },
    checkOutDate() {
      return this.parseDateTZ(
        this.reservation.check_out,
        'YYYY-MM-DD',
        this.reservation.listing.timezone
      )
    },
  },
  methods: {
    ...mapMutations(['showModal']),
    openConversation(guestyId) {
      const url = `/dashboard/guest-experience/all/${guestyId}`
      window.open(url, '_blank')
    },
    sensitivityColor(sensitivity) {
      if (sensitivity === 'Low') {
        return 'success'
      }
      if (sensitivity === 'Med') {
        return 'notification'
      }
      if (sensitivity === 'High') {
        return 'warning'
      }
      return 'grey'
    },
    onReservationEdit() {
      if (this.$route.name === 'multi-calendar') {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    showCatalogInfo() {
      this.showModal({
        name: 'CatalogItemInfoModal',
        props: {
          pickerMode: true,
        },
      })
    },
    getRiskColor(risk) {
      if (risk < 0.2) {
        return 'green lighten-2'
      }
      if (risk < 0.5) {
        return 'yellow accent-4'
      }
      if (risk < 0.8) {
        return 'red lighten-2'
      }
      return 'red darken-4'
    },
    calcReservationPrice(res) {
      return res.price !== 0
        ? res.price
        : Number(res.fare_accommodation || 0) +
            Number(res.cleaning_fee || 0) +
            Number(res.total_taxes || 0)
    },
    reservationChanged(val) {
      const reservation = this.conversation.related_reservations.find(
        r => r.reservation_guesty_id === val
      )
      this.$store.commit('updateChatReservation', reservation)
    },
    textClass({ status, metadata, amount }) {
      if (
        ['succeeded', 'requires_capture', 'canceled'].includes(status) ||
        this.paidOnGuesty(amount / 100)
      ) {
        return 'green--text'
      }
      if (
        !this.reservation.should_pay_deposit &&
        metadata['type'] === 'deposit' &&
        (status === 'requires_source' || status === 'requires_payment_method')
      ) {
        return 'secondary--text'
      } else {
        return 'red--text'
      }
    },
    markAspaid(reservationID) {
      const userConfirm = confirm('Are you sure?')
      if (userConfirm) {
        this.$store.dispatch('markAsPaid', reservationID).then(({ data }) => {
          if (data && data.success) {
            this.conversationRefresh()
          }
        })
      }
    },
    paidOnGuesty(amount) {
      return this.reservation.payment_items.find(
        p => p.confirmation_code && p.amount === amount
      )
    },
    paymentStatus({ status, metadata, amount }) {
      if (metadata['type'] === 'deposit') {
        if (
          !this.reservation.should_pay_deposit &&
          (status === 'requires_source' || status === 'requires_payment_method')
        ) {
          return "Shouldn't be paid yet"
        }
        if (status === 'succeeded') {
          return 'Paid and captured'
        }
        if (status === 'canceled') {
          return 'Paid and expired'
        }
      }
      if (status === 'succeeded' || status === 'requires_capture') {
        return 'Paid'
      }

      if (this.paidOnGuesty(amount / 100)) {
        return 'Paid on PMS'
      }
      if (
        status === 'requires_source' ||
        status === 'requires_payment_method'
      ) {
        return 'not paid'
      }

      return status
    },
    issuesAreDone(issues) {
      let res = true
      issues.forEach(issue => {
        if (issue.status !== 'Done') {
          res = false
        }
      })
      return res
    },
    sendInfo() {
      this.$store.commit(
        'updatePushedMessage',
        this.reservation.generated_code_msg
      )
    },
    generateBackupDoorCodes() {
      this.$store.dispatch('reservation/generateBackupDoorCodes', {
        reservation_id: this.reservation.id,
      })
    },

    afterTaskCreate() {
      this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
    },
    async conversationRefresh() {
      await this.$store.dispatch(
        'getChatConversation',
        this.conversation.guesty_id
      )
    },
    async approveRes() {
      this.approving = true
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        reservation_id: this.reservation.id,
        approve_reservation: true,
      })
      this.approving = false
      if (this.$route.query.reservation) {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    async declineRes() {
      this.declining = true
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        reservation_id: this.reservation.id,
        decline_reservation: true,
      })
      this.declining = true
      if (this.$route.query.reservation) {
        this.$store.commit('listingCalendar/calendarUpdate')
      }
    },
    openInfo() {
      this.$store.commit('updateShowListingInfoPop', true)
    },
    markPreApproved() {
      this.$store.dispatch('preApproveReservation', this.reservation.id)
      this.reservation.pre_approved = true
    },
    sourceColor(channel) {
      if (channel === 'Airbnb') {
        return 'airbnb-color'
      } else if (channel === 'Booking.com') {
        return 'booking-color'
      } else if (channel === 'HomeAway') {
        return 'homeaway-color'
      } else if (channel === 'Designedvr.com') {
        return 'designedvr-color'
      } else if (channel === 'Website') {
        return 'designedvr-color'
      } else if (channel === 'Expedia') {
        return 'expedia-color'
      }
    },
    loadListing() {
      this.$store.dispatch('changeListing', {
        listingId: this.reservation.listing_id,
        updateLoading: false,
      })
    },
    async updateCheckInOutTimes({
      arrival,
      departure,
      luggage_drop,
      dvr_notes,
    }) {
      await this.$store.dispatch('reservation/alterReservation', {
        id: this.reservation.id,
        planned_arrival: arrival,
        planned_departure: departure,
        luggage_drop,
        dvr_notes,
      })
      await this.conversationRefresh()
    },
    updateVoiceModal(payload) {
      this.$store.commit('conversations/updateVoiceModal', payload)
    },
    updateSalesStatus(value) {
      this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        sales_status: value,
      })
    },
  },
  data() {
    return {
      reservationDialog: false,
      dialog: false,
      declining: false,
      approving: false,
      selectedRes: null,
      STATUS_LABELS,
      currentStatus: null,
    }
  },
  components: {
    UserAvatar,
    ReservationEditModal,
    CheckInOutForm,
    ConversationInfoTitle,
    ReservationCalendar,
    ListingsSearch,
    ReservationsSelect,
    ListingTaskModule,
    CopyClipboard,
    ConversationInfoAgentConfirm,
    ConversationSelect,
    ConversationStatus,
  },
}
</script>
<style scoped>
.guest-label {
  top: -20px;
}

.rounded-lg {
  border-radius: var(--v-radius-lg);
}

.gap-2 {
  gap: 8px;
}

.listing-banner {
  position: relative;
  overflow: hidden;
}

.listing-banner__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
}

:deep(.v-card) {
  border-color: var(--v-secondary-lighten1) !important;
  transition: box-shadow 0.2s ease;
}

:deep(.v-card:hover) {
  box-shadow: var(--v-shadow-md) !important;
}

:deep(.v-btn) {
  text-transform: none;
}

:deep(.v-card__text) {
  padding: 24px;
}

:deep(.v-divider) {
  border-color: var(--v-secondary-lighten2);
}

:deep(.text-overline) {
  font-size: 11px;
  letter-spacing: 0.1em;
}

:deep(.v-chip) {
  font-size: 11px;
  height: 20px;
}

:deep(.v-icon) {
  font-size: 18px;
}

:deep(.v-icon.v-icon--small) {
  font-size: 16px;
}

:deep(.v-icon.v-icon--x-small) {
  font-size: 14px;
}

:deep(.v-progress-circular) {
  font-size: 12px;
  font-weight: 600;
}

:deep(.v-tooltip__content) {
  background: white;
  color: var(--v-secondary-darken4);
  box-shadow: var(--v-shadow-lg);
  border-radius: var(--v-radius-lg);
  padding: 16px;
}

:deep(.v-img) {
  background-color: var(--v-secondary-lighten4);
}

:deep(.v-card.v-card--outlined) {
  background-color: var(--v-surface);
}

.text-decoration-none {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
