<template>
  <div class="comp-wrapper">
    <FullCalendar id="pricing-cal" ref="calendar" :options="config">
      <template #dayCellContent="{ date }">
        <div class="text-end w-100">
          <span
            class="date-indicator text-caption blue-grey--text font-weight-semibold"
            >{{ $moment(date).format('DD') }}</span
          >
          <v-tooltip
            open-delay="500"
            bottom
            color="darkpurple"
            :disabled="!hasPricing(date)"
            min-width="300"
          >
            <template #activator="{ on }">
              <v-row
                no-gutters
                class="pricing-tag text-caption"
                v-on="on"
                @click="onShowBlockModal(date)"
              >
                <v-col
                  cols="12"
                  class="d-flex justify-space-around text-center font-weight-semibold text-body-2 my-2"
                >
                  <div class="d-flex justify-center black--text align-center">
                    {{ toAmount(getPrice(date)) }}
                    <span class="indicator ms-1"></span>
                    <v-icon
                      v-if="statusSuccess(date)"
                      x-small
                      color="deep-purple darken-3"
                      class="ms-1"
                      >mdi-check-decagram</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="12" class="mb-1">
                  <span
                    v-if="diff(date)"
                    class="blue-grey lighten-5 rounded px-2 py-05"
                  >
                    <span class="d-inline-flex">
                      <v-icon v-if="diff(date) > 0" x-small color="success"
                        >mdi-arrow-up-bold</v-icon
                      >
                      <v-icon v-if="diff(date) < 0" x-small color="error"
                        >mdi-arrow-down-bold</v-icon
                      >
                      <span
                        class="blue-grey--text text--darken-4 font-weight-medium ms-1"
                        >{{ toMoney(diff(date)) }}</span
                      >
                    </span>
                  </span>
                </v-col>
                <v-col cols="12">
                  <div
                    v-show="amountOfUnitsText(date)"
                    class="text-caption text-start"
                  >
                    <v-icon color="white" x-small>fas fa-home</v-icon>
                    <span class="ml-1">{{ amountOfUnitsText(date) }}</span>
                  </div>
                  <div
                    v-if="minNights(date)"
                    class="nights-location d-flex text-caption text-end blue-grey--text text--darken-4"
                  >
                    <v-icon color="white" x-small
                      >mdi-moon-waning-crescent</v-icon
                    >
                    {{ minNights(date) }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <DayPriceInfo
              v-if="hasPricing(date)"
              :rate="hasPricing(date)"
              :date="formatDateRate(date)"
            />
          </v-tooltip>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import round from 'lodash/fp/round'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import getOr from 'lodash/fp/getOr'
import DayPriceInfo from 'components/day-price-info'
import { mapState, mapGetters } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  name: 'PricingCalendar',
  components: {
    DayPriceInfo,
    FullCalendar,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['calendar'],

  mounted() {
    let calApi = this.calendar
    if (!calApi) {
      calApi = this.$refs.calendar.getApi()
      this.$emit('update:calendar', calApi)
    }
    if (calApi) {
      $('#pricing-cal button.fc-button-primary').on('click', () => {
        this.$store.dispatch('getPricingMonthlyStats', {
          date: calApi.view.title,
          listingId: this.currentPricing.listing_id,
        })
        this.$store.dispatch('getPricingYearlyStats', calApi.view.title)
      })
    }
    this.$store.dispatch('getPricingMonthlyStats', {
      date: calApi.view.title,
      listingId: this.currentPricing.listing_id,
    })
    this.$store.dispatch('getPricingYearlyStats', calApi.view.title)
    setTimeout(() => {
      calApi.updateSize()
    }, 500)
  },
  computed: {
    ...mapState(['currentPricing']),
    ...mapGetters(['weekStartsAt']),
    config() {
      const classByDateRate = this.classByDateRate
      return {
        firstDay: this.weekStartsAt,
        plugins: [dayGridPlugin],
        themeSystem: 'Yeti',
        initialView: 'dayGridMonth',
        aspectRatio: 2,
        dayCellClassNames({ date }) {
          return classByDateRate(date)
        },
        eventColor: 'transparent',
      }
    },
  },
  methods: {
    classByDateRate(date) {
      if (this.currentPricing.days_rates[this.formatDateRate(date)]) {
        const day = this.currentPricing.days_rates[this.formatDateRate(date)]

        if (day['status'] === 'available') {
          let styleClass = ''
          const ratio = day['price'] / day['base_price']
          if (day.manual_override)
            styleClass += ' override-background primary--text'
          if (ratio < 0.9) {
            styleClass += ' price-low'
          } else if (ratio < 1.05) {
            styleClass += ' price-mid'
          } else if (ratio < 1.2) {
            styleClass += ' price-high'
          } else {
            styleClass += ' price-very-high'
          }
          styleClass += ' day-cell'
          return styleClass
        } else if (day['status'] === 'unavailable') {
          return 'error--text  blocked-cal'
        }
        if (day.status === 'success') return 'day-success'
        return ''
      }
    },
    isOverridden(date) {
      if (this.isInvestor) return false
      const rate =
        this.currentPricing.days_rates[this.$moment(date).format('YYYY-MM-DD')]
      return rate && rate.manual_override
    },
    hasPricing(date) {
      return this.currentPricing.days_rates[this.formatDateRate(date)]
    },
    formatDateRate(date) {
      return this.$moment(date).format('YYYY-MM-DD')
    },
    diff(date) {
      const day = this.currentPricing.days_rates[this.formatDateRate(date)]
      if (day) {
        return round(day['price'] - day['beyond_pricing'])
      }
      return null
    },
    amountOfUnitsText(date) {
      const day = this.currentPricing.days_rates[this.formatDateRate(date)]
      if (day && day['available_count']) {
        return `${day['available_count']}`
      } else {
        return null
      }
    },
    minNights(date) {
      const day = this.currentPricing.days_rates[this.formatDateRate(date)]

      return day && day.min_days
    },
    statusSuccess(date) {
      return (
        this.currentPricing.days_rates[this.formatDateRate(date)] &&
        this.currentPricing.days_rates[this.formatDateRate(date)].status ===
          'success'
      )
    },
    getPrice(date) {
      return round(
        getOr(
          '',
          'price',
          this.currentPricing.days_rates[this.formatDateRate(date)]
        )
      )
    },
    updateRates(listings, from) {
      const existingRate = this.currentPricing.days_rates[from]
      const newRate = listings[0].days_rates[from]
      existingRate.price = newRate.price
      existingRate.status = newRate.status
      existingRate.manual_override = newRate.manual_override
      existingRate.note = newRate.note
      existingRate.min_days = newRate.minNights
    },
    onShowBlockModal(date) {
      const from = this.formatDateRate(date)
      if (!from) return
      const to = this.$moment(date).clone().add(1, 'days').format('YYYY-MM-DD')
      const dayRate = this.currentPricing.days_rates[from]

      this.$store.commit('showModal', {
        name: 'CalendarBlockModal',
        props: {
          from,
          to,
          note: '',
          listingId: this.currentPricing.listing_id,
          minNights: dayRate.min_days,
          isMultiUnit: dayRate.isMultiUnit,
          rates: this.currentPricing.days_rates,
          status:
            dayRate.status === 'unavailable' ? 'unavailable' : 'available',
          onChange: listings => this.updateRates(listings, from),
        },
        isPersistent: false,
      })
    },
  },
  data() {
    return {
      tooltip: false,
    }
  },
}
</script>
<style lang="scss" scoped>
:deep() .day-cell {
  background: white;
}
:deep() .fc-daygrid-day-events {
  min-height: 0 !important;
}
.pricing-tag {
  text-align: center;
}
.py-05 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.nights-location {
  position: absolute;
  top: 0;
  left: 5%;
}
:deep() .fc .fc-daygrid-day.fc-day-today {
  background-color: white !important;
}
.fc-day-today .date-indicator {
  background: var(--v-info-base) !important;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  color: white !important;
  padding: 2px 3px;
  font-size: 9px !important;
  font-weight: 700 !important;
  margin-right: -3px;
}

:deep() .override-background {
  background-color: #ffccbc3d !important;
}
:deep() .day-cell .indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
:deep() .price-very-high .indicator {
  background: #ff4c4c;
}

:deep() .price-high .indicator {
  background: #ff8c4c;
}

:deep() .price-mid .indicator {
  background: #7ebac0;
}

:deep() .price-low .indicator {
  background: #ffd24c;
}
:deep() .blocked-cal .indicator {
  background: #808080;
}
:deep() .day-success {
  background: #f7f7fd;
}
</style>
º
