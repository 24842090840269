<template>
  <div class="grey lighten-4">
    <v-progress-linear v-show="loadingGuestExpInfo" indeterminate />
    <conversation-drawer @update-conversations="updateConversations" />
    <v-tabs
      v-if="stats"
      v-model="tab"
      grow
      center-active
      slider-color="grey"
      :class="{ 'mt-0 mt-sm-2': true, 'hide-arrows': isMobile }"
      background-color="teal lighten-5"
      @change="tabChange"
    >
      <dashboard-tab
        href="#unanswered"
        label="unanswered"
        :missed-call="missedCall"
        :value="stats.unanswered_count"
      />
      <dashboard-tab
        v-if="showModule('ai')"
        href="#ai-escalations"
        :label="isMobile ? 'AI' : 'AI Escalations'"
        :value="stats.ai_escalations_count"
      />
      <dashboard-tab
        v-if="showModule('ai')"
        href="#ai-answered"
        :label="isMobile ? 'AI Active' : 'AI Active (12h)'"
        :value="stats.ai_answered_count"
      />
      <dashboard-tab
        href="#active_leads"
        label="Active"
        :value="stats.active_leads_count"
      />
      <dashboard-tab
        href="#follow_ups"
        label="Follow ups"
        :value="stats.follow_ups_count"
      />
      <dashboard-tab href="#all" label="All" />
    </v-tabs>
    <div>
      <div>
        <v-expansion-panels v-if="isMobile" accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>Filters</v-expansion-panel-header>
            <v-expansion-panel-content class="px-3">
              <conversation-filters
                :filters="filters"
                @filter-change="updateFilter"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <conversation-filters
          v-else
          :filters="filters"
          @filter-change="updateFilter"
        />
      </div>
      <div v-if="isMobile && conversations">
        <v-divider />
        <conversations-mobile
          :items="conversations"
          :pagination="pagination"
          @paginate="paginate"
        />
      </div>
      <conversations-table
        v-else
        :headers="headers"
        :items="conversations"
        :options="pagination"
        @update-conversations="refreshAll"
        @row-click="rowClick"
        @paginate="paginate"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import dashboardTab from 'components/common/dashboard-tab.vue'
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'
import ConversationsTable from 'components/crm/conversations-table.vue'
import ConversationFilters from './conversation-filters.vue'
import ConversationDrawer from './conversation-drawer.vue'
import { isEmpty } from 'lodash/fp'
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'
import ConversationsMobile from 'components/crm/conversations-mobile.vue'
import CrmMixin from 'components/mixins/crm-mixin'

export default {
  components: {
    dashboardTab,
    ConversationsTable,
    ConversationFilters,
    ConversationDrawer,
    ConversationsMobile,
  },
  mixins: [
    formattersMixin,
    colorsMixin,
    CommonFunctions,
    DeviceMixin,
    CrmMixin,
  ],
  computed: {
    ...mapState(['loadingGuestExpInfo']),
    missedCall() {
      return this.conversations.some(
        conversation => conversation.extra_data.missed_call
      )
    },
  },
  data() {
    return {
      crmMode: 'all',
      conversations: null,
      tab: this.$route.query.conversation ? 'all' : 'unanswered',
      stats: null,
      pagination: { page: 1 },
      filters: {},
      headers: [
        {
          text: '',
          value: 'reservation_status',
          sortable: false,
        },
        {
          text: 'Guest',
          value: 'guest_full_name',
          sortable: false,
        },
        {
          text: 'Dates',
          value: 'extra_data.reservation_check_in',
          sortable: false,
        },
        {
          text: 'Property',
          value: 'extra_data.listing_nickname',
          sortable: false,
        },
        {
          text: 'Price',
          value: 'reservation_price',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Last Msg',
          value: 'last_message_from_guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Assignee',
          value: 'assigned_user_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Source',
          value: 'extra_data.reservation_source',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      items: [{}],
    }
  },
  methods: {
    ...mapActions(['getAllInfo']),
    async getAll(filters = {}) {
      const { data } = await this.getAllInfo({ ...filters })
      this.stats = data.stats

      if (filters.stats_only) return
      this.conversations = data.conversations
      this.pagination = {
        page: +data.pagination.page,
        itemsPerPage: data.pagination.per_page,
        totalItems: data.pagination.conversations_count,
      }
    },
    tabChange(tab) {
      this.filters = {}
      this.getAll({ [tab]: true })
    },
    updateFilter(field, val) {
      this.filters = { ...this.filters, [field]: val }
      this.getAll({ [this.tab]: true, ...this.filters })
    },
    paginate(page) {
      if (page === this.pagination.page) return
      this.getAll({ [this.tab]: true, ...this.filters, page })
    },
    refreshAll(options = {}) {
      this.getAll({
        [this.tab]: true,
        ...this.filters,
        page: this.pagination.page,
        ...options,
      })
    },
    updateConversations() {
      this.getAll({ [this.tab]: true, ...this.filters, page: 1 })
    },
    rowClick({ guesty_id }) {
      this.$router.push({ query: { conversation: guesty_id } })
    },
  },
}
</script>
<style scoped>
::v-deep .v-data-footer {
  justify-content: end;
}
::v-deep .v-tabs-bar {
  height: 100%;
}
::v-deep .hide-arrows .v-slide-group__prev {
  display: none !important;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
