import formatterMixin from 'components/mixins/formatters-mixin'

export const CARDS = {
  purchase_price: {
    label: 'Purchase Price',
    icon: '$purchase_price',
    color: 'orange',
  },
  setup_costs: {
    label: 'Initial Investment',
    icon: '$growth',
    color: 'blue',
  },
  initial_investment_cost: {
    label: 'Total Equity',
    icon: '$equity',
    color: 'green',
  },
  cost_base: {
    label: 'Cost Base',
    icon: '$equity',
    color: 'red lighten-1',
  },
  cash_on_cash: {
    label: 'Cash On Cash',
    color: '#EAF3F5',
    textColor: '#445e64',
    icon: 'fa-dollar-sign',
    isPercent: true,
    iconColor: 'indigo lighten-1',
  },
  net_operating_income_before: {
    label: 'NOI',
    color: '#EAF3F5',
    textColor: '#445e64',
    icon: 'fa-chart-line',
    iconColor: 'indigo lighten-1',
  },
  net_operating_income_after: {
    label: 'NOI - After Debt',
    color: '#EAF3F5',
    textColor: '#445e64',
    icon: '$investment',
  },
  debt_service: {
    label: 'Debt Service',
    color: '#ffeed0',
    textColor: '#746039',
  },
  income: {
    label: 'Income',
    color: '#EAF5ED',
    textColor: '#396646',
  },
  expenses: {
    label: 'Expenses',
    color: '#FEF0EC',
    textColor: '#603128',
  },
  adr: {
    label: 'ADR',
    color: '#f9f7c8',
    textColor: '#747013',
    icon: 'fa-calendar-alt',
    iconColor: 'lime',
  },
  cap_rate: {
    label: 'Cap Rate',
    color: '#e3f6fa',
    textColor: '#48747c',
    icon: 'fa-building',
    iconColor: '#48747c',
    isPercent: true,
  },
  occupancy_percent: {
    label: 'Occupancy',
    color: '#F2EBF7',
    icon: 'fa-users',
    iconColor: 'purple',
    textColor: '#624774',
    isPercent: true,
  },
  yearly_debt_service: {
    label: 'Debt Service',
    color: '#ffeed0',
    textColor: '#746039',
    icon: 'fa-home',
    iconColor: 'orange',
  },
  projected_cash_on_cash: {
    label: 'Cash On Cash',
    color: '#EAF3F5',
    icon: 'fa-dollar-sign',
    textColor: '#445e64',
    isPercent: true,
    iconColor: 'indigo lighten-1',
  },
  projected_expenses: {
    label: 'Expenses',
    color: '#FEF0EC',
    icon: 'fa-wallet',
    iconColor: 'red',
  },
  projected_income: {
    label: 'Income',
    color: '#EAF5ED',
    icon: 'fa-money-bill',
    iconColor: 'green',
  },
  projected_net_operating_income_before: {
    label: 'NOI - Before',
    color: '#EAF3F5',
    textColor: '#445e64',
    icon: 'fa-chart-line',
    iconColor: 'indigo lighten-1',
  },
  projected_net_operating_income_after: {
    label: 'NOI - After',
    color: '#EAF3F5',
    textColor: '#445e64',
    icon: '$investment',
  },
}
const d = formatterMixin.methods.toMoney
export const TOOLTIPS = {
  expenses: v =>
    `Operational Costs (${d(
      v.expenses - v.relative_property_tax
    )}) + Relative Property Tax (${d(v.relative_property_tax)})`,
  initial_investment_cost: v =>
    `Setup Costs (${d(v.setup_costs)}) + Down Payment (${d(
      v.down_payment_amount
    )})`,
  cash_on_cash: v =>
    `NOI - After (${d(v.net_operating_income_after)}) / Total equity (${d(
      v.initial_investment_cost
    )})`,
  cost_base: v =>
    `Purchase price (${d(v.purchase_price)}) + Initial Renovation (${d(
      v.setup_costs
    )})`,
  net_operating_income_before: v =>
    `Income (${d(v.income)}) - Expenses (${d(v.expenses)})`,
  net_operating_income_after: v =>
    `Income (${d(v.income)}) - Expenses (${d(v.expenses)}) - Debt Service (${d(
      v.debt_service
    )})`,
  adr: v =>
    `Accommodation fare (${d(v.fare_accommodation)}) / Days Occupied (${
      v.occupancy_count
    })`,
  cap_rate: v =>
    `NOI (${d(v.net_operating_income_before)}) - Property Tax (${d(
      v.relative_property_tax
    )}) - Insurance (${d(v.relative_insurance)}) / Cost Base (${d(
      v.cost_base
    )})`,
  projected_cash_on_cash: v =>
    `Projected NOI - After (${d(
      v.projected_net_operating_income_after
    )}) / Total equity (${d(v.initial_investment_cost)})`,
  projected_income: v =>
    `Confirmed Income (${d(v.confirmed_income)}) + Projected Income (${d(
      v.projected_unconfirmed
    )})`,
  projected_net_operating_income_before: v =>
    `Projected Income (${d(v.projected_income)}) -  Projected Expenses (${d(
      v.projected_expenses
    )})`,
  projected_net_operating_income_after: v =>
    `Projected Income (${d(v.projected_income)}) - Projected Expenses (${d(
      v.projected_expenses
    )}) - Debt Service (${d(v.yearly_debt_service)})`,
}
