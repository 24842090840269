import get from 'lodash/fp/get'
import moment from 'moment-timezone'
import formattersMixin from 'components/mixins/formatters-mixin'
import sumBy from 'lodash/fp/sumBy'
import orderBy from 'lodash/orderBy'

const date = date => moment(date).utc().format('YYYY-MM-DD')
const datetime = date => moment.unix(date).utc().format('YYYY-MM-DD')
const toMoney = formattersMixin.methods.toMoney

export const summaryValues = item => {
  const values = [
    {
      label: 'Price',
      value: toMoney(item.price, { currency: item.currency }),
    },
    {
      label: 'Guests',
      value: item.guests_count,
    },
    {
      label: 'Nights',
      value: item.nights_count,
    },
    {
      label: 'Channel',
      value: item.source,
    },
    {
      label: 'PreCheck In',
      value:
        get('pre_check_in.status', item) === 'Done' ? 'Filled' : 'Not Filled',
    },
  ]

  if (item.created_by_id)
    values.push({ label: 'Created By', value: item.created_by_id })

  return values
}

export const guestValues = item => {
  return [
    {
      label: 'Guest Name',
      value: get('guest.full_name', item),
    },
    {
      label: 'Phone',
      value: item.guest.phones.join(', ') || '-',
    },
    {
      label: 'Email',
      value: item.guest.emails.join(', ') || '-',
    },
    {
      label: 'Receives Marketing',
      value: item.guest.marketing_enabled ? 'Yes' : 'No',
    },
  ]
}

export const preCheckInValues = item => {
  return [
    {
      label: 'Email',
      value: get('pre_check_in.client_info.email', item),
    },
    {
      label: 'Phone',
      value: get('pre_check_in.client_info.phone_number', item),
    },
    {
      label: 'Requests Parkings',
      value: get('pre_check_in.client_info.need_parking', item) ? 'Yes' : 'No',
    },
    {
      label: 'Cars',
      value: get('pre_check_in.client_info.car_amount', item),
    },
    {
      label: 'Adults',
      value: get('pre_check_in.guest_count_info.adults', item) || '-',
    },
    {
      label: 'Children',
      value: get('pre_check_in.guest_count_info.kids', item) || '-',
    },
    {
      label: 'Babies',
      value: get('pre_check_in.guest_count_info.babies', item) || '-',
    },
    {
      label: 'Guests',
      value:
        (get('pre_check_in.guest_count_info.guests', item) || []).join(', ') ||
        '-',
    },
    {
      label: 'Special Requests',
      value:
        (Object.keys(item.special_requests) || [])
          .filter(k => item.special_requests[k])
          .join(', ') || '-',
    },
  ]
}

export const paymentIntentsHeaders = [
  { text: 'Payment Intents', value: 'type' },
  { text: 'Amount', value: 'amount' },
  { text: 'Status', value: 'status' },
  { text: 'Paid at', value: 'paid_at' },
  { text: '', value: 'actions' },
]
export const paymentItemsHeaders = [
  { text: 'Payment Items', value: 'type' },
  { text: '', value: 'amount' },
  { text: '', value: 'paid_at' },
  { text: '', value: 'attachments' },
  { text: '', value: 'status' },
]
export const invoiceHeaders = [
  { text: 'Invoices', value: 'type' },
  { text: '', value: 'amount' },
  { text: '', value: 'actions' },
]

export const paymentIntents = item => {
  return item.payment_intents.map(intent => {
    const chargesData = intent.charges?.data?.[0] ?? {}
    const { amount_captured = 0, amount_refunded = 0 } = chargesData

    return {
      id: intent.id,
      type: intent.metadata.type,
      amount: intent.amount / 100,
      status: intent.status,
      client_secret: intent.client_secret,
      payment_intent_id: intent.payment_intent_id,
      amount_received: intent.amount_received / 100,
      paid_at: intent.created ? datetime(intent.created) : '-',
      charges: intent.charges?.data?.flatMap(charge => [
        charge,
        ...charge.refunds.data,
      ]),
      isRefundable: amount_refunded < amount_captured,
    }
  })
}

export const paymentItems = item => {
  return item.payment_items.map(payment => ({
    type: 'Payment',
    amount: toMoney(payment.amount, { currency: item.currency }),
    paid_at: date(payment.paid_at || item.confirmed_at),
    note: payment.note,
    attachments: (payment.attachments || []).map(attachment => ({
      url: attachment.url || attachment,
      filename: attachment.filename || attachment.split('/').pop(),
    })),
    status: payment.status,
  }))
}

export const invoiceItems = item => {
  if (!item) return []
  const invoiceItems = (item.invoice_items || []).filter(a => a.amount !== 0)
  const additionalInvoiceItems = (item.additional_invoice_items || []).filter(
    a => a.amount !== 0
  )
  const fees_items = item.fees_items.concat(additionalInvoiceItems)
  const adjusted_items = fees_items.filter(
    fee => fee.type === 'ACCOMMODATION_FARE_ADJUSTMENT'
  )

  if (adjusted_items.length) {
    const total = sumBy('amount')(adjusted_items)
    adjusted_items.push({
      title: 'Base Price',
      type: 'ACCOMMODATION_FARE_ADJUSTMENT',
      base_type: 'ACCOMMODATION',
      tax_relation: 'owner',
      amount: item.fare_accommodation - total,
    })
  }
  let all_adjusted_items = orderBy(adjusted_items, ['amount'], ['desc'])

  all_adjusted_items = all_adjusted_items.map(fee => ({
    ...fee,
    title: `${
      fee.title.toLowerCase().includes('fee') ||
      fee.additional ||
      fee.base_type === 'ACCOMMODATION'
        ? fee.title
        : `${fee.title} Fee`
    }`,
    amount: toMoney(fee.amount, { currency: item.currency }),
  }))

  const additionalPmcItems = additionalInvoiceItems.filter(
    a => a.additional && a.type !== 'ACCOMMODATION_FARE_ADJUSTMENT'
  )
  const items = invoiceItems.concat(additionalPmcItems).map(invoice => ({
    title:
      !invoice.additional &&
      (invoice.type === 'TAX' || invoice.base_type === 'TAX') &&
      !invoice.title.toLowerCase().includes('tax')
        ? `${invoice.title} Tax`
        : invoice.title,
    type: invoice.type,
    inclusive: invoice.vat_inclusive,
    adjusted_items:
      invoice.type === 'ACCOMMODATION_FARE' ? all_adjusted_items : [],
    amount: toMoney(invoice.amount, { currency: item.currency }),
    additional: invoice?.additional,
  }))
  return items
}

export const financeItems = item => {
  const items = [
    {
      title: 'Price',
      amount: toMoney(item.price, { currency: item.currency }),
    },
    {
      title: 'Fare accommodation',
      amount: toMoney(item.fare_accommodation, { currency: item.currency }),
    },
    {
      title: 'Channel fee',
      amount: toMoney(item.host_channel_fee, { currency: item.currency }),
    },
    {
      title: 'Cleaning fee',
      amount: toMoney(item.cleaning_fee, { currency: item.currency }),
    },
    {
      title: 'Net income',
      amount: toMoney(item.net_income, { currency: item.currency }),
    },
    {
      title: 'Owner income',
      amount: toMoney(item.client_profit, { currency: item.currency }),
    },
    {
      title: 'Management fee ',
      amount: toMoney(item.management_commission, { currency: item.currency }),
    },
    {
      title: 'Processing fee ',
      amount: toMoney(item.processing_fee, { currency: item.currency }),
    },
    {
      title: 'Total pmc fees',
      amount: toMoney(item.company_fees, { currency: item.currency }),
    },
    {
      title: 'Total taxes',
      amount: toMoney(item.total_taxes, { currency: item.currency }),
    },
  ]
  return items
}

export const customVariables = item => {
  return Object.entries(item.custom_fields)
    .filter(f => !isNaN([1]))
    .map(f => ({
      title: f[0],
      amount: toMoney(f[1], { currency: item.currency }),
    }))
}
