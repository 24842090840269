<template>
  <v-row>
    <v-col cols="12" sm="6" class="d-flex align-center">
      <v-btn color="primary" class="mr-4" @click="downloadTemplate">
        {{ $t('Download Template') }}
      </v-btn>

      <v-file-input
        v-model="ratesFile"
        :label="$t('Upload Rates Excel')"
        accept=".csv, .xlsx"
        :loading="uploading"
        hide-details
        @change="handleFileUpload"
      />
    </v-col>

    <v-col v-if="importErrors.length" cols="12">
      <p class="red--text">{{ $t('Import Errors:') }}</p>
      <ul>
        <li v-for="(error, i) in importErrors" :key="i" class="red--text">
          {{ error }}
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import customAxios from '@/axios/config'

export default {
  name: 'RateImports',

  data() {
    return {
      ratesFile: null,
      uploading: false,
      importErrors: [],
      headers: ['Nickname', 'Id', 'From', 'To', 'Price', 'Min nights'],
    }
  },

  methods: {
    downloadTemplate() {
      const csvContent = this.headers.join(',')

      const blob = new Blob([csvContent], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', 'rates_import_template.csv')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },

    async handleFileUpload() {
      if (!this.ratesFile) return

      this.uploading = true
      this.importErrors = []

      try {
        const formData = new FormData()
        formData.append('file', this.ratesFile)

        const response = await customAxios.post(
          '/api/pricing-setting/rate-imports',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )

        if (response.data.errors) {
          this.importErrors = response.data.errors
        } else {
          this.$emit('import-complete')
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.uploading = false
        this.ratesFile = null
      }
    },
  },
}
</script>
